function objectSamplesFormat(selectedCategory, objectSamples, reduce = false) {
  const reduceArr = (arr) =>
    arr?.reduce((acc, curr) => {
      acc[curr.name] = curr?.data || [];
      return acc;
    }, {});
  let formattedObjectSamples = [];
  if (selectedCategory?.categoryName === "Services") {
    const takeOffSample = objectSamples?.find((el) => el.name === "Estimations")
      ?.data[0]?.versionServices;
    const servicesSamples = Object.values(takeOffSample)
      ?.map((item) => item.services)[0]
      ?.map((el) => {
        const newElevations = el?.serviceOptions?.[0] || [];
        if (!!el?.serviceOptions) {
          return {
            name: el?.label,
            data: [{ ...el, serviceOptions: newElevations }],
          };
        } else {
          return { name: el?.label, data: [el] };
        }
      });

    formattedObjectSamples = servicesSamples;
  } else {
    formattedObjectSamples = objectSamples;
  }
  return reduce ? reduceArr(formattedObjectSamples) : formattedObjectSamples;
}

export default objectSamplesFormat;
