import getDateRange from "./getDateRange";
import { getPayrollHr } from "../../../FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

/**
 * @typedef params
 * @property {Array} crews List of employees
 * @property {Array} jobsites List of jobsites that employees work on
 * @property {dayjs.Dayjs[]} dateRange Range of 2 dates that set the range of data call
 * @property {{clientName: string; configId: string; clientKey: {iv: string; encryptedData: string}}} selectedClient Subcontractor
 * @property {Component} progressRef Reference of the progress component
 *
 * @param {params} params
 * @returns Array with employee payroll actions.
 */
async function matchHrFingerCheckData({
  crews,
  crewTeams,
  dateRange,
  selectedClient,
  progressRef,
}) {
  progressRef.setPercentage(0);
  const accountName = selectedClient.clientName;
  let result = [];
  let stopFunction = false;
  const allDaysIncluded = getDateRange(dateRange[0], dateRange[1]);
  const estimatedTime = allDaysIncluded.length * 0.5;
  const cancelButton = document.getElementById("cancelButton");
  const company = `${accountName || ""}`;
  let arrayOfTimeouts = [];

  function stopFunc() {
    stopFunction = true;
  }

  cancelButton?.addEventListener("click", stopFunc);

  for (let i = 0; i < allDaysIncluded.length; i++) {
    const percentage = ((0.5 * i) / estimatedTime) * 100;
    const addProgress = setTimeout(
      () => progressRef.setPercentage(percentage),
      500 * i + 1
    );
    arrayOfTimeouts.push(addProgress);
    if (stopFunction) {
      break;
    }
  }

  const cardRawData = await getPayrollHr({
    dateRange,
    clientKey: selectedClient?.clientKey,
  }).then((res) => res.data);

  let sortedCardRawData = [];
  const groupedCardRawData = _.groupBy(
    cardRawData.map((el) => ({
      ...el,
      punchTimeStamp: parseInTz(el?.punchTime).valueOf(),
    })),
    ({ EmployeeNumber }) => `${accountName}-${EmployeeNumber}`
  );

  for (const employeeId in groupedCardRawData) {
    const employeeData = groupedCardRawData[employeeId].sort(
      (a, b) => a.punchTimeStamp - b.punchTimeStamp
    );
    sortedCardRawData = sortedCardRawData.concat(employeeData);
  }

  for (let i = 0; i < sortedCardRawData?.length; i++) {
    if (stopFunction) {
      break;
    }
    const entry = sortedCardRawData[i];
    const employeeId = `${accountName}-${entry?.employeeId}`;

    const punchTime = entry?.punchTime && parseInTz(entry?.punchTime);
    const punchDate = entry?.punchDate && parseInTz(entry?.punchDate);

    const employeeMatch = crews.find(
      (emp) =>
        emp?.employeeId === employeeId ||
        emp?.employeeId === `${accountName}-${entry?.employeeId}`
    );

    const teamMatch = crewTeams.find((team) => {
      if (employeeMatch?.foreman === true) {
        return team?.crewForeman?.crewId === employeeMatch?.crewId;
      } else {
        return (
          (team?.crewMembers || []).findIndex(
            (member) => member?.crewId === employeeMatch?.crewId
          ) > -1
        );
      }
    });

    const newEntry = {
      ...entry,
      company,
      punchDate,
      punchTime,
      payrollType: "",
      companyName: company,
      reason: entry?.reason,
      activityStatus: "Completed",
      crewId: employeeMatch?.crewId,
      employeeNumber: entry?.employeeId,
      crewTeamId: teamMatch?.crewTeamId,
      punchTimeStamp: punchTime?.valueOf(),
      crewTeamName: teamMatch?.crewTeamName,
      employeeId: employeeMatch?.employeeId,
      uploadName: "Imported from FingerCheck",
      employeeRole: employeeMatch?.crewPosition,
      salaryType: employeeMatch?.salaryType || "Hourly",
      employeeRate: parseFloat(employeeMatch?.employeeRate || 0),
      employeeFullName: employeeMatch?.crewName || entry?.employeeFullName,
      employeeType: {
        jobsiteMatch: {
          jobName: "",
          payrollType: "",
        },
        role: employeeMatch?.crewPosition,
      },
      jobsiteId: "",
      jobsiteMatch: {
        jobName: "",
        services: [],
        jobsiteId: "",
        jobAddress: "",
        googleSheetLink: "",
        reimbursement: false,
      },
    };

    result.push(newEntry);
  }
  for (const timeout of arrayOfTimeouts) {
    clearTimeout(timeout);
  }
  if (stopFunction) {
    progressRef.setPercentage(0);
    return "Stopped";
  } else {
    progressRef.setPercentage(100);
    return result;
  }
}

export default matchHrFingerCheckData;
