import { HoistInfoHeader } from "./HoistInfoHeader";
import { JumpsButtons } from "./JumpsButtons";
import { JumpsConfigModal2 } from "./JumpsConfig/JumpsConfigModal2";
import "./JumpProgressModal.scss";

export const HoistElements = ({
  elevation,
  scheduleDays,
  serviceId,
  elevationIndex,
  setHoistPLis,
  typeOfWork,
  editMode,
  typeOfRemoval,
  serviceDefinitions,
  hoistDriveIds,
  updateProgressLogs,
  estimationId,
  elevationId,
  estimation,
  serviceName,
  elevationStatus,
  updateBreakdownLogs,
}) => {
  return (
    <div>
      <div
        className="hoist-elements"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.4rem",
          height: 40,
        }}
      >
        <HoistInfoHeader {...{ elevation }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <JumpsConfigModal2
            {...{
              elevation,
              serviceId,
              elevationIndex,
              setHoistPLis,
              typeOfWork,
              isFrom: "scheduling",
            }}
          />
        </div>
      </div>
      {elevation?.jumps?.length > 0 && (
        <JumpsButtons
          {...{
            elevation,
            scheduleDays,
            serviceId,
            elevationIndex,
            setHoistPLis,
            typeOfWork,
            editMode,
            typeOfRemoval,
            serviceDefinitions,
            hoistDriveIds,
            updateProgressLogs,
            estimationId,
            elevationId,
            estimation,
            serviceName,
            elevationStatus,
            serviceId,
            updateBreakdownLogs,
          }}
        />
      )}
    </div>
  );
};
