import {
  FetchDataIcon,
  JobApplicationIcon,
  IssuedPermitsIcon,
  ApprovedPermitsIcon,
  ElectricalPermitsIcon,
} from "../Projects/DobTab/assets";
import { apiRoutes, range } from "../Fleet/utils";
import { sortBy, reverse, slice } from "lodash";
import { lazyFetch } from "../../../utils";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export const initialFilterValues = {
  fetch: [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: [
        {
          id: uuidv4(),
          operator: "AND",
          column: "",
          value: "",
          formula: "is one of",
        },
      ],
    },
  ],
  "job application filing": [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: [
        {
          id: uuidv4(),
          operator: "AND",
          column: "",
          value: "",
          formula: "is one of",
        },
      ],
    },
  ],
  "issued permits": [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: [
        {
          id: uuidv4(),
          operator: "AND",
          column: "",
          value: "",
          formula: "is one of",
        },
      ],
    },
  ],
  "approved permits": [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: [
        {
          id: uuidv4(),
          operator: "AND",
          column: "",
          value: "",
          formula: "is one of",
        },
      ],
    },
  ],
  "electrical permits": [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: [
        {
          id: uuidv4(),
          operator: "AND",
          column: "",
          value: "",
          formula: "is one of",
        },
      ],
    },
  ],
};

export const initialDaysFetchHandler = {
  "job application filing": {
    day: undefined,
    range: undefined,
    time: undefined,
  },
  "issued permits": {
    day: undefined,
    range: undefined,
    time: undefined,
  },
  "approved permits": {
    day: undefined,
    range: undefined,
    time: undefined,
  },
  "electrical permits": {
    day: undefined,
    range: undefined,
    time: undefined,
  },
};

export let unChangedRowData = {};
export let unChangedSplittedRowData = {};
export let leadCompanyNames = [];

export const initialDOBLeadQuery =
  "https://data.cityofnewyork.us/resource/ipu4-2q9a.json?$query=SELECT * WHERE";
export const initialApprovedPermitQuery =
  "https://data.cityofnewyork.us/resource/rbx6-tga4.json?$query=SELECT * WHERE";
export const initialElectricPermitQuery =
  "https://data.cityofnewyork.us/resource/dm9a-ab7w.json?$query=SELECT * WHERE";

//thunction that makes fetch on dob,approved,issued and electrical api
export const fetchDobData = async ({
  setLoading,
  setDobData,
  setUnslicedData,
  limit = 10000,
}) => {
  //here we generate query for to fetch data for the last 90 days  ex.(currDay-1day,currDay-2day,currDay-3day... to currDay-90day) job application
  const queryJobApplication = `https://data.cityofnewyork.us/resource/w9ak-ipjd.json?$select=*&$where=filing_date between '${dayjs()
    ?.startOf("day")
    ?.subtract(3, "month")
    ?.format("YYYY-MM-DDTH:mm:ss.SSS")}' and '${dayjs()
    ?.endOf("day")
    ?.format("YYYY-MM-DDTH:mm:ss.SSS")}'&$limit=${limit + 1}`;
  //here we generate query for to fetch data for the last 90 days  ex.(currDay-1day,currDay-2day,currDay-3day... to currDay-90day) issued permits
  const queryDOBLeads = range(1, 92)?.reduce(
    (acc, curr, _, arr) =>
      (acc += ` dobrundate = '${dayjs()
        ?.subtract(curr, "day")
        ?.format("MM/DD/YYYY")} 00:00:00'${
        curr !== arr.at(-1) ? " OR" : ` LIMIT ${limit + 1}`
      } `),
    initialDOBLeadQuery // ktu do shtojm orderin dhe klimitin
  );
  //here we generate query for to fetch data for the last 90 days  ex.(currDay-1day,currDay-2day,currDay-3day... to currDay-90day) approved permits
  const queryApprovedPermitQuery = range(1, 92)?.reduce(
    (acc, curr, _, arr) =>
      (acc += ` approved_date = '${dayjs()
        ?.subtract(curr, "day")
        ?.format("YYYY-MM-DD")}T00:00:00.000'${
        curr !== arr.at(-1) ? " OR" : ` LIMIT ${limit + 1}`
      } `),
    initialApprovedPermitQuery // ktu do shtojm orderin dhe klimitin
  );
  //here we generate query for to fetch data for the last 90 days  ex.(currDay-1day,currDay-2day,currDay-3day... to currDay-90day) for electric permit
  const queryElectricalPermitQuery = range(1, 92)?.reduce(
    (acc, curr, _, arr) =>
      (acc += ` permit_issued_date = '${dayjs()
        ?.subtract(curr, "day")
        ?.format("YYYY-MM-DD")}T00:00:00.000'${
        curr !== arr.at(-1) ? " OR" : ` LIMIT ${limit + 1}`
      } `),
    initialElectricPermitQuery
  );

  //here we fetch with the generated query
  const [
    { value: dobLeads },
    { value: approvedPermits },
    { value: electricalPermits },
    { value: jobApplication },
    { value: leadsData },
  ] = await Promise.allSettled([
    ...[
      //fetch from  issued permits
      fetch(queryDOBLeads),
      //fetch from approved permits
      fetch(queryApprovedPermitQuery),
      //fetch from electrical permits
      fetch(queryElectricalPermitQuery),
      //fetch from Job Application
      fetch(queryJobApplication),
    ].map(async (promise) => (await promise).json()),

    //here we take the list of company name that we have on our DB
    lazyFetch({
      tableName: apiRoutes.leads,
      listOfKeys: ["leadCompany"],
    }),
  ]);

  leadCompanyNames = leadsData;

  //here me map data that we fetch
  const data = {
    "job application filing": reverse(
      sortBy(
        jobApplication
          ?.filter(({ filing_date }) => !!filing_date)
          ?.map((jobApplicationFiling) => ({
            ...jobApplicationFiling,
            applicant_full_name: `${jobApplicationFiling?.applicant_first_name} ${jobApplicationFiling?.applicant_last_name}`,
            filing_date: dayjs(jobApplicationFiling?.filing_date).valueOf(),
            current_status_date: dayjs(
              jobApplicationFiling?.current_status_date
            ).valueOf(),
            filing_representative_full_name: `${jobApplicationFiling?.filing_representative_first_name} ${jobApplicationFiling?.filing_representative_last_name}`,
            filing_representative_full_address: `${jobApplicationFiling?.filing_representative_street_name}, ${jobApplicationFiling?.filing_representative_city} ${jobApplicationFiling?.filing_representative_zip}`,
            initial_cost: +jobApplicationFiling?.initial_cost,
            Coordinates: `Lat: ${jobApplicationFiling?.latitude} Lng: ${jobApplicationFiling?.longitude}`,
            jobsite_full_address: `${jobApplicationFiling?.house_no} ${jobApplicationFiling?.street_name}, ${jobApplicationFiling?.borough}, NY ${jobApplicationFiling?.zip}`,
            owners_billing_address: `${jobApplicationFiling?.owner_s_street_name} ${jobApplicationFiling?.city},${jobApplicationFiling?.state} ${jobApplicationFiling?.zip}`,
          })),
        ["filing_date"]
      )
    ),

    "issued permits": reverse(
      sortBy(
        dobLeads?.map((issuedPermits) => ({
          ...issuedPermits,
          dobrundate: dayjs(issuedPermits?.dobrundate)?.valueOf(),
          filing_date: dayjs(issuedPermits?.filing_date)?.valueOf(),
          issuance_date: dayjs(issuedPermits?.issuance_date)?.valueOf(),
          expiration_date: dayjs(issuedPermits?.expiration_date)?.valueOf(),
          job_start_date: dayjs(issuedPermits?.job_start_date)?.valueOf(),
          jobsite_full_address: `${issuedPermits?.house__} ${issuedPermits?.street_name}, ${issuedPermits?.borough}, NY`,
          owners_full_name: `${issuedPermits?.owner_s_first_name} ${issuedPermits?.owner_s_last_name}`,
          full_coordinates: `Lat: ${issuedPermits?.gis_latitude} Lng: ${issuedPermits?.gis_longitude}`,
          permit_fullName: `${issuedPermits?.permittee_s_first_name} ${issuedPermits?.permittee_s_last_name}`,
        })),
        ["dobrundate"]
      )
    ),
    "approved permits": reverse(
      sortBy(
        approvedPermits?.map((approvedPermit) => ({
          ...approvedPermit,
          issued_date: dayjs(approvedPermit?.issued_date)?.valueOf(),
          approved_date: dayjs(approvedPermit?.approved_date)?.valueOf(),
          expired_date: dayjs(approvedPermit?.expired_date)?.valueOf(),
          owners_full_name: approvedPermit?.owner_name,
          jobsite_full_address: `${approvedPermit?.house_no} ${approvedPermit?.street_name}, ${approvedPermit?.borough}, NY`,
          applicant_fullName: `${approvedPermit?.applicant_first_name} ${approvedPermit?.applicant_last_name}`,
          filing_representative_fullName: `${approvedPermit?.filing_representative_first_name} ${approvedPermit?.filing_representative_last_name}`,
        })),
        ["approved_date"]
      )
    ),
    "electrical permits": reverse(
      sortBy(
        electricalPermits?.map((electricalPermits) => ({
          ...electricalPermits,
          permit_issued_date: dayjs(
            electricalPermits?.permit_issued_date
          )?.valueOf(),
          filing_date: dayjs(electricalPermits?.filing_date)?.valueOf(),
          completion_date: dayjs(electricalPermits?.completion_date)?.valueOf(),
          disability_expiration_date: dayjs(
            electricalPermits?.disability_expiration_date
          )?.valueOf(),
          job_start_date: dayjs(electricalPermits?.job_start_date)?.valueOf(),
          worker_comp_expiration_date: dayjs(
            electricalPermits?.worker_comp_expiration_date
          )?.valueOf(),
          general_liability_expiration: dayjs(
            electricalPermits?.general_liability_expiration
          )?.valueOf(),
          jobsite_full_address: `${electricalPermits?.house_number} ${electricalPermits?.street_name}, ${electricalPermits?.borough}, NY ${electricalPermits?.zip_code}`,
          owners_full_name: `${electricalPermits?.owner_first_name} ${electricalPermits?.owner_last_name}`,
          full_coordinates: `Lat: ${electricalPermits?.gis_latitude} Lng: ${electricalPermits?.gis_longitude}`,
          applicant_fullName: `${electricalPermits?.applicant_first_name} ${electricalPermits?.applicant_last_name}`,
        })),
        ["permit_issued_date"]
      )
    ),
  };

  //// this saves unchanged data
  unChangedRowData = {
    "job application filing": data["job application filing"],
    "issued permits": data["issued permits"],
    "approved permits": data["approved permits"],
    "electrical permits": data["electrical permits"],
  };

  setUnslicedData(data);

  unChangedSplittedRowData = {
    "job application filing": slice(
      data["job application filing"],
      [0],
      [10000]
    ),
    "issued permits": slice(data["issued permits"], [0], [10000]),
    "approved permits": slice(data["approved permits"], [0], [10000]),
    "electrical permits": slice(data["electrical permits"], [0], [10000]),
  };

  //this saves unchanged splited data
  setDobData({
    "job application filing": slice(
      data["job application filing"],
      [0],
      [10000]
    ),
    "issued permits": slice(data["issued permits"], [0], [10000]),
    "approved permits": slice(data["approved permits"], [0], [10000]),
    "electrical permits": slice(data["electrical permits"], [0], [10000]),
  });
  limit !== 10000 && fetchDobData({ setLoading, setDobData, setUnslicedData });
  setLoading(false);
};
//cards on top names
export const tabCategories = [
  "fetch",
  "job application filing",
  "issued permits",
  "approved permits",
  "electrical permits",
];
//card header icons
export const headerIcons = {
  [tabCategories[0]]: FetchDataIcon,
  [tabCategories[1]]: JobApplicationIcon,
  [tabCategories[2]]: IssuedPermitsIcon,
  [tabCategories[3]]: ApprovedPermitsIcon,
  [tabCategories[4]]: ElectricalPermitsIcon,
};
//ag grid default column definition
export const defaultColDef = {
  enableColResize: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  resizable: true,
  flex: 1,
  minWidth: 100,
  filter: true,
};
//this handle show modal
export const showModal = (setIsModalVisible) => {
  setIsModalVisible(true);
};

//this handle show modal
export const showModalFetch = (setIsModalFetchVisible) => {
  setIsModalFetchVisible(true);
};

//this clears filters on the catedory that the user is
export const clearAllFilters = ({
  setFilters,
  setDobData,
  selectedCategory,
  unslicedData,
}) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    [selectedCategory]: [
      {
        id: uuidv4(),
        operator: "AND",
        conditions: [
          {
            id: uuidv4(),
            operator: "AND",
            column: "",
            value: "",
            formula: "is one of",
          },
        ],
      },
    ],
  }));
  setDobData((data) => ({
    ...data,
    [selectedCategory]: slice(unslicedData[selectedCategory], [0], [10000]),
  }));
};

//grid Sidebar options
export const subLeadsGridSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};
