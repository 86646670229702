import { lazyFetch } from "../../../../../utils";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

export const citationValues = async ({
  element = {},
  rowObject = {},
  objectsWithPk,
}) => {
  const { formItemName = "", label = "", type } = element;
  const { citationObject } = rowObject;

  function replaceNbsp(text) {
    return text?.replace(/&nbsp;/g, " ");
  }

  if (element.type === "textEditor") {
    return citationObject
      ? replaceNbsp(citationObject[formItemName])
      : replaceNbsp(rowObject[formItemName]);
  }
  if (element.table) {
    const primaryKey = objectsWithPk.find(
      (obj) => obj.apiName === element.table
    )?.primaryKey;

    try {
      const response = await lazyFetch({
        tableName: element.table,
        listOfKeys: [element.key],
        filterKey: primaryKey,
        filterValue: citationObject
          ? citationObject[primaryKey]
          : rowObject[formItemName],
      });
      return response?.[0]?.[element.key] || "";
    } catch (error) {
      console.error("Error fetching data:", error);
      return "";
    }
  }

  if (type?.includes("date")) {
    return (
      dayjsNY(
        citationObject ? citationObject[formItemName] : rowObject[formItemName]
      )?.format("MM/DD/YYYY") || ""
    );
  }

  return citationObject
    ? citationObject[formItemName]
    : rowObject[formItemName];
};
