import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import React, { useMemo, useState, useRef, useCallback } from "react";
import {
  CloseIconModal,
  CloseIconModalHeader,
  // OpenedEyeIcon,
} from "../../../../../../../../assets";
import {
  JoditEditorComp,
  MondayButton,
} from "../../../../../../../commonComponents";
import bracketsToTextProposal from "./bracketsToTextProposal";
import { SaveIcon } from "../../../../../../../SidebarPages/BasePage/src";
// import TemplateDesigner from "../../NewTemplate/TemplateDesigner/TemplateDesigner";
import { useSettingsProposalContext } from "../../ProposalBuilderSettings/ProposalContext";
import ServiceCategoriesSelectionProposal from "../../NewTemplate/ServiceCategoriesSelectionProposal/ServiceCategoriesSelectionProposal";
import {
  createNewTemplate,
  updateProposalTemplate,
} from "../../../utils/requests";
import SaveNameModal from "../../NewTemplate/SaveNameModal";
import { customConfig } from "../../../../ServicesTemplate/utils/customConfig";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
// import ExistingTemplate from "../../NewTemplate/ExistingTemplate/ExistingTemplate";

import "../../NewTemplate/NewProposalTemplate.scss";

const EditGeneralTemplate = ({
  item,
  visible = false,
  closeModal = () => {},
  type = "generalTemplate",
  callback,
}) => {
  const proposalDesignRef = useRef();
  const { curryDispatch, estimationSample, serviceTemplates } =
    useSettingsProposalContext();

  const [previewVisible, setPreviewVisible] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [nameVisible, setNameVisible] = useState(false);
  const [tempContent, setTempContent] = useState({
    correct: item?.templateContent || "",
    converted: "",
  });

  const [details, setDetails] = useState({
    templateCategory: !!item
      ? item?.templateCategory
      : type === "generalTemplate"
      ? "general"
      : "service",
    otherDetails: {
      services: item?.additionalDetails?.services || [],
    },
    templateContent: item?.templateContent || "",
    service: item?.additionalDetails?.service || null,
  });

  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  //region onSave
  const onPreSave = async (name) => {
    setSaveDisabled(true);
    const newContent = proposalDesignRef.current.component.value;
    const templateBody = {
      isActive: true,
      templateTitle: name,
      createdAt: new Date(),
      templateContent: newContent,
      templateType: "Proposal Settings",
      createdBy: userConfiguration?.nameOfUser,
      templateCategory: details.templateCategory,
      additionalDetails: {
        service: details?.service || null,
        services: details?.otherDetails?.services || [],
      },
    };
    if (!!item) {
      if (callback) {
        callback(newContent);
      } else {
        curryDispatch(
          updateProposalTemplate(item.templateId, {
            templateContent: newContent,
            additionalDetails: {
              service: details?.service || null,
              services: details?.otherDetails?.services || [],
            },
          })
        );
      }
    } else {
      curryDispatch(createNewTemplate(templateBody));
    }
    setSaveDisabled(false);
    closeModal();
  };

  //region onPreview
  const onPreviewClick = useCallback(
    (editor) => {
      if (type !== "generalTemplate" && !details?.service?.workName) {
        return message.info("Please select a service to preview");
      }
      function getConvertedContent() {
        try {
          const converted = bracketsToTextProposal({
            estimationSample,
            templateContent: proposalDesignRef.current.component.value,
            // editorRef: proposalDesignRef,
            item: item
              ? item
              : {
                  additionalDetails: {
                    service: {
                      workName: details?.service?.workName,
                    },
                  },
                },
            serviceToPreview: null,
            type,
            serviceTemplates,
          });
          return converted;
        } catch (e) {
          console.error("error while processing template", e);
          return editor.value;
        }
      }
      setTempContent((prev) => ({
        correct: previewVisible
          ? prev.correct || proposalDesignRef.current.component.value
          : proposalDesignRef.current.component.value,
        converted: previewVisible ? prev.converted : getConvertedContent(),
      }));
      setPreviewVisible(!previewVisible);
    },
    [
      proposalDesignRef.current,
      previewVisible,
      details,
      item,
      type,
      serviceTemplates,
      estimationSample,
      tempContent,
    ]
  );

  //region config
  const editorConfig = useMemo(() => {
    const config = customConfig();
    const newButtons = [
      {
        icon: "eye",
        tooltip: "Preview",
        name: "preview",
        exec: onPreviewClick,
      },
    ];
    config.buttons = [
      ...config.buttons,
      ...["hr", "outdent", "indent", "|"],
      ...newButtons,
    ];
    return config;
  }, [proposalDesignRef.current, onPreviewClick]);

  const modalTitle = useMemo(() => {
    const tempType =
      type === "generalTemplate"
        ? " General Proposal Template"
        : " Service Proposal Template";
    return item
      ? `Edit ${tempType}` + " | " + item?.templateTitle
      : `Create New ${tempType}`;
  }, [type, item]);

  //region editorContent
  const editorContent = useMemo(() => {
    return !previewVisible ? tempContent.correct : tempContent.converted;
  }, [tempContent, details, previewVisible]);

  return (
    <Modal
      open={visible}
      centered
      width="100%"
      maskClosable={false}
      className={
        "new-template-modal " + (isDarkMode ? "new-template-modal-dark" : "")
      }
      title={modalTitle}
      onCancel={closeModal}
      closeIcon={<CloseIconModalHeader fill="#323338" />}
      destroyOnClose
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MondayButton
            onClick={closeModal}
            Icon={<CloseIconModal />}
            className="mondayButtonRed"
          >
            Close
          </MondayButton>
          <MondayButton
            className="mondayButtonBlue"
            disabled={saveDisabled}
            onClick={() => {
              previewVisible && setPreviewVisible(false);
              if (!!item) {
                onPreSave(item?.templateTitle);
              } else {
                setNameVisible(true);
              }
            }}
            Icon={<SaveIcon />}
          >
            Save Changes
          </MondayButton>
        </div>
      }
    >
      <div className="edit_template__header">
        <InfoText
          {...{
            text: "Edit the template content below",
          }}
        />
      </div>
      <div className="designer-proposal">
        <JoditEditorComp
          {...{
            height: "100%",
            content: editorContent,
            // setContent: (val) => {
            //   proposalDesignRef.current.component.value = val;
            // },
            customConfig: editorConfig,
            ref: proposalDesignRef,
          }}
        />
        {/* <TemplateDesigner
        {...{
          proposalDesignRef,
          closeModal: closeModal,
          type,
          customVal: details?.templateContent,
          }}
          /> */}
        <ServiceCategoriesSelectionProposal
          {...{
            type,
            details,
            setDetails,
            disabled: !!item,
            proposalDesignRef,
            onChange: () => {
              setPreviewVisible(false);
            },
          }}
        />{" "}
      </div>
      {nameVisible && (
        <SaveNameModal
          {...{
            onSave: onPreSave,
            visibility: nameVisible,
            setVisible: setNameVisible,
          }}
        />
      )}
      {/* {previewVisible && (
        <ExistingTemplate
          {...{
            type,
            item: {
              templateContent: details?.templateContent,
              additionalDetails: {
                service: details?.service || null,
                services: details?.otherDetails?.services || [],
              },
              templateTitle: item?.templateTitle,
            },
            editorRef: proposalDesignRef,
            modalVisibility: previewVisible,
            setModalVisibility: setPreviewVisible,
            services: details?.otherDetails?.services,
          }}
        />
      )} */}
    </Modal>
  );
};

export default EditGeneralTemplate;
