import { useState } from "react";

const useIsReplyEmailBoxActive = () => {
  const [isActive, setIsActive] = useState("");

  return {
    isActive,
    setIsActive,
  };
};

export default useIsReplyEmailBoxActive;
