import { ExportPreview } from "../../../../../commonComponents";
import { safetyContactsStyles } from "../../../../Projects/ProjectSafetyReport/helpers";
import { commentStyles } from "../../../../TaskManager/Tasks/TasksModals/EditTask/components/TaskExportPreview/TaskExportPreview";
import { safetyPdfBody } from "../../../safetyPdf";
import AddSafetyContacts from "./components/AddSafetyContacts";
import SortReportFields from "./components/SortReportFields";
import {
  convertSafetyReport,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "./helpers";

function SafetyExportPreview({
  wordPreview,
  setWordPreview,
  rowObject,
  safetyCategory,
  currentProject,
  getInitialExportData,
}) {
  const defaultHeaderSection = [
    {
      text: {
        paragraphs: [
          {
            text: rowObject?.["safetyName"] + " " + safetyCategory + " Report",
            class: "safety-title",
            bold: true,
            header: true,
          },
        ],
      },
    },
    {
      text: {
        paragraphs: [
          {
            text: "Date: " + new Date().toLocaleDateString(),
            class: "safety-section-header no-margin",
            bold: true,
          },
        ],
      },
    },
  ];

  return wordPreview ? (
    <ExportPreview
      {...{
        //region PDF PREVIEW
        open: wordPreview,
        onCancel: () => setWordPreview(false),
        documentTitle: rowObject?.safetyName + " " + safetyCategory + " Report",
        getInitialData: getInitialExportData,
        asyncInitialData: true,
        keyName: "safety " + safetyCategory,
        applyFilters: safetyExportApplyFilters,
        convertData: convertSafetyReport,
        tabs: [
          {
            key: "modify",
            label: "Modify Fields",
            component: SortReportFields,
          },
          currentProject?.projectLatitude && {
            key: "safetyContacts",
            label: "Safety Contacts",
            component: AddSafetyContacts,
          },
        ].filter(Boolean),
        customStyles: {
          ...SAFETY_REPORT_STYLES,
          ...safetyContactsStyles,
          ...commentStyles,
        },
        defaultHeaderSection,
      }}
    />
  ) : null;
}

export default SafetyExportPreview;
