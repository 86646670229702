import { editorButtons } from "../../../../../../helpers/constants/editorButtons";

export const customConfig = (keyTags = []) => {
  const customConfig = {
    buttons: editorButtons,
    extraButtons: [
      keyTags.length && {
        name: "Dynamic Keys",
        list: keyTags.map(({ name }) => name),
        exec: (editor, event, { control }) => {
          if (!control.args) return false;

          const selectedValue = control.args?.[0] || control.value;
          !!selectedValue && editor.s.insertHTML(`{${selectedValue}} `);
        },
      },
    ].filter(Boolean),
  };

  return customConfig;
};
