import { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { LogsButton, MondayButton } from "../../../../../../commonComponents";
import { TrashIcon, XIcon } from "../../../../../Communication/assets";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { PdfDocIcon } from "../../../../../../../assets";
import { LogsIcon } from "../../../../../DynamicView/src";
import ToDoButton from "../../../../../ToDos/components/ToDoButton/ToDoButton";
import TaskExportPreview from "./TaskExportPreview/TaskExportPreview";

const tooltipCategory = "Tasks";

export const EditTaskModalFooter = ({
  updateTask,
  closeModal,
  setDeleteConfirmModal,
  handleCloneTask,
  exportToPdf,
  recordId,
  recordName,
  taskId,
  updatedTask,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [openPDFPreview, setOpenPDFPreview] = useState(false);

  return (
    <div className="task_details_footer">
      <MondayButton
        disabled={disableBtn}
        Icon={<TrashIcon />}
        className={"mondayButtonRed"}
        onClick={() => setDeleteConfirmModal(true)}
      >
        Delete
      </MondayButton>
      <MondayButton
        className="mondayButtonPdf"
        Icon={<PdfDocIcon />}
        onClick={() => setOpenPDFPreview(true)}
        tooltipCategory={tooltipCategory}
        tooltipKey="exportPdf"
      >
        PDF Preview
      </MondayButton>
      <span>
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={() => closeModal({ cancel: true })}
          disabled={disableBtn}
        >
          Close
        </MondayButton>
        <LogsButton
          {...{
            buttonLabel: "Task Activity",
            logsTitle: "Task Logs",
            filtersObj: { recordId: taskId },
            tooltipCategory,
            tooltipKey: "taskActivity",
          }}
        />

        <ToDoButton {...{ recordId, recordName, tooltipCategory }} />
        <MondayButton
          Icon={<CopyOutlined />}
          onClick={handleCloneTask}
          className="mondayButtonBlue"
          disabled={disableBtn}
          tooltipCategory={tooltipCategory}
          tooltipKey="cloneTask"
        >
          Clone Task
        </MondayButton>
        <MondayButton
          className="mondayButtonGreen"
          onClick={() => {
            setDisableBtn(true);
            updateTask(() => setDisableBtn(false));
          }}
          disabled={disableBtn}
          Icon={<TickIcon />}
        >
          Save
        </MondayButton>
      </span>
      <TaskExportPreview
        {...{
          openPDFPreview,
          setOpenPDFPreview,
          updatedTask,
        }}
      />
    </div>
  );
};
