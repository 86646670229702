import { groupBy } from "lodash";
import { Popover, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useState, Dispatch, SetStateAction, useContext, useMemo } from "react";

import {
  CrewTeamType,
  ScheduleType,
  EmployeeReportType,
} from "../../payrollLiveTypes";
import { EmployeeSidebarItem } from "..";
import PayrollLiveContext from "../../PayrollLiveContext";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { DropDownArrow } from "src/components/SidebarPages/Fleet/components/InputComponent/assets";

import "./TeamSidebarItem.scss";

type Props = CrewTeamType & {
  selectedEmployees: Array<string>;
  members: Array<EmployeeReportType>;
  setSelectedEmployees: Dispatch<SetStateAction<Array<string>>>;
};

const statusColors = {
  In: "#00a464",
  Out: "#787677",
  "No Punch": "#f04f4e",
  Unscheduled: "#7FB5B5",
  "Don't Match": "#e9c466",
};

function TeamSidebarItem(props: Props) {
  const {
    members,
    crewForeman,
    crewTeamName,
    selectedEmployees,
    setSelectedEmployees,
  } = props;
  const { filters, setFilters, employeesReport, mapRef, schedules, jobsites } =
    useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [showEmployees, setShowEmployees] = useState<boolean>(false);

  function onTeamClick() {
    let tmpEmployeeNumber = filters.employeeNumber;

    for (let i = 0; i < members.length; i++) {
      const crewEmp = members[i];
      if (tmpEmployeeNumber.includes(crewEmp?.employeeNumber)) {
        tmpEmployeeNumber = tmpEmployeeNumber.filter(
          (nr) => nr !== crewEmp?.employeeNumber
        );
      } else {
        tmpEmployeeNumber.push(crewEmp?.employeeNumber);
      }

      const employeesCoordinates = employeesReport.flatMap((emp) =>
        emp.punchCoordinates?.lat &&
        tmpEmployeeNumber.includes(emp.employeeNumber)
          ? emp.punchCoordinates
          : []
      );

      if (mapRef.current) {
        mapRef.current.fitBoundsToMarkers(employeesCoordinates);
      }
    }

    const projectsIncluded = employeesReport.flatMap((el) =>
      tmpEmployeeNumber.includes(el.employeeNumber) ? el.projectId : []
    );

    setSelectedEmployees(tmpEmployeeNumber);
    setFilters((prev) => ({
      ...prev,
      employeeNumber: tmpEmployeeNumber,
      schedules: projectsIncluded,
    }));
  }

  function toggleEmployeesDropDown() {
    setShowEmployees((prev) => !prev);
  }

  function onJobClick(projectId: string) {
    let coordinates = (schedulesIncluded?.[projectId] || []).flatMap((el) =>
      el?.punchCoordinates?.lat ? el.punchCoordinates : []
    );
    const index = schedules.findIndex((el) => el?.projectId === projectId);
    const jobIndex = jobsites.findIndex((el) => el?.jobsiteId === projectId);
    const jobCoordinates =
      schedules?.[index]?.addressPosition ||
      jobsites?.[jobIndex]?.addressPosition;

    if (filters?.schedules.includes(projectId)) {
      setFilters((prev) => ({
        ...prev,
        schedules: [],
        employeeNumber: [],
      }));
      mapRef.current.fitBoundsToMarkers([]);
    } else {
      setFilters((prev) => ({
        ...prev,
        schedules: [projectId],
        employeeNumber: (schedulesIncluded?.[projectId] || []).map(
          (el) => el?.employeeNumber
        ),
      }));
      mapRef.current.fitBoundsToMarkers(coordinates.concat(jobCoordinates));
    }
  }

  const statusGroupedMembers = useMemo(() => {
    const statusGrouped = groupBy(
      members,
      (el: EmployeeReportType) => el?.liveStatus
    );
    return statusGrouped;
  }, [members]);

  const schedulesIncluded = useMemo(() => {
    return groupBy(
      members.filter((el) => el?.liveStatus !== "No Punch"),
      (el) => el?.projectId || el?.jobsiteId
    );
  }, [members]);

  return (
    <section
      className={`team-sidebar-item ${showEmployees ? "members-open" : ""} ${
        darkMode ? "team-sidebar-item-dark" : ""
      }`}
    >
      <div className="team-data">
        <span className="team-name" onClick={onTeamClick}>
          {crewTeamName}
        </span>
        <div style={{display: "flex", gap: 10}}>
        <label>Foreman: </label>
        <span>{crewForeman?.crewName}</span>
        </div>
      </div>
      <div className="members-statuses">
        {Object.keys(statusGroupedMembers).map((status) => {
          return (
            <Tooltip title={status}>
              <div
                className="status-container"
                style={{
                  backgroundColor: statusColors?.[status],
                }}
              >
                <div className="count">
                  {statusGroupedMembers?.[status]?.length || 0}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </div>
      <div className="team-data">
        <span>Jobs Included: </span>
        {Object.keys(schedulesIncluded).map((projectId) => {
          const index = schedules.findIndex(
            (el) => el?.projectId === projectId
          );
          const includedSchedule = schedules?.[index] as ScheduleType & {
            jobAddress?: string;
          };

          const jobName =
            includedSchedule?.scheduleAddress || includedSchedule?.jobAddress;

          return (
            <div className="jobs-container">
              <label
                onClick={() => onJobClick(projectId)}
                className={`job-name ${
                  (filters?.schedules || [])?.includes(projectId)
                    ? "active"
                    : ""
                }`}
              >
                {jobName || "No Match"}
              </label>

              <Popover
                trigger="hover"
                title={
                  <div className="sidebar-popover-header">
                    Employees Included
                  </div>
                }
                content={
                  <div className="sidebar-popover-content">
                    {schedulesIncluded?.[projectId].map((el) => (
                      <div className="employee-included">
                        <span
                          className="status-dot"
                          style={{
                            backgroundColor:
                              statusColors?.[el?.liveStatus] || "#e9c466",
                          }}
                        ></span>
                        <span>{el?.employeeName}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <div className="members-count">
                  ({schedulesIncluded?.[projectId]?.length})
                </div>
              </Popover>
            </div>
          );
        })}
      </div>
      <div className="members-container">
        <div className="dropdown-trigger">
          <span className="members-count" onClick={toggleEmployeesDropDown}>
            See all <span className="count">({members?.length || 0})</span>{" "}
            members
          </span>
          <DropDownArrow
            className="dropdown-icon"
            onClick={toggleEmployeesDropDown}
            style={showEmployees ? { transform: "rotate(180deg)" } : {}}
          />
        </div>
        <div className="members-list">
          {showEmployees
            ? members.map((mem) => {
                return (
                  <EmployeeSidebarItem
                    {...{ ...mem, selectedEmployees, setSelectedEmployees }}
                  />
                );
              })
            : null}
        </div>
      </div>
    </section>
  );
}

export default TeamSidebarItem;
