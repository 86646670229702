import React from "react";
import { useSelector } from "react-redux";
import { CoreLogo } from "../../components/ProposalPages/FirstProposalPage/assets";

import "../styles/HeaderStatistics.scss";

function HeaderStatistics({ title, description, onGoBack = () => {} }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  return (
    <div className={`header-statistics-wrapper ${isDarkMode ? "dark" : ""}`}>
      <div className="header-statistics__left">
        {" "}
        <span>
          <div className="header-statistics__title">{title}</div>
          <span className="header-statistics__desc">{description}</span>
        </span>
        <span onClick={onGoBack} className="header-statistics__go-back">
          Go back
        </span>
      </div>{" "}
      <div className="header-statistics__right">
        <div onClick={onGoBack} className="coreLogoBlackHolder">
          <CoreLogo />
        </div>
      </div>
    </div>
  );
}

export default HeaderStatistics;
