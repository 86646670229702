import { useDispatch } from "react-redux";
import {
  markAsImportant,
  markAsNotImportant,
  markAsRead,
  markAsUnread,
  setCheckedList,
} from "../actions/communicationActions";
import { callGmailAPI } from "../components/SidebarPages/Communication/functions";
import { showInfoMsg } from "../utils";
import useLogManagement from "./useLogManagement";
import { useSelector } from "react-redux";

const useEmailActions = () => {
  const dispatch = useDispatch();
  const { handleGenerateNewLog } = useLogManagement();
  const { recordData = {}, selectedCategory } = useSelector(
    (state) => state.communication
  );

  const handleMarkAs = async (markAs, checkedList, authData) => {
    const req = {
      emailIds: checkedList,
      moveTo: [],
      removeFrom: [],
      markAs: [markAs],
      authData,
    };

    const res = await callGmailAPI("changeLabel", req);

    if (res?.data && res?.data === "success") {
      checkedList.forEach((itemId) => {
        if (markAs === "IMPORTANT") {
          if (selectedCategory === "DRAFT") {
            dispatch(markAsImportant(itemId, true));
            showInfoMsg({ content: "Draft marked as important." });
          } else {
            showInfoMsg({ content: "Email marked as important." });
            dispatch(markAsImportant(itemId, false));
          }

          handleGenerateNewLog(
            "Mark as important",
            "Mark as",
            "NOT IMPORTANT",
            "IMPORTANT",
            "Communication",
            recordData?.recordId
          );
        } else if (markAs === "NOT IMPORTANT") {
          if (selectedCategory === "DRAFT") {
            dispatch(markAsNotImportant(itemId, true));
            showInfoMsg({ content: "Draft marked as not important." });
          } else {
            console.log("here email marked as not important");
            showInfoMsg({ content: "Email marked as not important." });
            dispatch(markAsNotImportant(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as not important",
            "Mark as",
            "IMPORTANT",
            "NOT IMPORTANT",
            "Communication",
            recordData?.recordId
          );
        } else if (markAs === "READ") {
          if (selectedCategory === "DRAFT") {
            dispatch(markAsRead(itemId, true));
          } else {
            dispatch(markAsRead(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as read",
            "Mark as",
            "NOT READ",
            "READ",
            "Communication",
            recordData.recordId
          );
        } else if (markAs === "UNREAD") {
          if (selectedCategory === "DRAFT") {
            dispatch(markAsUnread(itemId, true));
          } else {
            dispatch(markAsUnread(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as unread",
            "Mark as",
            "READ",
            "UNREAD",
            "Communication",
            recordData.recordId
          );
        }
      });
    }
    // setCheckedList([]);
    dispatch(setCheckedList([]));
  };

  return { handleMarkAs };
};

export default useEmailActions;
