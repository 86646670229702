import React from "react";
import { MondayButton } from "../../../../../commonComponents";
import { ForwardIconEmail, ReplyIcon } from "../../../assets";
import ReplyEmailBoxContainer from "../../ReplyEmailBox/ReplyEmailBoxContainer";
import useIsReplyEmailBoxActive from "../../ReplyEmailBox/hooks/useIsReplyEmailBoxActive";

import { useSelector } from "react-redux";

import "./email-view-footer.scss";

function EmailViewFooter({
  isEditable,
  mainEmail,
  rowData,
  authData,
  emails,
  setEmail,
}) {
  const { isActive, setIsActive } = useIsReplyEmailBoxActive();
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div>
      {isActive === "" ? (
        <div
          className={`footer-buttons-container ${
            isDarkMode ? "dark-mode" : ""
          }`}
        >
          <MondayButton
            className={`reply-btn mondayButtonGrey ${
              !isEditable ? "cursor-not-allowed" : ""
            }`}
            Icon={<ReplyIcon />}
            onClick={() => isEditable && setIsActive("reply")}
            data-testid="reply-btn"
          >
            Reply
          </MondayButton>

          <MondayButton
            className={`forward-btn mondayButtonGrey ${
              !isEditable ? "cursor-not-allowed" : ""
            }`}
            Icon={<ForwardIconEmail />}
            onClick={() => isEditable && setIsActive("forward")}
            data-testid="forward-btn"
          >
            Forward
          </MondayButton>
        </div>
      ) : (
        <ReplyEmailBoxContainer
          authData={authData}
          emails={emails}
          isEditable={isEditable}
          mainEmail={mainEmail}
          rowData={rowData}
          setEmail={setEmail}
          isActive={isActive}
          setIsActive={setIsActive}
          isFooter={true}
        />
      )}
    </div>
  );
}

export default EmailViewFooter;
