import { Popover, Tooltip } from "antd";
import React from "react";
import SingleEmailViewOptions from "../../../utils/SingleEmailViewOptions";
import { useSelector } from "react-redux";
import { ThreeDotsIcon } from "../../../assets";

function More({ isEditable, setIsActive, isActive }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const optionClicked = (actionType) => {
    setIsActive(actionType);
  };

  return (
    <Tooltip {...{ title: "More" }}>
      <Popover
        {...{
          placement: "bottom",
          content: SingleEmailViewOptions({
            replyClicked: () => optionClicked("reply"),
            forwardClicked: () => optionClicked("forward"),
            isActive,
            isEditable,
            setIsActive,
          }),
          trigger: isEditable ? "click" : "",
        }}
        overlayInnerStyle={{
          background: isDarkMode ? "#2a2b3a" : "#fff",
          fill: isDarkMode ? "#fff" : "#323338",
        }}
        data-testid="options-popover"
      >
        <span
          {...{
            className: "threeDots",
            style: isEditable ? {} : { cursor: "not-allowed" },
          }}
        >
          <ThreeDotsIcon />
        </span>
      </Popover>
    </Tooltip>
  );
}

export default More;
