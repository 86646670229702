import { useState } from "react";

import { MondayButton } from "../../../../../commonComponents";
import { EmailIcon } from "../../../../BasePage/src";

import EmailsModal from "../../../../../commonComponents/EmailsModal";

const SafetyEmails = (props) => {
  const { recordId, categoryName, title } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <MondayButton
        {...{
          Icon: <EmailIcon />,
          className: "mondayButtonBlue",
          onClick: () => setOpen(true),
        }}
      >
        Communication
      </MondayButton>

      <EmailsModal
        title={title}
        open={open}
        setOpen={setOpen}
        recordId={recordId}
        categoryName={categoryName}
      />
    </>
  );
};

export default SafetyEmails;
