import "./ProposalAssistant.scss";
import { Alert, Timeline } from "antd";
import React from "react";
import Swal from "sweetalert2";
import { CheckIconModal } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";

const alertStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  // width: "500px",
};

function ProposalAssistant({
  editorRef = "",
  issues = { missingServices: [] },
}) {
  const onApplyLegacyTemplates = async () => {
    editorRef.current.component.selection.insertHTML(
      "<p>This is inserted HTML content</p>"
    );
    Swal.fire({
      title: "Are you sure?",
      text: "This will apply legacy templates to the services",
      icon: "warning",
    });
  };

  return (
    <div className="proposalAssistantModalWrapper">
      {/* <DifferencesFinderWrapper /> */}
      <Timeline
        className="proposalAssistantTimeline"
        items={[
          {
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message="1. Pick a viable Estimate"
                  type="success"
                  description="The estimate should be viable and should have all the necessary details filled by Engineers, Architects and Account Managers."
                  showIcon
                />
              </div>
            ),
            color: "green",
          },
          {
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message="2. Make sure all the services are applied"
                  description="All the services should be applied to the estimate. If any service is missing, it will be shown in the list below."
                  // description="Detailed description and advice about successful copywriting."
                  type="success"
                  showIcon
                />
              </div>
            ),
            color: "green",
          },

          {
            color: issues.missingServices.length > 0 ? "red" : "green",
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message={
                    issues.missingServices.length > 0
                      ? "3. Missing Services Templates"
                      : "3. All Services Applied"
                  }
                  type={issues.missingServices.length > 0 ? "error" : "success"}
                  showIcon
                  // action={
                  //   issues.missingServices.length > 0 ? (
                  //     <MondayButton
                  //       className="mondayButtonBlue"
                  //       onClick={onApplyLegacyTemplates}
                  //       Icon={<CheckIconModal />}
                  //     >
                  //       Apply Legacy Templates
                  //     </MondayButton>
                  //   ) : null
                  // }
                  description={
                    issues.missingServices.length > 0
                      ? `Some services are missing templates. Please apply the templates to the services: ${issues.missingServices
                          .map((e) => {
                            console.log("e", e);
                            return e?.label || "test";
                          })
                          .join(", ")}`
                      : "All services have templates applied."
                  }
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ProposalAssistant;
