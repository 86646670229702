import { useState } from "react";
import { DepartmentIcon } from "../../../../../../../../assets";
import TableTemplate from "./TableTemplate/TableTemplate";
import { Tooltip } from "antd";
import { TrashIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { RedWarningModal } from "../../../../../../../commonComponents";

function DepartmentList({
  departmentsList = [],
  setSelectedDepartment,
  selectedDepartment,
  deleteDepartmentHandler,
}) {
  const [warningVisible, setWarningVisible] = useState(false);
  const header = [
    { name: "Department", flex: 2 },
    { name: "Nr. of Roles", flex: 1 },
    { name: "Nr. of Users", flex: 1 },
  ];

  function changeDep(departmentName) {
    setSelectedDepartment((prev) =>
      prev === departmentName ? "" : departmentName
    );
  }

  return (
    <div className="dep-list">
      <div className="dep-list-header">
        <DepartmentIcon />{" "}
        <span>{departmentsList.length + " Departments"} </span>
      </div>
      <TableTemplate
        {...{
          header,
          data: departmentsList,
          onRowClick: (department) => {
            changeDep(department.departmentName);
          },
          activeRow: (department) =>
            department.departmentName === selectedDepartment,
          renderItem: (department, index) => {
            const { departmentName = "", roles = [], users = [] } = department;
            return (
              <>
                <span
                  className={`role-users `}
                  style={{
                    flex: header[0].flex,
                    flexWrap: "nowrap",
                    display: "flex",
                    overflow: "visible",
                    alignItems: "center",
                    gap: 15,
                    width: "fit-content",
                  }}
                >
                  {users.length === 0 && roles.length === 0 ? (
                    <Tooltip title="Delete Department" placement={"top"}>
                      <TrashIcon
                        fill="#FE4C4A"
                        onClick={() => {
                          setWarningVisible(departmentName);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {departmentName}
                </span>
                <span style={{ flex: header[1].flex }}>{roles.length}</span>
                <span style={{ flex: header[2].flex }}>{users.length}</span>
              </>
            );
          },
        }}
      />

      {!!warningVisible && (
        <RedWarningModal
          {...{
            visible: !!warningVisible,
            onCancel: () => setWarningVisible(false),
            deleteModal: true,
            footerProps: {
              onConfirm: async () => {
                await deleteDepartmentHandler(warningVisible, () => {
                  setSelectedDepartment("");
                  setWarningVisible(false);
                });
              },
            },
            children: "Are you sure you want to delete this department?",
          }}
        />
      )}
    </div>
  );
}

export default DepartmentList;
