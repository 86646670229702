import axios from "axios";
import Swal from "sweetalert2";
// import PDFCard from "./PDFCard/PDFCard";
import { Empty, message } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { FileSearchOutlined } from "@ant-design/icons";
import ActionsHeader from "./ActionsHeader/ActionsHeader";
import proposalPathConfig from "../../../utils/pathConfig";
// import { MondayButton } from "../../../../commonComponents";
// import { PdfMergerIcon } from "../FirstProposalPage/assets";
import { blobToBase64 } from "../../../../SidebarPages/utils";
import DocMergerUpload from "./DocMergerUpload/DocMergerUpload";
// import PageRangePicker from "./PageRangePicker/PageRangePicker";
import { useProposalContext } from "../../../WaitingRoomProposal/ProposalContext";
// import PdfsFinderFromDocuments from "./PdfsFinderFromDocuments/PdfsFinderFromDocuments";
import DocuSealForm from "../../ProposalHeader/components/DocuSeal/DocuSealForm/DocuSealForm";

import "./MergePdfs.scss";

const MergePdfs = ({ editorRef }) => {
  const [allFilesList, setFileList] = useState([]);
  const [customConfigPages, setCustomConfigPages] = useState({});
  const [mergedPdf, setMergedPdf] = useState();
  const [previewPDF, setPreviewPDF] = useState(false);
  const [customFiles, setCustomFiles] = useState([]); // from google drive

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { proposalData } = useProposalContext();
  const [mergedBase64, setMergedBase64] = useState();
  const [enable, setEnable] = useState(false);

  const onUploadChange = ({ fileList, type = "" }) => {
    console.log("fileList", fileList);
    let allFiles = [...allFilesList];
    allFiles.push({ ...(fileList?.[0] || {}), type });
    allFiles = allFiles.map((file, idx) => ({
      ...file,
      thumbUrl: URL.createObjectURL(file.originFileObj),
    }));
    setFileList(allFiles);
    // setFileList(fileList);
  };

  // IMPORTANT, TO SEND TO BACKEND FOR MERGING
  //region onMergeAll
  const onMergeAll = async () => {
    // Swal.fire({
    //   title: "<strong>Merging PDFs</strong>",
    //   icon: "info",
    //   html: "Please wait while we merge your PDFs! ",
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    message.loading("Generating PDF, please wait...", 0);
    let FILES_TO_SEND = await Promise.all(
      allFilesList?.map((file) => {
        if (file?.type === "local") {
          return {
            ...file,
            fileSrc: blobToBase64(file?.originFileObj).then((res) => res),
          };
        } else {
          return {
            ...file,
            fileSrc: file.src,
          };
        }
      })
    );

    await axios
      .post(
        `${proposalPathConfig}/api/mergePdfDocs`,
        {
          // originalPdf: await blobToBase64(originalPdf.originFileObj),
          // pdfToAttach: await blobToBase64(pdfToAttach.originFileObj),
          originalPdf: [
            ...(await Promise.all(FILES_TO_SEND?.map((el) => el?.fileSrc))),
          ]?.filter((el) => typeof el === "string"),
          configuration: {
            fileList: FILES_TO_SEND,
            pagesConfig: customConfigPages,
            // ...(idxConfigs || {}),
          },
        }
        // { responseType: "blob" }
      )
      .then(async ({ data: mergedPdf }) => {
        setMergedBase64(mergedPdf);
        var blob = b64toBlob(mergedPdf, "application/pdf");
        const blobURL = URL.createObjectURL(blob);
        // const theWindow = window.open(blobURL);
        // const theDoc = theWindow.document;
        // const theScript = document.createElement("script");
        // function injectThis() {
        //   window.print();
        // }
        // theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        // theDoc.body.appendChild(theScript);
        setPreviewPDF(true);
        setMergedPdf(blobURL);
        // window.open(fileURL);
      })
      .catch((e) => {
        console.log("error", e);
        Swal.fire({
          title: "<strong>Error</strong>",
          icon: "error",
          html: "There was an error merging your PDFs! ",
        });
      })
      .finally(() => {
        message.destroy();
      });
  };

  const onSendToDocuSeal = async () => {
    setEnable(true);
    return;
    let mergedFile = mergedPdf; //URL
    let base64Data = mergedBase64;
    console.log("base64Data", base64Data);
    const response = await axios
      .post(
        `http://10.0.0.85:8080/api/docusealPDFImporter`,
        // `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
        {
          base64: base64Data,
        }
      )
      .then((res) => {
        console.log("restttt", res.data);
        // setExternalId(res?.data?.external_id);
        setEnable(true);
      });
  };

  //region onAddCurrentProposal
  const onAddCurrentProposal = async () => {
    console.log("onAddCurrentProposal", proposalData);
    const response = await axios.post(
      `${proposalPathConfig}/api/htmlDocusealConverter`,
      {
        html: `${editorRef.current.value || "Testing Proposal"}`,
      }
    );
    let fileUrl = response?.data?.documents?.[0]?.url; //PDF
    if (!!fileUrl) {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileName = "engineers_work.pdf"; // Set the desired file name
      setFileList((prev) => [
        ...prev,
        {
          uid: Math.random(),
          name: fileName,
          type: "local",
          originFileObj: blob,
          thumbUrl: URL.createObjectURL(blob),
        },
      ]);
      // FileSaver.saveAs(blob, fileName);
    }

    console.log("response", response);
  };

  const printBob = (blobURL) => {
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement("script");
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  const customFilesChanger = (files) => {
    setFileList((prev) => [
      ...(prev || []),
      ...files?.map((file) => ({
        ...(file || {}),
        type: "drive",
        uid: file?.id,
      })),
    ]);
  };

  //region onStartOver
  const onStartOver = () => {
    Swal.fire({
      title: "<strong>Start Over</strong>",
      icon: "info",
      html: "Are you sure you want to start over? ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        setFileList([]);
        setCustomConfigPages({});
        setMergedPdf();
        setPreviewPDF(false);
        setCustomFiles([]);
        Swal.close();
      }
    });
  };

  const onDeleteFile = (file) => {
    setFileList((prev) => prev?.filter((f) => f.uid !== file.uid));
    message.success("File removed successfully");
  };

  //region onMoveUp
  const onMoveUp = (file) => {
    const index = allFilesList.findIndex((f) => f?.uid === file?.uid);
    if (index === 0) return;
    const newFiles = structuredClone(allFilesList);
    const [movedFile] = newFiles.splice(index, 1);
    newFiles.splice(index - 1, 0, movedFile);
    console.log("newFIles", newFiles);
    setFileList(newFiles);
  };

  //region onDelete
  const onDelete = (file) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
    let tempConfig = { ...(customConfigPages || {}) };
    if (tempConfig?.[file?.uid]) {
      delete tempConfig[file.uid];
      setCustomConfigPages(tempConfig);
    }
  };

  //region onMoveDown
  const onMoveDown = (file) => {
    const index = allFilesList.findIndex((f) => f?.uid === file?.uid);
    if (index === allFilesList.length - 1) return;
    const newFiles = structuredClone(allFilesList);
    const [movedFile] = newFiles.splice(index, 1);
    newFiles.splice(index + 1, 0, movedFile);
    setFileList(newFiles);
  };

  const getCustomPagesInfo = (customConfigPages, file) => {
    if (!customConfigPages?.[`${file?.uid}`]) return "";
    return "| " + customConfigPages?.[`${file?.uid}`]?.pagesInfo;
  };

  const onCategoryChanger = (e, file) => {
    setFileList((prev) => {
      const idx = prev.findIndex((f) => f?.uid === file?.uid);
      const newFile = { ...prev[idx] };
      newFile.category = e.target.value;
      const newPrev = [...prev];
      newPrev[idx] = newFile;
      return newPrev;
    });
  };

  console.log("logsssssss", {
    allFilesList,
    customConfigPages,
    mergedPdf,
    previewPDF,
    customFiles,
  });

  useEffect(() => {
    if (allFilesList.length !== 0) {
      onMergeAll();
    }
  }, [allFilesList, customConfigPages]);

  return (
    <div className="mergePdfsContainer">
      <ActionsHeader
        {...{ onStartOver, onMergeAll, onSendToDocuSeal, onAddCurrentProposal }}
      />
      <div className="mergePdfsBodyContainer">
        <DocMergerUpload
          {...{
            allFilesList,
            onUploadChange,
            customConfigPages,
            setCustomConfigPages,
            customFilesChanger,
            customFiles,

            onMoveUp,
            onDelete,
            onMoveDown,
          }}
        />
        <div className="mergePdfsIframe">
          {mergedPdf && previewPDF && !!allFilesList?.length ? (
            <iframe
              title="PDF Viewer"
              style={{
                width: "100%",
                height: "650px",
                // height: "900px",
              }}
              src={mergedPdf || ""}
            />
          ) : (
            <span
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Empty description="Please upload your documents" />
            </span>
          )}
        </div>
        {enable && (
          <DocuSealForm
            // externalId={externalId}
            enable={enable}
            setEnable={setEnable}
          />
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default MergePdfs;

export const b64toBlob = (content, contentType) => {
  contentType = contentType || "";
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
};
