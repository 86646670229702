import { getAccessRights } from "../../../../../../../../../../utils";

export const getAllUserAccess = (userConfiguration, title) => {
  const users = userConfiguration?.allUsers?.Items?.filter((user) => {
    const accessRights = getAccessRights({
      userConfiguration: user,
      title: title,
    });
    if (accessRights && user.routeConfig) {
      const filteredRoutes = user.routeConfig.filter(
        (route) => route.title === title
      );
      return filteredRoutes.length > 0;
    }
    return false;
  }).map((user) => ({
    value: user.identityId,
    label: user.nameOfUser,
  }));
  return users;
};
