import dayjs from "dayjs";
import { trim } from "lodash";
import { getAddressComponent } from "../../../SidebarPages/Fleet/utils/addressParseHelpers";
import { Flex, Form, Popover, Tooltip } from "antd";
import { MondayButton } from "../../../commonComponents";

export const OPPORTUNITY_INFORMATION = "Opportunity Information";
export const PROJECT_INFORMATION = "Project Information";
export const JOBSITE_ADDRESS = "Jobsite Address";
const notAllowedStages = ["Canceled", "Awarded", "Awarded Change Order"];

export const fieldsJSON = ({
  form,
  accountName = "",
  uploadFile,
  requiredBidDueDate,
  projectExecutives,
  projectManagersOptions,
  hasEstimations,
  clients,
  leads,
  opportunityStages = [],
  accessToken,
  onPickerSuccess,
  fileNames,
  parentId,
  taxRatePopOverVisible,
  sources,
  opportunityTeam,
  fetchedOpportunities,
  onTaxExemptSelect,
  alternativeAddressesVisible,
  AlternativeAddressesTitle,
  AlternativeAddressesComp,
  contacts,
  isContactDisabled,
  setSelectedCompanyName,
  recordTypes,
  salesPerson,
  taxExemptOptions,
  setOpportunityAddress,
  companyNameDropdown,
  laborTypes,
  contactsDropDown,
  changeOrderData,
  insuranceTypes,
  isDarkMode,
  checkAccess,
  setAlternativeAddressesVisible,
  onSelect,
  onDeselect,
}) => {
  const primaryContact = Form.useWatch("primaryContact", form);
  const alternativeAddresses =
    Form.useWatch("alternativeAddresses", { form, preserve: true }) || [];

  return {
    type: {
      formItemName: "opportunityType",
      type: "radio",
      options: recordTypes,
    },
    [OPPORTUNITY_INFORMATION]: [
      {
        label: "Opportunity Name",
        formItemName: "opportunityName",
        placeholder: "Enter opportunity name...",
      },
      {
        className: "opportunityInformation__Company",
        label: "Company Name",
        formItemName: "accountName",
        placeholder: "Select company name...",
        type: "select",
        showSearch: true,
        required: true,
        disabled: hasEstimations || !!accountName || !!changeOrderData,
        rules: [
          {
            validator: (_, value) => {
              return (!!accountName || !!changeOrderData) &&
                (clients?.some(
                  (c) => c.accountId === value || c.accountName === value
                ) ||
                  leads?.some(
                    (l) => l.leadId === value || l.leadCompany === value
                  ))
                ? Promise.reject(new Error("This Client/Lead does not exist"))
                : Promise.resolve();
            },
          },
        ],
        onSelect: (value) => {
          setSelectedCompanyName(value);
          form.resetFields(["primaryContact", "contacts"]);
        },
        dropdownRender: companyNameDropdown,
        customOptions: [
          !!checkAccess.clientAccess
            ? { label: "Clients", options: clients }
            : {
                label: "Clients - No Access",
                options: [],
              },
          !!checkAccess.leadAccess
            ? { label: "Leads", options: leads }
            : {
                label: "Leads - No Access",
                options: [],
              },
        ],
      },
      {
        label: "Primary Contact",
        formItemName: "primaryContact",
        placeholder: "Select primary contact...",
        type: "select",
        showSearch: true,
        required: true,
        disabled: isContactDisabled,
        dropdownRender: contactsDropDown,
        customOptions: contacts.map(
          ({
            contactId,
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactRole = "",
          }) => ({
            value: contactId,
            label: trim(
              `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
              " -"
            ),
          })
        ),
      },
      {
        label: "Contacts",
        formItemName: "contacts",
        placeholder: "Select contacts...",
        type: "select",
        showSearch: true,
        mode: "multiple",
        dropdownRender: contactsDropDown,
        disabled: isContactDisabled || !primaryContact,
        customOptions: contacts
          .filter(({ contactId }) => contactId !== primaryContact)
          .map(
            ({
              contactId,
              contactFirstName = "",
              contactLastName = "",
              contactEmail = "",
              contactRole = "",
            }) => ({
              value: contactId,
              label: trim(
                `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
                " -"
              ),
            })
          ),
      },
      !changeOrderData && {
        label: "Opportunity Stage",
        formItemName: "opportunityStage",
        placeholder: "Select...",
        initialValue: "New - Not yet started",
        type: "select",
        showSearch: true,
        required: true,
        options: opportunityStages.filter(
          (stage) => !notAllowedStages.includes(stage)
        ),
      },
      !changeOrderData && {
        label: "Opportunity Source",
        formItemName: "opportunitySource",
        placeholder: "Select...",
        type: "autocomplete",
        options: sources,
      },
      {
        label: "Labor Requirement",
        formItemName: "laborRequirement",
        placeholder: "Select labor requirement...",
        type: "select",
        showSearch: true,
        initialValue: "Standard Labor",
        options: laborTypes,
      },
      {
        label: "Insurance Requirement",
        placeholder: "Select insurance requirement...",
        formItemName: "insuranceRequirement",
        type: "select",
        showSearch: true,
        options: insuranceTypes,
      },
      {
        label: "Tax Exempt",
        formItemName: "taxExempt",
        placeholder: "Select...",
        initialValue: "Yes",
        type: "select",
        onChange: onTaxExemptSelect,
        showSearch: true,
        options: taxExemptOptions,
      },
      {
        label: "Tax Rate",
        formItemName: "taxRate",
        initialValue: "0",
        addonAfter: "%",
        type: "input",
        disabled: true,
        popoverVisible: taxRatePopOverVisible,
        popoverPlacement: "bottom",
        popoverContent: "Only Rental Tax",
      },
      {
        label: "Project Executive",
        formItemName: "projectExecutive",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        options: projectExecutives,
      },
      {
        label: "Project Managers",
        formItemName: "projectManager",
        placeholder: "Select Project Manager...",
        type: "select",
        mode: "multiple",
        // required: true,
        showSearch: true,
        customOptions: projectManagersOptions,
        onChange: (val, option) => {
          const newOption = option?.filter((o) => !!Object.keys(o).length);
          // const { label, value, ...rest } = newOption;
          form.setFieldValue("projectManager", newOption);
        },
        maxTagCount: 2,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
      {
        label: "Sales Person",
        formItemName: "salesPerson",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        mode: "multiple",
        options: salesPerson,
      },
      {
        label: "Team",
        formItemName: "opportunityTeam",
        placeholder: "Select team",
        type: "select",
        customOptions: opportunityTeam,
        mode: "multiple",
        required: true,
        allowClear: false,
        maxTagCount: 2,
        onSelect,
        onDeselect,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
    ].filter(Boolean),
    [JOBSITE_ADDRESS]: {
      main: [
        {
          className: "opportunityAddressInput",
          label: (
            <Flex style={{ flex: 1 }} justify="space-between">
              <span>Project Address</span>
              <MondayButton
                hasIcon={false}
                className="mondayButtonBlue alternativeAddressButton"
                onClick={() => setAlternativeAddressesVisible(true)}
              >
                <Popover content="Alternative Addresses">
                  {alternativeAddresses?.length || 0}
                </Popover>
              </MondayButton>
            </Flex>
          ),
          rules: [
            {
              required: true,
              message:
                "*The Jobsite Address needs to be more than 3 characters long!",
            },
          ],
          formItemName: "opportunityAddress",
          placeholder: "Enter jobsite address...",
          onSelect: async (value) => {
            setOpportunityAddress(value);
            getAddressComponent(value, false, "").then((res) => {
              form.setFieldValue("opportunityAddCity", res.city);
              form.setFieldValue("opportunityAddState", res.state);
              form.setFieldValue("opportunityAddStreet", res.street);
              form.setFieldValue("opportunityAddress", res.address);
              form.setFieldValue("opportunityAddPostalCode", res.zipCode);
            });
          },
          // required: true,
          type: "placesautocomplete",
          style: { minWidth: 305 },
          disabled: hasEstimations || !!changeOrderData,
          existingValues: fetchedOpportunities?.map(
            ({ opportunityAddress = "", accountName = "" }) => ({
              value: opportunityAddress,
              label: `${opportunityAddress} - ${accountName}`,
              disabled: true,
            })
          ),
          form,
          popoverVisible: alternativeAddressesVisible,
          popoverTitle: AlternativeAddressesTitle,
          popoverContent: AlternativeAddressesComp,
          popoverClassName: `alternativeAddressesContainer ${
            isDarkMode && "alternativeAddressesContainerDark"
          }`,
          popoverPlacement: "bottomRight",
          isCustomValue: true,
        },
        {
          label: "Street",
          placeholder: "Enter street...",
          formItemName: "opportunityAddStreet",
          disabled: true,
          style: { minWidth: 305 },
        },
        {
          label: "City",
          placeholder: "Enter city...",
          formItemName: "opportunityAddCity",
          disabled: true,
        },
      ],
      split: [
        {
          label: "State",
          placeholder: "Enter state...",
          formItemName: "opportunityAddState",
          disabled: true,
        },
        {
          label: "Postal Code",
          placeholder: "Enter postal code...",
          formItemName: "opportunityAddPostalCode",
          disabled: true,
        },
      ],
    },
    [PROJECT_INFORMATION]: {
      dates: [
        {
          label: "Bid Due Date",
          formItemName: "bidDueDate",
          ...(requiredBidDueDate
            ? {
                type: "datepicker",
                required: requiredBidDueDate,
                rules: [
                  {
                    validator: (_, value) =>
                      !!value &&
                      value <= form.getFieldValue("proposalStartDate")
                        ? Promise.reject(
                            new Error(
                              "Cannot be earlier than Proposed Start Date"
                            )
                          )
                        : Promise.resolve(),
                  },
                ],
              }
            : {
                disabled: requiredBidDueDate,
                type: "input",
                value: "N/A",
                placeholder: "N/A",
              }),
        },
        {
          label: "Proposed Start Date",
          formItemName: "proposalStartDate",
          type: "datepicker",
        },
        {
          label: "Request Received Date",
          formItemName: "requestReceivedDate",
          type: "datepicker",
          required: true,
          disabledDate: (current) =>
            current && dayjs(current).isAfter(dayjs().endOf("day")),
        },
      ],

      checkboxesAndUploads: [
        {
          label: "Scope Sheet:",
          type: "gPicker",
          accessToken,
          uploaderId: "Opportunity Scope Sheet",
          onPickerSuccess,
          fileNames,
          parentId,
          style: uploadFile ? {} : { display: "none" },
          required: uploadFile,
        },
      ],
    },
  };
};

export const body = ({
  form,
  zipCode,
  accountId,
  leadId,
  googleDriveFolderIds,
  googleDriveUploads,
  opportunityLatitude,
  opportunityLongitude,
  opportunityPlaceId,
  clients,
  leads,
}) => {
  const formValues = form.getFieldsValue(true);

  return {
    ...formValues,
    accountName:
      clients?.find(
        (client) => client.accountId === (formValues?.accountName ?? accountId)
      )?.accountName ||
      leads?.find((lead) => lead.leadId === (formValues?.accountName ?? leadId))
        ?.leadCompany ||
      "",
    opportunityAddress: form
      ?.getFieldValue("opportunityAddress")
      ?.replace(", USA", ""),
    taxRate: parseFloat(form.getFieldValue("taxRate")) / 100,
    proposalStartDate: !!form.getFieldValue("proposalStartDate")
      ? dayjs(form.getFieldValue("proposalStartDate")).valueOf()
      : "",
    bidDueDate: !!form.getFieldValue("bidDueDate")
      ? dayjs(form.getFieldValue("bidDueDate")).valueOf()
      : "",
    requestReceivedDate:
      dayjs(form.getFieldValue("requestReceivedDate")).valueOf() || "",
    opportunityType: form.getFieldValue("opportunityType") || "",
    buildingDimensions: "",
    workDetail: formValues.workDetail || "",
    proposedConstructionServices: [],
    opportunityStatus: "Not Converted",
    googleDriveUploads,
    projectSiteZip: zipCode,
    googleDriveFolderIds,
    accountId,
    leadId,
    opportunityLatitude,
    opportunityLongitude,
    opportunityPlaceId,
  };
};
