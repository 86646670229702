import { useSelector } from "react-redux";
import "./CardTemplate.scss";

function CardTemplate({ title, children, className }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      {...{
        className: `card-template ${
          isDarkMode ? "card-template-dark" : ""
        } ${className}`,
      }}
    >
      {title && <div className="card-template-title">{title}</div>}
      <div className="card-template-body">{children}</div>
    </div>
  );
}

export default CardTemplate;
