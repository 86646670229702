import { Card, Modal } from "antd";
import React from "react";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import "./ProgressCrews.scss";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
const ProgressCrews = ({ viewCrews = {}, onCancel = () => {} }) => {
  const { visible, data } = viewCrews || {};

  const details = [
    {
      field: "Schedule Address",
      value: data?.scheduleAddress,
    },
    {
      field: "Schedule Name",
      value: data?.scheduleName,
    },
    {
      field: "Type of work",
      value: data?.typeOfWork,
    },
    {
      field: "Estimation",
      value: data?.estimation,
    },
    {
      field: "Service Name",
      value: data?.serviceName,
    },
    {
      field: "Elevation",
      value: data?.elevation,
    },
    {
      field: "Pli",
      value: data?.pli,
    },
    {
      field: "Date",
      value: dayjsNY(data?.startDate).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <Modal
        {...{
          open: visible,
          centered: true,
          title: `Crew Members`,
          onCancel,
          closeIcon: <XIcon />,
          width: "fit-content",
          maxWidth: 1200,
          className: "progress-crews-track-modal",
          footer: (
            <MondayButton
              onClick={onCancel}
              {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
            >
              Close
            </MondayButton>
          ),
        }}
      >
        <>
          <div style={{ marginBottom: 10 }}>
            {details.map((item) => {
              return (
                <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                  <span style={{ fontWeight: 600 }}>{item.field}:</span>
                  <span>{item.value}</span>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {Object.entries(data?.crews || {})?.map(([key, val]) => {
              return (
                <Card title={key} className="crew-progress-card">
                  <div>
                    {val?.map((item) => {
                      return (
                        <ul>
                          <li>{item?.crewName}</li>
                        </ul>
                      );
                    })}
                  </div>
                </Card>
              );
            })}
          </div>
        </>
      </Modal>
    </div>
  );
};

export default ProgressCrews;
