import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  WithTooltip,
  MondayButton,
  ExportOrEmailDropdown,
} from "../../../commonComponents";
import {
  onFilter,
  onDateFilter,
  onTimeFilter,
  onNumberRangeFilter,
} from "../../../SidebarPages/utils";
import { payrollModalData } from "../utils";
import { LeftAlignRenderer } from "./components";
import { FilterIcon } from "../../../SidebarPages/BasePage/src";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../Tabs/DEG/components/modalComponents/utils/cellFunctions";
import ChartModal from "../../../ChartButton/ChartModal/ChartModal";
import { CheckIconModal, ExcelIconWhite } from "../../../../assets";
import { AddIcon } from "../../../Header/components/GoogleEvents/assets";
import { keys } from "../../Settings/settingsComponents/Roles/RolesData";
import Filter from "../../../SidebarPages/BasePage/components/Filter/Filter";
import ChartComponent from "../../../SidebarPages/components/AgChart/ChartComponent";

import "../Payroll.scss";
import { DropDownArrow } from "../../../SidebarPages/Fleet/components/InputComponent/assets";
import FilterSelection from "../../../SidebarPages/BasePage/components/FilterSelection/FilterSelection";
import SaveFiltersModal from "../../../SidebarPages/BasePage/components/SaveFiltersModal/SaveFiltersModal";
import { AdvancedFilterProvider } from "../../../commonComponents/AdvancedFilters/context/AdvancedFilterContext";

import "../Payroll.scss";

const defaultColumnDefs = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
};

const sideBar = {
  toolPanels: [
    {
      id: "columns",
      iconKey: "columns",
      labelKey: "columns",
      labelDefault: "Columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      iconKey: "filter",
      labelKey: "filters",
      labelDefault: "Filters",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};

function PayrollLayout({
  title,
  rowData,
  // logsFor,
  dataIdKey,
  rowToEdit,
  columnDefs,
  setRowData,
  getRowStyle,
  headerHeight,
  hasNew = true,
  rowHeight = 39,
  // chartingOptions,
  components = {},
  // charting = true,
  gridFilters = [],
  excelExport = "",
  customNew = false,
  paginationPageSize,
  customActionButtons,
  context = undefined,
  pinnedBottomRowData,
  degStepFilters = {},
  onRowAdd = () => {},
  onRowEdit = () => {},
  modalVisible = false,
  getGridApi = () => {},
  // onEditClick = () => {},
  onRowRemove = () => {},
  overlayLoadingTemplate,
  defaultColumnDefinition,
  postSortRows = () => {},
  setRowToEdit = () => {},
  getColumnApi = () => {},
  onRowClicked = undefined,
  additionalGridProps = {},
  suppressCellFocus = false,
  isRowSelectable = () => {},
  setModalVisible = () => {},
  setDegStepFilters = () => {},
  onSelectionChanged = () => {},
  rowMultiSelectWithClick = true,
  // getChartingOptions = () => {},
  defaultExcelExportParams = {},
  additionalActionsButtons = [],
  onCellValueChanged = undefined,
  enterNavigatesVertically = true,
  suppressDoubleClickEdit = false,
  getModifiedChartData = () => {},
  formatCellValueHandler = () => {},
  onColumnRowGroupChanged = () => {},
  onRowDoubleClicked: doubleClickHandler,
  isFromHrManagement = false,
}) {
  // const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);

  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  // const [chartArr, setChartArr] = useState([]);
  const [chartOptions, setChartOptions] = useState();
  const [filtersData, setFiltersData] = useState({});
  const [chartEndDate, setChartEndDate] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [chartStartDate, setChartStartDate] = useState();
  const [fieldSelected, setFieldSelected] = useState(null);
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [chartModalVisible, setChartModalVisible] = useState(false);

  // States for Filter Saver
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [saveFiltersModal, setSaveFiltersModal] = useState(false);
  const { defaultFilter, ...templateOptions } =
    preferences?.basepages?.[title] || {};
  // const templateOptions = rest;

  // const {
  //   modalToggler = () => {},
  //   // logsModalVisible = false,
  //   // setLogsModalVisible = () => {},
  //   loading = false,
  //   // editLogs: logsFromHook = [],
  // } = {};
  // logsFor ? useSaveLogs(logsFor) : {};

  const gridRef = useRef();
  const sërqInputRef = useRef();

  // const currentDate = dayjsNY();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    getGridApi(param.api);
    setColumnApi(param.columnApi);
    getColumnApi(param.columnApi);
  }, []);

  const autoSizeAllColumns = useCallback(
    (skipHeader) => {
      if (rowData) {
        const allColumnIds = [];
        gridRef?.current?.columnApi?.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
        const widths = {
          rowWidth: parseInt(
            document.getElementsByClassName("ag-row")[0]?.offsetWidth
          ),
          parentWidth: parseInt(
            document.getElementsByClassName("ag-body-viewport")[0]?.offsetWidth
          ),
        };
        // If total width of rows is less than the container width, fit columns to screen
        if (widths.rowWidth < widths.parentWidth) {
          gridRef?.current?.api?.sizeColumnsToFit();
        }
      }
    },
    [rowData]
  );

  const onFirstDataRendered = useCallback(() => {
    // param.columnApi.autoSizeAllColumns(false);
    autoSizeAllColumns(false);
  }, [autoSizeAllColumns]);

  // const openChartModal = () => {
  //   const chartOptions = getChartingOptions(rowData);
  //   setChartArr(chartOptions);
  //   setChartStartDate(currentDate.subtract(10, "year").format("YYYY-MM-DD"));
  //   setChartEndDate(currentDate.endOf("year").format("YYYY-MM-DD"));
  //   setChartModalVisible(true);
  // };

  const closeChartModal = () => {
    setChartModalVisible(false);
  };

  const gridColumns = useMemo(() => {
    return fieldSelected
      ? columnDefs.map((column) =>
          column?.field === fieldSelected
            ? column
            : { ...column, getQuickFilterText: () => "" }
        )
      : columnDefs;
  }, [fieldSelected, columnDefs]);

  const onRowDoubleClicked = useCallback(
    (row) => {
      if (!suppressDoubleClickEdit) {
        setRowToEdit(row.data);
        setNewModalVisible(true);
      }

      if (doubleClickHandler) {
        doubleClickHandler(row);
      }
    },
    [suppressDoubleClickEdit, doubleClickHandler]
  );

  const getRowId = useCallback(
    (param) => {
      if (dataIdKey) {
        return param?.data?.[dataIdKey];
      }
    },
    [dataIdKey]
  );

  const defaultActionButtons = [
    !!excelExport && {
      Icon: ExcelIconWhite,
      onClick() {
        exportToExcel();
      },
      tooltipKey: "exportExcel",
    },
    // !!charting && {
    //   Icon: ChartIcon,
    //   onClick() {
    //     openChartModal();
    //   },
    //   tooltipKey: "showCharts",
    // },
  ].filter(Boolean);

  const NewModal = payrollModalData[title];

  function exportToExcel() {
    gridApi.exportDataAsExcel({
      fileName: `${excelExport}.xlsx`,
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName.includes("Punch Time")) {
          return dayjsNY(value).format(DEG_TIME_FORMAT);
        } else if (headerName === "Punch Date") {
          return dayjsNY(value).format(DEG_DATE_FORMAT);
        } else if (headerName === "Jobsite Match") {
          return value?.jobAddress;
        } else if (headerName === "SOW") {
          return (value?.sow || []).join(", ");
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function drawChart(param) {
    setChartOptions(param);
    setChartModalVisible(false);
  }

  function clearFilters() {
    setFiltersData({});
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  // Prevent row selection on when edit and delete icons are clicked
  const onCellClicked = (e) => {
    if (["edit", "delete"].includes(e.colDef.field)) {
      e.node.setSelected(false);
    }
  };

  useEffect(() => {
    if (gridApi) {
      let activeFilters;
      let newFiltersObject = [];
      if (keys(filtersData).find((el) => filtersData[el] !== null)) {
        keys(filtersData)
          // .filter((val) => filtersData[val] !== "null")
          .map((e) => {
            // if (filtersData[e] !== null) {
            if (e.includes("end")) {
              return { [`${e}end`]: filtersData[e] };
            } else if (e.includes("start")) {
              return { [`${e}start`]: filtersData[e] };
            } else if (e.includes("range1")) {
              return { [`${e}range1`]: filtersData[e] };
            } else if (e.includes("range2")) {
              return { [`${e}range2`]: filtersData[e] };
            } else if (e.includes("timeRange1")) {
              return { [`${e}timeRange1`]: filtersData[e] };
            } else if (e.includes("timeRange2")) {
              return { [`${e}timeRange2`]: filtersData[e] };
            } else {
              return { [e]: filtersData[e] };
            }
            // }
          })
          .filter((e) => e !== undefined)
          .filter((e) => {
            return gridFilters?.find((el) => {
              const filterKey = Array.isArray(el?.key) ? el?.key?.[0] : el?.key;
              if (keys(e)[0].includes("end")) {
                return filterKey === keys(e)[0].split("end")[0];
              } else if (keys(e)[0].includes("start")) {
                return filterKey === keys(e)[0].split("start")[0];
              } else if (keys(e)[0].includes("range1")) {
                return filterKey === keys(e)[0].split("range1")[0];
              } else if (keys(e)[0].includes("range2")) {
                return filterKey === keys(e)[0].split("range2")[0];
              } else if (keys(e)[0].includes("timeRange1")) {
                return filterKey === keys(e)[0].split("timeRange1")[0];
              } else if (keys(e)[0].includes("timeRange2")) {
                return filterKey === keys(e)[0].split("timeRange2")[0];
              } else {
                return filterKey === keys(e)[0];
              }
            });
          })
          .map((e) => {
            if (keys(e)[0].includes("start")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("start")[0]
                  ),
                  value: [dayjs(e[keys(e)[0]] || "")],
                },
              ];
            } else if (keys(e)[0].includes("end")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("end")[0]
                  ),
                  value: [
                    ...((newFiltersObject || []).find(
                      ({ key }) => key === keys(e)[0].split("end")[0]
                    )?.value || undefined),
                    dayjs(e[keys(e)[0]] || ""),
                  ],
                },
              ];
            } else if (keys(e)[0].includes("timeRange1")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("timeRange1")[0]
                  ),
                  value: [e[keys(e)[0]]],
                },
              ];
            } else if (keys(e)[0].includes("timeRange2")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("timeRange2")[0]
                  ),
                  value: [
                    ...(newFiltersObject.find(
                      ({ key }) => key === keys(e)[0].split("timeRange2")[0]
                    )?.value || {}),
                    e[keys(e)[0]],
                  ],
                },
              ];
            } else if (keys(e)[0].includes("range1")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("range1")[0]
                  ),
                  value: [e[keys(e)[0]]],
                },
              ];
            } else if (keys(e)[0].includes("range2")) {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(
                    ({ key }) => key === keys(e)[0].split("range2")[0]
                  ),
                  value: [
                    ...(newFiltersObject.find(
                      ({ key }) => key === keys(e)[0].split("range2")[0]
                    )?.value || {}),
                    e[keys(e)[0]],
                  ],
                },
              ];
            } else {
              newFiltersObject = [
                ...newFiltersObject,
                {
                  ...gridFilters.find(({ key }) =>
                    Array.isArray(key)
                      ? key[0] === keys(e)[0].split("start")[0]
                      : key === keys(e)[0].split("start")[0]
                  ),
                  value: e[keys(e)[0]],
                },
              ];
            }
            return gridFilters;
          });

        activeFilters = [
          ...new Set(
            newFiltersObject?.map((item) => ({
              key: Array.isArray(item.key) ? item.key[0] : item.key,
              type: item.type,
              value: item.value,
              masterDetail: !!item?.masterDetail,
            }))
          ),
        ].filter(({ value }) => value !== undefined);
      } else {
        activeFilters = undefined;
      }
      if (activeFilters) {
        activeFilters?.map?.(({ key, type, value, masterDetail }) => {
          type === "Dropdown"
            ? onFilter({
                gridApi,
                column: Array.isArray(key) ? key[0] : key,
                current: value,
                type: type,
                masterDetail,
              })
            : type === "DateRange"
            ? onDateFilter?.({
                gridApi,
                column: key,
                dateArray: value,
                masterDetail,
              })
            : type === "NumberRange" || type === "TimeStampRange"
            ? onNumberRangeFilter?.({
                gridApi,
                column: key,
                numberArray: value,
                masterDetail,
              })
            : type === "TimeRange"
            ? onTimeFilter?.({
                gridApi,
                column: key,
                dateArray: value,
                activeFilters,
                gridFilters,
              })
            : onFilter?.({
                column: key,
                gridApi,
                type: "equals",
                current: dayjs(value).startOf("day"),
              });
        });
      } else {
        gridApi?.setFilterModel(null);
      }
    }
  }, [JSON.stringify(filtersData), gridApi]);

  const handleChartDateChange = (start, end) => {
    setChartStartDate(start);
    setChartEndDate(end);
  };

  const clearFilterBtn = async () => {
    setFiltersData({});
    gridApi?.setQuickFilter("");
    columnApi?.resetColumnState();
    columnApi?.resetColumnGroupState();
    gridApi?.setFilterModel(null);
    setSelectedTemplate("DEFAULT");
    autoSizeAllColumns(false);
  };

  return (
    <div className="payroll-view-layout">
      <div className="layout-filters-header">
        <div className="filter-buttons-section">
          <MondayButton
            Icon={<FilterIcon />}
            tooltipKey="openFilters"
            className="mondayButtonBlue"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            Open Filter
          </MondayButton>
          <div
            className="search-Div"
            style={
              keys(filtersData).length === 0 ||
              keys(filtersData).find((e) => filtersData[e] !== null) ===
                undefined
                ? { marginLeft: 0, marginRight: 10 }
                : { marginRight: 10 }
            }
          >
            <WithTooltip tooltipKey="search">
              <Input
                autoFocus={true}
                ref={sërqInputRef}
                value={searchInput}
                className="search-comp"
                placeholder="Search..."
                onChange={(e) => onFilterTextChange(e.target.value)}
                prefix={<SearchOutlined className="SearchLogoIcon" />}
                addonAfter={
                  <Select
                    allowClear={true}
                    className="select-comp"
                    popupMatchSelectWidth={false}
                    suffixIcon={<DropDownArrow />}
                    placeholder="Select field to filter"
                    onClear={() => setFieldSelected(null)}
                    popupClassName={isDarkMode && "darkDropDown"}
                    onSelect={(e) => {
                      sërqInputRef?.current?.focus?.();
                      setFieldSelected(e);
                    }}
                    options={columnDefs?.map(({ headerName, field }) => ({
                      value: field,
                      label: headerName,
                      key: field,
                    }))}
                  />
                }
              />
            </WithTooltip>
          </div>
        </div>
        <div className="actions-section">
          {/* {logsFor ? (
            <MondayButton
              className="mondayButtonBlue"
              Icon={<HistoryOutlined />}
              onClick={modalToggler}
              tooltipKey="showLogs"
              loading={loading}
            />
          ) : null} */}
          {Array.isArray(customActionButtons)
            ? customActionButtons
            : [
                ...(additionalActionsButtons || []),
                <ExportOrEmailDropdown
                  {...{
                    rowData,
                    title,
                    gridApi,
                    gridColumnApi: columnApi,
                    ...(formatCellValueHandler
                      ? { formatCellValueHandler }
                      : {}),
                    ...(exportToExcel ? { exportToExcel } : {}),
                  }}
                  key={"export-email-dropdown"}
                />,
                ...(defaultActionButtons || [])?.map(
                  ({ Icon, onClick, tooltipKey }, i) => (
                    <WithTooltip tooltipKey={tooltipKey} key={i}>
                      <span onClick={onClick} className="filterIcons">
                        <Icon />
                      </span>
                    </WithTooltip>
                  )
                ),
              ]}
          {isFromHrManagement && (
            <>
              <AdvancedFilterProvider
                {...{
                  rowData,
                  setRowData,
                  customFilterTitle: title,
                  fetchAllHandler: () => {},
                }}
              >
                <MondayButton
                  onClick={() => setSaveFiltersModal(true)}
                  className="mondayButtonBlue"
                  Icon={<CheckIconModal />}
                  // tooltipCategory={tooltipCategory}
                  tooltipKey="saveFilters"
                >
                  Save Filters
                </MondayButton>
                {Object.keys(templateOptions).length > 0 && (
                  <FilterSelection
                    {...{
                      selectedTemplate,
                      templateOptions,
                      columnApi,
                      gridApi,
                      filtersObject: [],
                      setFiltersData,
                      customFilterTitle: title,
                      filterSearchData: () => {},
                      clearFilterBtn,
                      rowData,
                    }}
                  />
                )}
              </AdvancedFilterProvider>
            </>
          )}
          {hasNew && (
            <MondayButton
              data-testid="payroll-add-new-btn"
              Icon={<AddIcon />}
              onClick={() => {
                if (customNew) {
                  setModalVisible(true);
                } else {
                  setNewModalVisible(true);
                }
              }}
              tooltipKey="onNew"
            >
              {`New ${
                title.slice(title.length - 3, title.length) === "ies"
                  ? title.slice(0, title.length - 3) + "y"
                  : title.slice(title.length - 1, title.length) === "s"
                  ? title.slice(0, title.length - 1)
                  : title
              }`}
            </MondayButton>
          )}
        </div>
      </div>
      <div className="layout-children-container">
        <div className="payrollGridWrapper">
          <div
            data-testid="payroll-layout-grid-container"
            className={`payroll-grid ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
          >
            <AgGridReact
              ref={gridRef}
              sideBar={sideBar}
              gridApi={gridApi}
              rowData={rowData}
              pagination={true}
              context={context}
              animateRows={true}
              rowSelection="multiple"
              columnDefs={gridColumns}
              reactiveCustomComponents
              getRowStyle={getRowStyle}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              postSortRows={postSortRows}
              rowGroupPanelShow={"always"}
              onCellClicked={onCellClicked}
              rowMultiSelectWithClick={true}
              isRowSelectable={isRowSelectable}
              suppressCellFocus={suppressCellFocus}
              onCellValueChanged={onCellValueChanged}
              onRowDoubleClicked={onRowDoubleClicked}
              onSelectionChanged={onSelectionChanged}
              onFirstDataRendered={onFirstDataRendered}
              pinnedBottomRowData={pinnedBottomRowData}
              enableRangeSelection={rowMultiSelectWithClick}
              overlayLoadingTemplate={overlayLoadingTemplate}
              onColumnRowGroupChanged={onColumnRowGroupChanged}
              enterNavigatesVertically={enterNavigatesVertically}
              defaultExcelExportParams={defaultExcelExportParams}
              defaultColDef={defaultColumnDefinition || defaultColumnDefs}
              components={{
                leftAlignRenderer: LeftAlignRenderer,
                ...components,
              }}
              {...{
                rowHeight,
                headerHeight,
                ...(paginationPageSize
                  ? {
                      paginationPageSize,
                    }
                  : {
                      paginationAutoPageSize: true,
                    }),
                ...(dataIdKey
                  ? {
                      getRowId,
                    }
                  : {}),
                ...additionalGridProps,
              }}
            />
          </div>
        </div>
      </div>
      {(newModalVisible || modalVisible) && !customNew ? (
        <NewModal
          title={title}
          rowData={rowData}
          rowToEdit={rowToEdit}
          editMode={!!rowToEdit}
          setRowData={setRowData}
          degStepFilters={degStepFilters}
          open={newModalVisible || modalVisible}
          visible={newModalVisible || modalVisible}
          setVisible={modalVisible ? setModalVisible : setNewModalVisible}
          refreshTable={(data, action = "add") => {
            if (action === "add") {
              if (Array.isArray(data)) {
                gridApi.applyTransaction({ add: data, addIndex: 0 });
              } else {
                gridApi.applyTransaction({ add: [data], addIndex: 0 });
              }
              onRowAdd(data);
            } else if (action === "delete") {
              gridApi.applyTransaction({ remove: [data] });
              onRowRemove(data);
            } else if (action === "edit") {
              gridApi.applyTransaction({ update: [data] });
              onRowEdit(data);
            }
          }}
          onCancel={() => {
            if (modalVisible) {
              setModalVisible(false);
            }
            setNewModalVisible(false);
            setRowToEdit(false);
            setDegStepFilters({});
            sessionStorage.removeItem("degStepFilters");
          }}
        />
      ) : null}
      {chartModalVisible && (
        <ChartModal
          {...{
            onSubmit: drawChart,
            open: chartModalVisible,
            onCancel: closeChartModal,
            // chartingOptions: chartArr,
            onDateChange: handleChartDateChange,
            data: getModifiedChartData(rowData, chartStartDate, chartEndDate),
          }}
        />
      )}
      {!!chartOptions && (
        <ChartComponent
          {...{
            open: !!chartOptions,
            onCancel() {
              setChartOptions();
            },
            options: chartOptions,
          }}
        />
      )}
      {filterOpen && (
        <Filter
          title={title}
          filters={gridFilters}
          setOpen={setFilterOpen}
          filtersData={filtersData}
          clearFilters={clearFilters}
          rowData={
            rowData ||
            gridApi?.clientSideRowModel?.rowsToDisplay?.map?.(
              (node) => node?.data
            )
          }
          getFilters={(val) => {
            // eslint-disable-next-line no-undef
            setFiltersData(structuredClone(val));
            if (gridApi) {
              gridApi.paginationGoToFirstPage();
            }
          }}
        />
      )}
      {saveFiltersModal && (
        <AdvancedFilterProvider
          {...{
            rowData,
            setRowData,
            customFilterTitle: title,
            fetchAllHandler: () => {},
          }}
        >
          <SaveFiltersModal
            {...{
              gridApi,
              columnApi,
              templateOptions,
              saveFiltersModal,
              customFilterTitle: title,
              setSaveFiltersModal,
            }}
          />
        </AdvancedFilterProvider>
      )}
    </div>
  );
}

PayrollLayout.propTypes = {
  hasNew: PropTypes.bool,
  title: PropTypes.string,
  rowData: PropTypes.array,
  onRowAdd: PropTypes.func,
  onRowEdit: PropTypes.func,
  logsFor: PropTypes.object,
  context: PropTypes.object,
  customNew: PropTypes.bool,
  setRowData: PropTypes.func,
  getGridApi: PropTypes.func,
  rowHeight: PropTypes.number,
  dataIdKey: PropTypes.string,
  components: PropTypes.array,
  rowToEdit: PropTypes.object,
  columnDefs: PropTypes.array,
  setRowDataL: PropTypes.func,
  onRowRemove: PropTypes.func,
  gridFilters: PropTypes.array,
  postSortRows: PropTypes.func,
  setRowToEdit: PropTypes.func,
  getColumnApi: PropTypes.func,
  onRowClicked: PropTypes.func,
  modalVisible: PropTypes.bool,
  excelExport: PropTypes.string,
  headerHeight: PropTypes.number,
  isRowSelectable: PropTypes.func,
  setModalVisible: PropTypes.func,
  degStepFilters: PropTypes.object,
  suppressCellFocus: PropTypes.bool,
  setDegStepFilters: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  getModifiedChartData: PropTypes.func,
  paginationPageSize: PropTypes.number,
  customActionButtons: PropTypes.array,
  pinnedBottomRowData: PropTypes.object,
  additionalGridProps: PropTypes.object,
  formatCellValueHandler: PropTypes.func,
  onColumnRowGroupChanged: PropTypes.func,
  suppressDoubleClickEdit: PropTypes.bool,
  rowMultiSelectWithClick: PropTypes.bool,
  overlayLoadingTemplate: PropTypes.string,
  enterNavigatesVertically: PropTypes.bool,
  defaultColumnDefinition: PropTypes.object,
  additionalActionsButtons: PropTypes.array,
  defaultExcelExportParams: PropTypes.object,
};

export default PayrollLayout;
