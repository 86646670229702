import { citationValues } from "./setCitationsValues";

export async function generateCitationObjects(
  data,
  form,
  statuses,
  currentStep,
  dynamicFields,
  objectsWithPk,
  updatedValues,
  employeeName
) {
  const prevObj = {
    citationName: data.citationName,
    citationStatus: data.citationStatus,
    employeeName: data.citationObject?.employeeName,
  };
  const currObj = {
    citationName: form.getFieldValue("citationName"),
    citationStatus: statuses[currentStep]?.statusName,
    employeeName: employeeName,
  };

  const prevPromises = dynamicFields.map((field) =>
    citationValues({
      element: field,
      rowObject: data,
      objectsWithPk,
    })
  );

  const currPromises = dynamicFields.map((field) =>
    citationValues({
      element: field,
      rowObject: updatedValues,
      objectsWithPk,
    })
  );

  const [prevResults, currResults] = await Promise.all([
    Promise.all(prevPromises),
    Promise.all(currPromises),
  ]);

  dynamicFields.forEach((field, index) => {
    prevObj[field.formItemName] = prevResults[index];
    currObj[field.formItemName] = currResults[index];
  });

  return { prevObj, currObj };
}
