import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../../BasePage/src";
import { LoadableComp } from "../../../XComponents";
import { callGmailAPI, getLength, sortBy } from "../../functions";
import { useCommunication } from "../../providers/CommunicationProvider";
import EmailViewFooter from "./footer/EmailViewFooter";
import SingleEmailView from "./single-email-view/SingleEmailView";

import "./EmailView.scss";
import { EmailBoxBodyProvider } from "../EmailBox/providers/EmailBoxBodyProvider";
import { AttachmentsProvider } from "../EmailBox/providers/AttachmentsProvider";

/**
 *
 * @param {String} id id of the selected email
 * @param {Function} setEmails callback function that update state of list of email
 * @param {Object} rowData object of selected application record
 * @param {Object} authToken credintials of logged google user account if not set is false
 * @returns
 */
const EmailView = ({ id, rowData, isEditable = true }) => {
  const [emails, setEmails] = useState([]);
  const { authData } = useCommunication();
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const mainEmail = emails?.find(({ isParent }) => isParent === true);

  useEffect(() => {
    callGmailAPI("getEmail", {
      id,
      authData,
    })
      ?.then((res) => {
        if (!res.data?.error) {
          setEmails(res?.data);
        } else {
          navigate("?tab=Communication");
          message.error({ content: res.data.message });
        }
      })
      .catch((err) => {
        navigate("?tab=Communication");
        console.error(err);
      });
  }, [id]);

  return (
    <LoadableComp {...{ loading: !!!getLength(emails) }}>
      <div className={`email-view-container ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="email-container-header">
          <Button
            className="go-back-btn"
            type="text"
            shape="circle"
            onClick={() => {
              navigate("?tab=Communication");
            }}
            icon={<LeftArrow />}
            data-testid="go-back-btn"
          />
        </div>

        <h2 className="subject">
          {mainEmail?.subject?.replace(/[^\x20-\x7E]/g, "")}
        </h2>

        {emails?.map((email, index) => (
          <AttachmentsProvider>
            <EmailBoxBodyProvider>
              <SingleEmailView
                key={email.id}
                mainEmail={email}
                setEmail={setEmails}
                rowData={rowData}
                authData={authData}
                isEditable={isEditable}
                isLast={index === emails.length - 1}
                emails={emails}
              />
            </EmailBoxBodyProvider>
          </AttachmentsProvider>
        ))}

        <EmailViewFooter
          isEditable={isEditable}
          mainEmail={mainEmail}
          rowData={rowData}
          authData={authData}
          emails={emails}
          setEmail={setEmails}
        />
      </div>
    </LoadableComp>
  );
};
export default EmailView;
