import { PopoverForDate } from "../PopoverForDate";
import { ScheduleDateTimePicker } from "../ScheduleDateTimePicker";
import { Badge, message, Tooltip } from "antd";
import { DayWarningsView } from "../DayWarningsView";
import { v4 as uuidv4 } from "uuid";
import {
  CREW_FLEET_POPOVER_TYPE,
  CrewTrucksSelectPopover,
} from "../SelectView/CrewTrucksSelectPopover";
import { Button, DatePicker, Input, Popover, Space, TimePicker } from "antd";
import WeatherComponent from "../../../WeatherComponent";
import NotFoundIcon from "../../../../../../icons/Not_Found.webp";
import { ScheduleDayNotesModal } from "../ScheduleDayNotesModal";
import { DayStatusChangeModal } from "../DayStatusChangeModal/DayStatusChangeModal";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./ScheduleDayInnerView.scss";
import { useRedux } from "../../../../../SidebarPages/hooks";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { useSelector } from "react-redux";
import {
  automaticPostpone,
  getDaysForSpecificService,
  isHoliday,
  preventDuplicate,
  swalToast,
} from "../../SchedulingFirstPage/helperData";
import ScheduleDay from "../../models/ScheduleDay";
import { MondayButton } from "../../../../../commonComponents";
import { AddIcon } from "../../../../components/GoogleEvents/assets";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import moment from "moment";
import { InfoIconBlue } from "../SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { CalendarIcon, PlusIcon } from "../../../../../../assets";
import { PlusOutlined } from "@ant-design/icons";
import CrewsModal from "../SelectView/newModals/CrewsModal/CrewsModal";

export const ScheduleDayInnerView = ({
  scheduleId,
  setToBeScheduled,
  scheduleDay,
  scheduleDays,
  onlyWorkingDailyHours,
  toBeScheduled,
  dayIndex,
  fleetInfo,
  setScheduleDays,
  projectId,
  scheduleAddress,
  typeOfWork,
  fullWeatherArray,
  hours,
  notesToAddForScheduleDays,
  onRemoveDay,
  updateNotesToAddForScheduleDays,
  changeScheduleDayStatus,
  handleInputChange,
  schedules,
  onRemoveTheShiftedDay,
  editMode,
  setAbleToSave,
  postponedOnce,
  dispatchForSchedule,
  setDisptachDays,
  fromApprovals,
  isDarkMode,
  setDidPostpone,
  thisProjectSchedules,
  scheduleName,
  updateScheduleCrews,
  scheduleCrews,
  createdAt,
  newCrewsUpdate,
}) => {
  const [isWritable] = useRedux("isWritableScheduling");
  const { programFields } = useSelector((state) => state?.programFields);
  const { image, weather, startDate, endDate, status } = scheduleDay || {};
  const [day, setDay] = useState(scheduleDay);
  const [options, setOptions] = useState({
    byNumber: true,
    byDate: false,
    value: null,
  });
  const [visibleCrews, setVisibleCrews] = useState(false);

  const timePickerStyle = {
    width: 90,
    height: 32,
    background: "#FFFFFF",
    borderRadius: 5,
    border: "none",
    background: dayIndex % 2 === 0 ? "#F5F5F7" : "#fff",
  };

  const iconWeatherCSS = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 25,
    height: 25,
    borderRadius: 5,
    background: "#1264A3",
    cursor: "pointer",
    border: "none",
  };

  const daysUsedInServices =
    Object.values(toBeScheduled || {})
      ?.flat()
      ?.flatMap((a) =>
        a?.serviceOptions?.[0]
          ?.flatMap(({ items = [] }) => items)
          ?.flatMap(({ days = [] }) => days)
      )
      ?.filter((a) => scheduleDays?.find(({ id }) => id === a)) || [];

  const removeDay = (e) => {
    setAbleToSave(true);
    if (scheduleDay.comesFromPostponed()) {
      removeShiftedDay(scheduleDay.id);
    } else if (scheduleDay.isNotAvailable()) {
      handleNotAvailableDay(e);
    } else if (daysUsedInServices?.includes(scheduleDay?.id)) {
      message.warning("Can't remove this day because it is in usage!");
    } else if (scheduleDays?.length === 1) {
      message.error("Can't remove the only day!");
    } else if (shouldDeleteWithDispatch()) {
      deleteDayWithDispatch();
    } else {
      onRemoveDay(scheduleDay.id);
      // swalToast({
      //   icon: "success",
      //   title: "Day deleted successfully!",
      //   timer: 3500,
      //   position: "bottom-end",
      //   showConfirmButton: false,
      // });
    }
  };

  const removeShiftedDay = (dayId) => {
    onRemoveTheShiftedDay(dayId);
    swalToast({
      icon: "success",
      title: "Day deleted successfully!",
      timer: 3500,
      position: "bottom-end",
      showConfirmButton: false,
    });
  };

  const handleNotAvailableDay = (e) => {
    e.stopPropagation();
    message.warning(
      `Can't remove ${status} day. Only confirmed days can be deleted like that.`
    );
  };

  const shouldDeleteWithDispatch = () => {
    return (
      dispatchForSchedule?.some(
        (item) =>
          dayjsNY(item.dispatchDate).startOf("day").valueOf() ===
          dayjsNY(scheduleDay.startDate).startOf("day").valueOf()
      ) && editMode
    );
  };

  const deleteDayWithDispatch = () => {
    setDisptachDays({
      action: "Day Delete",
      dayEdited: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
      status: scheduleDay?.status,
      scheduleId,
      prev: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
      new: "Deleted",
      dispatchId: dispatchForSchedule?.find(
        (item) =>
          dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
          dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
      )?.dispatchId,
    });
    onRemoveDay(scheduleDay?.id);
    swalToast({
      icon: "success",
      title: "Day deleted successfully!",
      timer: 3500,
      position: "bottom-end",
      showConfirmButton: false,
    });
  };

  const dayName = new Date(
    dayjs(new Date(scheduleDays?.[dayIndex]?.startDate))
      .tz("America/New_York")
      .format("YYYY/MM/DD")
  ).toLocaleString("default", {
    weekday: "long",
  });

  const disableDate = (e) => {
    let disabled = false;
    scheduleDays?.forEach((day) => {
      if (
        dayjsNY(e).startOf("day").valueOf() ===
        dayjsNY(day?.startDate).startOf("day").valueOf()
      ) {
        disabled = true;
      }
    });
    return disabled;
  };

  const handleTimeChange = (e, type) => {
    const newScheduleDays = [...scheduleDays];
    const currentScheduleDay = newScheduleDays?.[dayIndex];

    if (type === "startDate") {
      if (dayjsNY(e).isSame(dayjsNY(currentScheduleDay?.endDate), "minute")) {
        message.error("Start date can't be equal to end date!");
        return;
      } else if (
        dayjsNY(e).isAfter(dayjsNY(currentScheduleDay?.endDate), "minute")
      ) {
        message.error("End date can't be before start date!");
        return;
      } else {
        currentScheduleDay.startDate = e;
      }
    } else if (type === "endDate") {
      if (dayjsNY(e).isSame(dayjsNY(currentScheduleDay?.startDate), "minute")) {
        message.error("End date can't be equal to start date!");
        return;
      } else if (
        dayjsNY(e).isBefore(dayjsNY(currentScheduleDay?.startDate), "minute")
      ) {
        message.error("End date can't be before start date!");
        return;
      } else {
        currentScheduleDay.endDate = e;
      }
    }

    setScheduleDays(newScheduleDays);
    setAbleToSave(true);
  };

  const usHolidays =
    programFields?.find?.(
      ({ fieldId = "" }) => fieldId === "0f4dd981-1638-48a6-a54c-1fdb5ecab319"
    )?.fieldOptions || [];

  const crewsForThisDay =
    scheduleCrews?.length > 0
      ? scheduleCrews?.filter((crew) =>
          crew?.days?.some(
            (day) =>
              dayjsNY(day?.startDate).format("MM/DD/YYYY") ===
              dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")
          )
        )
      : [];

  const addDaysByOptions = async () => {
    const { byNumber, byDate, value } = options || {};
    let daysToBeAdded = [];
    let loadingMessage = null;
    let successMessage = null;

    try {
      if (!value) {
        throw new Error("Please enter a valid value!");
      }

      const getDifference = (date1, date2) => {
        return dayjs(date1).diff(dayjs(date2), "day");
      };

      const nr = !!byNumber
        ? value
        : getDifference(value, scheduleDay?.startDate);

      loadingMessage = message.loading(
        `Adding ${nr} ${nr === 1 ? "day" : "days"}...`,
        0
      );

      if (!!byNumber) {
        let actualDay = 0;
        for (let i = 0; i <= value; i++) {
          const currentData = scheduleDay;
          let nextDay = dayjs(currentData?.startDate).add(i + 1, "day");
          if (nextDay.day() === 0 || nextDay.day() === 6) {
            continue;
          }
          actualDay++;
          daysToBeAdded.push(
            Object.assign(new ScheduleDay(), {
              id: uuidv4(),
              day: actualDay + dayIndex + 1,
              startDate: nextDay.valueOf(),
              endDate: dayjs(currentData?.endDate)
                .add(i + 1, "day")
                .valueOf(),
              status: "Confirmed",
              crews: [],
              fleet: [],
              desiredCrewNr: 0,
              notes: [],
              weather: [],
              image: "",
              linkedDays: {
                comesFrom: undefined,
                postponedTo: undefined,
              },
            })
          );
        }
      } else {
        let date = dayjs(value);
        let actualDay = 0;
        let currentData = scheduleDay;
        let days = date.diff(dayjs(currentData?.startDate), "day");
        for (let i = 0; i <= days; i++) {
          if (
            dayjs(currentData?.startDate)
              .add(i + 1, "day")
              .day() === 0 ||
            dayjs(currentData?.startDate)
              .add(i + 1, "day")
              .day() === 6
          ) {
            continue;
          }
          actualDay++;
          daysToBeAdded.push(
            Object.assign(new ScheduleDay(), {
              id: uuidv4(),
              day: actualDay + dayIndex + 1,
              startDate: dayjs(currentData?.startDate)
                .add(i + 1, "day")
                .valueOf(),
              endDate: dayjs(currentData?.endDate)
                .add(i + 1, "day")
                .valueOf(),
              status: "Confirmed",
              crews: [],
              fleet: [],
              desiredCrewNr: 0,
              notes: [],
              weather: [],
              image: "",
              linkedDays: {
                comesFrom: undefined,
                postponedTo: undefined,
              },
            })
          );
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));

      successMessage = message.success(`Days added successfully!`);
    } catch (error) {
      message.error(error.message);
    } finally {
      if (loadingMessage) {
        loadingMessage();
      }
      if (successMessage) {
        setAbleToSave(true);
        const element = document.getElementById("days-container-scroll");
        if (element) {
          setTimeout(() => {
            element.scroll({
              left: 0,
              top: element.scrollHeight + 200,
              behavior: "smooth",
            });
          }, 0);
        }
      }
      setScheduleDays([...scheduleDays, ...daysToBeAdded]);
    }
  };

  return (
    <>
      <div className="inner-schedule" id={dayIndex === 0 ? "schedule-day" : ""}>
        <Tooltip title="Remove this day">
          <div className="delete-schedule-day-X">
            <XIcon
              width={10}
              height={10}
              onClick={
                fromApprovals
                  ? removeDay
                  : editMode
                  ? isWritable
                    ? removeDay
                    : ""
                  : removeDay
              }
            />
          </div>
        </Tooltip>
        <div className="date-values-schedule">
          <div className="values-container">
            <span className="value-title">Day</span>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#1f2a44",
                color: "#fff",
                width: 30,
                height: 30,
                border: "none",
              }}
            >
              {scheduleDay?.day}
            </Button>
          </div>
          <div className="values-container">
            <div style={{ display: "flex", gap: "0.4rem" }}>
              <div className="value-title">Start date</div>
              <div
                style={{
                  fontWeight: 600,
                  color: ["Saturday", "Sunday"]?.includes?.(dayName)
                    ? "rgb(221, 73, 73)"
                    : "#68c142",
                }}
              >
                ({dayName})
              </div>
            </div>
            <Tooltip
              title={
                dayjs(new Date(scheduleDays?.[dayIndex]?.startDate)).valueOf() <
                dayjs().valueOf()
                  ? "This day is in the past"
                  : ""
              }
            >
              <DatePicker
                format={"MM/DD/YYYY"}
                className="datepicker-schedule-day"
                showToday={false}
                inputReadOnly={true}
                allowClear={false}
                disabledDate={(e) => disableDate(e)}
                popupClassName={isDarkMode && "darkDateDropDown"}
                disabled={
                  postponedOnce
                    ? true
                    : !!fromApprovals
                    ? false
                    : editMode
                    ? !isWritable
                    : false
                }
                style={{
                  width: 168,
                  borderRadius: 5,
                  background: dayIndex % 2 === 0 ? "#F5F5F7" : "#fff",
                  border: "none",
                }}
                value={dayjs(
                  new Date(scheduleDays?.[dayIndex]?.["startDate"])
                ).tz("America/New_York")}
                onChange={(e) => {
                  if (!!scheduleDay?.linkedDays?.comesFrom) {
                    message.error(
                      "This day is linked to another day. Please postpone the day instead!"
                    );
                    return;
                  }

                  if (
                    dispatchForSchedule?.some(
                      (item) =>
                        dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
                        dayjsNY(scheduleDays[dayIndex]?.startDate)
                          .startOf("day")
                          .valueOf()
                    ) &&
                    editMode
                  ) {
                    setDisptachDays({
                      action: "Change of date",
                      prev: dayjs(scheduleDays[dayIndex]?.startDate).format(
                        "MM/DD/YYYY"
                      ),
                      new: dayjs(e).format("MM/DD/YYYY"),
                      dayEdited: dayjs(scheduleDay?.startDate).format(
                        "MM/DD/YYYY"
                      ),
                      status: scheduleDay?.status,
                      scheduleId,
                      dispatchId: dispatchForSchedule?.find(
                        (item) =>
                          dayjsNY(item?.dispatchDate)
                            .startOf("day")
                            .valueOf() ===
                          dayjsNY(scheduleDay?.startDate)
                            .startOf("day")
                            .valueOf()
                      )?.dispatchId,
                    });
                  }

                  let prevStart = dayjs(scheduleDays[dayIndex]?.startDate).tz(
                    "America/New_York"
                  );
                  let tempStart = dayjs(e)
                    .tz("America/New_York")
                    .set({
                      hour: dayjs(prevStart).tz("America/New_York").hour(),
                      minute: dayjs(prevStart).tz("America/New_York").minute(),
                      second: "00",
                    });
                  let prevEnd = dayjs(scheduleDays[dayIndex]?.endDate).tz(
                    "America/New_York"
                  );
                  let tempEnd = dayjs(e)
                    .tz("America/New_York")
                    .set({
                      hour: dayjs(prevEnd).tz("America/New_York").hour(),
                      minute: dayjs(prevEnd).tz("America/New_York").minute(),
                      second: "00",
                    });

                  handleInputChange(
                    dayIndex,
                    [tempStart, tempEnd],
                    "bothDates"
                  );
                }}
              ></DatePicker>
            </Tooltip>
          </div>

          <div className="values-container">
            <div className="value-title">Start time</div>
            <TimePicker
              value={dayjs(new Date(scheduleDays[dayIndex]?.["startDate"])).tz(
                "America/New_York"
              )}
              format={"h:mm a"}
              popupClassName={isDarkMode && "darkDateDropDown"}
              style={timePickerStyle}
              allowClear={false}
              showNow={false}
              inputReadOnly={true}
              current
              onChange={(event) => {
                if (
                  dispatchForSchedule?.some(
                    (item) =>
                      dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
                      dayjsNY(scheduleDays[dayIndex]?.startDate)
                        .startOf("day")
                        .valueOf()
                  ) &&
                  editMode
                ) {
                  setDisptachDays({
                    action: "Start Time Change",
                    dayEdited: dayjs(scheduleDay?.startDate).format(
                      "MM/DD/YYYY"
                    ),
                    status: scheduleDay?.status,
                    scheduleId,
                    prev: dayjs(scheduleDays?.[dayIndex]?.startDate).format(
                      "h:mm a"
                    ),
                    new: dayjs(event).format("h:mm a"),
                    dispatchId: dispatchForSchedule?.find(
                      (item) =>
                        dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
                        dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
                    )?.dispatchId,
                  });
                }

                handleTimeChange(event, "startDate");
                // handleInputChange(dayIndex, event, "startDate");
              }}
              disabled={
                postponedOnce
                  ? true
                  : !!fromApprovals
                  ? false
                  : editMode
                  ? !isWritable
                  : false
              }
            />
          </div>
          <div
            className="values-container"
            disabled={!!fromApprovals ? false : editMode ? !isWritable : false}
          >
            <div className="value-title">End time</div>
            <TimePicker
              value={dayjs(new Date(scheduleDays[dayIndex]?.["endDate"])).tz(
                "America/New_York"
              )}
              allowClear={false}
              inputReadOnly={true}
              style={timePickerStyle}
              showNow={false}
              popupClassName={isDarkMode && "darkDateDropDown"}
              onChange={(event) => {
                handleTimeChange(event, "endDate");
              }}
              disabled={
                postponedOnce
                  ? true
                  : !!fromApprovals
                  ? false
                  : editMode
                  ? !isWritable
                  : false
              }
              format={"h:mm a"}
            />
          </div>
        </div>

        <div className="bottom-structure-day-inner">
          {editMode ? (
            <>
              <DayStatusChangeModal
                {...{
                  scheduleDays,
                  scheduleDay,
                  changeScheduleDayStatus,
                  notesToAddForScheduleDays,
                  toBeScheduled,
                  editMode,
                  updateNotesToAddForScheduleDays,
                  editMode,
                  postponedOnce,
                  setDisptachDays,
                  dispatchForSchedule,
                  scheduleId,
                  isWritable,
                  daysForDispatch: dispatchForSchedule,
                  fromApprovals,
                  setDidPostpone,
                  scheduleName,
                }}
              />
            </>
          ) : null}
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 25,
              height: 25,
              background: "#1264a3",
              borderRadius: 5,
              color: "#fff",
              border: "none",
            }}
            onClick={() => setVisibleCrews(true)}
          >
            {crewsForThisDay?.length || 0}
          </Button>
          {/* {!postponedOnce && !fromApprovals && (
            <CrewTrucksSelectPopover
              {...{
                dayIndex,
                scheduleDays,
                setScheduleDays,
                scheduleDay,
                projectId,
                scheduleAddress,
                scheduleId,
                schedules,
                type: CREW_FLEET_POPOVER_TYPE.CREWS,
                tip: true,
                setAbleToSave,
                className: "dayCardBtn2",
                editMode,
                setToBeScheduled,
                toBeScheduled,
                setScheduleDays,
                thisProjectSchedules,
                updateScheduleCrews,
                scheduleCrews,
                createdAt,
                newCrewsUpdate,
              }}
            />
          )} */}
          {!postponedOnce && !fromApprovals && editMode && (
            <CrewTrucksSelectPopover
              {...{
                scheduleId,
                dayIndex,
                fleetInfo,
                scheduleDays,
                setScheduleDays,
                scheduleDay: day,
                scheduleAddress,
                typeOfWork,
                type: CREW_FLEET_POPOVER_TYPE.FLEET,
                className: "dayCardBtn2",
                setAbleToSave,
                editMode,
                setToBeScheduled,
                toBeScheduled,
                dispatchForSchedule,
              }}
            />
          )}
          {!fromApprovals && (
            <Popover
              title="Weather For Respective Days"
              trigger="click"
              placement="right"
              overlayClassName={`this-day-weather-popover ${
                isDarkMode && "this-day-weather-popover-dark"
              }`}
              content={
                <WeatherComponent
                  {...{
                    fullWeatherArray,
                    scheduleAddress,
                    startDate,
                    endDate,
                    weatherThisDay: weather,
                    isDarkMode,
                  }}
                />
              }
            >
              <div>
                <div style={iconWeatherCSS}>
                  <img
                    style={{ width: 20, height: 20 }}
                    src={image || NotFoundIcon}
                    alt={""}
                  />
                </div>
              </div>
            </Popover>
          )}
          <Badge
            count={
              !!notesToAddForScheduleDays?.[scheduleDay?.id]?.text ||
              scheduleDay?.notes?.length > 0
                ? "1"
                : null
            }
            color="#71cf48"
            size="small"
          >
            <ScheduleDayNotesModal
              {...{
                scheduleDay,
                notesToAddForScheduleDays,
                updateNotesToAddForScheduleDays,
                editMode,
                setAbleToSave,
                setToBeScheduled,
              }}
            />
          </Badge>

          {dayIndex === scheduleDays?.length - 1 && (
            <div>
              <Popover
                title={null}
                trigger={"click"}
                overlayClassName={`add-days-popover-shortcut${
                  isDarkMode ? "dark" : ""
                }`}
                placement="topRight"
                onOpenChange={(e) => {
                  if (e) {
                    setOptions({
                      byNumber: true,
                      byDate: false,
                      value: null,
                    });
                  }
                }}
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      color: isDarkMode ? "#fff" : "#000",
                    }}
                  >
                    <div style={{ display: "flex", gap: 10 }}>
                      <span>{<InfoIconBlue />}</span>
                      <span style={{ width: 550 }}>
                        Add multiple days by specifying how many days after the
                        last day of the schedule you want to add at once using
                        the number option. And when using the date option, the
                        gap between the last current day in schedule and the
                        selected day will be filled.
                        <br />
                        <span>
                          <span>
                            Only <b>100</b> days can be added at once.
                          </span>
                          <br />
                        </span>
                      </span>
                    </div>
                    <div
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <Button
                        style={{
                          borderRadius: 5,
                          background: options?.byNumber ? "#1264A3" : "#fff",
                          color: options?.byNumber ? "#fff" : "#1264A3",
                          border: "none",
                        }}
                        onClick={() =>
                          setOptions({
                            byNumber: true,
                            byDate: false,
                          })
                        }
                      >
                        By Number
                      </Button>
                      <Button
                        style={{
                          borderRadius: 5,
                          background: options?.byDate ? "#1264A3" : "#fff",
                          color: options?.byDate ? "#fff" : "#1264A3",
                          border: "none",
                        }}
                        onClick={() => {
                          setOptions({
                            byNumber: false,
                            byDate: true,
                          });
                        }}
                      >
                        By Date
                      </Button>
                    </div>
                    <div style={{ display: "flex", gap: 7 }}>
                      <InputComponent
                        type={!!options?.byNumber ? "text" : "datepicker"}
                        dropdownClassName={isDarkMode ? "darkDateDropDown" : ""}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addDaysByOptions();
                          }
                        }}
                        placeholder={
                          !!options?.byNumber
                            ? "Enter number of days"
                            : "Enter date"
                        }
                        value={options?.value}
                        defaultValue={
                          options?.byDate ? dayjs(scheduleDay?.startDate) : null
                        }
                        disabledDate={(e) =>
                          dayjs(e)
                            .startOf("day")
                            .isSameOrBefore(
                              dayjs(scheduleDay?.startDate).startOf("day")
                            ) ||
                          dayjs(e)
                            .startOf("day")
                            .isSameOrAfter(
                              dayjs(scheduleDay?.startDate)
                                .add(101, "day")
                                .startOf("day")
                            )
                        }
                        onChange={(e) => {
                          const value = options?.byNumber
                            ? Number(e.target.value)
                            : e;
                          if (options?.byNumber && isNaN(value)) {
                            message.error("Only numbers are allowed!");
                            return;
                          }
                          if (options?.byNumber && value > 100) {
                            message.error(
                              "Only 100 days can be added at once!"
                            );
                            return;
                          }

                          setOptions({
                            ...options,
                            value,
                          });
                        }}
                      />
                      <MondayButton
                        onClick={() => addDaysByOptions()}
                        {...{
                          Icon: <AddIcon />,
                        }}
                      >
                        Add
                      </MondayButton>
                    </div>
                  </div>
                }
              >
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(113, 207, 72)",
                    width: 25,
                    height: 25,
                    border: "none",
                    borderRadius: 5,
                    color: "#fff",
                  }}
                >
                  <span>
                    <PlusOutlined />
                  </span>
                </Button>
              </Popover>
            </div>
          )}
          <div style={{ display: "flex", gap: "1rem" }}>
            <Tooltip
              title={
                hours < 10 ? (
                  <span>
                    Total hours: {parseFloat(hours)}. Working hours:{" "}
                    {onlyWorkingDailyHours}{" "}
                    {parseFloat(hours) > 7 ? "and (1 hour break)" : null}
                  </span>
                ) : (
                  <div>
                    {`${hours - 9}`}
                    {hours - 9 === 1 ? " hour" : " hours"} overtime
                  </div>
                )
              }
            >
              <span className={"day-hours "}>
                {(!!hours ? hours : 9) < 10 ? (
                  onlyWorkingDailyHours
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.4rem",
                    }}
                  >
                    <div>{`${onlyWorkingDailyHours}`}</div>
                  </div>
                )}
              </span>
            </Tooltip>
            {isHoliday(usHolidays, scheduleDay) && (
              <div>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#fe4c4a",
                  }}
                >
                  Holiday
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {visibleCrews && (
        <CrewsModal
          {...{
            visibleCrews,
            setVisibleCrews,
            scheduleDay,
            crewsForThisDay,
            updateScheduleCrews,
            dayIndex,
            scheduleDays,
            scheduleId,
          }}
        />
      )}
    </>
  );
};
