import { Button, Dropdown } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ImportantIconGolden,
  Mail,
  MailOpen,
  NotImportant,
  ThreeDotsIcon,
} from "../../../../assets";
import {
  getIsImportantAndNotImportant,
  getIsImportantStatus,
  getIsUnreadAndRead,
  getIsUnreadStatus,
} from "../../../../functions";

import "./MoreActions.scss";

function MoreActions({ checkedList, handleMarkAs, emails }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const isUnread =
    useMemo(() => {
      return getIsUnreadStatus(checkedList);
    }, [checkedList]) || false;
  const isImportant = getIsImportantStatus(checkedList) || false;
  const isUnreadAndRead = getIsUnreadAndRead(checkedList) || false;
  const isImportantAndNotImportant =
    getIsImportantAndNotImportant(checkedList) || false;

  const getMarkAsImportantView = isImportantAndNotImportant
    ? [
        {
          key: "important",
          label: (
            <Button
              type="link"
              onClick={() =>
                handleMarkAs(
                  "IMPORTANT",
                  checkedList.map((email) => email.id)
                )
              }
              icon={<ImportantIconGolden />}
            >
              Mark as important
            </Button>
          ),
        },
        {
          key: "not-important",
          label: (
            <Button
              type="link"
              onClick={() =>
                handleMarkAs(
                  "NOT IMPORTANT",
                  checkedList.map((email) => email.id)
                )
              }
              icon={<NotImportant className="not-important-icon" />}
            >
              Mark as not important
            </Button>
          ),
        },
      ]
    : [
        {
          key: "important-not-important",
          label: (
            <Button
              type="link"
              onClick={() =>
                handleMarkAs(
                  isImportant ? "NOT IMPORTANT" : "IMPORTANT",
                  checkedList.map((email) => email.id)
                )
              }
              icon={
                isImportant ? (
                  <NotImportant className="not-important-icon" />
                ) : (
                  <ImportantIconGolden />
                )
              }
            >
              {isImportant ? "Mark as not important" : "Mark as important"}
            </Button>
          ),
        },
      ];

  const getMarkAsView = isUnreadAndRead
    ? [
        {
          key: "1",
          label: (
            <Button
              type="link"
              onClick={() =>
                handleMarkAs(
                  "READ",
                  checkedList.map((email) => email.id)
                )
              }
              icon={<MailOpen />}
              data-testid="mark-as-read-btn"
            >
              Mark as read
            </Button>
          ),
        },
        {
          key: "2",
          label: (
            <Button
              type="link"
              onClick={() =>
                handleMarkAs(
                  "UNREAD",
                  checkedList.map((email) => email.id)
                )
              }
              icon={<Mail />}
            >
              Mark as unread
            </Button>
          ),
        },
      ]
    : [
        {
          key: "1",
          label: (
            <Button
              type="link"
              onClick={() => {
                handleMarkAs(
                  isUnread ? "READ" : "UNREAD",
                  checkedList.map((email) => email.id)
                );
              }}
              icon={isUnread ? <MailOpen /> : <Mail />}
              data-testid={isUnread ? "mark-as-read-btn" : "mark-as-unread-btn"}
            >
              {isUnread ? "Mark as read" : "Mark as unread"}
            </Button>
          ),
        },
      ];

  const items =
    checkedList?.length > 0
      ? [...getMarkAsView, ...getMarkAsImportantView]
      : [
          {
            key: "1",
            label: (
              <Button
                type="link"
                onClick={() =>
                  handleMarkAs(
                    "READ",
                    emails.map((email) => email.id)
                  )
                }
                icon={<MailOpen />}
              >
                Mark all as read
              </Button>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "2",
            title: "Test",
            disabled: true,
            label: (
              <p className="select-emails-to-see-more-actions">
                Select emails to see more actions
              </p>
            ),
          },
        ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      overlayClassName={`more-actions-dropdown-overlay ${
        isDarkMode ? "dark-mode" : ""
      }`}
      data-testid="more-actions-component"
    >
      <Button
        type="text"
        className="three-dots-icon"
        data-testid="more-actions-component-trigger"
      >
        <ThreeDotsIcon />
      </Button>
    </Dropdown>
  );
}

export default MoreActions;
