import { API } from "aws-amplify";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../utils";
import { updateProgramFieldByName } from "../../../../../../../../actions/programFields";
import broadcastNotification from "../../../../../../../../helpers/controllers/broadcastNotification";
import { Groups } from "../../../RolesData";

const deleteDepartment = async (
  depToDelete = "",
  programFields = [],
  dispatch = () => {},
  userConfiguration = {},
  userGroupsData = [],
  callbackFn = () => {}
) => {
  if (!depToDelete) {
    showErrorMsg({
      content: "No department selected to delete!",
    });
    return;
  }
  showLoadingMsg({ content: "Deleting department. Please wait..." });

  const departmentGroups = Groups({
    groups: userGroupsData.filter(
      (group) =>
        group.departmentName.toLowerCase() === depToDelete.toLowerCase()
    ),
  });

  const allDepartments = programFields?.find(
    ({ fieldName }) => fieldName === "Departments"
  );
  if (!!departmentGroups && departmentGroups.length > 0) {
    showErrorMsg({
      content:
        "Its possible to delete department only if there are no roles for that department!",
    });
  } else {
    const newDepartments = allDepartments.fieldOptions?.filter(
      (el) => el.departmentName !== depToDelete
    );
    await API.patch(
      "programFields",
      `/programFields/5f3ef5a5-3e9f-4365-bcb0-b93278ed2452`,
      {
        body: {
          fieldOptions: newDepartments,
        },
      }
    ).then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: "Departments",
          fieldOptions: newDepartments,
        })
      );
      broadcastNotification(
        "64",
        `onDeleteDepartment`,
        [
          {
            common: userConfiguration.nameOfUser,
            commonNext: depToDelete,
          },
          {
            userName: userConfiguration.nameOfUser,
            currentUser: userConfiguration.cognitoUserId,
          },
        ],
        ""
      );
      callbackFn();
      showSuccessMsg({
        content: "Department has been deleted successfully!",
      });
    });
  }
};

export default deleteDepartment;
