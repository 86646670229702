import React from "react";
import { Tooltip } from "antd";

import "./ProposalSidebar.scss";

const Sidebar = ({ chosenView, onSwitchView, proposalSidebarData = [] }) => {
  return (
    <div className="sidebar-content">
      {proposalSidebarData.map((view, index) => (
        <Tooltip
          placement="right"
          title={view.hoverText}
          key={index + view.key}
        >
          <div
            className={`sidebar-item ${chosenView === index ? "selected" : ""}`}
            onClick={() => onSwitchView(index)}
          >
            {view.icon}
          </div>
        </Tooltip>
      ))}{" "}
    </div>
  );
};

export default Sidebar;
