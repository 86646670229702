import { diffWords } from "diff";
import { checkForTag } from "./checkForTag";

export const highlightDifferences = (topicTemp, content, returnKey = false) => {
  // add p tag at the beginning and at the end of the text if it does not have
  const updatedContent = checkForTag(content);
  const updatedTopicTemp = checkForTag(topicTemp);

  const diffResult = diffWords(updatedTopicTemp, updatedContent);

  let extraWord = "";
  const res = [];

  // Edit diffResult for wrong separation of tags
  diffResult.forEach((item, index) => {
    const lastChars = item.value.slice(-2);
    const stringWithoutLastChars = item.value.slice(0, -2);

    if (lastChars === "</") {
      const newValue = extraWord
        ? extraWord + stringWithoutLastChars
        : stringWithoutLastChars;
      extraWord = "</";
      res.push({
        ...item,
        value: newValue,
      });
    } else if (lastChars.slice(-1) === "<") {
      if (item[index + 1] === "/") return (extraWord = "<");

      const newValue = extraWord
        ? extraWord + item.value.slice(0, -1)
        : item.value.slice(0, -1);
      extraWord = "<";
      res.push({
        ...item,
        value: newValue,
      });
    } else if (item.value === "/") {
      return (extraWord = "</");
    } else {
      const newValue = extraWord ? extraWord + item.value : item.value;
      extraWord = "";
      res.push({
        ...item,
        value: newValue,
      });
    }
  });

  // Adding style color green or red to text
  let coloredContent = "";
  let coloredTopicTemp = "";

  res.forEach((part) => {
    if (part.added) {
      coloredTopicTemp += `<span style="color:green">${part.value}</span>`;
    } else if (part.removed) {
      coloredContent += `<span style="color:red">${part.value}</span>`;
    } else {
      coloredContent += part.value;
      coloredTopicTemp += part.value;
    }
  });

  const result = {
    prev: coloredContent,
    curr: coloredTopicTemp,
  };

  if (!returnKey) {
    return result;
  }

  return result[returnKey];
};
