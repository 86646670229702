import { formatCurrency } from "../../../utils";
import RemoveItem from "../components/RemoveItem";
import GridEditableCell from "../../../../commonComponents/GridEditableCell/GridEditableCell";
import TaxCheckBox from "../components/TaxCheckbox";
import BillableCheckBox from "../components/BillableCheckBox";
import ItemSelect from "../components/ItemSelect";
import ProjectSelect from "../components/ProjectSelect";
import ServiceSelect from "../components/ServiceSelect";

const cellStyle = {
  color: "white",
  backgroundColor: "#68C142",
};

export const newBillColumnDefs = () => [
  { headerName: "#", field: "itemId", maxWidth: 60 },
  {
    headerName: "Name",
    field: "itemName",
    maxWidth: 300,
    cellRenderer: (props) => <ItemSelect {...props} />,
  },
  { headerName: "Category", field: "itemCategory", maxWidth: 200 },
  { headerName: "Description", field: "itemDescription",editable: true, },
  {
    headerName: "Project",
    field: "projectName",
    maxWidth: 300,
    cellRenderer: (props) => <ProjectSelect {...props} />,
  },
  {
    headerName: "Service",
    field: "itemService",
    cellRenderer: (props) => <ServiceSelect {...props} />,
  },
  {
    headerName: "Amount",
    field: "amount",
    cellStyle,
    editable: true,
    maxWidth: 160,
    cellEditor: "PeriodCellEditor",
    cellRenderer: ({ value }) => (
      <GridEditableCell value={formatCurrency(value)} hasIcon />
    ),
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    cellStyle,
    maxWidth: 160,
    cellEditor: "PeriodCellEditor",
    cellRenderer: ({ value }) => (
      <GridEditableCell value={formatCurrency(value)} />
    ),
  },
  {
    headerName: "Billable",
    field: "billable",
    maxWidth: 110,
    cellRenderer: BillableCheckBox,
  },

  {
    headerName: "Tax",
    field: "tax",
    maxWidth: 81,
    cellRenderer: TaxCheckBox,
  },
  {
    headerName: "Total",
    field: "total",
    cellStyle,
    maxWidth: 160,
    cellEditor: "PeriodCellEditor",
    cellRenderer: ({ value }) => (
      <GridEditableCell value={formatCurrency(value)} />
    ),
  },
  { headerName: "", field: "itemId", maxWidth: 50, cellRenderer: RemoveItem },
];
