import { Upload } from "antd";
import PDFCard from "../PDFCard/PDFCard";
import { useSelector } from "react-redux";
import { FileSearchOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../../commonComponents";
import { PdfMergerIcon } from "../../FirstProposalPage/assets";
import PdfsFinderFromDocuments from "../PdfsFinderFromDocuments/PdfsFinderFromDocuments";

function DocMergerUpload({
  allFilesList = [],
  onUploadChange,
  customConfigPages,
  setCustomConfigPages,
  customFilesChanger,
  customFiles,

  onMoveDown,
  onMoveUp,
  onDelete,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      key="customFilesContainer"
      style={{
        ...(!allFilesList?.length ? { justifyContent: "center" } : {}),
      }}
      className="customFilesContainer"
    >
      <div className="pdf-list">
        {allFilesList?.map((file, idx) => (
          <PDFCard
            fileName={file?.name}
            key={file?.uid || file.id || idx}
            file={file}
            setCustomConfigPages={setCustomConfigPages}
            configPages={customConfigPages}
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
            onDelete={onDelete}
          />
        ))}
      </div>
      <div
        style={{
          gap: "10px",
          padding: 20,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {" "}
        {allFilesList.length === 0 ? <PdfMergerIcon /> : null}
        <div
          style={{
            gap: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Upload
            onChange={({ fileList }) => {
              onUploadChange({ fileList, type: "local" });
            }}
            fileList={[]}
            listType="picture"
            // className="proposalDraggerDocuments"
            maxCount={15}
            accept="application/pdf"
          >
            <MondayButton
              className="mondayButtonBlue"
              Icon={<FileSearchOutlined />}
            >
              Browse
            </MondayButton>
          </Upload>
          <PdfsFinderFromDocuments
            {...{
              customFilesChanger,
              customFiles,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DocMergerUpload;
