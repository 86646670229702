import { MondayButton } from "../../../../commonComponents";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon } from "../../../Communication/assets";
import { LogsIcon } from "../../../DynamicView/src";
import { XIcon } from "../../../Communication/assets";
import { EmailIcon, ExportPdfIcon, FilledEyeIcon } from "../../../../../assets";
import { viewSafetyModal } from "../NewPropertyDamageIncidentModal";
import { Tooltip } from "antd";
import { TasksIconNew } from "../../../../../icons";
import { DynamicAvatars } from "../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { InspectionConfigurationIcon } from "../../../../../icons/Settings";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { LinkButton } from "../../../../commonComponents";
import ToDoButton from "../../../ToDos/components/ToDoButton/ToDoButton";
import IncidentEmails from "../IncidentEmails";

function modalFooter({
  onCancel = () => {},
  onSave = () => {},
  onDelete = () => {},
  onShowLogs = () => {},
  onGeneratePdf = () => {},
  onReportView = () => {},
  onEmail = () => {},
  viewTasks = () => {},
  viewInspections = () => {},
  defaultId,
  safetyUsingIncident = [],
  hasReport = false,
  isDarkMode,
  selectedTeam = [],
  changes = false,
  hasInspections = false,
  incidentName = "",
  tooltipCategory = "",
  noteAccess = null,
  incidentCategory = "",
  popoverProps = null,
  projectId = null,
  setWordPreview = () => {},
  loading = false,
}) {
  return [
    <>
      {!!defaultId && (
        <div className="buttonContainer">
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick: onDelete,
              tooltipCategory: ["Incidents", tooltipCategory],
              tooltipKey: "delete",
            }}
          >
            Delete
          </MondayButton>
          <MondayButton
            {...{
              Icon: <LogsIcon />,
              className: "mondayButtonBlue",
              onClick: onShowLogs,
              tooltipCategory: ["Incidents", tooltipCategory],
              tooltipKey: "showLogs",
            }}
          >
            Show Logs
          </MondayButton>
          {/* <MondayButton
              {...{
                Icon: <ExportPdfIcon />,
                className: "mondayButtonBlue",
                tooltipCategory: ["Incidents", tooltipCategory],
                tooltipKey: "exportPdf",
                onClick: onGeneratePdf
              }}
            >
              Export To Pdf
            </MondayButton> */}
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => setWordPreview(true),
              Icon: <ExportPdfIcon />,
            }}
          >
            PDF Preview
          </MondayButton>
          {!!hasReport && (
            <MondayButton
              {...{
                Icon: <ExportPdfIcon />,
                className: "mondayButtonBlue",
                onClick: onReportView,
                tooltipCategory: ["Incidents", tooltipCategory],
                tooltipKey: "showReport",
              }}
            >
              View Report
            </MondayButton>
          )}
          <ToDoButton
            {...{
              recordId: defaultId,
              recordName: incidentName,
              tooltipCategory: ["Incidents", tooltipCategory],
            }}
          />
          <MondayButton
            {...{
              Icon: <EmailIcon />,
              className: "mondayButtonBlue",
              onClick: onEmail,
              tooltipCategory: ["Incidents", tooltipCategory],
              tooltipKey: "openEmail",
              loading,
            }}
          >
            Email
          </MondayButton>
          {!!noteAccess && noteAccess?.read && (
            <div className="safety-notes-button">
              <Notes
                {...{
                  noteModalName: incidentName,
                  rowId: defaultId,
                  tooltipCategory,
                  tooltipCategory: ["Incidents", tooltipCategory],
                  topicCategory: incidentCategory,
                }}
              />
            </div>
          )}
          <MondayButton
            {...{
              Icon: <TasksIconNew />,
              className: "mondayButtonBlue",
              onClick: viewTasks,
              tooltipCategory: ["Incidents", tooltipCategory],
              tooltipKey: "showTasks",
            }}
          >
            Tasks
          </MondayButton>
          {!!hasInspections && (
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                Icon: (
                  <InspectionConfigurationIcon
                    style={{ height: 23, width: 23 }}
                  />
                ),
                onClick: viewInspections,
                tooltipCategory: ["Incidents", tooltipCategory],
                tooltipKey: "showInspections",
              }}
            >
              Inspections
            </MondayButton>
          )}
          <LinkButton popoverProps={popoverProps} title={"Go to"} />
          {safetyUsingIncident.length > 0 && (
            <Tooltip title={"View Safety Reports using this Incident"}>
              <MondayButton
                {...{
                  Icon: <FilledEyeIcon style={{ height: 20, width: 20 }} />,
                  className: "mondayButtonBlue previewButton",
                  onClick: () => viewSafetyModal(safetyUsingIncident),
                }}
              ></MondayButton>
            </Tooltip>
          )}

          <IncidentEmails
            recordId={defaultId}
            categoryName="Incidents"
            title={incidentName}
          />
        </div>
      )}
      <div className="buttonContainer">
        {!!selectedTeam?.length && !!defaultId ? (
          <DynamicAvatars
            {...{
              title: "Team",
              isDarkMode: isDarkMode,
              members: selectedTeam.reduce(
                (acc, { members = [] }) => [...acc, ...members],
                []
              ),
            }}
          />
        ) : null}
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            onClick: onCancel,
            tooltipCategory: ["Incidents", tooltipCategory],
            tooltipKey: "cancel",
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: onSave,
            disabled: !changes || safetyUsingIncident.length > 0,
            tooltipCategory: ["Incidents", tooltipCategory],
            tooltipKey: "delete",
          }}
        >
          Save
        </MondayButton>
      </div>
    </>,
  ].filter(Boolean);
}

export default modalFooter;
