import createPDF from "../../../../integrations/createPDF";
import dayjs from "dayjs";
import htmlToPdfmake from "html-to-pdfmake";
import { compareIncluding } from "../../../SidebarPages/utils";
import { companyDetails } from "../../../../helpers/constants";

const formatDate = (date) => {
  return dayjs(date).format("MM/DD/YYYY hh:mm A");
};

export const generateRepliesContent = (
  replies,
  customStyles = false,
  level = 0
) => {
  return replies.map((reply) => {
    const indentation = level * 20;

    return {
      stack: [
        {
          columns: [
            {
              text: reply.author,
              style: customStyles ? customStyles.subSubheader : "subSubheader",
              width: "*",
              margin: [10 + indentation, 0, 0, 0],
            },
            {
              text: formatDate(reply.createdAt),
              style: customStyles ? customStyles.subSubheader : "subSubheader",
              alignment: "right",
              width: "*",
            },
          ],
        },
        {
          columns: htmlToPdfmake(reply.replyContent).map((repContent) => ({
            ...repContent,
            margin: [10 + indentation, 5, 0, 10],
          })),
        },
        ...(reply.replies && reply.replies.length
          ? generateRepliesContent(reply.replies, customStyles, level + 1)
          : []),
      ],
    };
  });
};

const { logoName } = companyDetails;

export const onGeneratePdf = ({ notesProps, base64, title, exportFilter }) => {
  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, logoName)
  )?.base64;

  const pageMargins = [20, 120, 20, 40];

  const filteredNotes = exportFilter?.length
    ? notesProps?.filter(({ author }) => exportFilter.includes(author))
    : notesProps;

  const content = filteredNotes.map((note, index) => {
    const { author, category, createdAt, noteComment, replies } = note;

    const formattedDate = dayjs(createdAt).format("MM/DD/YYYY hh:mm A");
    const richTextContent = htmlToPdfmake(noteComment);
    const richTextRepliesContent = replies.length
      ? generateRepliesContent(replies)
      : [];

    const fillColor = index % 2 === 0 ? "#FFFFFF" : "#f2f2f2";

    return {
      table: {
        widths: ["*"],
        body: [
          [
            {
              stack: [
                { text: author, style: "header" },
                {
                  columns: [
                    { text: category, style: "subheader", width: "*" },
                    {
                      text: formattedDate,
                      style: "subheader",
                      alignment: "right",
                      width: "*",
                    },
                  ],
                },
                {
                  svg: `<svg xmlns="http://www.w3.org/2000/svg" width="470" height="1" viewBox="0 0 470 1">
                    <line id="Line_419" data-name="Line 419" x2="470" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
                    </svg>`,
                  width: 470,
                  height: 2,
                },
                { text: "Comment:", style: "comment", margin: [0, 10, 0, 10] },
                {
                  columns: richTextContent.map((cont) => ({
                    ...cont,
                    margin: [10, 5, 0, 10],
                  })),
                },
                { text: "Replies:", style: "comment" },
                ...richTextRepliesContent,
              ],
            },
          ],
        ],
      },
      layout: {
        fillColor: fillColor,
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length ? "gray" : "green";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length ? "gray" : "green";
        },
        paddingLeft: function (i, node) {
          return 40;
        },
        paddingRight: function (i, node) {
          return 40;
        },
        paddingTop: function (i, node) {
          return 20;
        },
        paddingBottom: function (i, node) {
          return 20;
        },
      },
      margin: [0, 0, 0, 10],
    };
  });

  const docDefinition = {
    pageMargins,
    header: {
      columns: [
        {
          text: `Title: ${title}`,
          fontSize: 11,
          alignment: "left",
          margin: [20, 10],
          width: "*",
          bold: true,
        },
        {
          image: logo,
          fit: [100, 100],
          alignment: "center",
          width: "*",
        },
        {
          text: new Date().toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          fontSize: 10,
          alignment: "right",
          margin: [20, 10],
          width: "*",
        },
      ],
      margin: [0, 10, 0, 40],
    },
    content: content,
    footer: function (currentPage, pageCount) {
      return {
        text: "Page " + currentPage.toString() + " of " + pageCount,
        alignment: "center",
        margin: [0, 0, 20, 0],
      };
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      subheader: {
        fontSize: 10,
        margin: [0, 0, 0, 5],
      },
      comment: {
        fontSize: 12,
        margin: [0, 0, 0, 5],
      },
      subSubheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 0, 5, 5],
      },
    },
  };

  createPDF({
    action: "download",
    docDefinition,
    title: `Notes | ${title}.pdf`,
  });
};
