import { Table } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { lazyFetch } from "../../../../../../utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { SmallLoadableComp } from "../../../../../Sidebars/components";
import { InfoText } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

import "./ProposalDocumentSelector.scss";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon, XIcon } from "../../../../../InternalChat/assets/icons";

function ProposalDocumentSelector({ proposalData, onSave, onDiscard }) {
  let projectId = proposalData?.estimation?.projectId;
  const { accessToken } = useSelector((state) => state.accessToken);

  const { getFilesByFolderId, getImageSrc } = driveApi({ accessToken });
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [allFiles, setAllFiles] = useState({});

  const tabs = ["Project", "Estimation", "Permit Drawings"];

  //region doc getter
  async function documentationGetter(
    docObj,
    keyToSave,
    keysToAdd = {},
    returnFiles = false
  ) {
    let filesToReturn = [];

    await getFilesByFolderId(docObj).then(async (res) => {
      let files = await res?.json().then((res) => res?.files || []);
      for (const file of files) {
        if (file?.id) {
          await getFilesByFolderId(file?.id).then(async (res) => {
            await res?.json().then(async (res) => {
              let tmpFiles = res?.files || [];
              for (const doc of tmpFiles) {
                await getImageSrc(doc?.id).then(async (res) => {
                  doc.src = res?.src;
                  doc.blob = res?.blob;
                  doc.category = file?.name;
                });
              }
              filesToReturn.push({ ...file, ...keysToAdd, files: tmpFiles });
            });
          });
        }
      }
    });

    if (returnFiles) {
      return filesToReturn;
    } else {
      setAllFiles((prev) => ({ ...prev, [keyToSave]: filesToReturn }));
      setLoading(false);
    }
  }

  //region project
  async function projectFiles() {
    setLoading(true);
    let projectObj = await API.get("projects", `/projects/${projectId}`);
    const docId = projectObj?.googleDriveFolderIds?.docObject;
    if (!!docId) {
      await documentationGetter(docId, "Project");
    } else {
      setAllFiles((prev) => ({ ...prev, Project: [] }));
      setLoading(false);
    }
  }

  //region estimation
  async function estimationFiles() {
    setLoading(true);
    let docId = proposalData?.estimation?.googleDriveFolderIds?.docObject || "";
    if (!!docId) {
      await documentationGetter(docId, "Estimation");
    } else {
      setAllFiles((prev) => ({ ...prev, Estimation: [] }));
      setLoading(false);
    }
  }

  //region drawings
  async function drawingsFiles() {
    setLoading(true);
    await lazyFetch({
      tableName: "permitDrawings",
      filterKey: "projectId",
      filterValue: projectId,
      listOfKeys: ["googleDriveFolderIds", "permitId", "sow"],
    }).then(async (res) => {
      let filesToReturn = [];
      for (const file of res) {
        if (file?.googleDriveFolderIds?.docObject) {
          const docsForPermit = await documentationGetter(
            file?.googleDriveFolderIds?.docObject,
            "Permit Drawings",
            { permitId: file?.permitId, sow: file?.sow },
            true
          );
          filesToReturn = filesToReturn.concat(docsForPermit);
        }
      }
      setAllFiles((prev) => ({ ...prev, "Permit Drawings": filesToReturn }));
      setLoading(false);
    });
  }

  //region formatted all
  const formattedFiles = useMemo(() => {
    let tmp = {};
    Object.keys(allFiles).forEach((key) => {
      tmp[key] = [];
      allFiles[key].forEach((file) => {
        file?.files?.forEach((f) => {
          if (f?.mimeType === "application/pdf") {
            tmp[key].push({
              ...f,
              key: f?.id,
              ...(file?.sow ? { sow: file?.sow } : {}),
              viewLink: (
                <a onClick={() => window.open(f?.blob, "_blank")}>View File</a>
              ),
            });
          }
        });
      });
    });
    return tmp;
  }, [allFiles]);

  //region toRender
  const filesToRender = useMemo(() => {
    if (selectedTab) {
      let tmp = formattedFiles[selectedTab];
      return tmp;
    } else {
      return [];
    }
  }, [allFiles, selectedFiles, selectedTab]);

  //region onSelectChange
  const onSelectChange = useCallback(
    (newSelectedRowKeys) => {
      setSelectedFiles((prev) => {
        return { ...prev, [selectedTab]: newSelectedRowKeys };
      });
    },
    [selectedFiles, selectedTab]
  );

  //region useEffect
  useEffect(() => {
    if (selectedTab) {
      if (selectedTab === "Project") {
        if (!allFiles[selectedTab]) {
          projectFiles();
        }
      } else if (selectedTab === "Estimation") {
        if (!allFiles[selectedTab]) {
          estimationFiles();
        }
      } else if (selectedTab === "Permit Drawings") {
        if (!allFiles[selectedTab]) {
          drawingsFiles();
        }
      }
    }
  }, [selectedTab]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedFiles?.[selectedTab] || [],
      onChange: onSelectChange,
    }),
    [selectedTab, selectedFiles, onSelectChange]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "File Name",
        dataIndex: "name",
      },
      {
        title: "Category/Folder",
        dataIndex: "category",
      },
      selectedTab === "Permit Drawings" && {
        title: "SOW",
        dataIndex: "sow",
      },
      {
        title: "More Information",
        dataIndex: "viewLink",
      },
    ].filter(Boolean);
  }, [selectedTab]);

  function onConfirm() {
    const flattedFiles = Object.values(formattedFiles).reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const flattedSelectedFiles = Object.values(selectedFiles).reduce(
      (acc, val) => acc.concat(val),
      []
    );
    const tmp = flattedFiles
      .filter((file) => flattedSelectedFiles.includes(file.id))
      .map((el) => {
        const { viewLink, ...rest } = el;
        return rest;
      });

    onSave(tmp);
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="proposalDocumentSelector"
    >
      <div className="document-selector-body">
        <InfoText text="Select a tab to view documents" />
        <div className="tabs-container">
          {tabs.map((tab, index) => (
            <div
              style={{
                cursor: !!loading ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (selectedTab === tab || !!loading) return;
                setSelectedTab(tab);
              }}
              key={index}
              className={`tab ${selectedTab === tab ? "selected" : ""}`}
            >
              {tab}
            </div>
          ))}
        </div>
        <SmallLoadableComp loading={loading}>
          <Table
            columns={columns}
            dataSource={filesToRender}
            rowSelection={rowSelection}
          />
        </SmallLoadableComp>
      </div>
      <div className="document-selector-footer">
        <MondayButton
          {...{
            className: "mondayButtonGrey",
            onClick: () => onDiscard(),
            Icon: <XIcon />,
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            className: "mondayButtonGreen",
            onClick: () => onConfirm(),
            Icon: <TickIcon />,
          }}
        >
          Confirm
        </MondayButton>
      </div>
    </div>
  );
}

export default ProposalDocumentSelector;
