import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { filterProjectsByForeman } from "../Components/FieldDashboardTable/helpers";
import fetchToBeScheduledDataForeman from "./fetchToBeScheduledDataForeman";
import dayjs from "dayjs";

async function fetchForemanRowData({ userConfiguration }) {
  try {
    const [projectResponse, scheduleResponse] = await Promise.all([
      fetchAllData("projects", "projects", "projectId"),
      fetchAllData("scheduling", "scheduling", "scheduleId"),
    ]);

    const projectData = projectResponse;
    const scheduleData = scheduleResponse;
    if (!projectData?.length) {
      throw new Error("No projects found!");
    }

    const projectsWithSchedules = projectData?.map?.((project) => {
      const schedulesForProject = scheduleData?.filter(
        (schedule) => schedule?.projectId === project?.projectId
      );

      return {
        ...project,
        schedules: schedulesForProject?.sort(
          (a, b) => dayjs(a?.createdAt) - dayjs(b?.createdAt)
        ),
      };
    });

    return filterProjectsByForeman(
      projectsWithSchedules,
      userConfiguration?.nameOfUser
    );
  } catch (error) {
    console.error("Error fetching data", error);
    throw new Error("Error getting data!");
  }
}

export default fetchForemanRowData;
