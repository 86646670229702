import _ from "lodash";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import findPolygonRange from "./findPolygonRange";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

dayjs.extend(customParseFormat);

/**
 *
 * @param {Array} addresses list of addresses to get coordinates
 * @param {String} address element of addresses
 * @returns Array of objects {address: coordinates of address}
 */
async function getBulkCoordinates({ addresses }) {
  const directionService = new window.google.maps.DirectionsService();
  const cleanAddresses = addresses.filter((adrs) => !!adrs.trim());

  if (addresses.length <= 12) {
    const locations = await directionService
      .route({
        origin: cleanAddresses[0],
        destination: cleanAddresses[cleanAddresses.length - 1],
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: cleanAddresses
          .slice(1, cleanAddresses.length)
          .flatMap((location) =>
            location.trim()
              ? {
                  location,
                  stopover: true,
                }
              : []
          ),
      })
      .then(({ routes }) => {
        const listOfLocations = routes[0]?.legs.map(
          ({ start_location }, index) => ({
            [cleanAddresses[index]]: {
              lat: start_location.lat(),
              lng: start_location.lng(),
            },
          })
        );
        return listOfLocations;
      })
      .catch((error) =>
        console.log("Error getting locations: ", { error, addresses })
      );
    return locations;
  } else {
    console.error("Max length of addresses should be 10!");
    console.log("Max length of addresses should be 10!");
  }
}

/**
 * Function that matches the DEG records with the jobsites
 * @param {Array} array The array of records
 * @param {Boolean} makeCall Wether the function fetches the punch coordinates
 * @param {Array} jobsites The list of jobsites
 * @param {Array} crews The list of crews
 * @param {Component} progressRef Reference of the progress component
 */
async function addDataToArray({
  array,
  crews,
  jobsites,
  crewTeams,
  accountName,
  progressRef,
}) {
  let stopFunction = false;
  let modifiedParsedData = [];
  const addressRegister = {};
  progressRef.setPercentage(0);

  const cancelButton = document.getElementById("cancelButton");

  function stopFunc() {
    stopFunction = true;
  }

  cancelButton?.addEventListener?.("click", stopFunc);

  const groupByLocation = _.groupBy(
    array,
    ({ punchLocation }) => punchLocation
  );

  const listOfLocations = Object.keys(groupByLocation);

  let arrayOfAddressesInChunks = [];
  for (let i = 0; i < listOfLocations.length; i += 12) {
    arrayOfAddressesInChunks.push(listOfLocations.slice(i, i + 12));
  }

  for (const addresses of arrayOfAddressesInChunks) {
    let foundCoordinates = 0;
    if (stopFunction) {
      break;
    }
    await getBulkCoordinates({ addresses })
      .then((results) => {
        if (results?.length) {
          foundCoordinates = foundCoordinates + results.length;
          for (const result of results) {
            Object.assign(addressRegister, result);
          }
        }
      })
      .catch((error) => console.log("Error getting location coords: ", error));

    const addressesFoundPercentage =
      (foundCoordinates / listOfLocations.length) * 100;

    progressRef.addPercentage(addressesFoundPercentage);
  }

  for (const location in groupByLocation) {
    const entriesPerLocation = groupByLocation[location];
    const pseudoLocation = Object.keys(addressRegister).find((address) =>
      address.includes(location)
    );

    const coordinatesFromLocation = addressRegister?.[location]
      ? addressRegister?.[location]
      : addressRegister?.[pseudoLocation];

    for (let i = 0; i < entriesPerLocation.length; i++) {
      const employee = structuredClone(entriesPerLocation[i]);
      const tmpName = employee?.employeeFullName || "";

      const employeeName =
        tmpName?.slice(tmpName?.indexOf(",") + 2).trim() +
        " " +
        tmpName?.slice(0, tmpName?.indexOf(",")).trim();
      Object.assign(employee, { employeeFullName: employeeName });

      let punchPosition = coordinatesFromLocation;

      // employee?.punchCoordinates?.lat
      //   ? employee.punchCoordinates
      //   : !!(employee?.punchCoordinates || "").trim()
      //   ? {
      //       lat: Number(posStr.slice(0, posStr.indexOf(","))),
      //       lng: Number(posStr.slice(posStr.indexOf(",") + 2, posStr.length - 1)),
      //     }
      //   :
      // addressRegister[location];

      /**
       * Find the closest jobsite to employee punchPosition
       */
      const jobsiteMatch = jobsites
        .flatMap((job) => {
          const {
            geoFenceInfo: newInfo = [],
            geofenceInfo: oldInfo = [],
            locationRadius,
            addressPosition,
          } = job;
          let range;
          const geoInfo = newInfo || [{ geoFenceInfo: oldInfo }];

          // Find closest jobsite from geofence
          if (punchPosition?.lat) {
            if (geoInfo?.length) {
              range = findPolygonRange({
                points: geoInfo[0]?.geoFenceInfo,
                position: punchPosition,
                radius: +locationRadius,
              });
            } else {
              // when there is no geofence get the closest jobsite from jobsite position
              const distanceFromJob = withinRadius(
                addressPosition,
                punchPosition,
                locationRadius
              );
              range = {
                inRange: distanceFromJob.withinRange,
                distanceInFeet: distanceFromJob.distanceInFeet,
              };
            }
          }

          if (range?.inRange) {
            return { ...job, distance: range.distanceInFeet };
          } else {
            return [];
          }
        })
        .sort((a, b) => (a?.distance || 0) - (b?.distance || 0))?.[0];

      const employeeMatch = crews.find(
        (emp) =>
          emp?.employeeId === `${accountName}-${employee?.employeeId.trim()}`
      );

      const teamMatch = crewTeams.find((team) => {
        if (employeeMatch?.foreman === true) {
          return (
            team?.crewForeman?.crewId &&
            team?.crewForeman?.crewId === employeeMatch?.crewId
          );
        } else {
          return (
            (team?.crewMembers || []).findIndex(
              (member) => member?.crewId === employeeMatch?.crewId
            ) > -1
          );
        }
      });

      if (jobsiteMatch?.addressPosition?.lat && employee?.punchType === "ID") {
        const distance = jobsiteMatch?.distance;
        Object.assign(employee, {
          distanceFromJob: distance,
          duration: distance / 3.5,
        });
      }

      let punchDate = undefined;
      if (employee?.punchDate) {
        if (dayjs(employee?.punchDate, "MM/DD/YYYY", true)) {
          try {
            punchDate = parseInTz(dayjs(employee?.punchDate, "MM/DD/YYYY"));
          } catch (error) {
            console.log("error: ", error);
          }
        }
      }

      let punchTime = undefined;
      if (punchDate && employee?.punchTime) {
        punchTime = setHourMinute(
          punchDate,
          parseInTz(dayjs(employee?.punchTime, "h:mm A"))
        );
      }

      let jobsiteToConsider = jobsiteMatch;

      Object.assign(employee, {
        punchType: employee?.punchType.includes("B")
          ? employee?.punchType.replace("B", "L")
          : employee?.punchType,
        punchCoordinates: coordinatesFromLocation,
        punchLocation: (employee?.punchLocation || "")?.trim()
          ? employee.punchLocation
          : jobsiteToConsider?.jobAddress,
        payrollType: jobsiteToConsider?.payrollType,
        employeeId:
          employeeMatch?.employeeId || !!employee?.employeeId
            ? `${accountName}-${employee?.employeeId.trim()}`
            : "",
        employeeNumber: employee?.employeeId,
        crewId: employeeMatch?.crewId,
        companyName: accountName,
        company: accountName,
        employeeRate:
          jobsiteToConsider?.payrollType !== "Prevailing Wage"
            ? parseFloat(employeeMatch?.employeeRate || 0)
            : !isNaN(
                parseFloat(
                  jobsiteToConsider?.rates?.[employeeMatch?.crewPosition]
                )
              )
            ? parseFloat(
                jobsiteToConsider?.rates?.[employeeMatch?.crewPosition]
              )
            : parseFloat(employeeMatch?.employeeRate || 0),
        employeeFullName: employeeMatch?.crewName || employee?.employeeFullName,
        activityStatus: "Draft",
        punchDate,
        punchTime,
        salaryType: employeeMatch?.salaryType || "Hourly",
        reason: employee?.reason || "",
        punchTimeStamp: punchTime?.valueOf(),
        employeeRole: employeeMatch?.crewPosition,
        crewTeamName: teamMatch?.crewTeamName,
        crewTeamId: teamMatch?.crewTeamId,
        employeeType: {
          jobsiteMatch: {
            jobName: jobsiteToConsider?.jobName,
            payrollType: jobsiteToConsider?.payrollType,
          },
          role: employeeMatch?.crewPosition,
        },
        jobsiteId: jobsiteToConsider?.jobsiteId,
        jobsiteMatch: {
          jobAddress: jobsiteToConsider?.jobAddress,
          jobName: jobsiteToConsider?.jobName,
          jobsiteId: jobsiteToConsider?.jobsiteId,
          googleSheetLink: jobsiteToConsider?.googleSheetLink,
          services: jobsiteToConsider?.services || [],
          reimbursement: !!jobsiteToConsider?.reimbursement,
        },
      });
      if (employee?.punchTime) {
        modifiedParsedData.push(employee);
      }
    }
  }

  if (stopFunction) {
    progressRef.setPercentage(0);
    return [];
  } else {
    progressRef.setPercentage(100);
    return modifiedParsedData;
  }
}

export default addDataToArray;
