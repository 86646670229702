export const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://oh8shxzp23.execute-api.us-east-1.amazonaws.com/dev",
    // URL: "https://66wb2o/45eh.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_rdTzDP74W",
    APP_CLIENT_ID: "5hceovbvja5avqbog6o6bu0fi1",
    IDENTITY_POOL_ID: "us-east-1:a9e3a60e-4d4f-4141-9796-3d502e9e7935",
  },
};
export const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://66wb2o45eh.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_rdTzDP74W",
    APP_CLIENT_ID: "5hceovbvja5avqbog6o6bu0fi1",
    IDENTITY_POOL_ID: "us-east-1:a9e3a60e-4d4f-4141-9796-3d502e9e7935",
  },
};
const config = {
  // Default to dev if not set
  ...(process.env.NODE_ENV === "production" ? prod : dev),
  // ...prod,
};

export default config;
