import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import { PlusIcon } from "../../../../assets";
import React, { useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import NewProposal from "../../NewProposal/NewProposal";
import { MondayButton } from "../../../commonComponents";
import { LeftArrow } from "../../../SidebarPages/BasePage/src";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { DeleteIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import ButtonDropdown from "../../../commonComponents/MondayButtonDropDown/ButtonDropdown";
import { MondayButtonIcon } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";

import "../styles/HeaderFilter.scss";

function HeaderFilter({ handleFilterChange, filters, rowData }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const [newProposal, setNewProposal] = useState(false);

  const onClearFilters = () => {
    handleFilterChange("reset");
  };

  const reportProjects = useMemo(() => {
    return uniqBy(
      rowData?.map((item) => ({
        label: item?.otherDetails?.jobSiteAddress,
        value: item?.projectId,
      })),
      "value"
    );
  }, [rowData]);

  const statusesOptions = useMemo(() => {
    return uniqBy(
      rowData?.map((item) => ({
        label: item?.proposalStatus,
        value: item?.proposalStatus,
      })),
      "value"
    );
  }, [rowData]);

  return (
    <div
      className={`proposal-header-filter-wrapper ${isDarkMode ? "dark" : ""}`}
    >
      {" "}
      <div className="proposal-header-left">
        {/* <MondayButtonIcon
          className="mondayButtonGrey"
          Icon={<LeftArrow />}
          name="Go Back"
          onClick={() => {
            window.location.href = "/";
          }}
        /> */}
        <InputComponent
          type="select"
          value={filters?.proposalStatus}
          placeholder="Select Status..."
          noFormItem
          customOptions={statusesOptions}
          style={{ width: "300px" }}
          onSelect={(e) => handleFilterChange("proposalStatus", e)}
        />

        {!filters.jobSiteAddress && !filters.proposalStatus ? null : (
          <MondayButton
            className="mondayButtonRed"
            Icon={<DeleteIcon />}
            onClick={onClearFilters}
            disabled={!filters.jobSiteAddress && !filters.proposalStatus}
          >
            Clear Filters
          </MondayButton>
        )}
      </div>
      <div className="proposal-header-right">
        <InputComponent
          value={filters?.jobSiteAddress}
          allowClear
          placeholder="Search here..."
          noFormItem
          style={{ width: "300px" }}
          prefix={<SearchOutlined className="SearchLogoIcon" />}
          onChange={(e) => handleFilterChange("jobSiteAddress", e.target.value)}
        />
        <ButtonDropdown
          {...{
            buttonClassName: "mondayButtonGreen",
            customOverlayClassName:
              "button-dropdown-overlay proposal-header-filter-dropdown",
            title: "Create New",
            tooltipKey: "onNew",
            dropDownOptions: [
              {
                key: "1",
                label: (
                  <>
                    <p>Create From Projects</p>
                    <PlusIcon fill="#71CF48" />
                  </>
                ),
                onClick: () => setNewProposal("Project"),
              },
              {
                key: "2",
                label: (
                  <>
                    <p>Create From Opportunities</p>
                    <PlusIcon fill="#71CF48" />
                  </>
                ),
                onClick: () => setNewProposal("Opportunity"),
              },
            ],
          }}
        />
      </div>
      {!!newProposal && (
        <NewProposal open={newProposal} setVisible={setNewProposal} />
      )}
    </div>
  );
}

export default HeaderFilter;
