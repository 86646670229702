import React, { useState } from "react";
import { EditFilled } from "@ant-design/icons";
import { DragHandleIcon } from "../../../../../../assets";
import EditGeneralTemplate from "../../../../../pages/Settings/settingsComponents/ProposalBuilder/components/EditTemplate/EditGeneral/EditGeneralTemplate";

import "./ServiceTemplates.scss";
import { SettingsProposalContext } from "../../../../../pages/Settings/settingsComponents/ProposalBuilder/components/ProposalBuilderSettings/ProposalContext";

function Card({
  template = {},
  onDragEnd = () => {},
  serviceTemplates,
  estimationSample,
  updateTemplate,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <div
        className="serviceTemplateCard"
        draggable
        onDragEnd={(event) => onDragEnd(event, template)}
        onDragStart={(event) => {
          event.dataTransfer.setData("text/html", "<span></span>");
        }}
      >
        <span className="serviceTemplateCard_title">
          {template?.serviceName || ""}
        </span>
        <span className="serviceTemplateCard_icons">
          {updateTemplate ? (
            <span onClick={() => setIsModalVisible(true)} className="iconOpen">
              <EditFilled />
            </span>
          ) : null}
          <DragHandleIcon />
        </span>
      </div>
      {isModalVisible && (
        <SettingsProposalContext.Provider
          value={{
            estimationSample,
            serviceTemplates,
            curryDispatch: () => {},
          }}
        >
          <EditGeneralTemplate
            item={{
              ...template,
              templateContent: template?.parentTemplateContent,
            }}
            type="generalTemplate"
            visible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
            callback={(newContent) => {
              updateTemplate(template?.templateId, newContent);
            }}
          />
        </SettingsProposalContext.Provider>
      )}
    </>
  );
}

export default Card;
