import React from "react";
import AttachmentView from "./attachment-view/AttachmentView";
import { uniqBy } from "lodash";

import "./attachments-container.scss";

function AttachmentsContainer({ attachments }) {
  const uniquedAttachments = uniqBy(attachments, "blob");

  return (
    <>
      <div className="attachments-divider" />
      <div className="attachments-container">
        {uniquedAttachments.map((attachment) => (
          <AttachmentView key={attachment.name} {...attachment} />
        ))}
      </div>
    </>
  );
}

export default AttachmentsContainer;
