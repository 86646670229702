import React, { useMemo, useState } from "react";
import { Button, Divider, Input } from "antd";
import { Select } from "antd";
import { useSelector } from "react-redux";
import {
  ESTIMATION_INSERTS,
  getServiceProposalInserts,
} from "../../../utils/dataHolder";
import { useRedux } from "src/components/SidebarPages/hooks";
import Swal from "sweetalert2";
import ServiceCategoriesInsideEditor from "./ServiceCategoriesInsideEditor/ServiceCategoriesInsideEditor";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";

import "./ServiceCategoriesSelectionProposal.scss";
import { useSettingsProposalContext } from "../../ProposalBuilderSettings/ProposalContext";

function ServiceCategoriesSelectionProposal({
  details,
  setDetails,
  proposalDesignRef,
  type,
  disabled = false,
  changeDisabled = () => {},
  onChange,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  const { serviceTemplates } = useSettingsProposalContext();

  const [selectedService, setSelectedService] = useState(null);

  const serviceProposalInserts = useMemo(() => {
    const inserts = getServiceProposalInserts(type, serviceTemplates) || [];
    if (type === "generalTemplate") {
      if (!selectedService) {
        return [];
      } else {
        return inserts?.filter((el) => {
          const serviceIdMatch =
            el?.others?.additionalDetails?.service?.workId ===
            selectedService?.workId;
          const servicesIncludeMatch =
            Array.isArray(el?.others?.additionalDetails?.services) &&
            el?.others.additionalDetails.services.includes(
              selectedService?.workId
            );
          return serviceIdMatch || servicesIncludeMatch;
        });
      }
    } else {
      const workName = details?.service?.workName;
      if (workName && workName !== "Hoist") {
        const first15Elements = inserts.slice(0, 15);
        return first15Elements;
      } else {
        return inserts;
      }
    }
  }, [serviceTemplates, type, details?.service?.workName, selectedService]);

  const generalInfoInserts = useMemo(() => {
    return Object.entries(ESTIMATION_INSERTS).map(([key, value]) => {
      return {
        name: key,
        value: value,
      };
    });
  }, []);

  const serviceOptions = useMemo(() => {
    return proposedTypeOfWork
      .filter((item) => item?.active === true)
      .map((item) => {
        // {value:"LEGACY", label:"LEGACY"}
        return {
          value: item?.workId,
          label: item?.workName,
        };
      });
  }, [serviceTemplates]);

  function onSelect(value, data) {
    setDetails((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        services: [...prev.otherDetails.services, value],
      },
    }));
  }
  function onDeselect(value, data) {
    setDetails((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        services: prev.otherDetails.services.filter((el) => el !== value),
      },
    }));
  }

  return (
    <div
      className={`service_categories_picker ${
        isDarkMode ? "service_categories_picker_dark" : ""
      }`}
    >
      <div className="pick-header">
        <div className="pick-title">
          Category:{" "}
          {type === "generalTemplate" ? "General Template" : "Service Template"}
        </div>{" "}
      </div>
      <div className="pick-body">
        <div className="pick-category">
          {details?.templateCategory === "service" ? (
            <InputComponent
              {...{
                // disabled,
                type: "select",
                // mode: "multiple",
                label: "Service",
                noFormItem: true,
                placeholder: "Select Service",
                initialValue: details?.service?.workName,
                onChange: (value) => {
                  const selectedService = proposedTypeOfWork.find(
                    (el) => el.workId === value
                  );
                  setDetails((prev) => ({
                    ...prev,
                    service: selectedService,
                  }));
                },
                customOptions: serviceOptions,
              }}
            />
          ) : null}
          {!!details?.service?.workName &&
          details?.service?.workName !== "Hoist" ? (
            <InputComponent
              {...{
                // disabled,
                type: "select",
                mode: "multiple",
                label: "Use also for",
                noFormItem: true,
                placeholder: "Select Service",
                initialValue: details?.otherDetails?.services,
                onSelect,
                onDeselect,
                customOptions: serviceOptions.filter(
                  (el) =>
                    el.value !== details?.service?.workId &&
                    el.value !== "3" &&
                    el.value !== "38"
                ),
                onClear: () => {
                  setDetails((prev) => ({
                    ...prev,
                    otherDetails: {
                      ...prev.otherDetails,
                      services: [],
                    },
                  }));
                },
              }}
            />
          ) : null}
        </div>{" "}
        {type === "generalTemplate" ? (
          <>
            <div>
              <div className="pick-title">General Info</div>
              <div className="pick-drags">
                {generalInfoInserts?.map((item, key) => {
                  return (
                    <div
                      key={"card" + key + `${disabled ? "disabled" : ""}`}
                      className="cardProposal"
                      draggable={true}
                      onDragStart={(event) => {
                        onChange();
                        event.dataTransfer.setData("text/plain", item?.value);
                      }}
                      onDragEnd={(event) => {
                        console.log("onDragEnd", event);
                      }}
                    >
                      <div className="cardProposalTitle">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider style={{ margin: "10px 0" }} />
          </>
        ) : null}
        <div className="pick-title">
          <span>Service Content</span>
          {type === "generalTemplate" && (
            <InputComponent
              {...{
                type: "select",
                // label: "Service",
                noFormItem: true,
                customOptions: serviceOptions,
                onChange: (value) => {
                  const selectedService = proposedTypeOfWork.find(
                    (el) => el.workId === value
                  );
                  setSelectedService(selectedService);
                },
                onClear: () => {
                  setSelectedService(null);
                },
                required: true,
                style: { width: "200px" },
              }}
            />
          )}
        </div>
        <div className="pick-drags">
          {serviceProposalInserts?.map((item, key) => {
            return (
              <div
                key={"card" + key + `${disabled ? "disabled" : ""}`}
                className="cardProposal"
                draggable={true}
                onDragStart={(event) => {
                  onChange();
                  if (type === "generalTemplate") {
                    const name = item?.name;
                    const id = item?.others?.templateId;
                    const service = selectedService?.workName;
                    const toTransfer = `{{${name + "_" + id + "_" + service}}}`;
                    event.dataTransfer.setData("text/plain", toTransfer);
                  } else {
                    event.dataTransfer.setData("text/plain", item?.value);
                  }
                }}
                onDragEnd={(event) => {
                  console.log("onDragEnd", event);
                }}
              >
                <div className="cardProposalTitle">{item.name}</div>
                {type === "generalTemplate" && (
                  <ServiceCategoriesInsideEditor
                    item={item}
                    keyRef={"card" + key}
                    changeDisabled={changeDisabled}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ServiceCategoriesSelectionProposal;
