import { keys } from "../../../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FilePreviewModal } from "../../../../../../Documentation/View/components";
import { useState } from "react";

import "./attachment-view.scss";

function AttachmentView({ mimeType, blob, name }) {
  const [filePreview, setFilePreview] = useState(false);
  const [fileDownload, setFileDownload] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const styledIcons = keys(defaultStyles);

  const type = styledIcons.find(
    (e) => name.split(".")[1]?.toUpperCase() === e?.toUpperCase()
  );

  return (
    <>
      <div
        key={blob}
        className="file-name-container"
        onClick={() => {
          setSelectedFile({
            src: `data:${mimeType};base64,${blob}`,
            mimeType,
          });
          mimeType?.toString().includes("image") ||
          mimeType?.toString().includes("pdf")
            ? setFilePreview(true)
            : setFileDownload(true);
        }}
        data-testid="file-name-container"
      >
        <FileIcon
          style={{ height: 100 }}
          extension={type}
          {...defaultStyles[type]}
        />
        <span className="file-name-label">{name}</span>
      </div>

      <FilePreviewModal
        {...{
          visible: filePreview,
          setVisible: () => setFilePreview(false),
          file: selectedFile,
          allowEmail: false,
        }}
      />

      {fileDownload && (
        <div {...{ style: { display: "none" } }}>
          <iframe {...{ src: selectedFile.src }} />
        </div>
      )}
    </>
  );
}

export default AttachmentView;
