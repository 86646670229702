import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { DragIcon } from "../../../../../../icons";
import { AlertIcon, DragHandleIcon } from "../../../../../../assets";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";
import { ReportCheck } from "../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

import "./ServicesSort.scss";

function ServicesSort({ editorRef }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [sortOrder, setSortOrder] = useState({});
  const [hidden, setHidden] = useState([]);

  const { proposalData, setProposalData, splitServices } = useProposalContext();

  const takeOffServices = useMemo(
    () => proposalData?.takeOff?.services || [],
    [proposalData?.takeOff?.services]
  );

  const fieldsToSort = useMemo(() => {
    const tmpServices = [...splitServices].map((el) => el?.serviceName);
    if (Object.keys(sortOrder).length === 0) {
      return tmpServices;
    } else {
      return tmpServices.sort((a, b) => sortOrder[a] - sortOrder[b]);
    }
  }, [sortOrder]);

  function reorderElements(result) {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;

    const startIndex = source.index;
    const endIndex = destination.index;
    if (startIndex === endIndex) {
      return;
    }

    const items = Array.from(fieldsToSort);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newOrder = (array) => {
      return array.reduce((acc, item, index) => {
        acc[item] = index;
        return acc;
      }, {});
    };
    setSortOrder(newOrder(items));
  }

  async function addServices(event) {
    const droppedHTML = splitServices
      .filter((el) => !hidden.includes(el?.serviceName))
      .sort((a, b) => sortOrder[a?.serviceName] - sortOrder[b?.serviceName])
      .map((el) => el?.serviceHtml)
      .join("\n");
    // const droppedHTML = item?.serviceHtml || "";
    const x = event.clientX;
    const y = event.clientY;
    const elements = document.elementsFromPoint(x, y);
    const isOverTarget = elements?.some((element) =>
      element?.classList?.contains("jodit-workplace")
    );
    event.preventDefault();
    if (editorRef.current && isOverTarget) {
      const wrappedHTML = `<div>${droppedHTML}</div>`;
      // const myMadeSenseData = getMyMadeSenseData(wrappedHTML, proposalData);
      console.log("onDragEnd ~ wrappedHTML:", { wrappedHTML });

      editorRef.current.component.selection.insertHTML(wrappedHTML);

      const getNewHtml = editorRef.current.component.value;
      const tmp = {
        ...(proposalData || {}),
        templateData: {
          ...(proposalData?.templateData || {}),
        },
        getNewHtml,
        parentTemplateContent: item?.parentTemplateContent || "",
      };

      setProposalData(tmp);
    }
  }

  return (
    <div className="servicesSort__container">
      <ReportCheck
        {...{
          dataTestId: "selectAll",
          id: "selectAll",
          label:
            fieldsToSort.length === hidden.length
              ? "Select All"
              : "Deselect All",
          onChange: (e) => {
            setHidden(e ? fieldsToSort : []);
          },
          checked: fieldsToSort.length === hidden.length,
          indeterminate:
            fieldsToSort.length > 0 && fieldsToSort.length < hidden.length,
        }}
      />
      <div
        style={{
          height: (fieldsToSort || [])?.length * 60 + 10,
          maxHeight: 450,
        }}
        className="servicesSort"
      >
        <DragDropContext onDragEnd={reorderElements}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="droppableDiv"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {fieldsToSort.map((title, i) => {
                  return (
                    <Draggable
                      key={title}
                      draggableId={title?.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                          variant={
                            snapshot.isDragging ? "elevation" : "outlined"
                          }
                          elevation={4}
                        >
                          <div className="draggableDiv">
                            <ReportCheck
                              {...{
                                dataTestId: title,
                                id: title,
                                label: (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    {title}{" "}
                                    {/* {!filteredReports?.some(
                                      (el) => el.reportName === title
                                    ) && (
                                      <Tooltip title="No report found for this service">
                                        <AlertIcon fill="#FE4C4A" />
                                      </Tooltip>
                                    )} */}
                                  </span>
                                ),
                                onChange: (e) => {
                                  setHidden(
                                    e
                                      ? hidden.filter((el) => el !== title)
                                      : [...hidden, title]
                                  );
                                },
                                checked: !hidden.includes(title),
                              }}
                              key={i}
                            />
                            <span
                              {...{
                                ...provided.dragHandleProps,
                              }}
                            >
                              <DragIcon />
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {fieldsToSort.length ? (
        <div className="servicesSort__buttons">
          <div
            className="drag-services"
            draggable
            onDragEnd={(event) => addServices(event)}
            onDragStart={(event) => {
              event.dataTransfer.setData("text/html", "<span></span>");
            }}
          >
            Drag Services
            <DragHandleIcon />
          </div>
        </div>
      ) : null}
      {!fieldsToSort.length && (
        <div className="servicesSort__noServices">
          <p>No services found</p>
        </div>
      )}
    </div>
  );
}

export default ServicesSort;
