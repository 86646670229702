import Signature from "../../../commonComponents/Signature/Signature";
import { BorderedTextCard, InputComponent } from "../../Fleet/components";

const SignaturesSection = ({
  safetyDirectorSignature,
  signature,
  setSignature,
  inputDisabled = true,
  removeDisabled = true,
  signDisabled = true,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <BorderedTextCard
        title="Safety Director Signature"
        className="safetyDirectorSignature"
      >
        <InputComponent
          {...{
            label: "Safety Director",
            initialValue: safetyDirectorSignature?.nameOfUser,
            disabled: inputDisabled,
            noFormItem: true,
            required: true,
          }}
        />
        <Signature
          {...{
            signature,
            setSignature,
            existingSignature: safetyDirectorSignature?.src,
            removeDisabled: removeDisabled,
            signDisabled: signDisabled,
          }}
        />
      </BorderedTextCard>
    </div>
  );
};

export default SignaturesSection;
