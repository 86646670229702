import { Form } from "antd";
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import { useResponsive } from "../../../../../hooks";
import { MondayButton } from "../../../../commonComponents";
import { ClearFilterIcon } from "../../../DynamicView/src/index";
import { InputComponent } from "../../../Fleet/components";
import { SearchIcon } from "../../assets/index";
import "./CustomFilter.scss";
import { useSelector } from "react-redux";
// import { AnimatedCheckBox } from "../../../Fleet/components/InputComponent/components";
const CustomFilter = ({ setCustomFilter, setValues, values }) => {
  // const [checked, setChecked] = useState(false);
  // const [checkBox, setCheckBox] = useState();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  const { mobile } = useResponsive();

  const ref = useOnclickOutside(() => {
    setCustomFilter(false);
  });

  useEffect(() => {
    values && form.setFieldsValue(values);
    // values && setChecked(values?.attachment || false)
  }, [values]);

  return (
    <>
      <Form form={form}>
        <div
          className={`customFilterComponent ${mobile && "mobile"} ${
            isDarkMode ? "dark-mode" : ""
          }`}
          ref={ref}
          data-testid="custom-filter"
        >
          <div className="customFilterContainer">
            <div className="from-to-container">
              <div className="from-input-container">
                <span className="LabelCustomFilterStyle">From</span>
                <Form.Item {...{ name: "from" }}>
                  <InputComponent
                    className="emailBoxSubject"
                    placeholder="Enter email..."
                    data-testid="from-input"
                  />
                </Form.Item>
              </div>
              <div className="to-input-container">
                <span className="LabelCustomFilterStyle">To</span>
                <Form.Item {...{ name: "to" }}>
                  <InputComponent
                    className="emailBoxSubject"
                    placeholder="Enter email..."
                    data-testid="to-input"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="subject-container">
              <span className="LabelCustomFilterStyle">Subject</span>
              <Form.Item {...{ name: "subject" }}>
                <InputComponent
                  className="emailBoxSubject"
                  placeholder="Enter subject..."
                  data-testid="subject-input"
                />
              </Form.Item>
            </div>
            <div className="range-picker-container">
              <span className="LabelCustomFilterStyle">Date</span>
              <Form.Item {...{ name: "date" }}>
                <InputComponent
                  type="rangepicker"
                  presets={getRangePresets()}
                  className="emailBoxDate"
                  dropdownClassName={isDarkMode && "darkDateDropDown"}
                  data-testid="date-input"
                />
              </Form.Item>
            </div>
            {/* 
            <div className="hasAttachmentsStyle">
              <AnimatedCheckBox
                form={form}
                key={checked}
                formItemName="attachment"
                label="Has attachment"
                initialValue={checkBox}
              />
            </div> */}
          </div>
          <div className="CustomFilterFooter">
            <MondayButton
              Icon={<ClearFilterIcon />}
              className="ClearStyle"
              onClick={() => {
                form.resetFields();
                setValues();
              }}
              disabled={!values}
              data-testid="clear-filter-btn"
            >
              Clear Filter
            </MondayButton>
            <MondayButton
              Icon={<SearchIcon />}
              className="SearchStyle"
              onClick={() => {
                setValues(form.getFieldsValue());
                setCustomFilter(false);
              }}
              data-testid="search-btn"
            >
              Search
            </MondayButton>
          </div>
        </div>
      </Form>
    </>
  );
};
export default CustomFilter;
