import { useState } from "react";

import { AddDepartmentModal } from "./AddDepartmentModal";
import { MondayButton } from "../../../../../../../commonComponents";
import { Plus } from "../../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import NewAddDepartmentModal from "./NewAddDepartmentModal/NewAddDepartmentModal";
import { VideoTutorialIcon } from "../../../../../../../../icons";
import { TourGuide } from "../../../common";
import { departmentPageStepData } from "../utils";
import VideoModal from "../../../../../../../VideoModal/VideoModal";

const AddDepartment = ({ departmentsList, addMultipleRolesHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  return (
    <>
      <div className="add-container" id="addDepartment">
        <MondayButton
          onClick={() => {
            setIsModalOpen("department");
          }}
          className="AddRoleButtonDepartment"
          {...{ Icon: <Plus /> }}
        >
          Add Department
        </MondayButton>
        <MondayButton
          onClick={() => {
            setIsModalOpen("role");
          }}
          className="AddRoleButtonDepartment"
          {...{ Icon: <Plus /> }}
        >
          Add Role
        </MondayButton>
        <TourGuide
          {...{
            tab: 7,
            category: "departmentsPage",
            stepsData: departmentPageStepData,
          }}
        />
        <MondayButton
          className="mondayButtonBlue"
          Icon={<VideoTutorialIcon />}
          onClick={() => setVideoModalVisible(true)}
        />
      </div>
      {isModalOpen && (
        <NewAddDepartmentModal
          {...{
            open: isModalOpen,
            onCancel: () => setIsModalOpen(false),
            departmentsList,
            addMultipleRolesHandler,
          }}
        />
      )}
      {videoModalVisible && (
        <VideoModal
          {...{
            modalOpen: videoModalVisible,
            setModalOpen: setVideoModalVisible,
            title: "Roles Access/Settings",
          }}
        />
      )}
    </>
  );
};

export default AddDepartment;
