import { Modal } from "antd";
import { useSelector } from "react-redux";
import ProposalStepper from "./ProposalStepper/ProposalStepper";
import { XIcon } from "../../SidebarPages/Communication/assets";

import "./NewProposal.scss";

function NewProposal({ open, setVisible }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <Modal
      {...{
        open,
        onCancel,
        footer: null,
        centered: true,
        maskClosable: false,
        closeIcon: <XIcon />,
        destroyOnClose: true,
        title: "New Proposal",
        className: `new-proposal-modal ${isDarkMode ? "dark" : ""}`,
      }}
    >
      <ProposalStepper {...{ proposalType: open, onCancel }} />
    </Modal>
  );
}

export default NewProposal;
