import { titleBodyHtmlTable } from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers/convertSafetyReport";
import dayjs from "dayjs";

const space = {
  text: {
    paragraphs: [
      {
        text: "",
        bold: true,
        fontSize: 14,
      },
    ],
  },
};

function convertTaskData(data) {
  const dataSections = [];

  const getReplies = (replies, level = 1) => {
    replies.forEach((reply) => {
      const {
        author: replyAuthor,
        createdAt: replyCreatedAt,
        replyContent,
      } = reply;
      const formattedReplyDate =
        dayjs(replyCreatedAt).format("MM/DD/YYYY hh:mm A");
      const replyHeader = {
        text: {
          paragraphs: [
            {
              text: `${replyAuthor} ${formattedReplyDate}`,
              fontSize: 13,
              bold: true,
              class: `reply-header-${level > 6 ? 6 : level}`,
            },
          ],
        },
      };
      dataSections.push(replyHeader);

      const replyBody = {
        text: {
          paragraphs: [
            {
              text: replyContent,
              fontSize: 12,
              lineHeight: 1.5,
              class: "reply-header",
            },
          ],
        },
      };
      dataSections.push(replyBody);
      if (reply.replies?.length > 0) {
        getReplies(reply.replies, level + 1);
      }
    });
  };

  if (!Array.isArray(data)) {
    return dataSections;
  }

  for (const section of data) {
    if (section.title === "Task Dependency") {
      const { title, body } = section;
      let titleClassName = `dependency-section-header`;
      const titleFormat = {
        text: {
          paragraphs: [
            {
              text: title,
              bold: true,
              fontSize: 16,
              class: titleClassName,
            },
          ],
        },
      };
      dataSections.push(titleFormat);
      body.forEach((el) => {
        const getTable = titleBodyHtmlTable(el, "dependency-body-header");
        dataSections.push(...getTable);
      });
    } else if (section.title === "Comments") {
      // Handle Comments section
      const { title, body } = section;
      let titleClassName = `comments-section-header`;
      const titleFormat = {
        text: {
          paragraphs: [
            {
              text: title,
              bold: true,
              fontSize: 16,
              class: titleClassName,
            },
          ],
        },
      };
      dataSections.push(titleFormat);

      // Loop through each comment in the body
      body.forEach((comment, index) => {
        const { author, createdAt, commentContent } = comment;
        const formattedDate = dayjs(createdAt).format("MM/DD/YYYY hh:mm A");

        // Format the comment header (author and date)
        const commentHeader = {
          text: {
            paragraphs: [
              {
                text: `${author} ${formattedDate}`,
                bold: true,
                fontSize: 14,
                margin: [0, 50, 0, 0, 0],
                class: "comment-header-author",
              },
            ],
          },
        };

        const commentText = {
          text: {
            paragraphs: [
              {
                text: "Comment:",
                bold: true,
                fontSize: 14,
                class: "comment-header",
              },
            ],
          },
        };

        dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        dataSections.push(commentHeader);
        dataSections.push(commentText);

        // Format the comment body (content)
        const commentBody = {
          text: {
            paragraphs: [
              {
                text: commentContent,
                fontSize: 12,
                lineHeight: 1.5,
                class: "comment-content",
              },
            ],
          },
        };
        dataSections.push(commentBody);

        // If there are replies, format them as well
        if (comment.replies && comment.replies.length > 0) {
          const replyText = {
            text: {
              paragraphs: [
                {
                  text: "Replies:",
                  bold: true,
                  fontSize: 14,
                  class: "comment-header",
                },
              ],
            },
          };
          dataSections.push(replyText);
          getReplies(comment.replies);
        }
      });
    } else {
      const getTable = titleBodyHtmlTable(section);
      dataSections.push(...getTable);
    }
  }

  return dataSections;
}

export default convertTaskData;
