import React, { useRef } from "react";
import { Modal, Input, message } from "antd";
import { MondayButton } from "../../../../../../commonComponents";
import {
  CheckIconModal,
  CheckWatermark,
  CloseIconModal,
} from "../../../../../../../assets";

import "./SaveNameModal.scss";

function SaveNameModal({ visibility, setVisible, onSave }) {
  const nameTemplate = useRef();
  const handleOk = () => {
    let name = nameTemplate.current?.input?.value;
    if (name?.length > 5 && name?.length < 50) {
      onSave(name);
    } else {
      message.error("Please enter at least 5 characters");
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <Modal
      header={null}
      open={visibility}
      onOk={handleOk}
      className="save-name-modal"
      destroyOnClose
      closeIcon={<></>}
      centered
      footer={null}
      maskClosable={false}
    >
      {/* <p>Name Template</p>
        <Input
          ref={nameTemplate}
          placeholder="Type the name of the template..."
        /> */}
      <div className="confirm-delete-modal-content">
        <div className="confirm-delete-header">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
              padding: "3px",
              borderRadius: "10px",
            }}
          >
            <span style={{ marginRight: "50px" }}>SAVE NEW TEMPLATE</span>
            <CheckWatermark />
          </div>
        </div>
        <div className="confirm-delete-body">
          <span className="delete-modal-content-title">
            Type at least 5 characters...
          </span>{" "}
          <span className="delete-modal-content-description">
            <Input
              ref={nameTemplate}
              placeholder="Type the name of the template..."
            />{" "}
          </span>
        </div>
        <div className="confirm-delete-footer">
          <MondayButton
            className="mondayButtonRed"
            buttonStyle={{ width: "140px" }}
            Icon={<CloseIconModal />}
            onClick={handleCancel}
          >
            Cancel
          </MondayButton>
          <MondayButton
            buttonStyle={{ width: "140px" }}
            Icon={<CheckIconModal />}
            onClick={handleOk}
          >
            Confirm
          </MondayButton>
        </div>
      </div>
    </Modal>
  );
}

export default SaveNameModal;
