import React from "react";
import { Input } from "antd";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import PickCustomPages from "../PickCustomPages/PickCustomPages";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteFilled,
} from "@ant-design/icons";

import "./PDFCard.scss";

const PDFCard = ({
  fileName,
  onMoveUp,
  onMoveDown,
  onDelete,
  onReplace,
  file,
  key,
  configPages,
  setCustomConfigPages,
}) => {
  const truncateFileName = (name) => {
    return name.length > 15 ? name.substring(0, 15) + "..." : name;
  };

  const [showNameInput, setShowNameInput] = useState(false);
  const [tempName, setTempName] = useState(
    configPages?.[file?.uid]?.customName || fileName
  );

  const onChangeName = (e) => {
    if (!e.target.value || fileName === e.target.value) {
      setShowNameInput(false);
      return;
    }
    let tempConfig = configPages;
    if (!tempConfig[file.uid])
      tempConfig[file.uid] = {
        pagesArr: [],
        pagesInfo: "",
        customName: "",
      };
    tempConfig[file.uid].customName = e.target.value;
    setCustomConfigPages(tempConfig);
    setShowNameInput(false);
  };

  return (
    <div className="pdf-card" key={key}>
      <Document className="pdfCardCanvas" file={file?.thumbUrl || file?.blob}>
        <Page pageNumber={1} />
      </Document>
      <div style={{ zIndex: 100 }} className="pdf-card__thumbnail">
        <div className="pdf-card__buttons">
          <button
            title="Move Up"
            className="pdf-card__button--up"
            onClick={() => onMoveUp(file)}
          >
            <ArrowUpOutlined />
          </button>
          <button
            title="Move Down"
            className="pdf-card__button--down"
            onClick={() => onMoveDown(file)}
          >
            <ArrowDownOutlined />
          </button>
          <button
            title="Delete"
            className="pdf-card__button--delete"
            onClick={() => onDelete(file)}
          >
            <DeleteFilled />
          </button>
          <button className="pdf-card__button--replace">
            {/* <EditOutlined /> */}
            <PickCustomPages
              {...{
                setCustomConfigPages,
                configPages,
                file,
                id: file?.uid || "1324",
                fileType: file?.type !== "local" ? "drive" : "local",
              }}
            />
          </button>
        </div>
      </div>
      <div
        className="pdf-card__filename"
        onClick={() => setShowNameInput(true)}
      >
        {" "}
        {showNameInput ? (
          <Input
            type="text"
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
            onBlur={onChangeName}
          />
        ) : (
          truncateFileName(tempName)
        )}
      </div>
    </div>
  );
};

export default PDFCard;
