import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { agGridPropsDefault } from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

function TakeOffStep({ stepperData, createProposalTakeOff }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const [gridApi, setGridApi] = useState(null);

  const rowData = useMemo(() => {
    return Object.keys(stepperData?.versionServices ?? {})?.map((el, i) => {
      let takeOff = stepperData?.versionServices;
      const newEl = {
        title: `${takeOff[el]?.versionName || ""} - ${
          takeOff[el]?.status || ""
        }`,
        status: takeOff[el]?.status,
        createdAt: takeOff[el]?.createdAt,
        createdBy: takeOff[el]?.createdBy?.name,
        key: el,
        id: el,
        takeOffItem: takeOff[el],
        estimation: stepperData?.estimationTakeOff,
        takeOffId: el,
      };
      return newEl;
    });
  }, [JSON.stringify(stepperData?.versionServices)]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows();
    createProposalTakeOff(selectedRows?.[0]);
  }, [gridApi]);

  function setPreviousSelectedNodes() {
    const selected = stepperData?.takeoff?.takeOffId;
    if (!!selected) {
      gridApi?.forEachNode((node) => {
        if (selected === node.data?.takeOffId) {
          node?.setSelected(true);
        }
      });
    }
  }

  useEffect(() => {
    if (gridApi && rowData?.length > 0 && stepperData?.takeoff?.takeOffId) {
      setPreviousSelectedNodes();
    }
  }, [rowData, stepperData, gridApi]);

  const getRowId = useCallback((param) => {
    if (param?.data?.takeOffId) {
      return param?.data?.takeOffId;
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        height: "100%",
        width: "100%",
      }}
    >
      <InputComponent
        {...{
          onChange: (e) => {
            gridApi.setQuickFilter(e.target.value);
          },
          prefix: <SearchOutlined style={{ color: "#323338" }} />,
          placeholder: "Search here...",
          className: "search-input",
          inputAllowClear: true,
          style: { maxWidth: "300px", height: "32px" },
          noFormItem: true,
        }}
      />
      <div
        className={`proposal-project-step ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          {...{
            ...agGridPropsDefault,
            rowData,
            columnDefs: takeOffColDefs,
            onSelectionChanged,
            defaultColDef: {
              enableColResize: false,
              resizable: true,
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              minWidth: 100,
              filter: true,
            },
            rowSelection: {
              mode: "singleRow",
            },
            getRowId,
            pagination: true,
            paginationPageSize: 10,
            headerHeight: 45,
            rowHeight: 40,
            paginationAutoPageSize: true,
            onGridReady: ({ api }) => {
              setGridApi(api);
            },
          }}
        />
      </div>
    </div>
  );
}

export default TakeOffStep;

const takeOffColDefs = [
  {
    headerName: "Take Off Version",
    field: "title",
    sortable: true,
    chartDataType: "series",
    filter: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Status",
    field: "status",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Created By",
    field: "createdBy",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Created On",
    field: "createdAt",
    sortable: true,
    sort: "desc",
    filter: true,
    valueFormatter: (params) => {
      return dayjsNY(params.value).format("MM/DD/YYYY HH:mm");
    },
  },
];
