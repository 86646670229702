import { message } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadableComp } from "../../XComponents";
import { queryParams } from "../../../../utils/queryParams";
import { IncidentModal as VehicleDamageModal } from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals";
import Report from "../../DynamicView/Pages/Overview/components/Report/Report";
import NewIncidentModal from "./NewIncidentModal";

import useRecordData from "../../Communication/hooks/use-record-data";
import DynamicReportDesigner from "../../Reports/components/DynamicReportDesigner/DynamicReportDesigner";

function IncidentModal({
  refreshTable = () => {},
  setEdit = null,
  category = null,
  requestObject = null,
  showNextStep = true,
  requestId = "",
  propProjId = "",
  propAddress = "",
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [documentationModalFiles, setDocumentationModalFiles] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const recordName = topicCategories?.find(
    (c) => c.categoryName === "Incidents"
  )?.apiName;
  const recordId = location.pathname.replace("/incidents/", "");
  useRecordData(recordName, recordId, location?.state?.rowObject);

  const [rowObject, setRowObject] = useState(
    location?.state?.rowObject || requestObject || undefined
  );
  const [visibleReport, setVisibleReport] = useState(false);

  const [cat, setCat] = useState(
    category
      ? category
      : rowObject?.incidentCategory
      ? rowObject?.incidentCategory
      : !!rowObject
      ? "Vehicle Damage"
      : undefined
  );

  function onClose() {
    if (!category && !location?.state?.rowObject) {
      navigate("/incidents");
      // let p = location.pathname === "/incidents";
      // navigate(p ? "/incidents" : "/fleets/overview", {
      //   search: queryParams({ tab: p ? cat : "Incidents" }),
      // });
    } else if (!!setEdit) {
      setEdit(false);
    } else {
      navigate(-1);
    }
  }

  const reportEditAccess =
    userConfiguration?.routeConfig
      ?.find((r) => r?.title === "Settings")
      ?.children.find((c) => c?.title === "Report")?.write || false;

  async function getSingleIncidentData() {
    message.loading({
      content: "Loading Form...",
      key: "modalLoading",
    });

    let id = location.pathname.replace("/incidents/", "");

    Promise.allSettled([API.get("incidents", `/incidents/${id}`)])
      .then(([inc]) => {
        if (inc.status === "fulfilled") {
          setRowObject(inc.value);
          setCat(inc.value.incidentCategory);
          setDocumentationModalFiles(null);
          message.destroy("modalLoading");
        } else {
          throw new Error("No Data Found");
        }
      })
      .catch((err) => {
        console.error("Error Getting Data: ", err);
        navigate("/incidents");
      });
  }

  useEffect(() => {
    if (!category && !rowObject) {
      getSingleIncidentData();
    }
  }, []);

  return (
    <LoadableComp loading={!cat}>
      {cat === "Vehicle Damage" ? (
        <VehicleDamageModal
          {...{
            visible: true,
            setVisible: onClose,
            deleteRow: onClose,
            showNextStep,
            refreshTable,
            rowObject,
            setVisibleReport,
            isDarkMode,
            requestId,
            propProjId,
            propAddress,
            nextStepHandler,
            handleSavedRecord,
            afterSaveRequest,
          }}
        />
      ) : (
        <NewIncidentModal
          {...{
            visible: true,
            getSingleIncidentData,
            setVisible: onClose,
            deleteRow: onClose,
            showNextStep,
            refreshTable,
            rowObject,
            setVisibleReport,
            isDarkMode,
            requestId,
            propProjId,
            propAddress,
            nextStepHandler,
            handleSavedRecord,
            incidentCategory: cat,
            afterSaveRequest,
            documentationModalFiles,
            setDocumentationModalFiles,
          }}
        />
      )}
      {visibleReport ? (
        reportEditAccess ? (
          <DynamicReportDesigner
            {...{
              visible: visibleReport,
              onCancel: () => setVisibleReport(false),
              recordId: rowObject?.incidentId || "",
              customCategoryName: `${
                rowObject?.incidentCategory || "Incident"
              } Report`,
              customPartitionKeys: { identityId: "incidentId" },
              setVisibleReport,
              sendByEmail: true,
              rowData: rowObject,
              customData: {
                ["incidents " + rowObject?.safetyApplicationCategory]: [
                  rowObject,
                ],
              },
            }}
          />
        ) : (
          <Report
            {...{
              isModalVisible: visibleReport,
              onCancel: () => setVisibleReport(false),
              customCategoryName: `${
                rowObject?.incidentCategory || "Incident"
              } Report`,
              customPartitionKeys: { identityId: "incidentId" },
              recordId: rowObject?.incidentId || "",
              setVisibleReport: setVisibleReport,
              sendByEmail: true,
            }}
          />
        )
      ) : null}
    </LoadableComp>
  );
}

export default IncidentModal;
