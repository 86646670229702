import { groupBy } from "lodash";
import {
  incidentPdfBody,
  separateWitnessForPdf,
} from "../../../../../Incidents/Modals/utils/incidentsPdf";

function incidentReportWordData({
  dynamicFields,
  selectedTeam,
  rowObject,
  formDataUsers,
  userConfiguration,
  defaultId,
  witnessCount,
  witnessSignings,
  rowObjectKey,
}) {
  const incidentTmp = [
    ...dynamicFields,
    ...((selectedTeam || [])?.length
      ? [
          {
            label: "Team Members",
            value: selectedTeam?.map((el) =>
              el?.members?.map((e) => e?.nameOfUser)
            ),
            category: "General Information",
          },
        ]
      : []),
  ];

  const prepareWitnessArr = separateWitnessForPdf({
    defaultId,
    rowObject,
    witnessCount,
    witnessSignings,
    rowObjectKey,
  });

  const witnessList = prepareWitnessArr?.map((el, i) => ({
    title: `Witness ${i + 1}`,
    body: el,
  }));

  const safetyDirectorSignatureSection = {
    title: "Safety Director Signature",
    body: [
      {
        key: "Safety Director",
        value:
          rowObject?.incidentObject?.safetyDirectorSignature?.nameOfUser || "",
      },
      {
        key: "Signature",
        value: rowObject?.incidentObject?.safetyDirectorSignature?.src || "N/A",
      },
    ],
  };

  const configPdfData = Object.keys(groupBy(incidentTmp, "category"))
    ?.filter((key) => key !== "undefined" && !!key)
    ?.map((key) => {
      return {
        title: key,
        body: incidentPdfBody({
          title: key,
          fieldsJSON: incidentTmp,
          rowObject,
          ...formDataUsers,
          userConfiguration,
        }),
      };
    });

  const comments = rowObject?.incidentObject?.comments?.length
    ? {
        title: "Comments",
        body: rowObject?.incidentObject?.comments,
      }
    : null;

  const result = [
    ...configPdfData,
    ...witnessList,
    safetyDirectorSignatureSection,
    ...(comments ? [comments] : []),
  ];

  return result;
}

export default incidentReportWordData;
