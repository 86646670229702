import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react";
import { Modal, Popconfirm, Select, message } from "antd";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../../commonComponents";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { fetchAllData } from "../../../../../../utils";
import { apiRoutes } from "../../../../../SidebarPages/Fleet/utils";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import FiltersModal from "../../../../../commonComponents/RowDataGridModal/components/FiltersModal";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { useEditLogs, usePaginationControls } from "../../../../../../hooks";
import { SmallLoadableComp } from "../../../../../Sidebars/components";
import "./UserInfoModal.scss";
import { v4 } from "uuid";

export const excelColumnsConfig = [
  "recordId",
  "recordName",
  "nameOfUser",
  "updatedAt",
];

const gridFilters = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Label",
    type: "Dropdown",
    key: "newLabel",
  },
  {
    globalKeyId: "b1c0e98c-6d0a-45d9-a6d5-826ad140351f",
    label: "Date & Time",
    type: "DateRange",
    key: "updatedAt",
  },
];

const PAGINATION = 9;

const UserInfoModal = ({ selectUserModal, setSelectUserModal }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedUser, setSelectedUser] = useState(
    userConfiguration.cognitoUserId
  );
  const [userData, setUserData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState();
  const [loading, setLoading] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const title = "User Video Logs";

  const onGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const userOptions =
    userConfiguration?.allUsers?.Items?.map((user) => ({
      value: user.cognitoUserId,
      label: user.nameOfUser,
    })) || [];

  const filters = [
    {
      id: v4(),
      operator: "AND",
      conditions: [
        {
          column: "category",
          columnType: "string",
          formula: "is",
          id: v4(),
          operator: "AND",
          value: "Video Tutorials",
        },
        {
          column: "cognitoUserId",
          columnType: "string",
          formula: "contains",
          id: v4(),
          operator: "AND",
          value: selectedUser,
        },
      ],
    },
  ];

  const { rowData = [], onPaginationChanged = () => {} } =
    usePaginationControls(
      "editLogs",
      "logId",
      setLoading,
      PAGINATION,
      "",
      filters
    );

  useEffect(() => {
    const updatedData = rowData.map((log) => ({
      ...log,
      newLabel: log?.updatedKeys[0]?.label || "",
    }));

    setUserData(updatedData);
  }, [rowData]);

  const handleAction = (user) => {
    setLoading(true);
    const filters = [
      {
        operator: "AND",
        conditions: [
          {
            operator: "AND",
            column: "category",
            value: "Video Tutorials",
            formula: "contains",
          },
          {
            operator: "AND",
            column: "cognitoUserId",
            value: user,
            formula: "contains",
          },
        ],
      },
    ];
    fetchAllData({
      endpoint: apiRoutes.editLogs,
      resultPosition: apiRoutes.editLogs,
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
        filters: JSON.stringify(filters),
      },
    }).then((result) => {
      setLoading(false);
      setUserData(
        result.map((log) => ({
          ...log,
          newLabel: log?.updatedKeys[0]?.label || "",
        }))
      );
    });
  };

  const columnDefs = [
    {
      headerName: "Label",
      field: "newLabel",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Value",
      field: "recordName",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "User",
      field: "nameOfUser",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Date & Time",
      field: "updatedAt",
      resizable: true,
      sort: "desc",
      cellRenderer: ({ data }) => dayjsNY(data?.updatedAt).format("MM/DD/YYYY"),
      filter: "agDateColumnFilter",
    },
  ];

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      enableRowGroup: true,
      sortable: true,
      flex: 1,
      filter: true,
      suppressSizeToFit: true,
    }),
    []
  );

  const excelStyles = useMemo(() => {
    return [
      {
        id: "header",
        alignment: {
          vertical: "Center",
        },
        font: {
          bold: true,
          size: 14,
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderRight: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 1,
          },
        },
      },
      {
        id: "cell",
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 1,
          },
          borderRight: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 1,
          },
        },
        alignment: {
          horizontal: "Left",
        },
      },
    ];
  }, []);

  const clearFilters = () => {
    gridApi?.setFilterModel(null);
    setAppliedFilters();
  };

  const handleUserChange = (val) => {
    setAppliedFilters();
    setSelectedUser(val);
    handleAction(val);
  };

  const generateLog = () => {
    const user = userConfiguration.allUsers?.Items?.find(
      (user) => user.cognitoUserId === selectedUser
    ).nameOfUser;

    saveAddedLogs({
      actionType: "Delete",
      category: "Video Tutorials",
      recordName: "Deleted",
      updatedKeys: [{ label: `Deleted All Logs of ${user}` }],
    });
  };

  const handleDeleteLogs = () => {
    const logsToDelete = userData.map((log) => log.logId);
    message.loading({ content: "Deleting logs...", duration: 0 });

    API.del("editLogs", "/editLogs/123", {
      body: {
        logs: logsToDelete,
      },
    }).then(() => {
      generateLog();
      setUserData([]);
      message.destroy();
      message.success("Logs deleted successfully!");
    });
  };

  return (
    <Modal
      className={`userVideoLogsModal ${
        isDarkMode && "userVideoLogsModal-dark"
      }`}
      title="User"
      open={selectUserModal}
      onCancel={() => setSelectUserModal(false)}
      footer={[
        <ExportOrEmailDropdown
          {...{
            rowData: userData,
            title: title,
            gridApi,
            gridColumnApi,
            position: "fixed",
            disabled: loading,
          }}
        />,
      ]}
    >
      <div className="body-container" style={{ width: "100%" }}>
        <>
          <label>User</label>
          <InputComponent
            type="select"
            allowClear={false}
            dropdownClassName={isDarkMode && "darkDropDown"}
            placeholder="Select User..."
            defaultValue={selectedUser}
            onChange={(val) => handleUserChange(val)}
            options={userOptions?.map((option) => (
              <Select.Option value={option.value}>{option.label}</Select.Option>
            ))}
          />
          {!!selectedUser && (
            <>
              <div className="buttons">
                <InputComponent
                  {...{
                    placeholder: "Search...",
                    type: "input",
                    inputAllowClear: true,
                    disabled: loading,
                    onChange: (event) =>
                      gridApi.setQuickFilter(event.target.value),
                    prefix: <SearchOutlined style={{ color: "#323338" }} />,
                  }}
                />
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<FilterIcon />}
                  onClick={() => setFiltersOpen(true)}
                  disabled={loading}
                >
                  Filters
                </MondayButton>
                <Popconfirm
                  title="Delete all logs"
                  description="Are you sure to delete all logs?"
                  onConfirm={handleDeleteLogs}
                  okText="Yes"
                  cancelText="No"
                >
                  <MondayButton
                    className="mondayButtonRed"
                    disabled={!userData.length || loading}
                    Icon={<DeleteOutlined />}
                  >
                    Erase all logs
                  </MondayButton>
                </Popconfirm>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 500,
                  marginTop: "16px",
                  position: "relative",
                }}
                className={
                  isDarkMode
                    ? "dark-ag-theme ag-theme-alpine-dark"
                    : "light-ag-theme ag-theme-alpine"
                }
              >
                <SmallLoadableComp loading={loading}>
                  <AgGridReact
                    {...{
                      rowData: userData,
                      columnDefs,
                      defaultColDef,
                      onGridReady,
                      excelStyles,
                      animateRows: true,
                      pagination: true,
                      defaultExcelExportParams: {
                        columnKeys: excelColumnsConfig,
                        fileName: `${selectedUser}_Video_Logs`,
                      },
                      paginationPageSize: PAGINATION,
                      onPaginationChanged: () => onPaginationChanged(),
                    }}
                  />
                </SmallLoadableComp>
              </div>
            </>
          )}
        </>
        {filtersOpen && (
          <FiltersModal
            {...{
              title,
              showFilterModal: filtersOpen,
              setShowFilterModal: setFiltersOpen,
              appliedFilters,
              setAppliedFilters: (filters) => {
                filters === "clearFilters"
                  ? clearFilters()
                  : setAppliedFilters(filters);
              },
              gridApi,
              filtersObject: gridFilters,
              rowData: userData,
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default UserInfoModal;
