import {
  getAccessRights,
  getCognitosForNotification,
} from "../../../../../../utils";

function docNotificationAccess({
  userConfiguration,
  teamsConfiguration,
  categoryAccessTitle,
}) {
  let toReturn = [];
  const validCognitos = getCognitosForNotification(
    userConfiguration,
    teamsConfiguration
  );

  const allUsersObj =
    userConfiguration?.allUsers?.Items?.reduce((acc, user) => {
      acc[user.cognitoUserId] = user;
      return acc;
    }, {}) || {};

  validCognitos.forEach((cognitoId) => {
    const user = allUsersObj[cognitoId];
    const hasCategoryAccess = getAccessRights({
      userConfiguration: user,
      title: categoryAccessTitle,
    }).routeConfig?.read;

    if (hasCategoryAccess) {
      toReturn.push(cognitoId);
    }
  });
  return toReturn;
}

export default docNotificationAccess;
