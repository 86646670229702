import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useContext } from "react";
import { InfoWindowF, OverlayViewF } from "@react-google-maps/api";

import { InfoIcon } from "src/assets";
import { EntryType } from "../../payrollLiveTypes";
import { PersonLocationIcon } from "src/assets/icons";
import { Marker } from "src/components/commonComponents";
import PayrollLiveContext from "../../PayrollLiveContext";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./EmployeeHistoryMarker.scss";
import "../EmployeeInfo/EmployeeInfo.scss";

interface Props {
  color?: string;
  key: string | number;
  onClick?: () => void;
  data: EntryType & { isForeman?: boolean };
  setEmployeesHistory?: Dispatch<
    SetStateAction<Array<EntryType & { color?: string }>>
  >;
}

const punchTypeColor = {
  ID: "#00a464",
  OL: "#787677",
  IL: "#00a464",
  OD: "#787677",
};

function EmployeeHistoryMarker(props: Props) {
  const { data, key, onClick, color, setEmployeesHistory } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const { historyInfo, setHistoryInfo } = useContext(PayrollLiveContext);

  function toggleInfo() {
    if (historyInfo === `${data?.employeeId}${data?.punchType}`) {
      setHistoryInfo(undefined);
    } else {
      setHistoryInfo(`${data?.employeeId}${data?.punchType}`);
    }
  }

  return (
    <OverlayViewF
      key={key}
      zIndex={2}
      position={data.punchCoordinates}
      mapPaneName={"overlayMouseTarget"}
    >
      <Marker
        onClick={() => {}}
        address={data.punchLocation}
        coordinates={data.punchCoordinates}
      >
        <div style={{ border: "1px solid reds" }}>
          <PersonLocationIcon
            width={40}
            height={38}
            data-testid="employee-history-marker"
            className="employee-history-marker"
            onClick={() => {
              toggleInfo();
              if (!!onClick) {
                onClick();
              }
            }}
            fill={
              data?.isForeman
                ? "#1264a38b"
                : punchTypeColor?.[data?.punchType] || "#e9c466"
            }
          />
          <span className="history-punch-type">{data?.punchType}</span>
        </div>
      </Marker>
      {historyInfo === `${data?.employeeId}${data?.punchType}` ? (
        <InfoWindowF
          zIndex={3}
          key={data.employeeId + "info"}
          position={data.punchCoordinates}
          options={{ pixelOffset: new google.maps.Size(0, -36) }}
        >
          <section
            className={`employee-info-window ${
              darkMode ? "employee-info-window-dark" : ""
            }`}
            style={{ boxShadow: `0px 0px 4px 0px ${color || "#e9c466"}` }}
          >
            <div className="info-header">
              <div className="info-title">{data.employeeFullName}</div>
              <XIcon
                onClick={() => {
                  setEmployeesHistory([]);
                  setHistoryInfo(undefined);
                }}
                width={12}
                height={12}
              />
            </div>
            <div className="info-body">
              <div className="location-information">
                <Tooltip title="Employee's Punch location">
                  <InfoIcon width={14} height={14} />
                </Tooltip>
                <span>{data?.punchLocation}</span>
              </div>
              <div className="info-data">
                <div>{dayjsNY(data.punchTime).format("hh:mm A")}</div>
                <div
                  className="cancel-info"
                  onClick={() => {
                    setEmployeesHistory([]);
                    setHistoryInfo(undefined);
                  }}
                >
                  Hide History
                </div>
              </div>
              <div className="info-data">
                <div>{data.punchType}</div>
              </div>
            </div>
          </section>
        </InfoWindowF>
      ) : null}
    </OverlayViewF>
  );
}

export default EmployeeHistoryMarker;
