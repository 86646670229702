import { Avatar } from "antd";
import { useResponsive } from "../../../../../../hooks";
import { useCommunication } from "../../../providers/CommunicationProvider";

import "./user-view.scss";

// A clickable  reusable component which shows the user information like name, photo and email
const UserView = ({ user, isSelected }) => {
  const { picture, email, name } = user ? user : {};
  const { mobile } = useResponsive();
  const { changeSelectedEmail, selectedEmail } = useCommunication();

  // this will be triggered when the component is clicked for the first time and changes the selected email from company to user and vice versa. It returns nothing if it is already clicked
  const handleOnClick = () => {
    if (!isSelected) {
      if (selectedEmail === "company") {
        changeSelectedEmail("user");
      } else {
        changeSelectedEmail("company");
      }
    }
  };

  return (
    <div
      className={`user-view-container ${isSelected ? "selected" : ""}`}
      onClick={handleOnClick}
      data-testid="user-view-container"
    >
      <div className="avatar-container">
        {picture ? (
          <Avatar alt="user-avatar" src={picture} />
        ) : (
          <Avatar className="no-image-avatar">
            {email?.toUpperCase().split("")[0]}
          </Avatar>
        )}
      </div>
      <div className="user-info">
        {/* name and email are hidden on mobile viewport */}
        {name && !mobile && <p className="user-name">{name}</p>}
        {email && !mobile && <p className="user-email">{email}</p>}
      </div>
    </div>
  );
};

export default UserView;
