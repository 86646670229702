import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import MondayButton from "../MondayButton/MondayButton";
import "./CustomModalHeader.scss";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import { Alert, Modal, Tooltip } from "antd";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { VideoTutorialIcon } from "../../Header/components/ModalVideoTutorials/assets/svg";
import { updateDocumentTitle } from "../../../utils";
import TooltipMonday from "../ToolTip/TooltipMonday";
import { driveApi } from "../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import { SmallLoadableComp } from "../../Sidebars/components";
import { useMediaQuery } from "../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";

/**
 * CustomModalHeader component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string|JSX} props.title - The title of the header.
 * @param {function} props.onClick - The click event handler for the guide button.
 * @param {boolean} props.hasButton - Indicates whether the header has a guide button.
 * @param {Object} props.style - The inline style for the header container.
 * @param {boolean} props.noX - Indicates whether the header has an 'X'.
 * @param {function} props.headerInfo - The function that renders additional header buttons near guide button.
 * @returns {JSX.Element} The rendered CustomModalHeader component.
 */
const CustomModalHeader = ({
  title = "",
  onClick = () => {},
  hasButton = true,
  style = {},
  noX = false,
  headerInfo = () => {},
}) => {
  return (
    <div
      className={noX ? "fullTitleContainer" : "titleContainer"}
      style={style}
    >
      <div className="title">{title}</div>
      <div className="buttons">
        {headerInfo()}
        {hasButton && (
          <Tooltip title="Guide">
            <QuestionCircleFilled
              style={{ fontSize: 23, color: "#1264A3", cursor: "pointer" }}
              onClick={onClick}
              id="tourButton"
              data-testid="tour-button"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CustomModalHeader;

/**
 * Renders a custom modal component for playing video tutorials.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visible - Determines if the modal is visible.
 * @param {function} props.setVisible - Callback function to toggle the visibility of the modal.
 * @param {string} [props.url=null] - The URL of the video tutorial.
 * @param {string} [props.title=null] - The title of the modal.
 * @param {number} [props.zIndex=null] - custom zIndex for the modal if needed.
 * @returns {JSX.Element}
 */
export const PlayVideoTutorial = ({
  visible,
  setVisible,
  url = null,
  title = null,
  zIndex = null,
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const [playing, setPlaying] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const { getImageSrc } = driveApi({ accessToken });

  const play = () => setPlaying(true);
  const pause = () => setPlaying(false);

  useEffect(() => {
    setLoading(true);
    getImageSrc(url.split("=")[1])
      .then((res) => {
        setLoading(false);
        setFileSrc(res);
      })
      .catch((err) => {
        console.log("ERR: ", err);
        setLoading(false);
        message.error("Error loading video");
      });
  }, []);
  return (
    <>
      <Modal
        {...{
          open: visible,
          setVisible,
          centered: true,
          onCancel: () => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setVisible(false);
          },
          footer: null,
          closeIcon: <XIcon />,
          className: "customVideoTutorialModal",
          title: title ? title : null,
          afterOpenChange: (event) => {
            if (typeof title === "string") {
              event && updateDocumentTitle({ newTitle: title });
            }
          },
          bodyStyle: title ? { paddingTop: 0 } : { paddingTop: 56 },
          zIndex: zIndex ? zIndex : 1000,
        }}
      >
        <div
          style={
            loading
              ? {
                  height: 150,
                  width: 300,
                }
              : {}
          }
        >
          <SmallLoadableComp loading={loading}>
            {!!url ? (
              <ReactPlayer
                {...{
                  url: [
                    {
                      src: fileSrc.src,
                    },
                  ],
                  play,
                  pause,
                  playbackRate: 1,
                  height: "100%",
                  width: "100%",
                  controls: true,
                  onReady: play,
                  onStart: play,
                  playing,
                  onPlay: () => {
                    play();
                  },
                  onPause: pause,
                  light: false,
                  playsinline: true,
                }}
              />
            ) : (
              <Alert message="No Video Tutorial" type="error" showIcon />
            )}
          </SmallLoadableComp>
        </div>
      </Modal>
    </>
  );
};

export const tourTitle = (title = "", onClick = () => {}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <MondayButton
        {...{
          className: "mondayButtonBlue",
          onClick: onClick,
          Icon: <VideoTutorialIcon />,
        }}
      >
        Watch Video
      </MondayButton>
      <div style={{ fontWeight: "bold" }}>{title}</div>
    </div>
  );
};

export const findTutorialSteps = (name = "", programFields) => {
  const steps = programFields
    ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
    ?.fieldOptions?.find(({ categoryName }) => categoryName === name)?.steps;
  return steps || [];
};

export const postTutorialSteps = (name = "", programFields, newSteps) => {
  const newBody = programFields.map((field) => {
    if (field.fieldName === "Tutorials Steps") {
      const newFieldOptions = field.fieldOptions.map((option) => {
        if (option.categoryName === name) {
          return { ...option, steps: newSteps };
        }
        return option;
      });
      return { ...field, fieldOptions: newFieldOptions };
    }
    return field;
  });

  return newBody;
  // API.patch();
};

export const MondayButtonIcon = ({
  name = "",
  onClick = () => {},
  Icon,
  style,
  className = null,
  disabled = false,
  tooltipProps = {},
  ...rest
}) => {
  return (
    <Tooltip
      title={name}
      {...{
        ...tooltipProps,
      }}
    >
      <MondayButton
        {...{
          onClick,
          Icon,
          className: !!className ? className : "mondayButtonBlue",
          style: !!style ? style : { alignSelf: "end" },
          disabled,
          ...rest,
        }}
      />
    </Tooltip>
  );
};

/**
 * Renders a component that displays an information text with an icon.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.text=""] - The text to be displayed.
 * @param {string} [props.className] - The CSS class name for the component.
 * @param {Object} [props.style] - The inline CSS styles for the component.
 * @param {boolean} [props.isDarkMode] - Indicates whether the component should be displayed in dark mode.
 * @param {boolean} [props.hideOnMobile=true] - Indicates whether the component should be hidden on mobile view.
 * @returns {JSX.Element} The rendered InfoText component.
 */
export const InfoText = ({
  text = "",
  className,
  style = {},
  hideOnMobile = true,
}) => {
  const isMobileView = useMediaQuery({
    query: "(min-width: 512px)",
  });
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const defStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    maxWidth: "800px",
    ...style,
    ...(isDarkMode ? { color: "#f9f9f9" } : {}),
  };
  return isMobileView && hideOnMobile ? null : (
    <div style={defStyle} className={className}>
      <InfoCircleFilled style={{ color: "#1264a3", fontSize: "20px" }} />
      <span>{text}</span>
    </div>
  );
};
