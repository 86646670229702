import _ from "lodash";
import { calculatePPU, calculatePrice } from "../formatters";
import { PriceSchemesType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import getNewId from "../polyfillers/getNewId";
import { calculateRent, calculateTotalPrice } from "../formatters/pricing";
import { createEmptyPLI } from "../polyfillers/PLICreator";
import {
  checkIfAllRowsHaveValidData,
  checkIfRowHasValidData,
  checkIfTabRowHasValidData,
  checkTABIfAllRowsHaveValidData,
} from "../formatters/validators";
import { message } from "antd";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
/*eslint eqeqeq:0*/

// called when a cell editing has stopped and the value has changed. used for autosave
export const cellValueChanged = (
  params,
  serviceIndex,
  optionIndex,
  elevationIndex,
  gridDataInState,
  selectedPriceSchemesForService,
  ServicesIndex,
  updateStateAndSave,
  setTakeOffTableData,
  canViewPrice
) => {
  // params.event.preventDefault();
  const { serviceId, isScope } = gridDataInState[serviceIndex];
  // if (isScope) return;
  let gridData = _.cloneDeep(gridDataInState);
  let rowToUpdate = undefined; //only ppu will rerender
  let rerenderOnlyPPU = false;
  if (checkIfIamEngineer(canViewPrice, params.colDef.field)) {
    //TODO: LOGIC!

    if (
      checkTABIfAllRowsHaveValidData(
        gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
      ) &&
      checkIfTabRowHasValidData(params.data)
    ) {
      //if yes add a new PLI row at the bottom
      const highestId = getNewId(
        gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
      );
      const newPLI = createEmptyPLI(
        serviceId,
        highestId,
        gridData[serviceIndex],
        optionIndex
      ); //will create a new PLI based on service type, it may be SidewalkShedPLI, NeedleBeamPLI, etc
      newPLI.ppu = gridData[serviceIndex].pricePerUnit; //by default it will take ppu from service (also it is unlocked and unapproved)
      gridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.push(newPLI); //add it in this elevation items
      params.api.applyTransaction({ add: [newPLI] }); //add it into ag-grid table
      updateStateAndSave(gridData); //save in state and save in estimation database
    }
    // if (checkIfRowHasValidData(params.data)) {
    //   const newPLI = createEmptyPLI(
    //     serviceId,
    //     highestId,
    //     gridData[serviceIndex],
    //     optionIndex
    //   ); //will create a new PLI based on service type, it may be SidewalkShedPLI, NeedleBeamPLI, etc
    //   // newPLI.ppu = gridData[serviceIndex].pricePerUnit; //by default it will take ppu from service (also it is unlocked and unapproved)
    //   gridData[serviceIndex].serviceOptions[optionIndex][
    //     elevationIndex
    //   ].items.push(newPLI); //add it in this elevation items
    //   params.api.applyTransaction({ add: [newPLI] }); //add it into ag-grid table}
    //   params.api.refreshCells({
    //     force: true,
    //     suppressFlash: false,
    //     rowNodes: [newPLI],
    //   });
    //   updateStateAndSave(gridData); //save in state and save in estimation database
    // }
    // params.api.ensureIndexVisible(params.rowIndex + 1);
    return;
  }

  console.log(
    "cellValueChanged",
    params,
    serviceIndex,
    optionIndex,
    elevationIndex,
    gridDataInState,
    selectedPriceSchemesForService,
    ServicesIndex,
    updateStateAndSave
  );

  for (
    let i = 0;
    i <
    gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
      .length;
    i++
  ) {
    let pli =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items[
        i
      ];
    // not scopes
    if (pli.id === params.data.id && !isScope) {
      console.log("params editor", serviceId);
      //find te proper PLI row to edit
      //usually this.state.gridData has been modified (maybe because it is used as row data).
      // If it is an auto created pli (last row), it will not take changes. this is why we are assigning this way to make sure our pli gets the correct values
      // noinspection FallThroughInSwitchStatementJS
      switch (params.colDef.field) {
        case "approved": {
          pli.approved = params.data.approved;
          if (Array.isArray(pli?.addons) && pli?.addons?.length > 0) {
            pli.addons.forEach(
              (addon) => (addon.approved = params.data.approved)
            );
          }
          console.log("myPli", pli);
          rowToUpdate = pli;
          pli.totalPrice = calculateTotalPrice(pli);

          break;
        }
        case "ppu": {
          //    console.log(pli)
          pli.ppu = +params.data.ppu; //add with valid expresion
          pli.lock = true;
          // if (isNaN(pli.rent))
          //   console.log(pli.rent)
          pli.price =
            calculatePrice(
              serviceId,
              params.data,
              selectedPriceSchemesForService[serviceId]
            ) || 0;
          pli.rent =
            calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);
          pli.totalPrice = +pli.price + +pli.taxAmount || 0;
          // System Scaffold include span price
          if (serviceId === 1) {
            for (const addOn of pli.addons) {
              if (addOn?.approved && addOn?.spanPrice) {
                pli.totalPrice += Number(addOn?.spanPrice || 0);
              }
            }
          }
          console.log("myPli", pli);

          rerenderOnlyPPU = true;
          rowToUpdate = pli;
          pli.totalPrice = calculateTotalPrice(pli);
          pli.lock = true;
          break;
        }
        case "rent": {
          pli.rent = +params.data.rent || 0;
          pli.lock = true;
          rowToUpdate = pli;
          pli.totalPrice = calculateTotalPrice(pli);

          break;
        }
        case "price": {
          pli.price = +params.data.price || 0;
          pli.lock = true;
          pli.rent =
            calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          pli.ppu = calculatePPU(serviceId, params.data) || 0;
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);
          pli.totalPrice = +pli.price + +pli.taxAmount || 0;
          rowToUpdate = pli;
          pli.totalPrice = calculateTotalPrice(pli);

          break;
        }
        case "lock": {
          const selectedPricingObject =
            selectedPriceSchemesForService[serviceId];
          pli.lock = params.data.lock;
          if (
            selectedPricingObject?.priceSchemesType === PriceSchemesType.SHEET
          ) {
            //do nothing, just rerender ppu column
            rerenderOnlyPPU = true; //no immediate ppu change, it will just render the suggestion tooltip
          }
          if (params.data.lock) {
            //if row has been locked, calculate the default price (calculate nothing)
            // pli.ppu = calculatePPU(gridData[serviceIndex].serviceId, params.data);
          } else {
            //take the selected PPU which is located on the top of all elevations
            // pli.ppu = this.state.pricePerUnitInfo[this.state.gridData[serviceIndex].serviceId];
            pli.ppu = +(gridData[serviceIndex].pricePerUnit || pli.ppu) || 0;
            pli.price = calculatePrice(serviceId, pli) || 0;
            pli.rent =
              calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          }
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);

          pli.totalPrice = +pli.price + +pli.taxAmount || 0;
          rowToUpdate = pli;
          pli.totalPrice = calculateTotalPrice(pli);
          console.log("LockCase", pli);

          break;
        }
        case "length": {
          console.log("we change", params);
          pli.length = +params.data.length || 0;
          pli.sqft = pli.length * pli.height || 0;
          pli.width = +params.data.width || 0;

          if (
            !!pli?.isJump?.jumpWidth >= +pli?.length &&
            pli?.isJump === true
          ) {
            pli.isJump.jumpWidth = 1;
          }

          if (isNaN(pli.ppu) && serviceId !== 11) {
            //if ppu is not defined, calculate ppu
            // pli.ppu = this.state.pricePerUnitInfo[this.state.gridData[serviceIndex].serviceId]; //this may return still a NaN if item.price is also NaN
            pli.ppu = +gridData[serviceIndex].pricePerUnit || 0;
          } else {
            pli.ppu = pricePerUnitCalculatorCases({
              pli,
              serviceId,
              gridData,
              serviceIndex,
              selectedPriceSchemesForService,
            }); // pli.ppu = 0;
            //if ppu is a number, calculate price
          }
          pli.price =
            +calculatePrice(
              serviceId,
              params.data,
              selectedPriceSchemesForService[serviceId]
            ) || 0;
          pli.rent =
            +calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);

          pli.totalPrice = +pli.price + +pli.taxAmount || 0;

          if (serviceId === ServicesIndex["Sidewalk Shed"]) {
            //in sidewalk shed, change W H L of all addons
            for (const addon of pli.addons) {
              addon.height = +pli.height || 0;
              addon.width = +pli.width || 0;
              addon.length = +pli.length || 0;
            }
          }
          pli.totalPrice = calculateTotalPrice(pli);

          rowToUpdate = pli;
          break;
        }
        case "height": {
          pli.height = +params.data.height || 0;
          pli.sqft = pli.length * pli.height || 0;
          pli.width = +params.data.width || 0;
          if (isNaN(pli.ppu) && serviceId !== 11) {
            //if ppu is not defined, calculate ppu
            // pli.ppu = this.state.pricePerUnitInfo[this.state.gridData[serviceIndex].serviceId]; //this may return still a NaN if item.price is also NaN
            pli.ppu = +gridData[serviceIndex].pricePerUnit || 0;
          } else {
            pli.ppu = pricePerUnitCalculatorCases({
              pli,
              serviceId,
              gridData,
              serviceIndex,
              selectedPriceSchemesForService,
            });
            // pli.ppu = 0;
            //if ppu is a number, calculate price
          }
          if (
            (pli.height > 150 && serviceId === ServicesIndex["Scaffold"]) ||
            serviceId === ServicesIndex["System Scaffold"]
          ) {
            // pli.ppu++;
          }
          console.log("myData", {
            param: params.data,
          });
          pli.price =
            +calculatePrice(
              serviceId,
              { ...params.data, ppu: pli.ppu },
              selectedPriceSchemesForService[serviceId]
            ) || 0;
          pli.rent =
            +calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);

          pli.totalPrice = +pli.price + +pli.taxAmount || 0;
          pli.totalPrice = calculateTotalPrice(pli);

          if (serviceId === ServicesIndex["Sidewalk Shed"]) {
            //in sidewalk shed, change W H L of all addons
            for (const addon of pli.addons) {
              addon.height = +pli.height || 0;
              addon.width = +pli.width || 0;
              addon.length = +pli.length || 0;
            }
          }

          rowToUpdate = pli;

          break;
        }
        case "width": {
          pli.width = +params.data.width || 0;
          if (isNaN(pli.ppu)) {
            //if ppu is not defined, calculate ppu
            // pli.ppu = this.state.pricePerUnitInfo[this.state.gridData[serviceIndex].serviceId]; //this may return still a NaN if item.price is also NaN
            pli.ppu = +gridData[serviceIndex].pricePerUnit || 0;
          } else {
            // pli.ppu = 0;
            //if ppu is a number, calculate price
          }
          pli.price =
            +calculatePrice(
              serviceId,
              params.data,
              selectedPriceSchemesForService[serviceId]
            ) || 0;
          pli.rent =
            +calculateRent(gridData[serviceIndex], pli, optionIndex) || 0;
          // pli.taxAmount = +pli.price * +pli.taxRate || 0;
          pli.taxAmount = pliTaxAmountCalculate(pli);

          pli.totalPrice = +pli.price + +pli.taxAmount || 0;

          if (serviceId === ServicesIndex["Sidewalk Shed"]) {
            //in sidewalk shed, change W H L of all addons
            for (const addon of pli.addons) {
              addon.height = +pli.height || 0;
              addon.width = +pli.width || 0;
              addon.length = +pli.length || 0;
            }
          }
          pli.totalPrice = calculateTotalPrice(pli);

          rowToUpdate = pli;
          break;
        }
        case "isInitial": {
          const isInital = params.data.isInitial;
          const curr = gridDataInState[serviceIndex];
          //    console.log(pli)
          pli.ppu = isInital
            ? +curr?.defaultPricePerUnit || 0
            : gridDataInState[serviceIndex]?.pricePerUnit; //add with valid expresion
          pli.lock = true;
          // if (isNaN(pli.rent))
          //   console.log(pli.rent)
          pli.price = calculatePrice(
            serviceId,
            { ...params.data, ppu: pli.ppu },
            selectedPriceSchemesForService[serviceId]
          );
          pli.rent = calculateRent(
            {
              ...gridData[serviceIndex],
              rent: isInital ? curr?.initialRent || 0 : curr?.rent || 0,
            },
            pli,
            optionIndex
          );
          // pli.taxAmount = +pli.price * +pli.taxRate;
          pli.taxAmount = pliTaxAmountCalculate(pli);

          pli.totalPrice = +pli.price + +pli.taxAmount;
          pli.totalPrice = calculateTotalPrice(pli);

          // System Scaffold include span price
          if (serviceId === 1) {
            for (const addOn of pli.addons) {
              if (addOn?.approved && addOn?.spanPrice) {
                pli.totalPrice += Number(addOn?.spanPrice || 0);
              }
            }
          }
          rerenderOnlyPPU = true;
          rowToUpdate = pli;
          break;
        }
        default: {
          //in all other cases, the edited field will be assigned to the corresponding field
          pli[params.colDef.field] = params.data[params.colDef.field];
          rowToUpdate = pli;
          break;
        }
      }
      // pli.totalPrice = calculateTotalPrice(pli);

      // const isInital = params.data.isInitial;
      // const curr = gridDataInState[serviceIndex];
      // if (params.colDef.field !== "price") {
      //   pli.rent = calculateRent(
      //     {
      //       ...gridData[serviceIndex],
      //       rent: isInital ? curr?.initialRent || 0 : curr?.rent || 0,
      //     },
      //     pli,
      //     optionIndex
      //   );
      // } else {
      //   console.log("myPli", pli);
      //   pli.price =
      //     calculatePrice(
      //       serviceId,
      //       rowToUpdate,
      //       selectedPriceSchemesForService[serviceId]
      //     ) || 0;
      //   pli.rent = calculateRent(
      //     {
      //       ...gridData[serviceIndex],
      //       rent: isInital ? curr?.initialRent || 0 : curr?.rent || 0,
      //     },
      //     pli,
      //     optionIndex
      //   );
      // }
    } else if (isScope) {
      let pli = {
        ...gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          .items[i],
        taxAmount: 0,
        totalPrice: 0,
      }; //if the edited row is not a PLI, it will be assigned to the corresponding field
      switch (params.colDef.field) {
        case "price": {
          pli.price = forceToNumber(params.data.price) || 0;
          pli.totalPrice = forceToNumber(params.data.price) || 0;
          rowToUpdate = pli;
          break;
        }
        default: {
          pli[params.colDef.field] = params.data[params.colDef.field];
        }
      }
    }
  }

  if (params.node.lastChild && serviceId.toString() !== "3" && !isScope) {
    //when typing in the last row
    //check if other rows are valid
    if (
      checkIfAllRowsHaveValidData(
        gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
      )
    ) {
      //if yes add a new PLI row at the bottom
      const highestId = getNewId(
        gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
      );
      const newPLI = createEmptyPLI(
        serviceId,
        highestId,
        gridData[serviceIndex],
        optionIndex
      ); //will create a new PLI based on service type, it may be SidewalkShedPLI, NeedleBeamPLI, etc
      newPLI.ppu = gridData[serviceIndex].pricePerUnit; //by default it will take ppu from service (also it is unlocked and unapproved)
      gridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.push(newPLI); //add it in this elevation items
      params.api.applyTransaction({ add: [newPLI] }); //add it into ag-grid table
    }
  }

  // if (isScope) {
  //   console.log("scope");
  //   params.api.refreshCells({}); //refresh all cells
  // }

  if (rowToUpdate) {
    try {
      params.api.applyTransaction({ update: [rowToUpdate] }); //update ag-grid table
      if (rerenderOnlyPPU) {
        //if we need to update PPU rendering (used only in PriceSheets types) to rerender "apply suggestion"
        params.api.refreshCells({
          force: true,
          suppressFlash: false,
          columns: ["ppu"],
          rowNodes: [rowToUpdate],
        });
      } else {
        //update the whole row
        params.api.refreshCells({
          force: true,
          suppressFlash: false,
          rowNodes: [rowToUpdate],
        });
      }
    } catch (e) {
      console.warning("updating ag grid data in view didn't go well");
      console.error(e);
    }
  }

  console.log("gridData check", params?.colDef?.field);
  let currField = params?.colDef?.field;
  if (
    currField === "addRentalsRecuringRate" ||
    currField === "addRentalsTotal" ||
    currField === "addRentalsPriceMonth" ||
    currField === "addRentalsMonths"
  ) {
    let currService = gridData?.[serviceIndex] || {};
    if (currService?.additionalRentalTerms) {
      let additionalRentalAmount = 0;
      currService.serviceOptions?.[0]?.forEach((elevation) => {
        let plis = elevation?.items || [];
        plis.forEach((pli) => {
          if (pli?.approved) {
            additionalRentalAmount += forceToNumber(pli?.addRentalsTotal);
          }
        });
      });
      // }
      currService.additionalRentalTerms.newPrice = additionalRentalAmount;
    }
  }
  updateStateAndSave(gridData); //save in state and save in estimation database
  // }
  params.api.ensureIndexVisible(params.rowIndex + 1);
};

const checkIfIamEngineer = (canViewPrice, colName) => {
  // If I am Engineer, tab enter!
  return true;
  if (canViewPrice) {
    return false;
  } else {
    switch (colName) {
      case "length":
        return true;
      case "height":
        return true;
      case "width":
        return true;
      case "fromFloor":
        return true;
      case "toFloor":
        return true;
      case "toFloorNote":
        return true;
      case "fromFloorNote":
        return true;
      case "includedCorners":
        return true;
      case "number_of_shoring":
        return true;
      case "diameter":
        return true;
      case "hoppers":
        return true;
      case "stories":
        return true;
      case "onFloor":
        return true;
      default:
        return false;
    }
  }
};

const pricePerUnitCalculatorCases = ({
  serviceId,
  gridData,
  serviceIndex,
  pli,
}) => {
  console.log("pricePerUnitCalculatorCases", {
    serviceId,
    gridData,
    serviceIndex,
    pli,
  });
  switch (serviceId) {
    case 15: {
      return +gridData[serviceIndex]?.pricePerUnit || pli?.ppu || 0;
    }
    default: {
      return +gridData[serviceIndex]?.pricePerUnit || pli?.ppu || 0;
    }
  }

  return 0;
};

// const definers = {
//   1: sidewalkShed,
//   2: scaffold,
//   3: hoist,
//   4: fence,
//   5: roofProtection,
//   6: overheadProtection,
//   7: needleBeam,
//   8: horizontalNetting,
//   9: barriers,
//   10: workingPlatform,
//   11: systemScaffold,
//   12: stairTower,
//   13: shoring,
//   14: plywoodEnclosure,
//   15: debrisChute,
//   16: turnStiles,
//   17: hangingScaffold,
//   18: verticalNetting,
//   19: windowProtection,
//   20: platformScaffold,
// };

export const pliTaxAmountCalculate = (pli) => {
  console.log("pliTaxAmountCalculate", pli);
  return (
    (forceToNumber(pli.price) + forceToNumber(pli.pliAddonTotal)) *
    forceToNumber(pli.taxRate)
  );
};
// pli.taxAmount = +pli.price * +pli.taxRate || 0;
