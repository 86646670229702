import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Tour, message } from "antd";
import {
  ESTIMATION_INFORMATION,
  footerButtons,
  fieldsJSON,
  body,
  gridFilters,
  dataGridColumns,
  gridSidebar,
} from "./estimationModalData";
import "./EstimationModal.scss";
import { MondayButton, ProgressComponent } from "../../../commonComponents";
import {
  BorderedTextCard,
  // InputComponent,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import {
  clearFilter,
  compareIncluding,
  // getLatLngByAddress,
  onFilter,
} from "../../../SidebarPages/utils";
import {
  apiRoutes,
  fetchData,
  // getCoordinatesAndZip,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import {
  ArchEngPopoverContent,
  FormGrid,
  RenderDynamicComponents,
} from "../Components";
import {
  fetchAllData,
  filterTables,
  getCognitosForNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
// import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
// import { wsFormRequests } from "../../../../AppData";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import { useDuplicatePrevention } from "../../../SidebarPages/hooks";
import { sortBy } from "lodash";
import { AssigneesToggle } from "./components";
// import { hasValuesChanged } from "../../../../utils/hasValuesChanged";
// import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { DownCaretWhite, PlusIcon } from "../../../../assets/icons";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
// import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { estimationWS } from "../../../../sockets";
// import { driveApi } from "src/integrations/DriveRequest";
import {
  useEditLogs,
  useProgramFields,
  useProgressComponent,
} from "../../../../hooks";
import ClientModal from "../ClientModal/ClientModal";
import NewNonUserModal from "../../../pages/Settings/settingsComponents/Engineers And Architects/Modal/NewNonUserModal";

// const requestFormWs = new WebSocket(wsFormRequests.online);
const EstimationModal = ({
  visible,
  setVisible,
  refreshTable = () => {},
  requestId = "",
  requestFiles = [],
  estimation = {},
  requestObject,
  opportunityData = {},
  isChangeOrder: isChangeOrderProp = false,
  isNextStep,
  afterSaveRequest = async () => {},
}) => {
  const navigate = useNavigate();
  const [
    { authenticatedUser },
    { isDarkMode },
    { userConfiguration },
    { programFields },
    { accessToken },
  ] = useSelector((state) => [
    state.authenticatedUser,
    state.darkMode,
    state.userConfig,
    state.programFields,
    state.accessToken,
  ]);
  const {
    jobSiteAddress,
    dueDate = undefined,
    estSTATUS: existingEstStatus,
    engAssignedTo,
    projectExecutive,
    archAssignedTo,
    anticipStartDate,
    anticipEndDate,
    estimationId,
    accountName,
    googleDriveFolderIds,
    accountIdForKey,
    projectIdData,
    opportunityId: changeOrderOppId = "",
  } = estimation;
  const [estimations, setEstimations] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [leads, setLeads] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [architects, setArchitects] = useState([]);
  const [engineers, setEngineers] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [saving, setSaving] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState();
  const [selectedJobsite, setSelectedJobsite] = useState();
  const [selectedEngineer, setSelectedEngineer] = useState();
  const [selectedArch, setSelectedArch] = useState();
  const [engineerPopoverVisible, setEngineerPopoverVisible] = useState(false);
  const [archPopoverVisible, setArchPopoverVisible] = useState(false);
  const [existingEngJobsites, setExistingEngJobsites] = useState([]);
  const [existingArchJobsites, setExistingArchJobsites] = useState([]);
  const [coreFieldsDisabled, setCoreFieldsDisabled] = useState(false);
  const [selectedPrjExecutive, setSelectedPrjExecutive] = useState();
  const [selectedAccountManager, setSelectedAccountManager] = useState();
  const [areAssigneesRequired, setAreAssigneesRequired] = useState(true);
  const [addressTypeAndId, setAddressTypeAndId] = useState(null);

  const [gridApi, setGridApi] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [estimationTeam, setEstimationTeam] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [gridLoading, setGridLoading] = useState(true);
  const [formChanged, setFormChanged] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [nonUserModal, setNonUserModal] = useState(false);

  const [form] = Form.useForm();
  const driveFunctions = useCreateDriveFolders("Estimations");
  const oppDriveFunctions = useCreateDriveFolders("Opportunities");

  const {
    ["Priority Status"]: priorityTypes,
    ["Status by Architecture"]: statusByArch,
    ["Status by Engineering"]: statusByEng,
  } = useProgramFields();

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Estimation"
    )?.videoLink;

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Estimations",
    actionType: "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const gridData = useMemo(() => {
    let data = [];
    if (!!gridApi)
      gridApi?.forEachNode((row) => {
        return data.push(row);
      });
    return data;
  }, [gridApi]);

  const originalEst = useMemo(() => {
    return !!estimationId
      ? estimations?.find(({ estimationId: arrEstId }) =>
          compareIncluding(arrEstId, estimationId)
        )
      : {};
  }, [estimations, estimationId]);

  useDuplicatePrevention({
    listOfRecords: estimations,
    filter: {
      jobSiteAddress: selectedJobsite,
      projectExecutive: selectedPrjExecutive,
      accountName: selectedCompanyName,
    },
    onDuplication: () =>
      message.warning("There is an existing estimation with this information!"),
  });
  //region MAIN DATA
  const {
    googleDriveFolderIds: projectDriveId = {},
    projectId,
    accountName: projectAccountName,
    alternativeAddresses: projectAltAddresses = [],
    projectExecutive: prjExecutive,
    projectManager: prjManager,
    projectLatitude,
    projectLongitude,
    borough: projectBorough,
    teamsConfiguration: projectTeamsConfiguration,
    proposalStartDate: projectStartDate,
  } = useMemo(() => {
    return (
      projects?.find?.(({ projectName, projectId: pId }) =>
        projectIdData && !changeOrderOppId
          ? pId === projectIdData
          : addressTypeAndId?.type === "Project" && !!addressTypeAndId?.id
          ? pId === addressTypeAndId?.id
          : projectName === selectedJobsite
      ) || {}
    );
  }, [
    projects,
    selectedJobsite,
    addressTypeAndId,
    projectIdData,
    changeOrderOppId,
  ]);

  const {
    alternativeAddresses: opportunityAltAddresses = [],
    opportunityId,
    estimations: opportunityEstimations = [],
    proposedTypeOfWork: services,
    accountName: opportunityAccountName,
    googleDriveFolderIds: opportunityDriveId = {},
    projectExecutive: oppProjectExecutive,
    // projectManager: oppProjectManager,
    opportunityLatitude,
    opportunityLongitude,
    borough: opportunityBorough,
    opportunityStage: opportunityStage,
    proposalStartDate: opportunityStartDate,
    teamsConfiguration: opportunityTeamsConfiguration,
  } = useMemo(() => {
    return (
      opportunities?.find(
        ({ opportunityId: oppId, opportunityAddress: oppAdd }) =>
          opportunityData?.opportunityId
            ? oppId === opportunityData?.opportunityId
            : changeOrderOppId
            ? oppId === changeOrderOppId
            : addressTypeAndId?.type === "Opportunity" && !!addressTypeAndId?.id
            ? oppId === addressTypeAndId?.id
            : oppAdd === selectedJobsite
      ) || {}
    );
  }, [
    opportunities,
    selectedJobsite,
    addressTypeAndId,
    opportunityData,
    changeOrderOppId,
  ]);

  const { leadId, googleDriveFolderIds: leadDriveObj } =
    leads?.find(({ leadCompany }) => leadCompany === selectedCompanyName) || {};

  const { accountId, googleDriveFolderIds: clientDriveObj } =
    clients?.find(({ accountName }) => accountName === selectedCompanyName) ||
    {};

  const driveParentId =
    !!projectId && !changeOrderOppId && !opportunityData?.opportunityId
      ? projectDriveId?.estimationObject
      : opportunityDriveId?.estimationObject;

  //Grid API
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const bodyObj = () =>
    body({
      form,
      services,
      existingEstStatus,
      alternativeAddresses: !!projectId
        ? projectAltAddresses
        : opportunityAltAddresses,
      estimationLatitude: !!projectId ? projectLatitude : opportunityLatitude,
      estimationLongitude: !!projectId
        ? projectLongitude
        : opportunityLongitude,
      borough: !!projectId ? projectBorough : opportunityBorough,
      opportunityId: !!changeOrderOppId ? changeOrderOppId : opportunityId,
      projectId: !!projectIdData ? projectIdData : projectId,
      leadId,
      accountId,
    });

  const formattedPExecutiveNames = projectExecutives?.map(
    ({ firstName, lastName }) => [firstName, lastName].join(" ")
  );

  // const nameExtractor = ({ nameOfUser }) => nameOfUser;

  // const formattedEngineersNames = engineers?.map(nameExtractor);

  // const formattedArchitectsNames = architects?.map(nameExtractor);

  const onEngineerPopoverRejection = () => {
    form.resetFields(["engAssignedTo", "estimationsEngineerFilter"]);
    setSelectedEngineer();
    setEngineerPopoverVisible("");
    clearFilter({ gridApi, column: "engAssignedTo" });
  };

  const onArchPopoverRejection = () => {
    form.resetFields(["archAssignedTo", "estimationsArchitectFilter"]);
    setSelectedArch();
    setArchPopoverVisible("");
    clearFilter({ gridApi, column: "archAssignedTo" });
  };

  const filterGrid = ({ column, current }) =>
    !!gridApi && onFilter({ gridApi, column, current });

  const clearGridFilter = (column) =>
    !!gridApi && clearFilter({ gridApi, column });

  const onAssigneesToggleChange = (val) => setAreAssigneesRequired(!val);

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const openEstimationTeamModal = () => {
    setOpen(true);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("estimationTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const ClearOptions = () => {
    setOpen(false);
    // form.resetFields(["estimationTeam"]);
  };

  const checkAccess = useMemo(() => {
    function findAccess(titleName) {
      return userConfiguration?.routeConfig?.find(
        ({ title }) => title === titleName
      );
    }
    let clientAccess = findAccess("Accounts");
    let leadAccess = findAccess("Leads");
    let projectsAccess = findAccess("Projects");
    let oppAccess = findAccess("Opportunities");
    return {
      clientAccess,
      leadAccess,
      projectsAccess,
      oppAccess,
    };
  }, [userConfiguration]);

  const addressOptions = useMemo(() => {
    const dropdownLabel = (label) => {
      return (
        <div>
          {`${label} - `}
          <span style={{ color: "red" }}>No Access</span>
        </div>
      );
    };
    const convertedProjects = projects
      ?.filter((pr) => !!pr?.opportunityId)
      .map((el) => el?.opportunityId);
    const filteredOpportunities = opportunities
      ?.filter(
        (opp) =>
          !convertedProjects.includes(opp?.opportunityId) &&
          opp?.opportunityStatus?.toLowerCase() !== "canceled" &&
          opp?.isChangeOrder !== true
      )
      ?.map((el) => ({
        label: el?.opportunityAddress,
        value: el?.opportunityAddress,
        type: "Opportunity",
        key: el?.opportunityId,
      }));
    const projectsOptions = projects?.map((el) => ({
      label: el?.projectName,
      value: el?.projectName,
      type: "Project",
      key: el?.projectId,
    }));
    return [
      !!checkAccess.oppAccess
        ? {
            label: "Opportunities",
            options: filteredOpportunities,
          }
        : {
            label: dropdownLabel("Opportunities"),
            options: [],
          },
      !!checkAccess.projectsAccess
        ? {
            label: "Projects",
            options: projectsOptions,
          }
        : {
            label: dropdownLabel("Projects"),
            options: [],
          },
    ];
  }, [projects, opportunities, checkAccess]);
  //region FIELDS
  const inputFields = fieldsJSON({
    form,
    filterGrid,
    onSelect,
    onDeselect,
    clearGridFilter,
    setSelectedJobsite,
    setSelectedCompanyName,
    setSelectedPrjExecutive,
    setSelectedAccountManager,
    setSelectedEngineer,
    setSelectedArch,
    jobSiteAddress,
    coreFieldsDisabled,
    projectExecutives,
    formattedPExecutiveNames,
    areAssigneesRequired,
    setClientModal,
    engineerPopoverVisible: visible && engineerPopoverVisible,
    archPopoverVisible: visible && archPopoverVisible,
    EngineerPopoverContent: (
      <ArchEngPopoverContent
        {...{
          onPopoverRejection: onEngineerPopoverRejection,
          assignedPerson: selectedEngineer?.nameOfUser,
          setVisible: setEngineerPopoverVisible,
          existingJobsites: existingEngJobsites,
          taskType: "estimation",
        }}
      />
    ),
    ArchPopoverContent: (
      <ArchEngPopoverContent
        {...{
          onPopoverRejection: onArchPopoverRejection,
          assignedPerson: selectedArch?.nameOfUser,
          setVisible: setArchPopoverVisible,
          existingJobsites: existingArchJobsites,
          taskType: "estimation",
        }}
      />
    ),
    addressOptions,
    clients: clients?.map(({ accountName }) => accountName),
    leads: leads
      ?.filter(({ leadStatus }) => leadStatus !== "Converted")
      ?.map(({ leadCompany }) => leadCompany),
    statusByArch: statusByArch?.map(({ statusName }) => statusName),
    statusByEng: statusByEng?.map(({ statusName }) => statusName),
    architects: architects.map(
      ({ architectId, nameOfUser, userName, userLink = "" }) => ({
        label: nameOfUser,
        value: nameOfUser,
        architectId,
        nameOfUser,
        userLink,
        userName,
      })
    ),
    engineers: engineers.map(
      ({ engineerId, nameOfUser, userName, userLink = "" }) => ({
        label: nameOfUser,
        value: nameOfUser,
        engineerId,
        nameOfUser,
        userLink,
        userName,
      })
    ),
    estimationTeam: estimationTeam?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    accountManagers: selectedPrjExecutive
      ? projectExecutives?.find(
          ({ firstName, lastName }) =>
            `${firstName} ${lastName}` === selectedPrjExecutive
        )?.accountManager
      : Array.from(
          new Set(
            projectExecutives?.reduce(
              (prev, { accountManager }) =>
                Array.isArray(accountManager)
                  ? [...prev, ...accountManager]
                  : [...prev, accountManager],
              []
            )
          )
        ),
    projectManagersOptions:
      programFields
        ?.find((prog) => prog.fieldName === "Project Managers")
        ?.fieldOptions?.map((manager) => ({
          ...manager,
          label: manager.nameOfUser,
          value: manager.identityId,
          cognitoUserId: manager.cognitoUserId,
        })) || [],
    priorityTypes: priorityTypes?.map(({ statusName }) => statusName),
    setAddressTypeAndId,
    checkAccess,
    setNonUserModal,
  });

  const populatedGridFilters = useMemo(
    () =>
      gridFilters({
        projectExecutives: formattedPExecutiveNames,
        engineers,
        architects,
      }),
    [formattedPExecutiveNames, engineers, architects]
  );

  const resetForm = () => {
    form.resetFields();
    setEngineerPopoverVisible(false);
    setSelectedJobsite("");
    setSelectedCompanyName("");
    driveFunctions?.reset();
  };

  function findOpportunityParentFolderId() {
    if (!!accountId) return clientDriveObj?.opportunitiesObject;
    else return leadDriveObj?.opportunitiesObject;
  }

  async function createFolder(type) {
    //? rare cases when opportunity folder is empty
    if (type === "Opportunity") {
      if (Object.keys(opportunityDriveId).length === 0) {
        const { folders: oppFolders } = await oppDriveFunctions.create({
          parentFolderName: `${selectedJobsite} ${dayjs
            .tz()
            .format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: findOpportunityParentFolderId(),
        });

        await API.put(
          apiRoutes.opportunities,
          `/${apiRoutes.opportunities}/${opportunityId}`,
          {
            body: {
              googleDriveFolderIds: oppFolders,
            },
          }
        );
        const { folders: estFolders } = await driveFunctions.create({
          parentFolderName: `${selectedJobsite} ${dayjs().format(
            "DD/MM/YYYY HH:mm:ss"
          )}`,
          parentId: oppFolders?.estimationObject,
        });
        return { estFolders };
      } else {
        message.warning(
          "Cannot find Estimation Folder in Opportunity's Google Drive Folder"
        );
        return { estFolders: {} };
      }
    }
  }
  //#region SAVE EST
  const saveEstimation = async (action) => {
    let res;
    showLoadingMsg();
    setSaving(true);
    let allFolders = null;
    if (!estimationId) {
      try {
        if (
          !!opportunityId &&
          !driveParentId &&
          addressTypeAndId?.type === "Opportunity"
        ) {
          const { estFolders } = await createFolder(addressTypeAndId?.type);
          allFolders = estFolders;
        } else {
          const { folders } = await driveFunctions.create({
            parentFolderName: `${selectedJobsite} ${dayjs().format(
              "DD/MM/YYYY HH:mm:ss"
            )}`,
            parentId: driveParentId,
          });
          // .catch((err) => console.log("Error Creating Folders: ", err));
          allFolders = folders;
        }

        // await moveRequestFiles({
        //   requestId,
        // });
      } catch (error) {
        console.log("Error Creating Folders: ", error);
        allFolders = null;
      }

      const engineerUserLink = userConfiguration?.allUsers?.Items?.filter(
        (user) => user?.cognitoUserId === selectedEngineer?.userLink
      )?.map(({ nameOfUser, identityId, cognitoUserId }) => ({
        nameOfUser,
        identityId,
        cognitoUserId,
      }));

      const architectUserLink = userConfiguration?.allUsers?.Items?.filter(
        (user) => user?.cognitoUserId === selectedArch?.userLink
      )?.map(({ nameOfUser, identityId, cognitoUserId }) => ({
        nameOfUser,
        identityId,
        cognitoUserId,
      }));

      const otherTeamUsers = [
        ...(form
          .getFieldValue("projectManager")
          ?.map(({ label, value, cognitoUserId = "" }) => ({
            nameOfUser: label,
            identityId: value,
            cognitoUserId: cognitoUserId,
          })) || []),
        // selectedEngineer,
        // selectedArch,
      ]
        .concat(engineerUserLink)
        .concat(architectUserLink);

      const teamsConfiguration = updateTeamsConfiguration(
        userConfiguration,
        selectedTeam,
        otherTeamUsers,
        true
      );
      setSelectedTeam([]);

      setVisibleCreationProgress({ action });
      updateProgressStatus({ updatingRecord: "executing" });
      await API.post(apiRoutes.estimations, `/${apiRoutes.estimations}`, {
        body: {
          ...bodyObj(),
          teamsConfiguration: teamsConfiguration,
          fromRequest: requestId,
          isChangeOrder: isChangeOrderProp,
          googleDriveFolderIds: allFolders || {},
        },
      })
        .then(async (r) => {
          setVisibleCreationProgress({ ...r, action });
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });
          saveAddedLogs({
            recordId: r?.estimationId,
            recordName: r?.jobSiteAddress,
            category: "Estimations",
            topic: r?.isChangeOrder ? "Change Order" : "",
          });

          //we update the estimations array in the found opportunity
          if (!!changeOrderOppId) {
            let chgOpEst = [];
            if (!!opportunityData?.estimations)
              chgOpEst = opportunityData?.estimations;
            //we can use the same array for the concat, even when the estimation is created
            //from a project's page or an opportunity's page
            //in the project's case the array is initialized to an empty array which is correct
            await API.put(
              "opportunities",
              `/opportunities/${changeOrderOppId}`,
              {
                body: { estimations: chgOpEst.concat(r?.estimationId) },
              }
            ).catch((err) => console.log("Error Updating Opportunity: ", err));

            if (!!changeOrderOppId && !!requestId) {
              delete requestObject?.googleDriveFolderIds;

              await afterSaveRequest({
                newRecordId: r?.estimationId,
                // requestId,
                path: "estimations",
                // cognitoUserId: userConfiguration?.cognitoUserId,
                moveFilesParams: {
                  filesToMove: requestFiles,
                  newParentId: allFolders?.estimationObject,
                  accessToken,
                },
              });
            }
          } else if (!!opportunityId) {
            await API.put("opportunities", `/opportunities/${opportunityId}`, {
              body: {
                estimations: opportunityEstimations.concat(r?.estimationId),
              },
            }).catch((err) => console.log("Error Updating Opportunity: ", err));
          }

          broadcastNotification(
            "3",
            "onCreation",
            [
              { common: user },
              {
                userName: user,
                currentUser: authenticatedUser?.sub,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  teamsConfiguration
                ),
              },
            ],
            r.estimationId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          res = r;
          showSuccessMsg();
          resetForm();
          setSaving(false);
          estimationWS.send(
            JSON.stringify({
              request: "estimationEdited",
              nodeEnv: process.env.NODE_ENV,
              body: { ...r },
            })
          );
          setTimeout(() => refreshTable([r]), 0);

          if (!!requestId && !changeOrderOppId) {
            await afterSaveRequest({
              newRecordId: r?.estimationId,
              // requestId,
              path: "estimations",
              // cognitoUserId: userConfiguration?.cognitoUserId,
              moveFilesParams: {
                filesToMove: requestFiles,
                newParentId: allFolders?.estimationObject,
                accessToken,
              },
            });

            // requestFormWs.send(
            //   JSON.stringify({
            //     request: "request-response",
            //     body: { requestId, responseFrom: "approved" },
            //   })
            // );
          }
        })
        .catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          showErrorMsg();
          console.error(e);
        });
    } else {
      const putBody = bodyObj();

      let tmpFolders = googleDriveFolderIds || [];
      try {
        if (!googleDriveFolderIds) {
          if (
            !!opportunityId &&
            !driveParentId &&
            addressTypeAndId?.type === "Opportunity"
          ) {
            const { estFolders } = await createFolder(addressTypeAndId?.type);
            tmpFolders = estFolders;
          } else {
            const { folders } = await driveFunctions.create({
              parentFolderName: `${selectedJobsite} ${dayjs().format(
                "DD/MM/YYYY HH:mm:ss"
              )}`,
              parentId: driveParentId,
            });

            tmpFolders = folders;
          }
        }
      } catch (err) {
        console.log("Error Creating Folders: ", err);
      }
      await API.put(
        apiRoutes.estimations,
        `/${apiRoutes.estimations}/${estimationId}`,
        {
          body: {
            ...putBody,
            googleDriveFolderIds: tmpFolders,
          },
        }
      )
        .then(() => {
          res = estimationId;
          showSuccessMsg();
          resetForm();
          setTimeout(() => refreshTable(), 0);
          setSaving(false);
        })
        .catch((e) => {
          showErrorMsg();
        });
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
    return res;
  };

  const onCancel = () => {
    resetForm();
    setVisible(false);
    setCancelModalVisible(false);
    !!estimationId && setTimeout(() => refreshTable(), 0);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const validateEstForm = (onSuccessFunc) => {
    const warningMsg = (role) =>
      `Please decide if you want to assign this estimation to the selected ${role}`;

    !engineerPopoverVisible
      ? !archPopoverVisible
        ? validateForm(form, onSuccessFunc)
        : message.warning(warningMsg("architect"))
      : message.warning(warningMsg("engineer"));
  };
  const onOptionClick = ({ key }) => {
    compareIncluding(key, "Save & Close")
      ? validateEstForm(() => saveEstimation("onFinish"))
      : validateEstForm(saveEstimation);
  };

  const filterGridByPerson = (column, person) => {
    !!person &&
      person !== originalEst?.[column] &&
      filterGrid({ column, current: person });
  };

  const isWorkingOnOtherEst = ({
    key,
    person,
    setPopoverVisible,
    setExistingJobsites,
  }) => {
    if (!!person) {
      const existingEstimations = estimations?.filter(
        ({ [key]: objectKey, estSTATUS }) =>
          compareIncluding(objectKey, person) &&
          !compareIncluding(estSTATUS, "finished") &&
          !compareIncluding(estSTATUS, "approved")
      );
      if (!!existingEstimations?.length && person !== originalEst[key]) {
        setExistingJobsites(existingEstimations);
        setPopoverVisible(true);
      }
    }
  };

  useEffect(() => {
    fetchAllData({
      endpoint: "estimations",
      resultId: "estimationId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "estimationId",
          "archAssignedTo",
          "engAssignedTo",
          "estSTATUS",
          "projectExecutive",
          "jobSiteAddress",
          "accountName",
        ]),
      },
    })
      .then((res) => {
        setEstimations(res);
      })
      .catch((err) => {
        console.log("Error Fetching Estimations: ", err);
      })
      .finally(() => {
        setGridLoading(false);
      });

    API.get("teams", "/teams")
      .then((teamsRes) => setEstimationTeam(teamsRes))
      .catch((err) => console.log("Error fetching Data", err));
    fetchAllData({ endpoint: "opportunities", resultId: "opportunityId" })
      .then((opportunitiesRes) => {
        setOpportunities(opportunitiesRes);
      })
      .catch((err) => console.log("Error fetching Data", err));
    fetchData("leads")
      .then((leadsRes) => setLeads(leadsRes))
      .catch((err) => console.log("Error fetching Data", err));
    fetchAllData({ endpoint: "projects", resultId: "projectId" })
      .then((projectsRes) => setProjects(projectsRes))
      .catch((err) => console.log("Error fetching Data", err));
    filterTables("accounts", "accountRecordType", "Client")
      .then((clientsRes) => setClients(clientsRes))
      .catch((err) => console.log("Error fetching Data", err));
    API.get("projectExecutives", "/projectExecutives")
      .then((projectExecutivesRes) =>
        setProjectExecutives(projectExecutivesRes)
      )
      .catch((err) => console.log("Error fetching Data", err));

    if (engineers?.length === 0 && architects?.length === 0) {
      Promise.allSettled([
        API.get("engineers", "/engineers"),
        API.get("architects", "/architects"),
      ])
        .then(([{ value: engineers }, { value: architects }]) => {
          setEngineers(sortBy(engineers, "nameOfUser"));
          setArchitects(sortBy(architects, "nameOfUser"));
        })
        ?.catch((err) =>
          console.log("Error getting engineers and architects", err)
        );
    }
  }, []);

  useEffect(() => {
    const selectedComp = projectAccountName || opportunityAccountName;
    let addType =
      !!projectAccountName && isChangeOrderProp === false
        ? "Project"
        : "Opportunity";
    const projectExecutive =
      !isChangeOrderProp && !!opportunityId
        ? oppProjectExecutive
        : prjExecutive;
    const projectManager = prjManager;
    if (!!selectedComp) {
      form.setFieldsValue({
        accountName: selectedComp,
        projectExecutive,
        ...(projectManager && {
          projectManager:
            projectManager?.map((manager) =>
              typeof manager === "string"
                ? manager
                : {
                    ...manager,
                    label: manager.nameOfUser,
                    value: manager.nameOfUser,
                  }
            ) || [],
        }),
        accountManager: selectedPrjExecutive
          ? projectExecutives?.find(
              ({ firstName, lastName }) =>
                `${firstName} ${lastName}` === selectedPrjExecutive
            )?.accountManager[0]
          : Array.from(
              new Set(
                projectExecutives?.reduce(
                  (prev, { accountManager }) => [...prev, accountManager],
                  []
                )
              )
            )[0],
      });
      setSelectedCompanyName(selectedComp);
      setSelectedPrjExecutive(projectExecutive);
      if (!addressTypeAndId) {
        setAddressTypeAndId({ type: addType, id: "" });
      }
    }
  }, [
    projectAccountName,
    opportunityAccountName,
    projectExecutives,
    selectedPrjExecutive,
  ]);

  useEffect(() => {
    if (!!jobSiteAddress) {
      const { dueDate: formDueDate } = form.getFieldsValue();

      form.setFieldsValue(
        !!estimationId
          ? {
              ...estimation,
              dueDate: !!dueDate ? dayjs(dueDate) : formDueDate,
              anticipStartDate: dayjs(anticipStartDate),
              anticipEndDate: dayjs(anticipEndDate),
            }
          : {
              ...estimation,
              dueDate: !!dueDate ? dayjs(dueDate) : formDueDate,
            }
      );
      if (!!opportunityStartDate || !!projectStartDate) {
        const oppST = !!opportunityStartDate ? dayjs(opportunityStartDate) : "";
        const prjST = !!projectStartDate ? dayjs(projectStartDate) : "";
        form.setFieldsValue({
          anticipStartDate: isChangeOrderProp ? oppST : prjST,
        });
      }
      if (!!estimationId) {
        setSelectedEngineer({ nameOfUser: engAssignedTo });
        setSelectedArch({ nameOfUser: archAssignedTo });
        setSelectedPrjExecutive(projectExecutive);
        if (!!gridData.length) {
          filterGridByPerson("archAssignedTo", archAssignedTo);
          filterGridByPerson("engAssignedTo", engAssignedTo);
          filterGridByPerson("projectExecutive", projectExecutive);
        }
      }
      setCoreFieldsDisabled(!!estimationId);
      setSelectedCompanyName(accountName);
      setSelectedJobsite(jobSiteAddress);
    }
  }, [
    JSON.stringify(estimation),
    originalEst,
    jobSiteAddress,
    !!gridData.length,
  ]);
  useEffect(() => {
    isWorkingOnOtherEst({
      key: "archAssignedTo",
      person: selectedArch?.nameOfUser,
      setPopoverVisible: setArchPopoverVisible,
      setExistingJobsites: setExistingArchJobsites,
    });
  }, [selectedArch, estimations?.length]);

  useEffect(() => {
    isWorkingOnOtherEst({
      key: "engAssignedTo",
      person: selectedEngineer?.nameOfUser,
      setPopoverVisible: setEngineerPopoverVisible,
      setExistingJobsites: setExistingEngJobsites,
    });
  }, [selectedEngineer, estimations?.length]);

  useEffect(() => {
    setInitialValues(form.getFieldsValue());
  }, []);

  useEffect(() => {
    if (!!projectTeamsConfiguration || !!opportunityTeamsConfiguration) {
      function getTeamId(teamName) {
        let teamId = estimationTeam?.find(
          (e) => e?.teamName === teamName
        )?.teamId;
        return !!teamId ? [teamId] : [];
      }
      form.setFieldValue(
        "estimationTeam",
        !!projectTeamsConfiguration
          ? projectTeamsConfiguration?.map((el) => el?.value)
          : !!opportunityTeamsConfiguration
          ? opportunityTeamsConfiguration?.map((el) => el?.value)
          : "Team"
      );

      let newEstTeam = !!projectTeamsConfiguration
        ? projectTeamsConfiguration?.map((el) => ({
            ...el,
            teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
          }))
        : !!opportunityTeamsConfiguration
        ? opportunityTeamsConfiguration?.map((el) => ({
            ...el,
            teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
          }))
        : [];

      setSelectedTeam([...newEstTeam]);
    }
  }, [projectTeamsConfiguration, opportunityTeamsConfiguration]);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "estimationModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Estimation Information": document.querySelector(
        ".estimationInformation"
      ),
      "Job Site Address": document.querySelector(".estimationJobSiteAddress"),
      Assign: document.querySelector(".assigneesToggleSwitch"),
      "Filter Estimation Table": document.querySelector(".filtersCard"),
      "Clear Filters": document.querySelector(".clearFiltersBtn"),
      "Estimation Table": document.querySelector(".estimationTable"),
      "Save Estimation": document.querySelector(".saveButton"),
    };
    return stepsMap?.[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);
  //region JSX
  return (
    <>
      <NormalSizedModal
        visible={visible}
        title={
          <CustomModalHeader
            title={`${!!estimationId ? "Edit" : "New"} Estimation`}
            onClick={() => {
              setTourOpen(true);
            }}
            noX={true}
          />
        }
        className={`estimationModalContainer ${
          isDarkMode && "estimationModalContainerDark"
        }`}
        onCancel={() => onCancel()}
        destroyOnClose
        newFooterDesign={footerButtons({
          saving,
          onCancel: () => {
            if (formChanged) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          },
          // !!hasValuesChanged(initialValues, form.getFieldsValue())
          //   ? setVisible(false)
          //   : setCancelModalVisible(true),
          onSave: () =>
            validateEstForm(() => saveEstimation("onSaveAndContinue")),
          onOptionClick,
          suffix: <DownCaretWhite />,
        })}
        centered
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="modalBodyContainer">
            <BorderedTextCard
              className="estimationInformation"
              title={ESTIMATION_INFORMATION}
            >
              <div className="inputFieldContainer">
                {RenderDynamicComponents(inputFields)}
                <div className="estimationAvatarsDiv">
                  {!!selectedTeam?.length && (
                    <MondayButton
                      {...{ Icon: <PlusIcon /> }}
                      className="membersButton"
                      onClick={openEstimationTeamModal}
                    >
                      Members
                    </MondayButton>
                  )}
                </div>
                <div className="estimationAvatars">
                  {!!selectedTeam?.length && (
                    <DynamicAvatars
                      {...{
                        title: "New Estimation Team",
                        isDarkMode: isDarkMode,
                        members: selectedTeam?.reduce(
                          (acc, el) => [...acc, ...(el?.members || [])],
                          []
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
              <Form.Item name="assigneesToggle">
                <AssigneesToggle
                  className="assigneesToggleSwitch"
                  onChange={onAssigneesToggleChange}
                  isDarkMode={isDarkMode}
                />
              </Form.Item>
            </BorderedTextCard>
            <FormGrid
              {...{
                gridApi,
                form,
                onGridReady,
                gridSidebar,
                showFilters: true,
                gridColumns: dataGridColumns,
                gridData: estimations?.filter(
                  ({ estSTATUS: dataStatus }) =>
                    !compareIncluding(dataStatus, "approved") &&
                    !compareIncluding(dataStatus, "finished")
                ),
                gridFilters: populatedGridFilters,
                rowHeight: 40,
                gridLoading,
                gridClassName: "estimationTable",
                isDarkMode,
              }}
            />
          </div>
        </Form>
      </NormalSizedModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <div>
        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              ClearOptions,
              setSelectedTeam,
              proppedAllTeams: estimationTeam,
              onSave: (team) => {
                form.setFieldValue("estimationTeam", team || []);
              },
            }}
          />
        )}
      </div>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Estimation Tutorial",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Estimations",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              const { action, estimationId } = visibleCreationProgress;
              if (!!action) {
                setVisible(false);
                action === "onSaveAndContinue" &&
                  opportunityId &&
                  !!!isNextStep &&
                  navigate(`/estimations/${estimationId}`);
              }
            },
          }}
        />
      )}
      {clientModal && (
        <ClientModal
          visible={clientModal}
          setVisible={setClientModal}
          refreshTable={(res) => setClients((prev) => res.concat(prev))}
          onCancel={() => setClientModal(false)}
        />
      )}
      {!!nonUserModal && (
        <NewNonUserModal
          open={!!nonUserModal}
          setVisible={setNonUserModal}
          rowData={nonUserModal === "architects" ? architects : engineers}
          editMode={false}
          role={nonUserModal}
          updateDataOnSave={({ data }) => {
            if (nonUserModal === "architects") {
              setArchitects((prev) => [data, ...prev]);
            } else {
              setEngineers((prev) => [data, ...prev]);
            }
          }}
        />
      )}
    </>
  );
};

export default EstimationModal;
