import { Menu } from "antd";
import React from "react";
import SidebarItems from "./SidebarItems";

function SidebarMenu({ collapsed, selectedCategory, handleMenuSelect }) {
  function getLabelAndSize(key, label, itemLength) {
    if (collapsed) return null;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="menu-item-label">{label}</p>
        <div style={{ fontWeight: 600 }}>{itemLength || ""}</div>
      </div>
    );
  }

  return (
    <Menu
      selectedKeys={selectedCategory?.id}
      mode="inline"
      className="communication-sidebar-menu"
      data-testid="sidebar-menu"
      items={SidebarItems().map((item) => {
        const content = getLabelAndSize(item.key, item.label, item.length);

        return {
          key: item.key,
          label: content,
          icon: item.icon,
          onClick: () => handleMenuSelect(item),
          "data-testid": `sidebar-menu-item-${item.label}`,
        };
      })}
    />
  );
}

export default SidebarMenu;
