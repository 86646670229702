import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { v4 as uuidv4 } from "uuid";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { agGridPropsDefault } from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

function EstimationStep({ stepperData, onSelectEstimation }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const [gridApi, setGridApi] = useState(null);

  const rowData = useMemo(() => {
    return stepperData?.applicableEstimations?.map((el) => {
      return {
        title: `Estimation #${el?.estimationNumber || 0}`,
        status: el?.estSTATUS || "N/A",
        createdAt: el?.createdAt,
        createdBy: el?.createdBy || "N/A",
        key: el?.estimationId,
        id: el?.estimationId,
        isChangeOrder: el?.isChangeOrder,
        value: el?.estimationId,
        versionServices: el?.versionServices,
        estimationTakeOff: el,
      };
    });
  }, [stepperData?.applicableEstimations]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows();
    onSelectEstimation(selectedRows?.[0]);
  }, [gridApi]);

  function setPreviousSelectedNodes() {
    const selected = stepperData?.selectedEstimation;
    if (!!selected) {
      gridApi?.forEachNode((node) => {
        if (selected === node.data?.id) {
          node?.setSelected(true);
        }
      });
    }
  }

  useEffect(() => {
    if (gridApi && rowData?.length > 0 && stepperData?.selectedEstimation) {
      setPreviousSelectedNodes();
    }
  }, [gridApi, rowData, stepperData?.selectedEstimation]);

  const getRowId = useCallback((param) => {
    if (param?.data?.id) {
      return param?.data?.id;
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        height: "100%",
        width: "100%",
      }}
    >
      <InputComponent
        {...{
          onChange: (e) => {
            gridApi.setQuickFilter(e.target.value);
          },
          prefix: <SearchOutlined style={{ color: "#323338" }} />,
          placeholder: "Search here...",
          className: "search-input",
          inputAllowClear: true,
          style: { maxWidth: "300px", height: "32px" },
          noFormItem: true,
        }}
      />
      <div
        className={`proposal-project-step ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          {...{
            ...agGridPropsDefault,
            rowData,
            columnDefs: estColumnDefs,
            onSelectionChanged,
            defaultColDef: {
              enableColResize: false,
              resizable: true,
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              minWidth: 100,
              filter: true,
            },
            rowSelection: {
              mode: "singleRow",
            },
            getRowId: getRowId,
            pagination: true,
            paginationPageSize: 10,
            headerHeight: 45,
            rowHeight: 40,
            paginationAutoPageSize: true,
            onGridReady: ({ api }) => {
              setGridApi(api);
            },
          }}
        />
      </div>
    </div>
  );
}

export default EstimationStep;

const estColumnDefs = [
  {
    headerName: "Estimation #",
    field: "title",
    sortable: true,
    chartDataType: "series",
    filter: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Status",
    field: "status",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Created By",
    field: "createdBy",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Created On",
    field: "createdAt",
    sortable: true,
    sort: "desc",
    filter: true,
    valueFormatter: (params) => {
      return dayjsNY(params.value).format("MM/DD/YYYY HH:mm");
    },
  },
];

// c4685690-742c-11ef-a5b0-b92d1ba53438
// ba020c80-9530-11ef-a323-f14dd045375a
// 6c579050-a768-11ef-84c2-ef2acfa36856
// 49075f00-7445-11ef-a4e5-5d560bca9815
// 33d6cf10-a68d-11ef-9565-471a0dfbf74c
// 24bcb350-9530-11ef-a323-f14dd045375a
