import { cloneDeep, isEmpty } from "lodash";
import { camelCaseToNormalText } from "../../../../../../../../commonComponents/RowDataGridModal/components/utils";
import { fetchPdfImages } from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers/initialExportData";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";

async function getInitialTaskPdfData(updatedTask, accessToken) {
  let taskObject = cloneDeep(updatedTask);

  const keysToInclude = [
    "taskSubTopic",
    "taskTopic",
    "createdAt",
    "createdBy",
    "remainingDays",
    "taskDeadline",
    "taskDescription",
    "taskEndDate",
    "taskLabels",
    "taskRelatedTo",
    "taskStartDate",
    "taskPriority",
    "taskStatus",
  ];

  let taskInformation = [];
  if (!isEmpty(taskObject)) {
    const newRowData = { ...taskObject };
    for (const key in newRowData) {
      if (!keysToInclude.includes(key)) {
        delete newRowData[key];
      }
    }
    taskInformation = Object.keys(newRowData)?.map((key) => {
      if (
        key === "taskDeadline" ||
        key === "taskEndDate" ||
        key === "taskStartDate"
      ) {
        return {
          key: camelCaseToNormalText(key),
          value: !!newRowData?.[key]
            ? dayjsNY(newRowData[key]).format("MM/DD/YYYY hh:mm A")
            : "",
        };
      } else if (key === "createdAt") {
        return {
          key: camelCaseToNormalText(key),
          value: !!newRowData?.[key]
            ? dayjsNY(newRowData[key]).format("MM/DD/YYYY")
            : "",
        };
      } else if (key === "taskLabels") {
        return {
          key: camelCaseToNormalText(key),
          value: newRowData?.[key].join(", ") || "",
        };
      }
      return {
        key: camelCaseToNormalText(key),
        value: newRowData?.[key] || "",
      };
    });
  }

  const taskDependency = Object.keys(taskObject?.taskDependency || {})?.some(
    (el) => (taskObject?.taskDependency?.[el] || [])?.length > 0
  )
    ? taskObject?.taskDependency
    : {};

  const content = [
    {
      title: "Task Information",
      body: taskInformation,
    },
    (taskObject?.taskAssignedTo || [])?.length > 0 && {
      title: "Task Assigned To",
      body: (taskObject?.taskAssignedTo || []).map(
        ({ nameOfUser = "", userName = "" }) => ({
          key: nameOfUser,
          value: userName,
        })
      ),
    },
    (taskObject?.taskSubTasks || [])?.length > 0 && {
      title: "Task Sub Tasks",
      body:
        taskObject?.taskSubTasks?.map(({ value = "", status = "" }) => ({
          key: "Title: " + value,
          value: "Status: " + status,
        })) || [],
    },
    Object.keys(taskDependency || {})?.length > 0 && {
      title: "Task Dependency",
      body: Object.keys(taskDependency).map((el) => ({
        title: camelCaseToNormalText(el),
        body: taskDependency?.[el]?.map(({ title = "", status = "" }) => ({
          key: title,
          value: "Status: " + status,
        })),
      })),
    },
    (taskObject?.taskThread || []).length > 0 && {
      title: "Comments",
      body: taskObject.taskThread,
    },
  ].filter(Boolean);

  const images =
    (await fetchPdfImages(
      { googleDriveUpload: updatedTask?.googleDriveUploads || [] },
      accessToken
    )) || [];

  return content.concat(images);
}

export default getInitialTaskPdfData;
