import Swal from "sweetalert2";
import dayjs from "dayjs";
import {
  approvalsDocDefinition,
  configPdfApprovalsData,
} from "../../helperData";
import { getBase64 } from "../../../../../SidebarPages/Incidents/Modals/utils/getBase64Pdf";
import axios from "axios";
import { compareIncluding } from "../../../../../SidebarPages/utils";
import { b64toBlob } from "../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import { devUrl, prodUrl } from "../../../../../../utils/mergePdfDocsUrl";
import htmlToPdfmake from "html-to-pdfmake";
import { generateRepliesContent } from "../../../../../commonComponents/Notes/utils/onGeneratePdf";

const fillColor = (rowIndex) => {
  return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
};

const pdfStyles = {
  header: {
    fontSize: 16,
    bold: true,
    margin: [0, 0, 0, 5],
  },
  subheader: {
    fontSize: 10,
    margin: [0, 0, 0, 5],
  },
  comment: {
    fontSize: 12,
    margin: [0, 0, 0, 5],
  },
  subSubheader: {
    fontSize: 12,
    bold: true,
    margin: [0, 0, 5, 5],
  },
};

export const fetchImageSrc = async ({ googleDriveUpload, driveRequest }) => {
  const driveUploads = googleDriveUpload?.filter(
    ({ type }) => type === "photo"
  );
  const allPromises = driveUploads?.map(({ id }) =>
    driveRequest?.getImageSrc(id)
  );

  const images = await Promise?.allSettled(allPromises).then((res) =>
    res?.map(({ value }) => value?.src)
  );
  return driveUploads?.map(({ url }, i) => {
    return { url: url, src: images[i] };
  });
};

export const pdfHelper = ({ title, body = [], noColor = false }) => {
  let images = [];
  if (body.length > 0) {
    images = body.map((image, i) => {
      return {
        columns: [
          {
            link: image.url,
            image: image.src,
            fit: [200, 200],
            width: "100%",
            alignment: "center",
          },
        ],
        margin: [0, 5, 0, 5],
      };
    });
  } else {
    images = [
      {
        columns: [
          {
            width: "50%",
            text: "No images",
            alignment: "left",
            style: ["lastColumn", "cellItem"],
          },
        ],
      },
    ];
  }
  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...images,
  ].filter((item) => !!item);
  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      //   headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
              columnGap: 10,
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const onGenerateCommentsPdf = (comments, category) => {
  const commentTables = comments.map((comment, index) => {
    const { commentContent, author, createdAt } = comment;
    const formattedDate = dayjs(createdAt).format("MM/DD/YYYY hh:mm A");
    const richTextContent = htmlToPdfmake(commentContent);
    const richTextRepliesContent = comment.replies.length
      ? generateRepliesContent(comment.replies, pdfStyles, 0)
      : [];

    const fillColor = index % 2 === 0 ? "#FFFFFF" : "#f2f2f2";

    return {
      table: {
        widths: ["*"],
        body: [
          [
            {
              stack: [
                { text: author, style: pdfStyles.header },
                {
                  columns: [
                    { text: category, style: pdfStyles.subheader, width: "*" },
                    {
                      text: formattedDate,
                      style: pdfStyles.subheader,
                      alignment: "right",
                      width: "*",
                    },
                  ],
                },
                {
                  svg: `<svg xmlns="http://www.w3.org/2000/svg" width="470" height="1" viewBox="0 0 470 1">
                    <line id="Line_419" data-name="Line 419" x2="470" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
                    </svg>`,
                  width: 470,
                  height: 2,
                },
                {
                  text: "Comment:",
                  style: pdfStyles.comment,
                  margin: [0, 10, 0, 10],
                },
                {
                  columns: richTextContent.map((cont) => ({
                    ...cont,
                    margin: [10, 5, 0, 10],
                  })),
                },
                { text: "Replies:", style: pdfStyles.comment },
                ...richTextRepliesContent,
              ],
            },
          ],
        ],
      },
      layout: {
        fillColor: fillColor,
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length ? "gray" : "green";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length ? "gray" : "green";
        },
        paddingLeft: function (i, node) {
          return 40;
        },
        paddingRight: function (i, node) {
          return 40;
        },
        paddingTop: function (i, node) {
          return 20;
        },
        paddingBottom: function (i, node) {
          return 20;
        },
      },
      margin: [0, 0, 0, 10],
    };
  });

  return commentTables;
};

export const generatePdf = async ({
  base64,
  originalRequestForm,
  driveRequest,
  requestType,
}) => {
  Swal.fire({
    title: "<strong>Generating PDF</strong>",
    icon: "info",
    html: "Please wait to generate PDF...!",
    didOpen: () => {
      Swal.showLoading();
    },
  });

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const images = await fetchImageSrc({
    googleDriveUpload: originalRequestForm?.requestObject?.gDriveUploads || [],
    driveRequest: driveRequest,
  });

  const Pdf = [
    configPdfApprovalsData({
      title: "Details",
      body: originalRequestForm,
    }),
    pdfHelper({
      title: "Images",
      body: images,
    }),
    onGenerateCommentsPdf(
      originalRequestForm.comments,
      originalRequestForm.category
    ),
  ];

  const createDocDef = approvalsDocDefinition(
    `${requestType} Request Form`,
    // "TITLE",
    Pdf,
    (companyLogo = companyLogo)
  );
  const pdfDocGenerator = pdfMake.createPdf(createDocDef);
  let fileList = [];
  const existedObject = await getBase64({
    pdfDocGenerator,
    title: "Claims",
    name: originalRequestForm?.requestObject?.recordName,
  });
  fileList.push(existedObject);

  await Promise.allSettled(
    originalRequestForm?.requestObject?.gDriveUploads?.map(async (element) => {
      let pdfCondition = element.mimeType?.includes("application/pdf");
      let blob;
      let size;
      let file;

      if (pdfCondition) {
        await driveRequest
          .getImageSrc(element?.id)
          .then((r) => r)
          .then((res) => (blob = res?.src));

        const fileSrc = new Promise((resolve, reject) => {
          resolve(blob);
        });

        file = {
          fileSrc: fileSrc,
          name: element?.name,
          originFileObj: {
            uid: element?.id,
          },
          percent: 0,
          size: size,
          status: "uploading",
          type: "local",
          uid: element?.id,
        };

        fileList.push(file);
      }
    })
  );

  await axios
    .post(process.env.NODE_ENV === "production" ? prodUrl : devUrl, {
      originalPdf: [
        ...(await Promise.all(fileList?.map((el) => el?.fileSrc))),
      ]?.filter((el) => typeof el === "string"),
      configuration: {
        fileList: fileList,
        pagesConfig: {},
      },
    })
    .then(async ({ data: mergedPdf }) => {
      Swal.close();

      var blob = b64toBlob(mergedPdf, "application/pdf");
      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL);
    })
    .catch((e) => {
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "There was an error while generating PDFs! ",
      });
    });
};
