import { useMemo, useState } from "react";
import {
  InfoText,
  MondayButtonIcon,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { DepartmentIcon } from "../../../../../../../../../assets";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { Modal } from "antd";
import { TickIcon } from "../../../../src";
import {
  MondayButton,
  RedWarningModal,
} from "../../../../../../../../commonComponents";
import "./NewAddDepartmentModal.scss";
import { useSelector } from "react-redux";

function NewAddDepartmentModal({
  open,
  onCancel,
  departmentsList = [],
  addMultipleRolesHandler = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [departmentName, setDepartmentName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [warningVisible, setWarningVisible] = useState(false);
  const [roleList, setRoleList] = useState([]);

  const dynamicText = useMemo(() => {
    if (open === "role") {
      return {
        title: "Create New Role",
        text: "Please select the department first and then add the new role.",
      };
    } else {
      return {
        title: "Create New Department",
        text: "Here you can create a new department and define the roles.",
      };
    }
  }, [open]);

  const rolesOfDepartment = useMemo(() => {
    if (open === "department") {
      return [];
    }
    if (departmentName) {
      const department = departmentsList.find(
        (el) => el.departmentName === departmentName
      );
      return department ? department.roles : [];
    }
    return [];
  }, [departmentName, departmentsList]);

  const validations = useMemo(() => {
    let obj = {
      addDisabled: false,
      duplicateRole: false,
      duplicateDepartment: false,
    };
    if (open === "department") {
      if (!departmentName || !roleName) {
        obj.addDisabled = true;
      }
      if (!roleName || roleList.includes(roleName.toLowerCase())) {
        obj.addDisabled = true;
      }
      if (
        departmentsList.some(
          (el) =>
            el.departmentName.toLowerCase() === departmentName.toLowerCase()
        )
      ) {
        obj.duplicateDepartment = true;
        obj.addDisabled = true;
      }
      if (roleList.length > 0 && roleList.some((role) => role === roleName)) {
        obj.duplicateRole = true;
      }
    } else {
      if (!roleName || roleList.includes(roleName.toLowerCase())) {
        obj.addDisabled = true;
      }
      if (
        rolesOfDepartment.length > 0 &&
        rolesOfDepartment.some(
          (r) => r.toLowerCase() === roleName.toLowerCase()
        )
      ) {
        obj.duplicateRole = true;
        obj.addDisabled = true;
      }
      if (
        roleList.length > 0 &&
        roleList.some((role) =>
          rolesOfDepartment.some((r) => r.toLowerCase() === role.toLowerCase())
        )
      ) {
        setRoleList((prev) =>
          prev.filter(
            (role) =>
              !rolesOfDepartment.some(
                (r) => r.toLowerCase() === role.toLowerCase()
              )
          )
        );
      }
    }
    return obj;
  }, [departmentName, roleName, rolesOfDepartment]);

  return (
    <Modal
      {...{
        title: dynamicText.title,
        open,
        onCancel: () => {
          if (roleList.length > 0) {
            setWarningVisible("cancel");
          } else {
            onCancel();
          }
        },
        centered: true,
        className: `newAddDepartmentModal ${
          isDarkMode ? "newAddDepartmentModalDark" : ""
        }`,
        destroyOnClose: true,
        closeIcon: <XIcon />,
        maskClosable: false,
        footer: (
          <div>
            <MondayButton
              {...{
                Icon: <XIcon />,
                onClick: () => {
                  if (roleList.length > 0) {
                    setWarningVisible("cancel");
                  } else onCancel();
                },
                className: "mondayButtonRed",
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                Icon: <TickIcon />,
                onClick: async () => {
                  await addMultipleRolesHandler(
                    roleList,
                    departmentName,
                    open === "department",
                    () => {
                      setRoleList([]);
                      setDepartmentName("");
                      setRoleName("");
                      onCancel();
                    }
                  );
                },
                className: "mondayButtonGreen",
                disabled: !departmentName || roleList?.length === 0,
              }}
            >
              Confirm
            </MondayButton>
          </div>
        ),
      }}
    >
      <InfoText
        {...{
          text: dynamicText.text,
        }}
      />
      <div className="dep-form-container">
        {open === "department" ? (
          <InputComponent
            {...{
              placeholder: "Department Name...",
              value: departmentName,
              onChange: (e) =>
                setDepartmentName(e.target.value ? e.target.value : ""),
              noFormItem: true,
            }}
          />
        ) : (
          <InputComponent
            {...{
              placeholder: "Department Name...",
              type: "select",
              className: "select-department",
              initialValue: departmentName,
              customOptions: departmentsList.map(({ departmentName }) => ({
                label: departmentName,
                value: departmentName,
              })),
              onChange: (e) => setDepartmentName(e),
            }}
          />
        )}

        <InputComponent
          {...{
            placeholder: "Role Name...",
            value: roleName,
            onChange: (e) => setRoleName(e.target.value ? e.target.value : ""),
            noFormItem: true,
            disabled: validations.duplicateDepartment,
          }}
        />
        <MondayButtonIcon
          {...{
            Icon: <TickIcon />,
            name: "Add Role",
            onClick: () => {
              setRoleList((prev) => [...prev, roleName]);
              setRoleName("");
            },
            disabled: validations.addDisabled,
          }}
        />
      </div>
      {open === "department" && validations.duplicateDepartment && (
        <div
          style={{ textAlign: "center", color: "#fe4c4a" }}
          className="error"
        >
          Department already exists!
        </div>
      )}
      {validations.duplicateRole && (
        <div
          style={{ textAlign: "center", color: "#fe4c4a" }}
          className="error"
        >
          This role already exists for this department!
        </div>
      )}
      {roleList.length > 0 && (
        <div data-testid="role-viewer-div" className="role-viewer">
          <div className="role-viewer-header">
            <DepartmentIcon /> {departmentName} Department
          </div>
          <div className="tags-container">
            {roleList.map((r, index) => {
              return (
                <div key={index} className="role-tag">
                  {r}
                  <XIcon
                    onClick={() => {
                      setRoleList((prev) => prev.filter((role) => role !== r));
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!warningVisible && (
        <RedWarningModal
          {...{
            visible: !!warningVisible,
            onCancel: () => setWarningVisible(false),
            children:
              warningVisible === "cancel"
                ? "Are you sure you want to cancel?"
                : "",
            footerProps: {
              onConfirm: async () => {
                if (warningVisible === "cancel") {
                  onCancel();
                }
              },
            },
          }}
        />
      )}
    </Modal>
  );
}

export default NewAddDepartmentModal;
