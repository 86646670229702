import { Tooltip } from "antd";
import React from "react";
import { ReplyIcon } from "../../../assets";

import "./reply.scss";

function Reply({ isEditable, setIsActive, isActive, label }) {
  return (
    <Tooltip title="Reply">
      <span
        className="replyIcon"
        style={isEditable ? {} : { cursor: "not-allowed" }}
        onClick={() => {
          if (isEditable && isActive === "") {
            setIsActive("reply");
          }
        }}
      >
        <ReplyIcon
          style={{ cursor: isActive === "reply" ? "not-allowed" : "" }}
        />
        {label && "Reply"}
      </span>
    </Tooltip>
  );
}

export default Reply;
