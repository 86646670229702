import { generateCoordinateImage } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dispatchPdfData";

async function vendorsReportFormatter(data) {
  return Promise.all(
    data.map(async (el) => {
      const { lat, lng } = el.vendorCoordinates;

      if (lat === null || lng === null) {
        return {
          ...el,
          image: {
            imageLink: undefined,
            googleMapLink: "",
          },
        };
      }

      const imageFromCoordinates = await generateCoordinateImage({
        coordinate: { lat, lng },
      });
      return {
        ...el,
        image: {
          imageLink: (imageFromCoordinates?.imageLink || "").split(",")[1],
          googleMapLink: imageFromCoordinates?.googleMapLink || "",
        },
      };
    })
  );
}

export default vendorsReportFormatter;
