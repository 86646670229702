import { Button, Checkbox, Layout, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDynamicViewContext } from "src/components/SidebarPages/DynamicView/utils/dynamicViewContext";
import useLogManagement from "src/hooks/useLogManagement";
import {
  markAsImportant,
  markAsNotImportant,
  markAsRead,
  markAsUnread,
  moveEmailTo,
  setSelectedCategory,
} from "../../../../../actions/communicationActions";
import { ImportantIconGolden, NotImportant, SearchIcon } from "../../assets";
import { callGmailAPI, getFilteredEmails } from "../../functions";
import EmailView from "../EmailView/EmailView";
import CommunicationContentHeader from "./communication-content-header/CommunicationContentHeader";
import CommunicationEmailView from "./conversation/CommunicationEmailView";
import { useQuery, useResponsive } from "../../../../../hooks";
import { showInfoMsg } from "../../../../../utils";

import "./communication-content.scss";
import { useCommunication } from "../../providers/CommunicationProvider";
import { useNavigate } from "react-router-dom";

const getCategoryKey = (category) => {
  if (category === "inbox") {
    return "1";
  } else if (category === "trash") {
    return "2";
  } else if (category === "important") {
    return "3";
  } else if (category === "sent") {
    return "4";
  } else return "5";
};

const CommunicationContent = ({
  openEmailBox,
  fetchData,
  searchValue,
  filterObject,
}) => {
  const dispatch = useDispatch();
  const {
    emails = [],
    drafts = [],
    recordData = {},
    loading = false,
    currentPage,
    selectedCategory,
  } = useSelector((state) => state.communication);
  const { authData } = useCommunication();
  const query = useQuery().get("threadId");
  const queryCategory = useQuery().get("category");
  const navigate = useNavigate();
  const selectedConversationViewId = query;

  const { isLeadEditable = true } = useDynamicViewContext();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [checkedList, setCheckedList] = useState([]);
  const [isEmailBoxOpen, setIsEmailBoxOpen] = useState(false);
  const { handleGenerateNewLog } = useLogManagement();
  const category = selectedCategory?.label?.toUpperCase();
  const { mobile, tablet, desktop } = useResponsive();
  const itemsPerPage = desktop ? 16 : 12;

  const filteredEmails = useMemo(() => {
    return getFilteredEmails(
      emails,
      drafts,
      category,
      searchValue,
      filterObject
    );
  }, [searchValue, filterObject, emails, category, drafts]);

  const totalItems = Math.ceil(filteredEmails.length / itemsPerPage);

  const triggerConversationView = (emailId) => {
    // setSelectedConversationViewlId(emailId);
    const threadId = new URLSearchParams({
      tab: "Communication",
      category: `${category.toLowerCase()}`,
      threadId: `${emailId}`,
    });

    navigate(`${window.location.pathname}?${threadId}`);

    setIsEmailBoxOpen((prev) => !prev);
  };

  const handleMarkAs = async (markAs, selectedEmails) => {
    const req = {
      emailIds: selectedEmails,
      moveTo: [],
      removeFrom: [],
      markAs: [markAs],
      authData,
    };

    const { data } = await callGmailAPI("changeLabel", req);

    if (data && data === "success") {
      selectedEmails.forEach((itemId) => {
        if (markAs === "IMPORTANT") {
          if (category === "DRAFT") {
            dispatch(markAsImportant(itemId, true));
            showInfoMsg({ content: "Draft marked as important." });
          } else {
            showInfoMsg({ content: "Email marked as important." });
            dispatch(markAsImportant(itemId, false));
          }

          handleGenerateNewLog(
            "Mark as important",
            "Mark as",
            "NOT IMPORTANT",
            "IMPORTANT",
            "Communication",
            recordData.recordId
          );
        } else if (markAs === "NOT IMPORTANT") {
          if (category === "DRAFT") {
            dispatch(markAsNotImportant(itemId, true));
            showInfoMsg({ content: "Draft marked as not important." });
          } else {
            showInfoMsg({ content: "Email marked as not important." });
            dispatch(markAsNotImportant(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as not important",
            "Mark as",
            "IMPORTANT",
            "NOT IMPORTANT",
            "Communication",
            recordData.recordId
          );
        } else if (markAs === "READ") {
          if (category === "DRAFT") {
            dispatch(markAsRead(itemId, true));
          } else {
            dispatch(markAsRead(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as read",
            "Mark as",
            "NOT READ",
            "READ",
            "Communication",
            recordData.recordId
          );
        } else if (markAs === "UNREAD") {
          if (category === "DRAFT") {
            dispatch(markAsUnread(itemId, true));
          } else {
            dispatch(markAsUnread(itemId, false));
          }
          handleGenerateNewLog(
            "Mark as unread",
            "Mark as",
            "READ",
            "UNREAD",
            "Communication",
            recordData.recordId
          );
        }
      });
    }
    setCheckedList([]);
  };

  const handleMoveTo = async (moveTo, removeFrom, selectedEmails) => {
    const req = {
      emailIds: selectedEmails,
      moveTo: [moveTo],
      removeFrom: [removeFrom],
      markAs: [],
      authData,
    };

    const { data } = await callGmailAPI("changeLabel", req);

    if (data && data === "success") {
      selectedEmails.forEach((emailId) => {
        if (moveTo === "TRASH" && removeFrom === "INBOX") {
          dispatch(moveEmailTo(emailId, removeFrom, moveTo));
          handleGenerateNewLog(
            "Move to trash",
            "Move to",
            "INBOX",
            "TRASH",
            "Communication",
            recordData.recordId
          );
        }
        if (moveTo === "INBOX" && removeFrom === "TRASH") {
          dispatch(moveEmailTo(emailId, removeFrom, moveTo));
          handleGenerateNewLog(
            "Move to inbox",
            "Move to",
            "TRASH",
            "INBOX",
            "Communication",
            recordData.recordId
          );
        }
      });
    }
    setCheckedList([]);
  };

  useEffect(() => {
    if (queryCategory) {
      const id = getCategoryKey(queryCategory);
      const label = queryCategory;
      dispatch(setSelectedCategory({ id, label }));
    }
  }, [queryCategory]);

  const renderItemsForPage = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredEmails.length);

    const emailsForPage = filteredEmails?.slice(startIndex, endIndex);

    return emailsForPage.map((e) => (
      <div
        key={e.id}
        className={`communication-view-container ${
          e?.labels.includes("UNREAD") ? "unread" : ""
        } 
        ${mobile ? "mobile" : "" || tablet ? "tablet" : ""}
        `}
      >
        <div className="communication-view-container-left">
          <Checkbox
            className="communication-content-checkbox"
            checked={checkedList?.includes(e)}
            onChange={() => {
              const newCheckedList = checkedList?.includes(e)
                ? checkedList?.filter((item) => item !== e)
                : [...checkedList, e];

              onChange(newCheckedList);
            }}
            data-testid="communication-content-checkbox"
          />

          <Button
            className="mark-important-btn"
            type="ghost"
            icon={
              e?.labels.includes("IMPORTANT") ? (
                <ImportantIconGolden />
              ) : (
                <NotImportant className="not-important-icon" />
              )
            }
            onClick={() => {
              if (e?.labels.includes("IMPORTANT")) {
                handleMarkAs("NOT IMPORTANT", [e?.id]);
              } else {
                handleMarkAs("IMPORTANT", [e?.id]);
              }
            }}
            data-testid="mark-important-btn"
          />
        </div>

        <CommunicationEmailView
          data={e}
          triggerEmailView={triggerConversationView}
          openEmailBox={openEmailBox}
        />
      </div>
    ));
  };

  const onChange = (list) => {
    setCheckedList(list);
  };

  return (
    <Layout
      className={`communication-content-layout ${
        isDarkMode ? "dark-mode" : ""
      }`}
      data-testid="communication-content-container"
    >
      <Layout.Content
        className={`communication-content-container ${
          isDarkMode ? "dark-mode" : ""
        }`}
      >
        {loading ? (
          <div className="loader" data-testid="loader">
            <Spin tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : selectedConversationViewId && category !== "DRAFT" ? (
          <EmailView
            {...{
              id: selectedConversationViewId,
              rowData: recordData,
              isEditable: isLeadEditable,
            }}
          />
        ) : (
          <>
            <CommunicationContentHeader
              filteredEmails={filteredEmails}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              category={category}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              // handlePageChange={handlePageChange}
              handleMoveTo={handleMoveTo}
              handleMarkAs={handleMarkAs}
              fetchData={fetchData}
            />

            {filteredEmails?.length > 0 ? (
              <div>{renderItemsForPage()}</div>
            ) : searchValue || filterObject ? (
              <div className="no-messages-matched">
                <SearchIcon className="searchIcon" />
                <p>No messages matched your search.</p>
              </div>
            ) : (
              <div className="no-messages-found">
                <p>
                  No messages found. Click refresh button to get the newest
                  messages or use the compose button to send some.
                </p>
              </div>
            )}
          </>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default CommunicationContent;
