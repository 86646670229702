import "./AvatarImage.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Image as AntdImage, Tooltip } from "antd";
import { driveApi } from "../../../integrations/DriveRequest";
import { getInitials } from "../../../utils";
import { updateUserImages } from "../../../actions";

const doesImageExist = (url) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
  });
};

/* 
  Please do not change this component with your props
  Component uses dispatch to save each of images to not fetch the same image 
*/
const AvatarImage = ({
  nameOfUser: nameOfUserProp,
  googleDriveFileId: googleDriveFileIdProp,
  cognitoUserId,
  size = 35,
}) => {
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state) => state.accessToken);
  const { userImages } = useSelector((state) => state.userImages);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const driveRequest = driveApi({ accessToken });

  const {
    googleDriveFileId = googleDriveFileIdProp,
    nameOfUser = nameOfUserProp,
  } =
    userConfiguration?.allUsers?.Items?.find((user) =>
      cognitoUserId
        ? user.cognitoUserId === cognitoUserId
        : user.nameOfUser === nameOfUserProp
    ) || {};

  const imgSrc = userImages?.[googleDriveFileId];

  useEffect(() => {
    if (!!googleDriveFileId && !!accessToken) {
      if (!imgSrc) {
        driveRequest.getImageSrc(googleDriveFileId).then(async (image) => {
          if (await doesImageExist(image.src)) {
            dispatch(updateUserImages({ [googleDriveFileId]: image.src }));
          }
        });
      }
    }
  }, [googleDriveFileId, accessToken, imgSrc]);

  return (
    <div
      className="avatar-image-container"
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <Tooltip title={nameOfUser}>
        {imgSrc ? (
          <AntdImage preview={false} src={imgSrc} alt="user-img" />
        ) : (
          <Avatar
            style={{
              backgroundColor: isDarkMode ? "#2a2b3a" : "#f8f8fa",
              color: isDarkMode ? "#fff" : "#323338",
            }}
          >
            <span style={{ fontWeight: 600 }}>{getInitials(nameOfUser)}</span>
          </Avatar>
        )}
      </Tooltip>
    </div>
  );
};

export default AvatarImage;
