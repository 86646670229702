import { LoginOutlined } from "@ant-design/icons";
import React from "react";
import { LogoutIcon } from "../../../../../../assets";
import { useResponsive } from "../../../../../../hooks";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { MondayButton } from "../../../../../commonComponents";
import { useCommunication } from "../../../providers/CommunicationProvider";

import "./user-auth-view.scss";

// The component is responsible for rendering the auth buttons
function UserAuthView() {
  const { onGoogleSignIn, onGoogleSignOut, userLoggedIn } = useGoogleSignIn();
  const { desktop } = useResponsive();
  const { changeSelectedEmail } = useCommunication();

  // handles login by calling onGoogleSignIn function is being provided by the useGoogleSignIn hook and changes the selected email always to the user which is being logged in.
  const handleLogin = () => {
    try {
      onGoogleSignIn();
      changeSelectedEmail("user");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`user-auth-view-container 
        ${!desktop ? "mobile" : ""}
      }`}
      data-testid="user-auth-view"
    >
      {/* Log in button is  rendered when user is not logged in */}
      {userLoggedIn ? (
        <MondayButton
          containerClassName="user-auth-view-container"
          className="button-styles"
          onClick={() => {
            onGoogleSignOut();
            changeSelectedEmail("company");
          }}
          Icon={<LogoutIcon className="logout-icon" />}
        >
          Log out
        </MondayButton>
      ) : (
        <MondayButton
          containerClassName="user-auth-view-container"
          className="button-styles"
          onClick={handleLogin}
          Icon={<LoginOutlined />}
        >
          Log in
        </MondayButton>
      )}
    </div>
  );
}

export default UserAuthView;
