import { WarningModal, MondayButton } from "../../../../commonComponents";
import { TickIcon, XIcon } from "../../../../InternalChat/assets/icons";

const WarningMessageModal = ({
  visible,
  setVisible,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <WarningModal
      visible={visible}
      setVisible={setVisible}
      title={title}
      closable={true}
    >
      {message}
      <div className="warningButtons">
        <MondayButton
          onClick={onCancel}
          Icon={<XIcon />}
          className="mondayButtonRed"
        >
          Go back
        </MondayButton>
        <MondayButton onClick={onConfirm} Icon={<TickIcon />}>
          Yes
        </MondayButton>
      </div>
    </WarningModal>
  );
};

export default WarningMessageModal;
