import { Modal } from "antd";
import React, { useState } from "react";
import { XIcon } from "../../../../../../assets/icons";

const ForwardMessages = (props) => {
  const activeUsers = props.userConfiguration.allUsers?.Items?.filter(
    (user) =>
      !user.isSuspended &&
      user.cognitoUserId !== props.userConfiguration.cognitoUserId
  );
  return (
    <div>
      <Modal
        open={Object.keys(props.forwardTo).length > 0}
        title={`Forward ${props.forwardTo.message} to...`}
        centered
        onCancel={props.onCancel}
        closeIcon={<XIcon />}
        onOk={props.onForwardMessage}
      >
        {activeUsers.map((user) => (
          <div>
            <input
              type="checkbox"
              value={user.cognitoUserId}
              onChange={(e) => {
                if (e.target.checked) {
                  props.setCheckedUsers([
                    ...props.checkedUsers,
                    user.cognitoUserId,
                  ]);
                } else {
                  props.setCheckedUsers(
                    props.checkedUsers.filter(
                      (checkedUser) => checkedUser !== user.cognitoUserId
                    )
                  );
                }
              }}
            />
            {user.nameOfUser}
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default ForwardMessages;
