/**
 * Fetches progress changes foremen files.
 *
 * @param {Object} options - The options for fetching progress changes foremen files.
 * @param {Function} options.list - The list function from driveApi.
 * @param {boolean} options.all - Indicates whether to filter files from all foremen or only one
 * @param {string} options.folderId - The folder ID.
 * @param {Object} options.foremanFiles - The foreman files object.
 * @param {Object} options.userConfiguration - The user configuration object.
 * @param {string} options.foremanId - cognitoUserId of the foreman.
 * @returns {Promise<Array>} - A promise that resolves to an array of modified files.
 */
export async function fetchProgressChangesForemenFiles({
  list = () => {},
  all = false,
  folderId = "",
  foremanFiles = {},
  userConfiguration,
  foremanId = "",
}) {
  if (!folderId) return [];
  if (all && !Object.keys(foremanFiles)?.length) return [];
  if (!all && !(foremanFiles?.[foremanId] || [])?.length) return [];
  return await getFilesByFolderId(folderId, list).then((res) =>
    res.json().then(({ files = [] }) => {
      if (!all) {
        const filesByForeman = files
          .filter((file) => {
            return (foremanFiles?.[foremanId] || [])?.includes(file?.id);
          })
          .map((file) => modifyFile(file, userConfiguration));
        return filesByForeman || [];
      } else {
        const allUsers = userConfiguration?.allUsers?.Items || [];
        const filesByAllForeman = Object.values(foremanFiles).flat();
        const filteredFiles = files?.filter((f) =>
          filesByAllForeman?.includes(f?.id)
        );
        if (!filteredFiles || filteredFiles?.length === 0) return [];

        return (
          filteredFiles?.map((file) => {
            const userIds = Object.keys(foremanFiles);
            const createdBy =
              userIds?.find((userId) =>
                foremanFiles?.[userId]?.includes(file?.id)
              ) || "";
            const user = allUsers.find(
              (user) => user.cognitoUserId === createdBy
            );
            return modifyFile(file, user);
          }) || []
        );
      }
    })
  );
}

const getFilesByFolderId = async (folderId, list) =>
  await list({
    q: `'${folderId}' in parents`,
    fields: "files(id, name, mimeType, modifiedTime, webContentLink)",
  });

function modifyFile(file, userConfiguration) {
  const { id, name, mimeType, modifiedTime, webContentLink } = file;
  return {
    id,
    name,
    mimeType,
    lastEditedUtc: modifiedTime,
    url: `https://drive.google.com/file/d/${file.id}/view?usp=sharing`,
    downloadUrl: webContentLink,
    type: getFileType(mimeType),
    uploadedBy: userConfiguration?.nameOfUser,
    uploadedAt: file.modifiedTime,
  };
}

function getFileType(mimeType) {
  if (mimeType.startsWith("image/")) {
    return "photo";
  } else if (mimeType.startsWith("video/")) {
    return "video";
  } else if (mimeType === "application/pdf") {
    return "pdf";
  } else {
    return "other";
  }
}
