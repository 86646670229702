import moment from "moment";
import React from "react";
import htmlParser from "../../../../../../utils/htmlParser";

import "./reply.scss";

const Reply = ({ fromUser, date, body, children, level = 0 }) => {
  const dateFormatted = moment(date).format("ddd, MMM D, YYYY");
  const timeFormatted = moment(date).format("h:mm A");

  return (
    <div className="reply-container">
      <div className="reply-date-container">{`On ${dateFormatted} at ${timeFormatted} <${fromUser}> wrote: `}</div>
      <div className="reply-body-container">
        {htmlParser(body.replace(/<br\s+data-mce-bogus="1"\s*\/?>/gi, ""))}
      </div>
      {children && children.length > 0 && (
        <div className="reply-container-chilren-wrapper ">
          {children.map((child, index) => (
            <Reply key={index} {...child} level={level + 1} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Reply;
