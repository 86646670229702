import {
  EntryType,
  ScheduleType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import { getJobDistance } from "./getJobDistance";
import {
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";

type PropTypes = {
  jobsites: Array<JobsiteType>;
  degEntries: Array<EntryType>;
  employeesReport: Array<EmployeeReportType & { defaultJob?: string }>;
  todaySchedules: Array<ScheduleType & { includedEmployeeIds: Array<string> }>;
};

export function getScheduledEmployees({
  jobsites,
  degEntries,
  todaySchedules,
  employeesReport,
}: PropTypes) {
  let jobsitesIncluded = [];
  let employeeMatchedReport = [];

  for (let r = 0; r < employeesReport.length; r++) {
    const empRep = employeesReport[r];
    let scheduleMatched = { matched: true, status: "Don't Match" };

    if (empRep?.liveStatus === "No Punch") {
      employeeMatchedReport.push(empRep);
      continue;
    }

    if (!empRep?.punchCoordinates?.lat || !empRep?.punchCoordinates?.lng) {
      employeeMatchedReport.push({ ...empRep, liveStatus: "Don't Match" });
      continue;
    }

    const employeeEntries = degEntries
      .filter((el) => el.employeeId === empRep?.employeeId)
      .sort((a, b) => a.punchTimeStamp - b.punchTimeStamp);
    const entry = employeeEntries?.[employeeEntries?.length - 1];
    if (entry?.jobsiteMatch?.jobsiteId) {
      const jobIndex = jobsites.findIndex(
        (job) => job.jobsiteId === entry?.jobsiteMatch?.jobsiteId
      );
      const matchedJob = jobsites[jobIndex];
      const geoFence = matchedJob?.geoFenceInfo?.[0]?.geoFenceInfo;

      const range = getJobDistance({
        geoFence,
        point: empRep?.punchCoordinates,
        jobCoordinates: matchedJob?.addressPosition,
      });

      const schIndex = todaySchedules.findIndex(
        (sch) => sch?.projectId === matchedJob?.projectId
      );

      const isScheduled = (
        todaySchedules?.[schIndex]?.includedEmployeeIds || []
      ).includes(entry?.crewId);

      let statusColor = null;
      let statusTitle = empRep?.liveStatus;

      if (empRep?.liveStatus === "In" && !range?.inRange) {
        statusColor = "#e9c466";
        statusTitle = "Don't Match";
      } else {
        if (!isScheduled) {
          statusColor = "#7FB5B5";
          statusTitle = "Unscheduled";
        }
      }

      const updatedRep = {
        ...empRep,
        color: statusColor,
        scheduleMatch: true,
        liveStatus: statusTitle,
        distance: range?.distanceInFeet,
        projectId: matchedJob?.projectId,
        jobsiteId: matchedJob?.jobsiteId,
      };

      employeeMatchedReport.push(updatedRep);
      if (
        todaySchedules.findIndex(
          (sch: ScheduleType & { jobsiteId?: string }) =>
            sch?.jobsiteId === matchedJob?.jobsiteId
        ) === -1 &&
        jobsitesIncluded.findIndex(
          (job) => job?.jobsiteId === matchedJob?.jobsiteId
        ) === -1
      ) {
        jobsitesIncluded.push(matchedJob);
      }
      scheduleMatched = { matched: true, status: statusTitle };
    }

    // #region Schedule match
    for (let i = 0; i < todaySchedules.length; i++) {
      if (scheduleMatched?.matched) {
        break;
      }
      const schedule = todaySchedules[i];
      const includedEmployeeIds = schedule?.includedEmployeeIds || [];

      if (includedEmployeeIds.includes(empRep.crewId)) {
        const geoFence = schedule?.geoFenceInfo?.[0]?.geoFenceInfo;

        const range = getJobDistance({
          geoFence,
          point: empRep?.punchCoordinates,
          jobCoordinates: schedule?.addressPosition,
        });
        const jobIndex = jobsites.findIndex(
          (job) => job?.projectId === schedule?.projectId
        );

        const updatedRep = {
          ...empRep,
          scheduleMatch: true,
          projectId: schedule?.projectId,
          distance: range?.distanceInFeet,
          jobsiteId: jobsites[jobIndex]?.jobsiteId,
          color:
            empRep?.liveStatus === "In" && !range?.inRange ? "#e9c466" : null,
          liveStatus:
            empRep?.liveStatus === "In" && !range?.inRange
              ? "Don't Match"
              : empRep?.liveStatus,
        };

        employeeMatchedReport.push(updatedRep);
        scheduleMatched = { matched: true, status: updatedRep?.liveStatus };
        break;
      }
    }

    // Default assigned job for employee
    if (empRep?.defaultJob && scheduleMatched?.status === "Don't Match") {
      const defaultJobIndex = jobsites.findIndex(
        (job) => job?.jobsiteId === empRep?.defaultJob
      );
      const jobMatch = jobsites?.[defaultJobIndex];
      const range = getJobDistance({
        point: empRep?.punchCoordinates,
        jobCoordinates: jobMatch?.addressPosition,
        geoFence: jobMatch?.geoFenceInfo?.[0]?.geoFenceInfo,
      });

      const updatedRep = {
        ...empRep,
        projectId: jobMatch?.projectId,
        jobsiteId: jobMatch?.jobsiteId,
        distance: range?.distanceInFeet,
        color:
          empRep?.liveStatus === "In" && !range?.inRange ? "#e9c466" : null,
        liveStatus:
          empRep?.liveStatus === "In" && !range?.inRange
            ? "Don't Match"
            : empRep?.liveStatus,
      };
      employeeMatchedReport.push(updatedRep);
      scheduleMatched = { matched: true, status: updatedRep?.liveStatus };
    }

    let closestJob;
    // #region Jobsite match
    for (let i = 0; i < jobsites.length; i++) {
      if (
        scheduleMatched?.matched &&
        scheduleMatched?.status !== "Don't Match"
      ) {
        break;
      }
      const job = jobsites[i];

      const geoFence =
        job?.geofenceInfo?.[0]?.geoFenceInfo ||
        job?.geoFenceInfo?.[0]?.geoFenceInfo ||
        job?.geoFenceInfo;

      const range = getJobDistance({
        point: empRep?.punchCoordinates,
        jobCoordinates: job?.addressPosition,
        tolerance: Number(job?.locationRadius),
        geoFence: geoFence as Array<CoordinateType>,
      });

      if (range?.distanceInFeet < (closestJob?.distance || 99999)) {
        closestJob = {
          ...job,
          inRange: range?.inRange,
          distance: range?.distanceInFeet,
        };
      }
    }

    if (closestJob) {
      let liveStatus = empRep?.liveStatus;
      let color = null;

      if (closestJob?.inRange) {
        if (empRep?.liveStatus === "In") {
          liveStatus = "Unscheduled";
          color = "#7FB5B5";
        }
      } else {
        if (
          empRep?.liveStatus === "In" ||
          empRep?.liveStatus === "Unscheduled"
        ) {
          liveStatus = "Don't Match";
          color = "#e9c466";
        }
      }

      const updatedRep = {
        ...empRep,
        color,
        liveStatus,
        jobsiteMatch: true,
        distance: closestJob?.distance,
        projectId: closestJob?.projectId,
        jobsiteId: closestJob?.jobsiteId,
      };

      if (
        employeeMatchedReport?.[employeeMatchedReport?.length - 1]
          ?.employeeId === updatedRep?.employeeId
      ) {
        employeeMatchedReport[employeeMatchedReport?.length - 1] = updatedRep;
      } else {
        employeeMatchedReport.push(updatedRep);
      }
      const includedJob = jobsitesIncluded.findIndex(
        (jb) => jb?.jobsiteId === closestJob?.jobsiteId
      );
      const includedSchedule = todaySchedules.findIndex(
        (sch) => sch?.projectId === closestJob?.projectId
      );
      if (includedJob === -1 && includedSchedule === -1) {
        jobsitesIncluded.push(closestJob);
      }
    }
  }
  const matchedReports = employeeMatchedReport.map((el) => el?.punchId);
  const unmatched = employeesReport.filter(
    (el) => !matchedReports?.includes(el?.punchId)
  );

  return {
    jobsitesIncluded,
    employeesMatched: employeeMatchedReport.concat(unmatched),
  };
}
