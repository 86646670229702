import React, { useState } from "react";
import "./JSONViewer.scss";
import { useSelector } from "react-redux";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";

const JSONViewer = ({ jsonData, onChange }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [expanded, setExpanded] = useState({});

  const handleEdit = (path, newValue) => {
    newValue = newValue.trim();

    if (newValue === "") {
      return;
    }
    const simpleParse = (val) => val?.includes(`"`) || val?.includes(`'`);
    let normalizedNewValue = simpleParse(`${newValue}`)
      ? JSON.parse(`${newValue}`)
      : JSON.parse(`"${newValue}"`);

    const updatedData = { ...jsonData };

    const keys = path
      .split(".")
      .map((key) => (key.includes("[") ? key.replace(/\[|\]/g, "") : key));

    let obj = updatedData;
    let currentValue;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        currentValue = obj[key];

        let normalizedCurrentValue = simpleParse(`${currentValue}`)
          ? JSON.parse(`${currentValue}`)
          : JSON.parse(`"${currentValue}"`);

        if (normalizedCurrentValue === normalizedNewValue) {
          console.log("Value is the same, no update needed.");
          return;
        }

        if (key.includes("[")) {
          const arrayIndex = parseInt(key);
          obj[arrayIndex] = normalizedNewValue;
        } else {
          obj[key] = normalizedNewValue;
        }
      } else {
        if (key.includes("[")) {
          const arrayIndex = parseInt(key);
          obj = obj[arrayIndex];
        } else {
          obj = obj[key];
        }
      }
    });

    onChange(updatedData);
  };

  // Add a new key to an object
  const handleAddKey = (path) => {
    const updatedData = { ...jsonData };
    const keys = path.split(".");
    let obj = updatedData;

    // Traverse to the object to which we want to add a key
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        const newKey = prompt("Enter new key:");
        if (newKey) {
          const newValue = prompt("Enter value for the new key:");
          obj[newKey] = newValue; // Add the new key-value pair
        }
      } else {
        obj = obj[key];
      }
    });

    onChange(updatedData); // Call onChange to propagate the new object
  };

  // Add a new item to an array
  const handleAddItem = (path) => {
    const updatedData = { ...jsonData };
    const keys = path.split(".");
    let obj = updatedData;

    // Traverse to the array to which we want to add an item
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        const newItem = prompt("Enter new item:");
        if (newItem) {
          obj.push(newItem); // Add the new item
        }
      } else {
        obj = obj[key];
      }
    });

    onChange(updatedData); // Call onChange to propagate the new array
  };

  // Function to toggle the expansion of an object/array
  const toggleExpand = (path) => {
    setExpanded((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  // Render value based on its type (string, number, boolean, object, array)
  const renderValue = (value, path) => {
    if (typeof value === "object") {
      return renderObject(value, path); // For nested objects and arrays
    } else {
      return (
        <span
        // contentEditable
        // suppressContentEditableWarning
        // onBlur={(e) => handleEdit(path, e.target.textContent)}
        >
          {JSON.stringify(value)}
        </span>
      ); // Editable primitive values
    }
  };

  // Render object or array, recursively
  const renderObject = (obj, path = "") => {
    if (Array.isArray(obj)) {
      return (
        <div style={{ marginLeft: "20px" }}>
          {obj.map((item, index) => {
            const currentPath = `${path}[${index}]`;
            return (
              <div key={currentPath}>
                <strong>Item {index}: </strong> {renderValue(item, currentPath)}
              </div>
            );
          })}
          {/* <button onClick={() => handleAddItem(path)}>Add Item</button> */}
        </div>
      );
    }

    return (
      <div style={{ marginLeft: "20px" }}>
        {Object.entries(obj || {})?.map(([key, value]) => {
          const currentPath = path ? `${path}.${key}` : key;

          return (
            <div key={currentPath}>
              <div>
                {typeof value === "object" && (
                  <span onClick={() => toggleExpand(currentPath)}>
                    {expanded[currentPath] ? (
                      <CaretDownOutlined />
                    ) : (
                      <CaretRightOutlined />
                    )}
                  </span>
                )}
                <strong title={typeof value}>{key}: </strong>{" "}
                {typeof value === "object" ? (
                  expanded[currentPath] ? (
                    renderObject(value, currentPath)
                  ) : Array.isArray(value) ? (
                    <span>{`[...]`}</span>
                  ) : (
                    <span>{`{...}`}</span>
                  )
                ) : (
                  renderValue(value, currentPath)
                )}
              </div>
            </div>
          );
        })}
        {/* <button onClick={() => handleAddKey(path)}>Add Key</button> */}
      </div>
    );
  };

  return (
    <div className={`jsonViewer ${isDarkMode && "jsonViewerDark"}`}>
      {renderObject(jsonData)}
    </div>
  );
};

export default JSONViewer;
