import React, { useState } from "react";
import coreImage from "../../assets/Core.png";
import CustomFilter from "../CustomFilter/CustomFilter";
import { AutoComplete, Button } from "antd";
import { useSelector } from "react-redux";
import { useGoogleSignIn } from "../../../../../hooks/useGoogleSignIn";
import { CommunicationIcon, OptionsIcon, SearchIcon } from "../../assets";
import AttachEmail from "../AttachEmail/AttachEmail";
import "./communication-header.scss";
import CommunicationLogs from "./communication-logs/CommunicationLogs";
import UserAuthView from "./user-auth-view/UserAuthView";
import UserView from "./user-view/UserView";
import { useResponsive } from "../../../../../hooks";
import { useCommunication } from "../../providers/CommunicationProvider";

const CommunicationHeader = ({
  isLeadEditable,
  setSearchValue,
  filterObject,
  setFilterObject,
}) => {
  // State of the emails filter
  const [customFilter, setCustomFilter] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userDetails, userLoggedIn } = useGoogleSignIn();
  const { mobile, tablet, desktop } = useResponsive();
  const { selectedEmail } = useCommunication();

  return (
    <div
      className={`communication-header-container ${
        mobile ? "mobile" : tablet ? "mobile" : ""
      } ${isDarkMode ? "dark-mode" : ""}`}
      data-testid="communication-header-container"
    >
      <div className="communication-header-container-left">
        {/* Title ,  Icon and Filter is visible only on  desktop   */}
        {!mobile && !tablet && (
          <>
            <CommunicationIcon className="communication-icon" />
            <p
              data-testid="communication-title"
              className="communication-title"
            >
              Communications
            </p>
            <div className="communication-search-container">
              {/* The filter which searches  through emails */}
              <AutoComplete
                className="filter-select"
                {...{
                  placeholder: "Search all emails",
                  popupMatchSelectWidth: 252,
                  onChange: setSearchValue,
                }}
              />
              <SearchIcon
                className="searchIcon"
                data-testid="communication-header-icon"
              />
              <div className="info-box" onClick={(e) => e.stopPropagation()}>
                <span
                  data-testid="options-icon"
                  className="optionsIcon"
                  onClick={() => {
                    setCustomFilter((prev) => !prev);
                  }}
                >
                  <OptionsIcon />
                </span>

                {customFilter ? (
                  <CustomFilter
                    {...{
                      setCustomFilter,
                      setValues: setFilterObject,
                      values: filterObject,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="communication-header-container-right">
        <div className="users-info-container">
          <UserView
            isSelected={selectedEmail === "company" ? true : false}
            user={{
              picture: coreImage,
              name: "Web App Account",
              email: "corestorage@corescaffold.com",
            }}
          />

          {userLoggedIn && (
            <>
              <span className="divider" />
              <UserView
                user={userDetails}
                isSelected={selectedEmail === "user" ? true : false}
              />
            </>
          )}
        </div>

        <div className="communication-header-container-right-actions">
          {!desktop && (
            <div>
              <Button
                onClick={() => setCustomFilter((prevState) => !prevState)}
                icon={<OptionsIcon />}
                className="search-trigger"
                type="text"
                data-testid="search-trigger-button"
              />

              {customFilter && (
                <CustomFilter
                  {...{
                    setCustomFilter,
                    getValues: setFilterObject,
                    values: filterObject,
                  }}
                />
              )}
            </div>
          )}

          <UserAuthView userLoggedIn={userLoggedIn} />
          <CommunicationLogs />

          {/* Attach email is logged in user specific and when isLeadEditable is true */}

          {userLoggedIn && selectedEmail === "user" && isLeadEditable && (
            <AttachEmail />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunicationHeader;
