import { CheckCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal, message } from "antd";
import { API } from "aws-amplify";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEmails } from "../../../../../actions/communicationActions";
import useLogManagement from "../../../../../hooks/useLogManagement";
import { AttachEmailIcon } from "../../../../../icons";
import { MondayButton } from "../../../../commonComponents/index";
import { DeleteIcon } from "../../../DynamicView/src";
import { XIcon } from "../../assets";
import { callGmailAPI } from "../../functions";
import { useCommunication } from "../../providers/CommunicationProvider";

import {
  showErrorMsg,
  showInfoMsg,
  showLoadingMsg,
} from "../../../../../utils";

import "./AttachEmail.scss";

// This component is responsible for opening and rendering the modal dialog which is used to add a copied gmail url email

const AttachEmail = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [urlsToAdd, setUrlToAdd] = useState([]);
  const [open, setOpen] = useState(false);
  const { recordData } = useSelector((state) => state.communication);
  const [newEmails, setNewEmails] = useState([]);
  const { authData } = useCommunication();
  const { recordName, recordId, attachedEmailsUrls, ...rest } =
    recordData || {};
  const [loading, setLoading] = useState(false);
  const isPlural = urlsToAdd.length > 1 ? true : false;
  const disabled = loading;

  const { handleGenerateNewLog } = useLogManagement();

  // constants which maps and returns all added urls and a delete icon  to remove them fromm the actual state
  const mappedUrls = useMemo(() => {
    return urlsToAdd.map((e) => (
      <div key={e} className="urlItem">
        <div className="urlItemName">
          <p>
            <a href={e} rel="noopener noreferrer" target="_blank">
              {e}
            </a>
          </p>
        </div>
        <span
          data-testid="remove-url-icon"
          className="removeIcon"
          onClick={() => {
            setUrlToAdd((prev) => prev.filter((el) => el !== e));
          }}
        >
          <DeleteIcon />
        </span>
      </div>
    ));
  }, [urlsToAdd]);

  // HandleOk will set the  loading true and show a loadding msg to indicate the user that the urls are being added.
  const handleOk = () => {
    setLoading(true);
    showLoadingMsg({
      content: `New ${isPlural ? "emails are" : "email is"} being added...`,
      duration: 0,
    });

    // This calling a backend endpoint passing the urls and authData and waiting for a response
    callGmailAPI("getMessagesByUrl", { url: urlsToAdd, authData })
      .then(async (res) => {
        if (res?.data?.error) {
          showErrorMsg({ content: res.data.error });
          return;
        }

        //If email/s is returned the state will be updated and after that the filtering to prevent duplications will take place
        if (res?.data?.emails) {
          setNewEmails((prevEmails) => [...prevEmails, ...res?.data?.emails]);
          const existingEmails = res?.data?.emails.map((email) => email) || [];
          const newEmailsFiltered = newEmails
            .filter((email) => !existingEmails.includes(email.emailId))
            .map((email) => email);
          const combinedEmails = [...existingEmails, ...newEmailsFiltered];

          // This will update the AWS table with the new emails emailIds and attachments if any
          await API.put(
            recordName === "permitdrawings" ? "permitDrawings" : recordName,
            `/${
              recordName === "permitdrawings" ? "permitDrawings" : recordName
            }/${recordId}`,
            {
              body: {
                emailMessagesIds: [
                  ...(rest.emailMessagesIds || []),
                  ...combinedEmails.map((email) => email.emailId),
                ].filter(Boolean),
                attachedEmailsUrls: [
                  ...(rest.attachedEmailsUrls || []),
                  ...combinedEmails?.map((email) => email.url),
                ],
              },
            }
          )
            .then(() => {
              // this will call a reducer function to update the redux state inside the communicationReducer
              dispatch(addEmails(res?.data?.emails));

              // a new log is generated
              handleGenerateNewLog(
                "Add",
                "Attach email",
                "Does not exist",
                "Attached new email",
                "Communication"
              );

              // a message info will let the user know that the email/s are added and the modal will close and the form fields will be reset
              showInfoMsg({
                content: `${
                  isPlural ? "Emails have" : "Email has"
                } been attached successfully`,
              });
              setOpen(false);
              form.resetFields();
              setUrlToAdd([]);
            })
            .catch((error) => {
              showErrorMsg({
                content: `Error while trying to attach new ${
                  isPlural ? "emails" : "email"
                }`,
              });
              console.error(error);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        showErrorMsg({
          content: `Error while trying to get ${urlsToAdd.length} by thread`,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // prevents the duplication of same urls to be added and let user know
  const onSubmit = () => {
    const url = form.getFieldValue("url");

    if (
      attachedEmailsUrls?.includes(url?.split("/#inbox/")[1]) ||
      newEmails.find((email) => email.url === url?.split("/#inbox/")[1])
    ) {
      message.info("This url is already attached to emails");
    } else {
      setUrlToAdd((prevState) => [...prevState, url]);
      form.resetFields();
    }
  };

  return (
    <div className="attachEmailModalContainer">
      <>
        <MondayButton
          className="mondayButton"
          Icon={<AttachEmailIcon />}
          onClick={() => setOpen(true)}
          style={{ maxWidth: 32 }}
          data-testid="open-modal-button"
        />

        <Modal
          title="Attach Emails"
          open={open}
          onOk={null}
          className="attachModal"
          onCancel={() => {
            setOpen(false);
            setUrlToAdd([]);
            form.resetFields();
          }}
          destroyOnClose={true}
          closeIcon={<XIcon />}
          footer={
            <div className="modalButtonsContainer">
              <MondayButton
                className="submitButton"
                // Icon={<CheckCircleOutlined />}
                onClick={handleOk}
                disabled={disabled}
              >
                Save
              </MondayButton>
              <MondayButton
                {...{
                  className: "mondayButtonRed",
                  // Icon: <XIcon />,
                  onClick: () => {
                    form.resetFields();
                    setUrlToAdd([]);
                    setOpen(false);
                  },
                }}
                disabled={disabled}
              >
                Cancel
              </MondayButton>
            </div>
          }
        >
          <div className="attachEmailBody">
            <Form form={form}>
              <div className="inputValueWithButton">
                <Form.Item
                  rules={[
                    { required: true, message: "Pease enter a gmail url!" },
                    {
                      type: "url",
                      message: "This is not a valid gmail url",
                    },
                  ]}
                  name="url"
                  style={{ width: "100%" }}
                >
                  <Input
                    className="inputValue"
                    data-testid="inputValue"
                    allowClear
                  />
                </Form.Item>
                <MondayButton
                  {...{
                    className: "submitButton",
                    onClick: () => {
                      form.validateFields().then(() => {
                        onSubmit();
                      });
                    },
                    Icon: <CheckCircleOutlined />,
                  }}
                  disabled={disabled}
                >
                  Submit
                </MondayButton>
              </div>
            </Form>
            <div className="selectedBody">{mappedUrls}</div>
          </div>
        </Modal>
      </>
    </div>
  );
};
export default AttachEmail;
