import { API } from "aws-amplify";
import { userGroups } from "../../../../../../../../actions";
import broadcastNotification from "../../../../../../../../helpers/controllers/broadcastNotification";
import { showSuccessMsg } from "../../../../../../../../utils";
import { message } from "antd";
import Swal from "sweetalert2";
import deleteDepartment from "./deleteDepartment";

const deleteRole = async (
  roleName,
  userGroupsData,
  dispatch,
  userConfiguration,
  departmentOfRole,
  programFields,
  callbackFn = () => {}
) => {
  if (!roleName || !departmentOfRole) {
    return message.error({
      content: "Role or department not found!",
      key: "delete",
    });
  }
  const userGroupsToDelete = userGroupsData.find(
    (user) =>
      user.groupName === roleName && user.departmentName === departmentOfRole
  );
  const newUserGroups = userGroupsData.filter(
    (user) => user.groupId !== userGroupsToDelete.groupId
  );

  const otherRolesInDepartment = newUserGroups.filter(
    (gr) => gr.departmentName === departmentOfRole
  );

  await API.del("userGroups", `/userGroups/${userGroupsToDelete.groupId}`)
    .then(async () => {
      dispatch(userGroups(newUserGroups));
      broadcastNotification(
        "64",
        `onDeleteRole`,
        [
          {
            common: userConfiguration.nameOfUser,
            commonNext: roleName,
          },
          {
            userName: userConfiguration.nameOfUser,
            currentUser: userConfiguration.cognitoUserId,
          },
        ],
        ""
      );
      if (otherRolesInDepartment.length === 0) {
        Swal.fire({
          title:
            "There are no roles in this department. Do you want to delete the department?",
          showCancelButton: true,
          confirmButtonText: "Delete",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteDepartment(
              departmentOfRole,
              programFields,
              dispatch,
              userConfiguration,
              userGroupsData
            );
          }
        });
      }
      showSuccessMsg({
        content: "Role has been deleted successfully!",
        key: "delete",
      });
      callbackFn();
    })
    .catch((err) => {
      message.error({
        content: "Something went wrong. Please try again!",
        key: "delete",
      });
      callbackFn();
      console.log("deleteRole ~ err", err);
    });
};

export default deleteRole;
