import { convertSafetyContactsData } from "../../../../../Projects/ProjectSafetyReport/helpers";
import dayjs from "dayjs";

const space = {
  text: {
    paragraphs: [
      {
        text: "",
        bold: true,
        fontSize: 14,
      },
    ],
  },
};

async function convertSafetyReport(data = []) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }

  const getReplies = (replies, level = 1) => {
    replies.forEach((reply) => {
      const {
        author: replyAuthor,
        createdAt: replyCreatedAt,
        replyContent,
      } = reply;
      const formattedReplyDate =
        dayjs(replyCreatedAt).format("MM/DD/YYYY hh:mm A");
      const replyHeader = {
        text: {
          paragraphs: [
            {
              text: `${replyAuthor} ${formattedReplyDate}`,
              fontSize: 13,
              bold: true,
              class: `reply-header-${level > 6 ? 6 : level}`,
            },
          ],
        },
      };
      dataSections.push(replyHeader);

      const replyBody = {
        text: {
          paragraphs: [
            {
              text: replyContent,
              fontSize: 12,
              lineHeight: 1.5,
              class: "reply-header",
            },
          ],
        },
      };
      dataSections.push(replyBody);
      if (reply.replies?.length > 0) {
        getReplies(reply.replies, level + 1);
      }
    });
  };

  // Separate the 'other' data and 'sections' data
  const otherData = [...data].filter((section) => section?.other);
  const sections = [...data].filter(
    (section) => !section?.additionalData && !section?.other
  );

  // Process each section asynchronously
  for (const section of sections) {
    if (section.title === "Comments") {
      // Handle Comments section
      const { title, body } = section;
      let titleClassName = `comments-section-header`;
      const titleFormat = {
        text: {
          paragraphs: [
            {
              text: title,
              bold: true,
              fontSize: 16,
              class: titleClassName,
            },
          ],
        },
      };
      dataSections.push(titleFormat);

      // Loop through each comment in the body
      body.forEach((comment, index) => {
        const { author, createdAt, commentContent } = comment;
        const formattedDate = dayjs(createdAt).format("MM/DD/YYYY hh:mm A");

        // Format the comment header (author and date)
        const commentHeader = {
          text: {
            paragraphs: [
              {
                text: `${author} ${formattedDate}`,
                bold: true,
                fontSize: 14,
                margin: [0, 50, 0, 0, 0],
                class: "comment-header-author",
              },
            ],
          },
        };

        const commentText = {
          text: {
            paragraphs: [
              {
                text: "Comment:",
                bold: true,
                fontSize: 14,
                class: "comment-header",
              },
            ],
          },
        };

        dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        index > 0 && dataSections.push(space);
        dataSections.push(commentHeader);
        dataSections.push(commentText);

        // Format the comment body (content)
        const commentBody = {
          text: {
            paragraphs: [
              {
                text: commentContent,
                fontSize: 12,
                lineHeight: 1.5,
                class: "comment-content",
              },
            ],
          },
        };
        dataSections.push(commentBody);

        // If there are replies, format them as well
        if (comment.replies && comment.replies.length > 0) {
          const replyText = {
            text: {
              paragraphs: [
                {
                  text: "Replies:",
                  bold: true,
                  fontSize: 14,
                  class: "comment-header",
                },
              ],
            },
          };
          dataSections.push(replyText);
          getReplies(comment.replies);
        }
      });
    } else {
      // Wait for the promise in 'body' to resolve before proceeding
      const bodyResolved = section.body ? await section.body : null;

      // Create tables (assuming 'titleBodyHtmlTable' processes the resolved body)
      const tables =
        titleBodyHtmlTable({
          ...section,
          body: bodyResolved, // Use the resolved body
        }) || [];

      // Push tables to dataSections
      dataSections.push(...tables);
    }
  }

  // If there's any 'other' data, process it
  if (otherData?.length > 0) {
    const hospitals = convertSafetyContactsData(otherData) || [];
    dataSections.push(...hospitals);
  }

  return dataSections;
}

export default convertSafetyReport;

// The 'titleBodyHtmlTable' function will remain the same, as it doesn't directly handle promises.
// It will now receive the resolved 'body' from the above code.
export function titleBodyHtmlTable(
  object,
  newTitleClassName = "",
  tableWrapperClassName = ""
) {
  const toReturn = [];
  if (!object) {
    return toReturn;
  }
  const { title, body } = object || {};
  let titleClassName = newTitleClassName
    ? newTitleClassName
    : `safety-section-header`;
  const titleFormat = {
    text: {
      paragraphs: [
        {
          text: title,
          bold: true,
          fontSize: 16,
          class: titleClassName,
        },
      ],
    },
  };
  toReturn.push(titleFormat);
  const bodyFormat = {
    class: tableWrapperClassName,
    table: {
      class: "safety-table",
      cols: [],
      rows: (body || []).map(({ key = "", value = "" }) => {
        const strVal = !!value ? "" + value : "";
        if (strVal?.includes("data:image")) {
          return [
            key,
            {
              width: 100,
              height: 100,
              src: strVal,
              class: "safety-image",
            },
          ];
        } else if (strVal?.includes("isPhoneNumber:")) {
          const newValue = strVal?.split("isPhoneNumber:")?.[1];
          return [
            key,
            {
              link: `tel:${newValue}`,
              text: newValue,
              class: "phone-number",
            },
          ];
        } else {
          return [key, strVal];
        }
      }),
    },
  };
  toReturn.push(bodyFormat);

  return toReturn;
}
