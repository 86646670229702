import _ from "lodash";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

export const forecastingRaport = ({
  forecastEstimations = {},
  scheduleEstimations = {},
}) =>
  [
    {
      header: "FORECASTING",
      borderBottomColor: "#ff6347",
      display: forecastingData(forecastEstimations),
    },
    {
      header: "SCHEDULING",
      borderBottomColor: "#483d8b",
      display: forecastingData(scheduleEstimations),
    },
    {
      header: "ACTUAL PROGRESS",
      borderBottomColor: "#228b22",
      progress: true,
      display: forecastingData(scheduleEstimations),
    },
  ].filter(Boolean);

export const forecastingData = (projectServices = {}) => {
  let toReturn = [];
  Object.entries(projectServices || {})?.forEach(([estimationId, values]) => {
    toReturn.push({
      estimation:
        values?.[0]?.quickbooksNumber ||
        values?.[0]?.estimationNumber ||
        estimationId,
      estimationId,
      services: values?.sort((a, b) => a?.serviceId - b?.serviceId),
    });
  });
  return toReturn;
};

function isMultiDimensional(arr) {
  return arr.some(Array.isArray);
}

export const transformData = (data, key) => {
  const result = {};

  Object.keys(data)?.forEach((id) => {
    const services = data?.[id];
    console.log("servicesss", { services });
    const groupedServices = {};

    services?.forEach((service) => {
      const { serviceId, serviceOptions, ...rest } = service;

      if (!groupedServices[serviceId]) {
        groupedServices[serviceId] = {
          serviceId,
          [`${key}daysWithProgress`]: service?.[`${key}daysWithProgress`],
          [`${key}progressHours`]: service?.[`${key}progressHours`],
          [`${key}potentialStartDate`]: service?.[`${key}potentialStartDate`],
          [`${key}plannedNrOfDays`]: service?.[`${key}plannedNrOfDays`],
          [`${key}plannedNrOfHours`]: service?.[`${key}plannedNrOfHours`],
          [`${key}plannedNrOfCrews`]: service?.[`${key}plannedNrOfCrews`],
          [`${key}plannedNrOfTrucks`]: service?.[`${key}plannedNrOfTrucks`],
          serviceOptions: serviceOptions || [],
          ...rest,
        };
      } else {
        const existing = groupedServices[serviceId];
        existing[`${key}potentialStartDate`] =
          dayjsNY(service?.[`${key}potentialStartDate`]).valueOf() <
          dayjsNY(existing?.[`${key}potentialStartDate`]).valueOf()
            ? service?.[`${key}potentialStartDate`]
            : existing?.[`${key}potentialStartDate`];
        existing[`${key}plannedNrOfDays`] += service?.[`${key}plannedNrOfDays`];
        existing[`${key}plannedNrOfHours`] +=
          service?.[`${key}plannedNrOfHours`];
        existing[`${key}daysWithProgress`] +=
          service?.[`${key}daysWithProgress`];
        existing[`${key}progressHours`] += service?.[`${key}progressHours`];
        existing[`${key}plannedNrOfCrews`] +=
          service?.[`${key}plannedNrOfCrews`];
        existing[`${key}plannedNrOfTrucks`] +=
          service?.[`${key}plannedNrOfTrucks`];
        existing["serviceOptions"] = [
          [
            ...(existing?.serviceOptions?.[0] || []),
            ...(serviceOptions?.[0] || []),
          ],
        ];
      }

      let keysToSum = [
        `${key}crewsProgress`,
        `${key}daysWithProgress`,
        `${key}progressHours`,
        `${key}plannedNrOfDays`,
        `${key}plannedNrOfHours`,
        `${key}plannedNrOfCrews`,
        `${key}plannedNrOfTrucks`,
      ];

      const groupEle = _.groupBy(
        groupedServices?.[serviceId]?.serviceOptions?.[0],
        "elevationId"
      );

      groupedServices[serviceId].serviceOptions[0] = Object.values(
        groupEle
      )?.map((group) => {
        return group?.reduce?.((acc, curr) => {
          keysToSum?.forEach((key) => {
            acc[key] = (acc[key] || 0) + (curr[key] || 0);
          });
          acc.items = [...(acc.items || []), ...(curr.items || [])];
          return acc;
        });
      });

      groupedServices[serviceId].serviceOptions[0] = groupedServices?.[
        serviceId
      ]?.serviceOptions?.[0]?.map((elevation) => {
        const groupItems = _.groupBy(elevation?.items, "id");
        elevation.items = Object.values(groupItems)?.map((group) => {
          return group?.reduce?.((acc, curr) => {
            keysToSum?.forEach((key) => {
              acc[key] = (acc[key] || 0) + (curr[key] || 0);
            });

            return acc;
          });
        });
        return elevation;
      });
    });

    result[id] = Object.values(groupedServices);
  });

  return result;
};

export const compareForeSchAct = (
  forecasting = {},
  scheduling = {},
  type = ""
) => {
  let toReturn = {};

  const keysToCompare = (level) => {
    let keys = [
      `${type}plannedNrOfDays`,
      `${type}plannedNrOfHours`,
      `${type}plannedNrOfCrews`,
      `${type}plannedNrOfTrucks`,
    ];
    if (level === "service") {
      keys.push(`${type}potentialStartDate`);
    }
    return keys;
  };

  Object.entries(forecasting || {}).forEach(([key, value]) => {
    const schedule = scheduling?.[key] || [];

    value?.forEach((service) => {
      const sameService = schedule?.find(
        (label) => label?.serviceId === service?.serviceId
      );

      if (sameService) {
        let serviceDifferences = {};
        let elevationDifferences = {};
        let pliDifferences = {};

        keysToCompare("service").forEach((k) => {
          if (service?.[k] !== sameService[k]) {
            serviceDifferences[k] = {
              forecasting: service[k],
              scheduling: sameService[k],
              type: "service",
            };
          }
        });

        sameService?.serviceOptions?.[0]?.forEach((elevation) => {
          const sameElevation = service?.serviceOptions?.[0]?.find(
            (ele) => ele?.elevationId === elevation?.elevationId
          );

          if (sameElevation) {
            keysToCompare().forEach((k) => {
              if (elevation?.[k] !== sameElevation[k]) {
                elevationDifferences[k] = {
                  forecasting: elevation[k],
                  scheduling: sameElevation[k],
                  type: "elevation",
                };
              }
            });
          }
          elevation?.items?.forEach((pli) => {
            const samePli = sameElevation?.items?.find(
              (item) => item?.id === pli?.id
            );
            if (samePli) {
              keysToCompare().forEach((k) => {
                if (elevation?.[k] !== sameElevation[k]) {
                  pliDifferences[k] = {
                    forecasting: pli[k],
                    scheduling: samePli[k],
                    type: "pli",
                  };
                }
              });
            }
          });
        });

        if (
          Object.keys(serviceDifferences).length > 0 ||
          Object.keys(elevationDifferences).length > 0 ||
          Object.keys(pliDifferences).length > 0
        ) {
          const keyName =
            value?.[0]?.quickbooksNumber || value?.[0]?.estimationNumber || key;

          if (!toReturn[keyName]) {
            toReturn[keyName] = {};
          }

          toReturn[keyName][service.label] = {
            service:
              Object.keys(serviceDifferences).length > 0
                ? serviceDifferences
                : undefined,
            elevation:
              Object.keys(elevationDifferences).length > 0
                ? elevationDifferences
                : undefined,
            pli:
              Object.keys(pliDifferences).length > 0
                ? pliDifferences
                : undefined,
          };
        }
      }
    });
  });
  return compareChanges(toReturn);
};

export const compareChanges = (data = {}) => {
  let changes = [];

  for (const [estimationId, categories] of Object.entries(data || {})) {
    for (const [section, categoryData] of Object.entries(categories || {})) {
      let categoryChanges = { service: [], others: [] };

      for (const [category, values] of Object.entries(categoryData || {})) {
        for (const [key, obj] of Object.entries(values || {})) {
          const { forecasting, scheduling } = obj;

          if (forecasting !== scheduling) {
            let before = forecasting !== undefined ? forecasting : "not set";
            let after = scheduling !== undefined ? scheduling : "not set";

            let changeText = `In estimation '${estimationId}', section '${section}', category '${category}', '${key}' changed from '${before}' to '${after}'.`;

            if (category === "service") {
              categoryChanges.service.push(changeText);
            } else {
              categoryChanges.others.push(changeText);
            }
          }
        }
      }

      changes.push(...categoryChanges.service, ...categoryChanges.others);
    }
  }
  return changes;
};
export const isKeyTheSame = (
  mainObj = {},
  estimationId,
  serviceId,
  index,
  keys = []
) => {
  const temp = Object.values(Object.values(mainObj)?.[index])
    ?.map((q) => q?.[estimationId])
    ?.map((service) => service?.[serviceId]);

  if (!Array.isArray(temp) || temp.length !== 3) return false;

  const [firstKey, secondKey] = keys;
  if (!firstKey || !secondKey) return false;

  const firstKeyValues = temp.slice(0, 2).map((obj) => obj?.[firstKey]);
  const secondKeyValue = temp[2]?.[secondKey];

  const isFirstKeySame =
    firstKeyValues[0] !== undefined && firstKeyValues[0] === firstKeyValues[1];

  const isSecondKeySame =
    secondKeyValue !== undefined && secondKeyValue === firstKeyValues[0];

  return isFirstKeySame && isSecondKeySame;
};
