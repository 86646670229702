import { Select, Button } from "antd";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useRedux } from "../../../../hooks";

import { onOptionClickHandler } from "../../utilities";
import { DropDownArrow } from "../../../../Fleet/components/InputComponent/assets";

import "./AutoComplete.scss";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";
import { useCreateInvoiceContext } from "../context/InvoiceModalContext";

const NewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.361"
    height="19.361"
    viewBox="0 0 19.361 19.361"
  >
    <path
      d="M22.978,16.064H16.064v6.915a1.383,1.383,0,1,1-2.766,0V16.064H6.383a1.383,1.383,0,1,1,0-2.766H13.3V6.383a1.383,1.383,0,1,1,2.766,0V13.3h6.915a1.383,1.383,0,1,1,0,2.766Z"
      transform="translate(-5 -5)"
      fill="#71CF48"
    />
  </svg>
);

const rowStyle = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
};

const Label = (el) => (
  <div className={"menuItemWrapper"}>
    <div className={"menuItemContainer"}>
      <div className={"column"}>
        <div style={rowStyle}>
          <div>{el.name}</div> <div>{el.data.groupName}</div>
        </div>
      </div>
    </div>
  </div>
);

const mapServiceOption = (el, index) => ({
  label: Label(el),
  value: el.name,
  index,
  group: el?.data?.groupName,
  el,
});

const containsIgnoreCase = (string1 = "", string2 = "") =>
  string1.toUpperCase().includes(string2.toUpperCase());

const generateOptions = (list, searchValue = "") => {
  if (searchValue) {
    list = list.filter(
      (el) =>
        containsIgnoreCase(el.name, searchValue) ||
        containsIgnoreCase(el.description, searchValue)
    );
  }
  return list.map(mapServiceOption);
};

export default function AutoCompleteProduct({ value, data }) {
  const { invoiceData } = useInitialInvoiceContext();
  const {
    products: rowData,
    setProducts: setRowData,
    productsList,
  } = useCreateInvoiceContext();

  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isWritable] = useRedux("invoiceIsWritable");
  const disabled = !isWritable && invoiceData;
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [newValue, setNewValue] = useState(value);
  const rowDataNames = rowData.map((el) => el.serviceId);
  const groupsOfService = {};
  rowData.forEach((el) => (groupsOfService[el.serviceId] = el.group));

  const filteredProductsList = productsList.filter(
    (el) =>
      !(
        rowDataNames.includes(el.serviceId) &&
        groupsOfService[el.serviceId] === el.data.groupName
      )
  );

  const extraServices = serviceDefinitions.map((service, index) => ({
    id: filteredProductsList.length + index + 1,
    serviceId: Number(service?.serviceId || service?.id),
    name: service.serviceName,
    description: "",
    amount: 0,
    taxAmount: 0,
    amountPercentage: 100,
    tax: false,
    isExtraService: true,
    group: "Extra Service",
    data: { groupName: "Extra Service" },
    total: 0,
  }));

  useEffect(() => {
    const list =
      data?.group !== "Extra Service" ? filteredProductsList : extraServices;
    setOptions(generateOptions(list));
  }, [productsList, rowData]);

  const onSearch = (searchValue) => {
    setSearchInput(searchValue);
    // setNewValue(searchValue);
    const list =
      data?.group !== "Extra Service" ? filteredProductsList : extraServices;
    setOptions(generateOptions(list, searchValue));
  };

  const onInputKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      navigator.clipboard.readText().then((pastedText) => {
        // setSearchInput(pastedText);
        // onSearch(pastedText);
        setSearchInput("")
        setNewValue(pastedText);
        setRowData((prev) => {
          return prev.map((item) =>
            item.id === data?.id ? { ...item, name: pastedText, group: "Extra Service" } : item
          );
        });
      });
    }
  };

  const addNewService = () => {
    setRowData((prev) => {
      return prev.map((item) =>
        item.id === data?.id ? { ...item, name: searchInput } : item
      );
    });
  };

  const notFoundContent =
    searchInput && searchInput.trim() ? (
      <Button
        onClick={addNewService}
        className="addNewInvoiceServiceButton"
        disabled={!searchInput.trim()}
      >
        <NewIcon /> Add this service
      </Button>
    ) : null;

  return (
    <Select
      suffixIcon={<DropDownArrow />}
      value={newValue}
      onSearch={onSearch}
      onInputKeyDown={onInputKeyDown}
      notFoundContent={notFoundContent}
      className="autoCompleteProduct"
      disabled={disabled}
      name="service"
      showSearch
      placeholder="Select a service"
      optionFilterProp="children"
      popupClassName={isDarkMode ? "darkDropDown" : ""}
      onSelect={(selectedValue, option) => {
        const selectedElement = option.el;
        if (selectedElement?.data?.type === "applications") {
          const services = productsList.filter(
            ({ data }) => data?.groupName === selectedElement?.data?.groupName
          );
          return onOptionClickHandler({ services, setRowData });
        }

        onOptionClickHandler({ services: [selectedElement], setRowData });
      }}
    >
      {options.map((option, i) => (
        <Select.Option key={i} value={option.value} el={option.el}>
          {option.value}
        </Select.Option>
      ))}
    </Select>
  );
}
