import { driveApi } from "../../../../../integrations/DriveRequest";
import htmlParser from "../../../../../utils/htmlParser";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { parsePhone } from "../../../Accounting/components/utilities";
import { citationValues } from "../../../Safety/Citations/helpers/setCitationsValues";

let date = new Date().toLocaleDateString();

function camelCaseToNormalText(input) {
  let normalText = input?.replace(/([a-z])([A-Z])/g, "$1 $2");

  normalText = normalText?.charAt(0)?.toUpperCase() + normalText?.slice(1);

  return normalText;
}

function removeTags(str) {
  if (str === null || str === "") return "";
  else str = str?.toString();

  const rg = /(<([^>]+)>)/gi;

  if (rg.test(str)) {
    return str.replace(rg, "");
  }

  return str;
}

export function separateWitnessForPdf({
  defaultId,
  rowObject,
  witnessCount,
  witnessSignings,
  rowObjectKey,
}) {
  let witnessKeys = [];
  let witnessFields = [];
  let tmpField = [];
  let rg =
    /witnessName-[A-Za-z]*-\d{1,}$|witnessStatement-\d{1,}$|witnessName-\d{1,}$/;

  let replacePattern = /witnessName-|-\d{1,}$/g;
  if (!!defaultId) {
    //separate all the keys that fulfill the witness condition
    Object.keys(rowObject[rowObjectKey]).forEach((key) => {
      if (rg.test(key)) {
        witnessKeys.push(key);
      }
    });

    for (let i = 0; i < witnessCount; i++) {
      let fieldsForIndex = witnessKeys.filter((key) => key?.endsWith(`-${i}`));
      fieldsForIndex.forEach((key) => {
        let keyToPush = key?.replaceAll(replacePattern, "");
        if (!isNaN(parseInt(keyToPush))) {
          keyToPush = "witnessName";
        }
        if (keyToPush?.toLowerCase()?.includes("phone")) {
          tmpField.push({
            key: keyToPush,
            value: parsePhone(rowObject[rowObjectKey]?.[key]),
          });
        } else {
          tmpField.push({
            key: keyToPush,
            value: rowObject[rowObjectKey]?.[key],
          });
        }
      });
      if (!!witnessKeys?.length) {
        tmpField.push({ key: "witnessSign", value: witnessSignings[i] });
        witnessFields.push(tmpField);
      }
      tmpField = [];
    }

    return witnessFields;
  } else return;
}

export const mapImages = (images) => {
  let tmpArr = [];
  for (let i = 0; i < images.length / 6; i++) {
    tmpArr.push(
      pdfImageSection({
        title: "Images",
        body: images.slice(i * 6, i * 6 + 6),
      })
    );
  }

  return tmpArr;
};

function replaceNbsp(text) {
  return text?.replace(/&nbsp;/g, " ");
}

const incidentPdfValues = ({
  element = {},
  foremen = [],
  rowObject = {},
  crewMembers = [],
  userConfiguration = {},
}) => {
  const { formItemName = "", label = "", type } = element;

  const name = formItemName?.toLowerCase();

  const { incidentObject } = rowObject;

  if (name === "reportpreparedby") {
    return userConfiguration?.allUsers?.Items?.find(
      ({ identityId }) => incidentObject?.[formItemName] === identityId
    )?.nameOfUser;
  } else if (name?.includes("crewmembers")) {
    return incidentObject?.[formItemName]?.map(
      (el) => crewMembers?.find(({ value }) => value === el)?.label || el?.value
    );
  } else if (name?.includes("foreman")) {
    return (
      foremen?.find((el) => el.value === incidentObject?.[formItemName])
        ?.label || incidentObject?.[formItemName]?.value
    );
  } else if (name?.includes("phone")) {
    return parsePhone(incidentObject?.[formItemName]);
  }

  if (type?.includes("date")) {
    return dayjsNY(incidentObject?.[formItemName])?.format("MM/DD/YYYY");
  } else if (type?.includes("time")) {
    return dayjsNY(incidentObject?.[formItemName])?.format("hh:mm A");
  }

  if (type === "textEditor") {
    return replaceNbsp(incidentObject?.[formItemName]);
  }

  if (label === "Team Members") {
    return element?.value?.map((el) => el).join(",  ");
  }

  return incidentObject?.[formItemName];
};

export const incidentPdfBody = async ({
  title,
  fieldsJSON,
  crewMembers,
  foremen,
  rowObject,
  userConfiguration,
  objectsWithPk,
}) => {
  let tmpPdfData = [];

  const promises = fieldsJSON?.map(async (el) => {
    if (el?.category === title) {
      //checks if the field is a citation field
      const value = rowObject.citationName
        ? await citationValues({
            element: el,
            rowObject,
            objectsWithPk,
          })
        : await incidentPdfValues({
            element: el,
            foremen,
            rowObject,
            crewMembers,
            userConfiguration,
          });

      return {
        key: el?.label,
        value,
      };
    }
    return null;
  });

  tmpPdfData = (await Promise.all(promises)).filter((item) => item !== null);

  if (title === "Additional Information") {
    tmpPdfData?.push(
      {
        key: "Assigned To",
        value: Array.isArray(rowObject?.incidentObject?.assignedTo)
          ? rowObject?.incidentObject?.assignedTo
              ?.map((el) => {
                if (!Array.isArray(el) && typeof el === "object") {
                  if (el?.hasOwnProperty("label")) {
                    return el?.label;
                  } else if (el?.hasOwnProperty("name")) {
                    return el?.name;
                  } else if (el?.hasOwnProperty("nameOfUser")) {
                    return el?.nameOfUser;
                  }
                }
                return el;
              })
              ?.join(", ")
          : rowObject?.incidentObject?.assignedTo,
      },
      {
        key: "Signature",
        value: rowObject?.incidentObject?.signature,
      }
    );
  }
  if (rowObject.citationName) {
    tmpPdfData?.unshift(
      {
        key: "Citation Name",
        value: rowObject.citationName,
      },
      {
        key: "Citation Status",
        value: rowObject.citationStatus,
      }
    );
    tmpPdfData?.push({
      key: "Safety Director Signature",
      value: rowObject?.citationObject?.safetyDirectorSignature.src,
    });
  }

  return tmpPdfData;
};

export async function imageForPdf({ rowObject, accessToken }) {
  const driveRequest = driveApi({ accessToken });
  const driveImages = rowObject?.googleDriveUpload
    ?.filter(({ type }) => type === "photo")
    ?.map(async (el) => {
      return await driveRequest.getImageSrc(el.id).then((res) => {
        return { img: res?.src, url: el?.url, name: el?.name };
      });
    });

  return Promise.allSettled(driveImages).then((res) => {
    return res?.map((e) => {
      return {
        src: e?.value?.img,
        link: e?.value?.url,
        name: e?.value?.name,
      };
    });
  });
}
export const pdfImageSection = ({ title, body = [], noColor = false }) => {
  const imagesPart = body?.map((image) => {
    return body
      ? {
          columns: [
            {
              width: "*",
              text: image?.name,
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            {
              link: image?.link,
              image: image?.src,
              width: 200,
              height: 110,
              fit: [200, 100],
              alignment: "center",
            },
          ],
        }
      : {
          width: "100%",
          text: "No images",
          alignment: "center",
          style: ["lastColumn", "cellItem"],
        };
  });
  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...imagesPart,
  ].filter((item) => !!item);
  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };
  const layout = !noColor ? { ...borders, fillColor } : borders;
  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

export const pdfBorderedSection = ({ title, body = [], noColor = false }) => {
  const formattedBody = body.map((item) => {
    return title
      ? {
          columns: [
            {
              width: "45%",
              text: camelCaseToNormalText(item.key),
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            {
              width: "10%",
              text: "",
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            item?.value && item?.value?.includes("data:image")
              ? {
                  image: item.value,
                  width: 100,
                  height: 100,
                }
              : {
                  width: "45%",
                  text: removeTags(item.value),
                  alignment: "left",
                  style: ["lastColumn", "cellItem"],
                },
          ],
        }
      : {};
  });

  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...formattedBody,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody?.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const fillColor = (rowIndex) => {
  return rowIndex % 2 === 0 ? "#f4f4f4" : "#fff";
};

export const docDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date: ${date}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },

      dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
};
