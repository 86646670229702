import { AttachmentsProvider } from "../EmailBox/providers/AttachmentsProvider";
import { DriveDocumentsProvider } from "../EmailBox/providers/DriveDocumentsProvider";
import { EmailBoxBodyProvider } from "../EmailBox/providers/EmailBoxBodyProvider";
import { EmailTemplatesProvider } from "../EmailBox/providers/EmailTemplatesProvider";
import { DocumentationTypesProvider } from "../EmailBox/providers/DocumentationTypesProvider";
import { FilePreviewModalProvider } from "../EmailBox/providers/FilePreviewModalProvider";
import ReplyEmailBox from "./ReplyEmailBox";
import { getReplyEmailBoxRecordDetails } from "./utils/getReplyEmailBoxRecordDetails";

import "./reply-email-box-container.scss";

function ReplyEmailBoxContainer({
  mainEmail,
  rowData,
  authData,
  setEmail,
  isEditable,
  isActive,
  setIsActive,
  emails = [],
}) {
  return (
    <div
      data-testid="reply-email-box-container"
      className="reply-email-box-container"
    >
      <AttachmentsProvider>
        <EmailBoxBodyProvider>
          <DriveDocumentsProvider>
            <EmailTemplatesProvider
              rowData={rowData}
              recordDetails={getReplyEmailBoxRecordDetails}
            >
              <DocumentationTypesProvider>
                <FilePreviewModalProvider>
                  <ReplyEmailBox
                    {...{
                      type: isActive,
                      parent: mainEmail,
                      rowData,
                      authData,
                      isChild: true,
                      onSave: (e, b) => {
                        e ? setEmail(e) : setEmail(b);
                        setIsActive("");
                      },
                      isEditable: isEditable,
                      setIsActive,
                      emails,
                    }}
                  />
                </FilePreviewModalProvider>
              </DocumentationTypesProvider>
            </EmailTemplatesProvider>
          </DriveDocumentsProvider>
        </EmailBoxBodyProvider>
      </AttachmentsProvider>
    </div>
  );
}

export default ReplyEmailBoxContainer;
