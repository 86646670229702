import {
  getDriverStatus,
  getFleetExpenses,
  getRentalServices,
  getFleetWorkOrders,
  getChargesOverview,
  getSchedulingServices,
  getCreditMemoServices,
  getEstimationServices,
  getFleetRepairWorkOrders,
  modifyAndSortApplications,
  getDispatchesByActivities,
  getSchedulingTotalProgress,
  getTakeOffServicesAndCompare,
  getSOVComparisonWithAccounting,
  groupAndInspectionsByActivityId,
  getRentalComparisonWithAccounting,
  getTakeOffComparisonWithAccounting,
  getSchedulingTotalProgressWithCharges,
  getForecastingRentalsPerService,
  generateNotInvoicedPaid,
  getBills,
  getIds,
  projectsOverview,
  retrieveInvoices,
  programFieldsParameters,
  codeInvitations,
  allUsers,
  geoFencesActiveActivities,
  fleetDocuments,
  getAccountingComparisonPerProject,
  getSalesReportApprovedEstimates,
  getGeoFencesActivities,
  getGeoFencesActiveActivities,
  getDriverStatistics,
  opportunityDetails,
  projectDetails,
  retrieveInspections,
  getHospitalsNearby,
} from "./ActiveReportJsUtils";
import { filterTables } from "./filterTables";
import { filteredCategoriesWithGeofence } from "../components/pages/Settings/settingsComponents/GeofenceConfigurations/AllProgramGeofences/utils/categoriesWithGeofence";
import { fetchAllData } from "../components/SidebarPages/Fleet/utils/fetchAllData";
import { fetchData } from "../components/SidebarPages/Fleet/utils";
import dayjs from "dayjs";

const formatNotes = (notes) => {
  return notes.map((note) => {
    const formattedNote = {
      ...note,
      createdAt: dayjs(note.createdAt).format("MM/DD/YYYY hh:mm A"),
    };

    if (formattedNote.replies && formattedNote.replies.length > 0) {
      formattedNote.replies = formatNotes(formattedNote.replies);
    }

    return formattedNote;
  });
};

export const dataSourceReportsFetch = async (
  { dataSourceName, categoryName },
  recordId
) => {
  let result = [];

  console.log({ dataSourceName, recordId });
  try {
    switch (dataSourceName) {
      case "userConfiguration":
        result = await fetchData("userConfiguration").then((r) => [r]);
        break;
      case "projectsIds":
        result = await getIds("projects");
        break;
      case "notes":
        const allNotes = await filterTables("notes", "recordId", recordId);
        const formattedNotes = formatNotes(allNotes);
        result = formattedNotes;
        break;
      case "accountsIds":
        result = await getIds("accounts");
        break;
      case "estimationsIds":
        result = await getIds("estimations");
        break;
      case "opportunitiesIds":
        result = await getIds("opportunities");
        break;
      case "invoicesIds":
        result = await getIds("invoices");
        break;
      case "permitdrawings":
        result = await fetchAllData(
          "permitDrawings",
          "permitDrawings",
          "permitId"
        );

        break;
      case "fleets":
        result = await fetchAllData("fleet", "fleet", "fleetId");

        break;
      case "billedServices":
        result = await generateNotInvoicedPaid();

        break;
      case "editLogs":
        result = await fetchAllData("editLogs", "editLogs", "logId", () => {}, {
          ProjectionExpression: [
            "logId",
            "updatedAt",
            "label",
            "actionType",
            "nameOfUser",
            "topic",
            "category",
          ],
        });

        break;
      case "activity":
        result = await fetchAllData(
          "fleetActivity",
          "fleetActivity",
          "activityId"
        );

        break;
      case "violations":
        result = await fetchAllData(
          "fleetViolations",
          "fleetViolations",
          "violationId"
        );

        break;
      case "dispatching":
        result = await fetchAllData(
          "formRequests",
          "formRequests",
          "formRequestId"
        );

        break;
      case "approvals":
        result = await fetchAllData(
          "formRequests",
          "formRequests",
          "formRequestId"
        );

        break;
      case "safetyPropertyDamage":
        result = recordId
          ? await fetchData("safety", `safety/${recordId}`).then((res) => [res])
          : await filterTables(
              "safety",
              "safetyApplicationCategory",
              "Property Damage"
            );

        break;
      case "safetyVehicleDamage":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Vehicle Damage"
        );

        break;
      case "safetyPersonalInjury":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Personal Injury"
        );

        break;
      case "safetyOtherTradeIncident":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Other Trade Incident"
        );

        break;
      case "incidentsPropertyDamage":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Property Damage"
        );
        break;
      case "incidentsVehicleDamage":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Vehicle Damage"
        );

        break;
      case "incidentsPersonalInjury":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Personal Injury"
        );

        break;
      case "incidentsOtherTradeIncident":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Other Trade Incident"
        );

        break;
      case "maintenance":
        result = await fetchAllData(
          "fleetMaintenance",
          "fleetMaintenance",
          "maintenanceId"
        );

        break;
      case "tasksManager":
        result = await fetchAllData(
          "tasksManagement",
          "tasksManagement",
          "taskId"
        );

        break;
      case "inspectionsView/:id":
        result = (
          await fetchAllData("inspections", "inspections", "inspectionId")
        ).map((el) => ({
          ...el,
          serviceLabels: el.inspectionReport
            .map((el) => el.serviceType)
            .join(", "),
        }));

        //is same with "inspectionsView/:id"
        break;
      case "urgentCaresNearby":
        {
          result = await getHospitalsNearby(recordId);
        }
        break;
      case "inspections": {
        const inspections = await fetchAllData(
          "inspections",
          "inspections",
          "inspectionId"
        );

        result =
          inspections?.map((inspection) => ({
            ...inspection,
            serviceLabels: (inspection?.inspectionReport || [])
              ?.map((report) => report?.serviceType || "")
              ?.join(", "),
          })) || [];

        break;
      }

      case "projectOverview":
        result = await projectsOverview();

        break;
      case "invoicesOpenBalance":
        result = await retrieveInvoices();
        break;

      case "invoices":
        result = await fetchAllData("invoices", "invoices", "invoiceId");
        break;

      case "payments":
        result = await fetchAllData("payments", "payments", "paymentId");
        break;

      case "parameters":
        result = await programFieldsParameters();
        break;
      case "invitationCodes":
        result = await codeInvitations();

        break;
      case "users":
        result = await allUsers();

        break;
      case "geoFencesActiveActivities":
        result = await geoFencesActiveActivities();

        break;
      case "fleetDocuments":
        result = await fleetDocuments();

        break;
      case "fleetInspectionCompletion":
        result = await groupAndInspectionsByActivityId();

        break;
      case "fleetRepairLog":
        result = await getFleetRepairWorkOrders();

        break;
      case "fleetExpenses":
        result = await getFleetExpenses();

        break;
      case "workOrders":
        result = await getFleetWorkOrders();

        break;
      case "requisitions":
        result = await modifyAndSortApplications();

        break;
      case "creditMemoServices":
        result = await getCreditMemoServices();

        break;
      case "chargesOverview":
        result = await getChargesOverview();

        break;
      case "takeOffComparison":
        result = await getTakeOffComparisonWithAccounting();

        break;
      case "rentalsComparison":
        result = await getRentalComparisonWithAccounting();

        break;
      case "accountingComparison":
        result = await getAccountingComparisonPerProject();

        break;
      case "scheduleOfValuesComparison":
        result = await getSOVComparisonWithAccounting();

        break;
      case "salesReportApprovedEstimates":
        result = await getSalesReportApprovedEstimates(); //same

        break;
      case "salesReportEstimationByService":
        result = await getSalesReportApprovedEstimates(); //same

        break;
      case "takeOffServiceComparison":
        result = await getTakeOffServicesAndCompare();

        break;
      case "estimationServices":
        result = await getEstimationServices();

        break;
      case "rentalServices":
        result = await getRentalServices();

        break;
      case "scheduleTotalProgress":
        result = await getSchedulingTotalProgress();

        break;
      case "scheduleTotalProgressWithCharges":
        result = await getSchedulingTotalProgressWithCharges();

        break;
      case "schedulingServices":
        result = await getSchedulingServices();

        break;
      case "geoFencesActivities":
        result = await getGeoFencesActivities();

        break;
      case "geoFencesActiveActivities":
        result = await getGeoFencesActiveActivities();

        break;
      case "driverStatistics":
        result = await getDriverStatistics();

        break;
      case "fleetDispatching":
        result = await getDispatchesByActivities();

        break;
      case "forecastRentals":
        result = await getForecastingRentalsPerService();

        break;
      case "projectGeofences":
        result = await filteredCategoriesWithGeofence(dataSourceName);

        break;
      case "opportunitiesGeofences":
        result = await filteredCategoriesWithGeofence(dataSourceName);

        break;
      case "bills":
        result = await getBills();

        break;
      case "projectDetails":
        result = await projectDetails(recordId);

        break;
      case "opportunityDetails":
        result = await opportunityDetails(recordId);

        break;
      case "safetyInspections":
        result = []; //hereeeeeeeeeeee

      default:
        result = recordId
          ? await fetchData(
              dataSourceName,
              `${dataSourceName}/${recordId}`
            ).then((res) => [res])
          : await fetchData(dataSourceName).then((res) =>
              Array.isArray(res) ? res : res?.[dataSourceName] || []
            );
        break;
    }
    console.log({ [dataSourceName]: result });
    return result;
  } catch (error) {
    console.error("dataSourceReportsFetch error:", error);
    throw error;
  }
};
