const configPaths = {
  dev: "http://10.0.0.84:8080",
  // dev: "https://leadmanager-express-backend.ue.r.appspot.com",
  prod: "https://leadmanager-express-backend.ue.r.appspot.com",
};

const proposalPathConfig =
  process.env.NODE_ENV === "production" ? configPaths.prod : configPaths.dev;

export default proposalPathConfig;
