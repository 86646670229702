import { Spin, Modal, Select, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { antdModalProps } from "../../../../../../utils/antdModalProps";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components/index";
import { Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src/index";
import { MondayButton } from "../../../../../commonComponents/index";
import NewRentals from "../../../../../SidebarPages/Projects/Accounting/Rentals/RentalsTab/NewRentals/NewRentals";
import NewScheduleOfValue from "../../../../../SidebarPages/Projects/Accounting/ScheduleOfValues/ScheduleOfValueTab/NewScheduleOfValue/NewScheduleOfValue";
import NewApplication from "../../../../../SidebarPages/Projects/Accounting/Applications/ApplicationsTab/NewApplication/NewApplication";
import NewChargeModal from "./Charges/NewChargeModal";
import InvoiceModal from "../../../../../SidebarPages/Accounting/components/CreateInvoice/InvoiceModal";
import { NewPayment } from "../../../../../SidebarPages/Accounting/Tabs/Payments/components/index";
import { fetchAllData } from "../../../../../SidebarPages/Fleet/utils/fetchAllData";
import { updateDocumentTitle } from "../../../../../../utils";
import { useSelector } from "react-redux";

//used just for charges
const categories = ["Rental", "Estimation", "Requisition", "SOV"];
const chargeTypes = ["Charge", "Credit Memo"];

const NewAccountingShortcut = ({
  setSelectedAccounting,
  selectedAccounting,
  getBodyToSaveInAutomation, //this is a function used in Automations to get body for api to save it later automatically
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [projects, setProjects] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [showAccountingModal, setShowAccountingModal] = useState(false);
  //states used for charges & credit
  const [selectedCat, setSelectedCat] = useState();
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    fetchAllData("projects", "projects", "projectId").then((res) =>
      setProjects(
        res.filter(({ projectStatus }) => projectStatus !== "Canceled")
      )
    );
  }, []);

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";

    setShowAccountingModal(false);
    setSelectedAccounting(false);
  };

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          title: `New ${selectedAccounting}`,
          className: `new-accounting-project-modal ${
            isDarkMode && "new-accounting-project-modal-dark"
          }`,
          afterOpenChange: (event) => {
            event &&
              updateDocumentTitle({ newTitle: `New ${selectedAccounting}` });
          },
          open: selectedAccounting,
          onCancel: closeModal,
        }}
      >
        <div className="accounting-project-modal">
          {projects ? (
            <>
              <InputComponent
                required
                showSearch
                label="Projects"
                dropdownClassName={isDarkMode && "darkDropDown"}
                placeholder="Select one Project"
                type="select"
                dataTestid="projects"
                options={projects?.map((project, index) => (
                  <Select.Option
                    key={index}
                    value={project?.projectName}
                    obj={project}
                  >
                    {project.projectName}
                  </Select.Option>
                ))}
                onSelect={(value, option) =>
                  value && setSelectedProject(option?.obj)
                }
                onClear={() => setSelectedProject()}
              />

              {/* For Charges we need two more inputs */}
              {selectedAccounting === "Charges" && (
                <>
                  <InputComponent
                    required
                    showSearch
                    label="Category"
                    placeholder="Category from"
                    dataTestid="category"
                    dropdownClassName={isDarkMode && "darkDropDown"}
                    type="select"
                    options={categories?.map((cat, index) => (
                      <Select.Option key={index} value={cat}>
                        {cat}
                      </Select.Option>
                    ))}
                    onSelect={(e) => setSelectedCat(e)}
                    onClear={() => setSelectedCat()}
                  />
                  <InputComponent
                    required
                    showSearch
                    label="Charge Type"
                    placeholder="Charge Type"
                    dataTestid="charge"
                    type="select"
                    dropdownClassName={isDarkMode && "darkDropDown"}
                    options={chargeTypes?.map((type, index) => (
                      <Select.Option key={index} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                    onSelect={(e) => setSelectedType(e)}
                    onClear={() => setSelectedType()}
                  />
                </>
              )}

              <div className="modalFooter">
                <MondayButton
                  {...{
                    Icon: <Tick width={19} height={19} />,
                    onClick: () => setShowAccountingModal(true),
                    className: `${
                      !selectedProject ||
                      (selectedAccounting === "Charges"
                        ? !selectedCat || !selectedType
                        : false)
                        ? "disabled"
                        : "enabled"
                    }`,
                    disabled:
                      !selectedProject ||
                      (selectedAccounting === "Charges"
                        ? !selectedCat || !selectedType
                        : false),
                  }}
                >
                  Next
                </MondayButton>
              </div>
            </>
          ) : (
            <Tooltip title="Fetching projects...">
              <Spin style={{ margin: "35px" }} size="large" />
            </Tooltip>
          )}
        </div>
      </Modal>
      {showAccountingModal && selectedAccounting === "SOV" ? (
        <NewScheduleOfValue
          visible={showAccountingModal}
          closeModal={closeModal}
          projectId={selectedProject?.projectId}
          includedAlreadyServices={false}
        />
      ) : showAccountingModal && selectedAccounting === "Application" ? (
        <NewApplication
          visible={showAccountingModal}
          closeModal={closeModal}
          projectId={selectedProject?.projectId}
        />
      ) : showAccountingModal && selectedAccounting === "Rentals" ? (
        <NewRentals
          visible={showAccountingModal}
          handleCloseForm={closeModal}
          projectId={selectedProject?.projectId}
          getBodyToSaveInAutomation={getBodyToSaveInAutomation}
        />
      ) : showAccountingModal && selectedAccounting === "Charges" ? (
        <NewChargeModal
          projectData={selectedProject}
          selectedCat={selectedCat}
          selectedType={selectedType}
          closeModal={closeModal}
          getBodyToSaveInAutomation={getBodyToSaveInAutomation}
        />
      ) : showAccountingModal && selectedAccounting === "Invoices" ? (
        <InvoiceModal
          projectData={selectedProject}
          handleExit={closeModal}
          getBodyToSaveInAutomation={getBodyToSaveInAutomation}
        />
      ) : showAccountingModal && selectedAccounting === "Payments" ? (
        <NewPayment
          showCreatePaymentModal={showAccountingModal}
          setShowCreatePaymentModal={closeModal}
          projectData={selectedProject}
          getBodyToSaveInAutomation={getBodyToSaveInAutomation}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default NewAccountingShortcut;
