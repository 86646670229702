import dayjs from "dayjs";

import { COST_ROLES } from "./cellFunctions";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";

dayjs.extend(weekOfYear);

function getWeeksTotal({
  workerList = [],
  allEntries = {},
  employeeOverheadShifts = {},
}) {
  let hoursPerDay = structuredClone(allEntries);
  let overheadHoursPerDay = structuredClone(employeeOverheadShifts);
  let weeksForWorker = {};

  for (const employeeId of workerList) {
    weeksForWorker[employeeId] = {};
    let salaryType = "Hourly";
    let role = undefined;
    let weeksWorked = _.groupBy(
      Object.keys(hoursPerDay?.[employeeId] || {}),
      (key) => dayjs(key, "MM/DD/YYYY").week()
    );
    let overheadWeeksWorked = _.groupBy(
      Object.keys(overheadHoursPerDay?.[employeeId] || {}),
      (key) => (key !== "NoDate" ? dayjs(key, "MM/DD/YYYY").week() : "NoDate")
    );

    weeksForWorker[employeeId] = weeksWorked;

    for (const weekIndex in weeksWorked) {
      let weekEntries = weeksWorked[weekIndex];
      let totalWorkHours = 0;
      let totalRegAmount = 0;
      let otHoursWeekly = 0;
      let otAmountWeekly = 0;
      let totalRegHours = 0;
      let totalOvhHours = 0;
      let totalOvhAmount = 0;
      let weekTypesOvertime = 0;
      let weeksTypeOvertimeHours = 0;
      let reimbursementTotalAmount = 0;

      let weeklyEntries = [];

      if (overheadWeeksWorked?.[weekIndex]?.length) {
        for (const entry of weekIndex) {
          totalOvhAmount += entry.totalOvh;
        }
      }

      for (let k = 0; k < weekEntries.length; k++) {
        let day = weekEntries[k];
        let dayShift = hoursPerDay[employeeId][day];

        for (const entry of dayShift["jobsitesInfo"]) {
          let overheadCondition = false;

          if (
            entry?.hrShift ||
            !entry.inJobsiteId ||
            COST_ROLES.includes(entry?.["employeeRole"])
          ) {
            overheadCondition = true;
          }

          salaryType = entry?.salaryType;
          role = entry["employeeRole"];

          if (!overheadCondition) {
            totalWorkHours =
              totalWorkHours + entry["overtimeHours"] + entry["workHours"];
            totalRegAmount = totalRegAmount + entry["regAmount"];
            totalRegHours = totalRegHours + entry["workHours"];
          } else {
            totalOvhHours =
              totalOvhHours + entry["overtimeHours"] + entry["workHours"];
            totalOvhAmount = totalOvhAmount + entry["regAmount"];
          }

          /**
           * Check if employee have worked on 2 reimbursement jobsites
           * that are close to each other on the same day and get reimbursement
           * only from the first jobsite
           */
          if (entry?.reimbursement) {
            const reimbursementShiftJobs = dayShift?.["jobsitesInfo"].filter(
              ({ reimbursement }) => reimbursement
            );

            const earliestJobWork = reimbursementShiftJobs.sort(
              (a, b) => a?.firstClockIn - b?.firstClockIn
            )?.[0];

            if (earliestJobWork?.entries?.[0] === entry?.entries?.[0]) {
              reimbursementTotalAmount =
                reimbursementTotalAmount + entry["employeeRate"] * 3;
            } else if (
              withinRadius(
                entry.punchCoordinates,
                earliestJobWork.punchCoordinates,
                5280
              ).withinRange
            ) {
              reimbursementTotalAmount =
                reimbursementTotalAmount + entry["employeeRate"] * 3;
            }
          }

          if (salaryType === "Salary") {
            continue;
          }

          //if the amount is of a weekly type payroll, we want to make separate calculations
          //in this case, the overtime hours are calculated based on the entire week
          if (
            entry?.payrollType === "Open Shop" ||
            entry?.payrollType === "Certified Payroll"
          ) {
            weeklyEntries.push({ ...entry, isOvh: overheadCondition });
            continue;
          }

          //in other cases, we want to keep the calculations from the daily amounts
          if (!overheadCondition) {
            otHoursWeekly = otHoursWeekly + entry["overtimeHours"];
            otAmountWeekly = otAmountWeekly + entry["otAmount"];
          } else {
            // totalOvhHours = totalOvhHours + entry["overtimeHours"];
            totalOvhAmount = totalOvhAmount + entry["otAmount"];
          }
        }
      }
      if (weeklyEntries?.length) {
        let weeklyRegHours = weeklyEntries?.reduce(
          (acc, val) => acc + val?.workHours + val?.overtimeHours,
          0
        );
        
        let weeklyOvhHours = weeklyEntries?.reduce(
          (acc, val) =>
            acc + (val?.isOvh ? val?.workHours + val?.overtimeHours : 0),
          0
        );

        if (weeklyRegHours > 40) {
          let overtime = weeklyRegHours - 40;
          let amount = weeklyEntries?.reduce(
            (acc, val) => acc + (!val?.isOvh ? val?.otAmount : 0),
            0
          );
          otAmountWeekly = otAmountWeekly + amount;
          otHoursWeekly = otHoursWeekly + overtime;

          weekTypesOvertime = weekTypesOvertime + otAmountWeekly;
          weeksTypeOvertimeHours = weeksTypeOvertimeHours + overtime;
        }

        if (weeklyOvhHours > 40) {
          let amount = weeklyEntries?.reduce(
            (acc, val) => acc + (val?.isOvh ? val?.otAmount : 0),
            0
          );
          let overtime = weeklyOvhHours - 40;
          totalOvhAmount = totalOvhAmount + amount;
          totalOvhHours = totalOvhHours + overtime;
        }
      }

      weeksForWorker[employeeId][weekIndex] = {
        days: weeksWorked[weekIndex],
        otHoursWeekly,
        otAmountWeekly,
        totalWorkHours,
        totalRegAmount,
        totalRegHours,
        employeeRole: role,
        totalOvhAmount,
        totalOvhHours,
        weekTypesOvertime,
        weeksTypeOvertimeHours,
        reimbursementTotalAmount,
      };
    }

    for (const weekIndex in overheadWeeksWorked) {
      let weekEntries = overheadWeeksWorked[weekIndex];
      let totalWorkHours = 0;
      let totalRegAmount = 0;
      let otHoursWeekly = 0;
      let otAmountWeekly = 0;
      let totalRegHours = 0;
      let totalOvhHours = 0;
      let totalOvhAmount = 0;
      let weekTypesOvertime = 0;
      let weeksTypeOvertimeHours = 0;
      let reimbursementTotalAmount = 0;

      if (weekIndex === "NoDate") {
        continue;
      }

      if (overheadWeeksWorked?.[weekIndex]?.length) {
        for (const day of weekEntries) {
          const totalOvh = overheadHoursPerDay?.[employeeId]?.[day];

          totalOvhAmount += totalOvh;
        }
      }

      if (weeksForWorker?.[employeeId]?.[weekIndex]) {
        const existingWeek = weeksForWorker[employeeId][weekIndex];
        Object.assign(existingWeek, {
          totalOvhAmount: (existingWeek?.totalOvhAmount || 0) + totalOvhAmount,
        });
      } else {
        weeksForWorker[employeeId][weekIndex] = {
          days: overheadWeeksWorked[weekIndex],
          otHoursWeekly,
          otAmountWeekly,
          totalWorkHours,
          totalRegAmount,
          totalRegHours,
          employeeRole: "",
          totalOvhAmount,
          totalOvhHours,
          weekTypesOvertime,
          weeksTypeOvertimeHours,
          reimbursementTotalAmount,
        };
      }
    }
  }

  return weeksForWorker;
}

export default getWeeksTotal;
