import { Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../commonComponents";
import { DeleteIcon } from "../../../../DynamicView/src";
import { useAttachments } from "../../EmailBox/providers/AttachmentsProvider";
import ReplyEmailBoxFooterToolbar from "./toolbar/ReplyEmailBoxFooterToolbar";

import "./reply-email-box-footer.scss";

function ReplyEmailBoxFooter({
  onSaveEmail,
  loading,
  documentsActive,
  setDocumentsActive,
  rowData,
  recordDetails,
  setIsActive,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { attachments } = useAttachments();

  return (
    <div className={`email-box-footer ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="left">
        <MondayButton
          {...{ className: "send-email-button", onClick: onSaveEmail }}
          loading={loading}
        >
          Send
        </MondayButton>

        <ReplyEmailBoxFooterToolbar
          rowData={rowData}
          recordDetails={recordDetails}
        />
      </div>

      <div className="right">
        {attachments?.length !== 0 && (
          <span
            onClick={() => setDocumentsActive(!documentsActive)}
            className="documentsStyle"
          >{`${attachments?.length} document${
            attachments?.length === 1 ? "" : "s"
          }`}</span>
        )}

        <Tooltip
          title="Remove"
          onClick={() => {
            setIsActive("");
          }}
        >
          <span className="removeIcon">
            <DeleteIcon />
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default ReplyEmailBoxFooter;
