import { useState } from "react";
import { MondayButtonIcon } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import {
  SearchIcon,
  XIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { UserIcon } from ".";
import TableTemplate from "./TableTemplate/TableTemplate";
import { UsernameRenderer } from "../../../../AllUsers/components";
import { useSelector } from "react-redux";

function RoleUsersList({ allUsers = [] }) {
  const [searchVal, setSearchVal] = useState("");

  return (
    <div className="users-list roles-list">
      <div className="dep-list-header">
        <UserIcon /> <span>{allUsers.length + " Users in total"} </span>
      </div>
      <div className="roles-list-search">
        <InputComponent
          {...{
            placeholder: "Search User...",
            value: searchVal,
            onChange: (e) => setSearchVal(e.target.value),
            prefix: <SearchIcon />,
            noFormItem: true,
          }}
        />
        {searchVal && (
          <MondayButtonIcon
            {...{
              Icon: <XIcon />,
              onClick: () => {
                setSearchVal("");
              },
              name: "Show All",
              className: "mondayButtonRed",
            }}
          />
        )}
      </div>
      <TableTemplate
        {...{
          data: allUsers.filter((user) =>
            user.nameOfUser.toLowerCase().includes(searchVal.toLowerCase())
          ),
          header: [
            { name: "Users", flex: 1.3 },
            { name: "Role", flex: 1 },
            { name: "Department", flex: 1 },
          ],
          className: "users-list-table",
          renderItem: (user, index) => {
            const {
              nameOfUser = "",
              groupName = "",
              departmentName = "",
            } = user;
            return (
              <>
                <UsernameRenderer
                  {...{
                    data: user,
                    value: nameOfUser,
                    onlyName: true,
                    className: "role-users username",
                  }}
                />
                <span style={{ flex: 1 }}>{groupName}</span>
                <span style={{ flex: 1 }}>{departmentName}</span>
              </>
            );
          },
        }}
      />
    </div>
  );
}

export default RoleUsersList;
