import { mapTagToData } from "../../../../../../../ProposalBuilder/components/ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";
import {
  getServiceContent,
  getTemplateContent,
} from "../../../../../../../ProposalBuilder/components/ProposalSidebar/helpers/fetchProposalTemplates";

function bracketsToTextProposal({
  estimationSample,
  templateContent,
  editorRef,
  item,
  serviceToPreview,
  type,
  serviceTemplates,
}) {
  if (!estimationSample?.versionServices) return;
  const takeOffId = Object.keys(estimationSample?.versionServices || {})[0];
  const data = {
    takeOffId,
    estimation: estimationSample,
    takeOff: estimationSample?.versionServices[takeOffId],
  };
  let tmpContent = templateContent;
  if (editorRef?.current) {
    tmpContent = editorRef.current.component.value;
  }
  let html = templateContent;
  if (!serviceToPreview) {
    const tmpItem = { ...item, templateContent: tmpContent };
    console.log("tmpItem:", { tmpItem });
    if (type === "generalTemplate") {
      html = getTemplateContent(tmpItem || "", serviceTemplates);
    } else {
      html = getServiceContent(tmpItem);
    }
    const wrappedHTML = `<div>${html}</div>`;

    const dataForPreview = mapTagToData({
      proposalData: data,
      html: wrappedHTML,
    });
    //   setPreview(dataForPreview);
    return dataForPreview;
  } else {
    const tmpItem = {
      ...item,
      templateContent: tmpContent,
      additionalDetails: {
        service: {
          workName: serviceToPreview,
        },
      },
    };
    html = getServiceContent(tmpItem);
    const wrappedHTML = `<div>${html}</div>`;
    const dataForPreview = mapTagToData({
      proposalData: data,
      html: wrappedHTML,
    });
    return dataForPreview;
    //   setPreview(dataForPreview);
  }
}

export default bracketsToTextProposal;
