/**
 * Groups users and roles by their respective departments.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.userGroups - An array of user group objects, each containing `groupName` and `departmentName`.
 * @param {Array} params.allUsers - An array of user objects, each containing user details including `departmentName`.
 * @param {Array} params.departments - An array of department objects, each containing `departmentName`.
 * @returns {Array} An array of department objects, each containing `departmentName`, `roles`, and `users`.
 */
function groupDepartments({
  userGroups = [],
  allUsers = [],
  departments = [],
}) {
  const groupedByDepartment = {};

  userGroups.forEach(({ groupName, departmentName }) => {
    if (!groupedByDepartment[departmentName]) {
      groupedByDepartment[departmentName] = {
        departmentName,
        roles: [],
        users: [],
      };
    }
    if (!groupedByDepartment[departmentName].roles.includes(groupName)) {
      groupedByDepartment[departmentName].roles.push(groupName);
    }
  });

  allUsers.forEach(({ departmentName, ...rest }) => {
    const {
      nameOfUser,
      groupName,
      userName,
      identityId,
      activeSessions,
      googleDriveFileId,
      isSuspended,
      lastSeen,
      cognitoUserId,
    } = rest;
    const user = {
      departmentName,
      nameOfUser,
      groupName,
      identityId,
      activeSessions,
      googleDriveFileId,
      isSuspended,
      lastSeen,
      userName,
      cognitoUserId,
    };
    if (groupedByDepartment[departmentName]) {
      groupedByDepartment[departmentName].users.push(user);
    }
  });

  const result = Object.values(groupedByDepartment);
  if (
    departments.some(
      ({ departmentName: depName }) =>
        !result.find(({ departmentName }) => departmentName === depName)
    )
  ) {
    departments.forEach(({ departmentName }) => {
      if (
        !result.find(
          ({ departmentName: depName }) => departmentName === depName
        )
      ) {
        result.push({
          departmentName,
          roles: [],
          users: [],
        });
      }
    });
  }

  return result;
}

export default groupDepartments;
