import { message } from "antd";
import Swal from "sweetalert2";
import stepsConfig from "./stepsConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useMemo } from "react";
import { filterTables } from "../../../../utils";
import { useRedux } from "../../../SidebarPages/hooks";
import { TickIcon } from "../../../InternalChat/assets/icons";
import { LeftArrow } from "../../../SidebarPages/BasePage/src";
import { MondayButton, Stepper } from "../../../commonComponents";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { InfoText } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { proposalPostHandler } from "../../../SidebarPages/Estimations/components/ProposalBuilder/utils/requests";

import "./ProposalStepper.scss";

function ProposalStepper({ proposalType, onCancel }) {
  const INITIAL_STEPPER_DATA = {
    sourceType: proposalType,
    project_or_opp: proposalType,
    selectedId: undefined,
    selectLabel: undefined,
    applicableEstimations: undefined,
    selectedEstimation: undefined,
    takeoff: undefined,
    versionServices: undefined,
    finalObject: undefined,
  };
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const navigate = useNavigate();

  const [current, setCurrent] = useState(1);
  const [stepperData, setStepperData] = useState(INITIAL_STEPPER_DATA);
  const [proposalFeedData, setProposalFeedData] = useRedux(
    "proposalFeedData",
    {
      projects: [],
      opportunities: [],
      loading: false,
    },
    false
  );

  //region 2nd Step
  const onSelectDataSrc = async (value, option) => {
    setStepperData((prev) => ({
      ...prev,
      selectedId: value?.key,
      selectLabel: value?.label,
    }));
    message.loading({
      content: "Getting estimations",
      key: "fetchingEstimations",
    });
    await filterTables(
      "estimations",
      `${proposalType !== "Opportunity" ? "projectId" : "opportunityId"}`,
      value.key
    ).then((estimations) => {
      const filteredEstimations = estimations?.filter((el) => {
        return Object.keys(el?.versionServices ?? {})?.length > 0;
      });
      if (!filteredEstimations?.length) {
        message.error({
          content: `No estimations found for ${value?.label}`,
          key: "fetchingEstimations",
        });
      } else {
        message.success({
          content: `${filteredEstimations?.length} estimations found for ${value?.label}`,
          key: "fetchingEstimations",
        });
      }
      setStepperData((prev) => ({
        ...prev,
        applicableEstimations: filteredEstimations || [],
      }));
    });
  };

  //region 3rd Step
  const onSelectEstimation = (selectedEstimation) => {
    if (!Object.keys(selectedEstimation?.versionServices ?? {})?.length) {
      return message.error("No take off versions found for this estimation");
    }
    setStepperData((prev) => ({
      ...prev,
      selectedEstimation: selectedEstimation?.value,
      versionServices: selectedEstimation?.versionServices,
      estimationTakeOff: selectedEstimation?.estimationTakeOff,
    }));
  };

  // Called after leaving the view

  //region onCancel
  const onHousekeeping = () => {
    setStepperData(INITIAL_STEPPER_DATA);
    setProposalFeedData({
      projects: [],
      opportunities: [],
      loading: false,
    });
    setCurrent(0);
    onCancel();
  };

  //region 4th Step
  const createProposalTakeOff = async (item) => {
    let PROPOSAL_DATA = {
      estimationId: item?.estimation?.estimationId,
      takeOffVersion: item?.takeOffItem?.id,
      proposalStatus: "Draft",
      projectId: item?.estimation?.projectId ?? item?.estimation?.opportunityId,
      savedContent: "",
      proposalId: "4343435353s",
      otherDetails: {
        title: `${item?.takeOffItem?.versionName || ""}`,
        jobSiteAddress: `${item?.estimation?.jobSiteAddress || ""}`,
        createdAt: new Date(),
        createdBy: userConfiguration?.nameOfUser || "Unknown",
        proposalType,
      },
      // otherDetails: {},
    };
    setStepperData((prev) => ({
      ...prev,
      takeoff: item,
      finalObject: PROPOSAL_DATA,
    }));
  };

  //region onFinish
  const onFinish = async () => {
    if (!stepperData?.takeoff) {
      return message.error("Takeoff not selected");
    }
    await proposalPostHandler({ proposalData: stepperData.finalObject }).then(
      async (res) => {
        Swal.fire({
          title: "Success!",
          text: "Proposal Created Successfully",
          icon: "success",
          confirmButtonText: "Ok",
          timer: 2000,
        });
        console.log("res", res);
        navigate(
          `/new-proposal-builder?tab=Take-Off&takeOffId=${stepperData?.takeoff?.key}&proposalId=${res?.proposalId}`
        );
      }
    );
  };

  //region steps
  const steps = useMemo(() => {
    return stepsConfig({
      stepperData,
      proposalType,
      onSelectDataSrc,
      onSelectEstimation,
      createProposalTakeOff,
    });
  }, [
    stepperData,
    proposalType,
    onSelectDataSrc,
    onSelectEstimation,
    createProposalTakeOff,
  ]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  //region nextDisabled
  const nextDisabled = useMemo(() => {
    if (current === 0) {
      return !stepperData?.sourceType;
    }
    if (current === 1) {
      return (
        !stepperData?.selectedId ||
        (stepperData?.applicableEstimations || [])?.length === 0
      );
    }
    if (current === 2) {
      return !Object.keys(stepperData?.versionServices ?? {})?.length;
    }
    if (current === 3) {
      return !stepperData?.takeoff;
    }
  }, [current, stepperData]);

  //region items
  const items = useMemo(
    () =>
      steps.map((item, i) => ({
        key: item.title,
        title: item.title,
      })),
    [steps]
  );

  const infoText = useMemo(() => {
    if (current === 1) {
      return (
        <span>
          Select a <strong>{proposalType}</strong> to create a proposal, then
          proceed to pick an estimation and finalize by selecting a take-off
          from the chosen estimation.
        </span>
      );
    }
    if (current === 2) {
      return (
        <span>
          Select an estimation from <strong>{stepperData?.selectLabel}</strong>,
          then proceed to pick a take-off from the chosen estimation.
        </span>
      );
    }
    if (current === 3) {
      return (
        <span>
          Select a take-off from <strong>{stepperData?.selectLabel}</strong>,
          then click “Create” to start working concretely on the new proposal.
        </span>
      );
    }
  }, [current, stepperData]);

  return (
    <>
      <InfoText
        {...{
          text: infoText,
          style: { maxWidth: "unset" },
        }}
      />
      <div className="antCustomStepper">
        <Stepper
          current={current}
          steps={items}
          stepRenderer={false}
          type="navigation"
          componentContainerClassName="proposalBuilderWrapper"
        />
      </div>
      <div className="antStepperHolder">{steps?.[current]?.content}</div>
      <div className="footer-actions">
        <MondayButton
          {...{
            className: "mondayButtonGrey",
            onClick: () => {
              onHousekeeping();
            },
            Icon: <XIcon />,
          }}
        >
          Cancel
        </MondayButton>
        <span className="footer-actions-right">
          {current > 1 && (
            <MondayButton
              className="mondayButtonBlue"
              Icon={<LeftArrow />}
              onClick={() => prev()}
            >
              Previous
            </MondayButton>
          )}{" "}
          {current > 0 && current < steps.length - 1 && (
            <MondayButton
              Icon={<LeftArrow style={{ transform: "rotate(180deg)" }} />}
              className="mondayButtonBlue"
              disabled={nextDisabled}
              type="primary"
              onClick={() => next()}
            >
              Next
            </MondayButton>
          )}
          {current === steps.length - 1 && (
            <MondayButton
              Icon={<TickIcon />}
              className="mondayButtonGreen"
              disabled={nextDisabled}
              onClick={onFinish}
            >
              Create
            </MondayButton>
          )}
        </span>
      </div>
    </>
  );
}

export default ProposalStepper;
