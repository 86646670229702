import { Form, Button } from "antd";
// import { Editor } from "@tinymce/tinymce-react";
import "./RichText.scss";
import ReactQuill from "react-quill";

const RichText = ({
  form,
  formItemName,
  initialValue,
  disabled = false,
  setValue,
  rules = [],
  onChange,
  value,
  placeholder,
}) => {
  const preserveSpaces = (html) => html?.replace(/ /g, "&nbsp;");

  return (
    <div className="richTextComp">
      {form && (
        <Form.Item
          name={formItemName}
          rules={rules}
          initialValue={initialValue}
        />
      )}
      <ReactQuill
        disabled={disabled}
        initialValue={preserveSpaces(initialValue)}
        value={preserveSpaces(value) || preserveSpaces(initialValue)}
        placeholder={placeholder}
        onChange={(e) => {
          return form
            ? form.setFieldsValue({ [formItemName]: e })
            : setValue(e);
        }}
      />
    </div>
  );
};
export default RichText;
