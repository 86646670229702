import JoditEditor, { Jodit } from "jodit-react";
import React, { useState, forwardRef, useMemo, useCallback } from "react";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";
import { clockIcon, pageBreakIcon, todayIcon } from "./ToolbarCustomIcons";
// import "jodit/src/plugins/mobile";
// import { uniq, uniqBy } from "lodash";
// function preparePaste(jodit) {
//   jodit.e.on(
//     "paste",
//     (e) => {
//       if (confirm("Change pasted content?")) {
//         jodit.e.stopPropagation("paste");
//         jodit.s.insertHTML("<p>Changed content</p>");
//         return false;
//       }
//     },
//     { top: true }
//   );
// }

// Jodit.plugins.add("preparePaste", preparePaste);

const JoditEditorCustom = forwardRef((props, ref) => {
  const { proposalData, setProposalData } = useProposalContext();
  const [content, setContent] = useState(proposalData?.savedContent ?? "");

  // const onInsert = () => {
  //   console.log("editor", ref);

  //   if (ref.current) {
  //     // Get Jodit editor instance
  //     // const editor = ref.current;
  //     console.log("editor", ref);
  //     // Insert HTMfL content programmatically
  //     ref.current.component.selection.insertHTML(
  //       "<p>This is inserted HTML content</p>"
  //     );
  //   }
  // };

  const config = useMemo(() => {
    return {
      // theme: "dark",
      // Jodit Editor configuration options
      readonly: false, // Ensure it's set to false if you want to insert content
      toolbarButtonSize: "medium",
      // iframe: true,
      height: 720,
      allowResizeY: false,
      allowResizeX: false,
      showCharsCounter: false,
      toolbarAdaptive: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      enter: "BR",
      mode: "document",
      buttons: [
        ...Jodit.defaultOptions.buttons,
        {
          name: "Insert Page Break",
          tooltip: "Insert Page Break",
          icon: pageBreakIcon,
          exec: (editor) => {
            editor.s.insertHTML(
              `<div style="page-break-after: always; width: 100%; text-align: center; border: 1px dotted #000;background-image: linear-gradient(#9198e5,#9198e5);
                background-attachment: local; ">PAGE BREAK</div>`
            );
          },
        },
        {
          name: "Insert Date",
          icon: todayIcon,
          tooltip: "Insert current Date",
          exec: (editor) => {
            editor.s.insertHTML(new Date().toDateString() + " ");
          },
        },
        {
          name: "Insert Time",
          icon: clockIcon,
          tooltip: "Insert current Time",
          exec: (editor) => {
            editor.s.insertHTML(new Date().toLocaleTimeString() + " ");
          },
        },
      ],

      // Other configurations as needed
    };
  }, []);

  const handleChange = useCallback((newContent) => {
    // Process content to add borders to tables (if needed)
    const processedContent = newContent
      .replace(/border-color:/g, "border: 1px solid ")
      .replace(/<\/table><br>/g, "</table>")
      .replace(/<table([^>]*)>/g, removeTableMargin)
      .replace(/<hr>/g, "<hr style='margin-top: 10px;'>");

    setContent(processedContent);
  }, []);

  return (
    <div
      className="new_proposal_builder__body__editor-wrapper"
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      <JoditEditor
        ref={ref}
        theme="dark"
        value={content}
        config={config}
        onBlur={handleChange}
      />
    </div>
  );
});

export default JoditEditorCustom;

function removeTableMargin(match, id) {
  if (id.includes("style=")) {
    if (!/margin\s*:\s*0\s*;/.test(id)) {
      return match.replace(/style="([^"]*)"/, 'style="$1 margin:0;"');
    }
    return match;
  } else {
    return `<table${id} style="margin:0;">`;
  }
}

//do custom things with the editor
const wrapSelectedTextWithSpan = () => {
  const editorElement = document.querySelector(".jodit-wysiwyg");
  if (editorElement) {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.style.color = "red"; // Example style
      span.appendChild(range.extractContents());
      range.insertNode(span);
      selection.removeAllRanges();
      selection.addRange(range);

      // Update the editor's content
      const updatedContent = editorElement.innerHTML;
      // setContent(updatedContent);
      editorRef.current.value = updatedContent;
    }
  }
};

const addStylesToParentElementAtCursor = () => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const parentElement =
      range.startContainer.nodeType === 1
        ? range.startContainer
        : range.startContainer.parentNode;

    // Add styles to the parent element
    parentElement.style.backgroundColor = "yellow"; // Example style
    parentElement.style.border = "1px solid red"; // Example style

    // Update the editor's content
    const editorElement = document.querySelector(".jodit-wysiwyg");
    const updatedContent = editorElement.innerHTML;
    // setContent(updatedContent);
    editorRef.current.value = updatedContent;
  }
};
