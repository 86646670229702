import { useSelector } from "react-redux";
import { ForwardIconEmail } from "../assets";
import Reply from "../components/EmailView/reply/Reply";
import "./utilsStyle.scss";

const SingleEmailViewOptions = ({
  forwardClicked,
  isActive,
  isEditable,
  setIsActive,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={`singleEmailOptions ${isDarkMode ? "dark-mode" : ""}`}
      data-testid="singe-email-options"
    >
      <Reply
        isActive={isActive}
        isEditable={isEditable}
        setIsActive={setIsActive}
        label
      />
      <span
        className="optionsStyle"
        onClick={forwardClicked}
        data-testid="forward-icon"
      >
        <ForwardIconEmail />
        Forward
      </span>
    </div>
  );
};

export default SingleEmailViewOptions;
