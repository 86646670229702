import React from "react";

import { HistoryOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
  MondayButton,
  RowDataGridModal,
} from "src/components/commonComponents";
import { useSelector } from "react-redux";
import { useResponsive } from "../../../../../../hooks";

import "./communication-logs.scss";
import { fetchAllData } from "../../../../../../utils";

const CommunicationLogs = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const { recordData } = useSelector((state) => state.communication);
  const { mobile, tablet } = useResponsive();

  // Made logs specific for the currenct record

  const fetchLogs = () => {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
        filters: JSON.stringify([
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "category",
                value: "Communication",
                formula: "is",
              },
              {
                operator: "AND",
                column: "recordId",
                value: recordData.recordId,
                formula: "is",
              },
            ],
          },
        ]),
        keysToInclude: JSON.stringify([
          "logId",
          "cognitoUserId",
          "updatedAt",
          "actionType",
          "recordName",
          "nameOfUser",
          "topic",
          "category",
        ]),
      },
    }).then((response) => {
      setData(response);
    });
  };

  return (
    <div
      className={`communication-logs-container ${
        (mobile && "mobile") || (tablet && "mobile")
      }`}
    >
      <MondayButton
        containerClassName="monday-button-container"
        className="mondayButtonBlue"
        onClick={() => {
          fetchLogs();
          setVisible(true);
        }}
        Icon={<HistoryOutlined />}
        style={{ maxWidth: "32px !important" }}
      />

      {visible && (
        <RowDataGridModal
          {...{
            visible,
            setVisible,
            title: `Communication Logs!`,
            rowData: data || [],
            propColumnDefs: {
              columns: [
                {
                  field: "nameOfUser",
                  headerName: "User",
                },
                { field: "actionType", headerName: "ACTION" },
                { field: "updatedAt", headerName: "Date & Time" },
              ],
            },
          }}
        />
      )}
    </div>
  );
};

export default CommunicationLogs;
