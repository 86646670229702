import moment from "moment";
import { message } from "antd";
import capitalizeFirstLetter from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/polyfillers/capitalizeFirstLetter";
import { ProgressStatistics } from "../../../../../SidebarPages/Scheduling/helpers/totals";
import {
  breakInspProgress,
  progressInPerc,
  statusTextAgGrid,
} from "../../../Scheduling/SchedulingFirstPage/helperData";
import { getItemProgress } from "../../../../../SidebarPages/Scheduling/helpers/calculators";
// import { evaluateMathExpression2 } from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/formatters/evaluators";
// import { editableProgressCell, progressStatusCellClassRules, scheduleIDCellClassRules } from "./Styles";
// import { TypeOfWorkDefinitions } from "../../../../../SidebarPages/Scheduling/models/TypeOfWorkDefinitions";
export const daily_progress_valueGetter = (params, { d1, d2 }) => {
  const pli = params.data;
  let progress = 0;

  if (pli.subPli && pli.subPli.length > 0) {
    //if pli has bub-plis it we need the total of progress in subplis
    for (const subPli of pli.subPli) {
      progress += get_item_progress(subPli, { d1, d2 });
    }
  } else {
    progress = get_item_progress(pli, { d1, d2 }); //just the progress of that pli
  }
  return progress;
};

export const progressPercentage_valueGetter = (
  params,
  { d1, d2 },
  typeOfWork
) => {
  return progressInPerc(params?.data?.totalProgress, d1, d2, params?.data);
};

export const status_valueGetter = (
  params,
  { d1, d2 },
  typeOfWork,
  typeOfWorkType
) => {
  const { totalProgress, scheduled } = getPli_schedule_AND_totalProgress(
    params,
    { d1, d2 },
    typeOfWorkType
  );
  const totalPrg = progressPercentage_valueGetter(
    params,
    { d1, d2 },
    typeOfWorkType
  );

  if (isNaN(totalPrg)) {
    return statusTextAgGrid?.[typeOfWorkType]?.notStarted;
  } else if (totalPrg >= 1 && totalPrg < 100) {
    return statusTextAgGrid?.[typeOfWorkType]?.inProgress;
  } else if (Number(totalPrg) === Number(scheduled)) {
    return statusTextAgGrid?.[typeOfWorkType]?.finished;
  } else if (Number(totalPrg) === 100) {
    return statusTextAgGrid?.[typeOfWorkType]?.finished;
  } else if (totalPrg > scheduled) {
    return statusTextAgGrid?.[typeOfWorkType]?.over;
  } else {
    return statusTextAgGrid?.[typeOfWorkType]?.notStarted;
  }
};

export const get_item_status = (
  { scheduled, progress },
  { d1, d2 },
  typeOfWorkObject
) => {
  if (isNaN(progress) || progress < 1) {
    return typeOfWorkObject?.progressStatuses?.NOT_DONE;
  }
  if (progress >= 1 && progress < scheduled) {
    return typeOfWorkObject?.progressStatuses?.PARTIALLY_DONE;
  }
  if (progress === scheduled) {
    return typeOfWorkObject?.progressStatuses?.TOTAL_DONE;
  }
  if (progress > scheduled) {
    return typeOfWorkObject?.progressStatuses?.OVER_DONE;
  }
};

export const get_item_progress = (item, { d1, d2 }) => {
  if (d2) return progressFor2Dimensions_forItem(item, d1, d2);
  else progressForDimension_byItem(item, d1);
};

export const remaining_valueGetter = (params, { d1, d2 }) => {
  const { totalProgress, scheduled } = getPli_schedule_AND_totalProgress(
    params,
    { d1, d2 }
  );

  return Math.round(scheduled - totalProgress);
};

export const remainingPercentage_valueGetter = (params, { d1, d2 }) => {
  const { totalProgress, scheduled } = getPli_schedule_AND_totalProgress(
    params,
    { d1, d2 }
  );

  return Math.round(((scheduled - totalProgress) / scheduled) * 100);
};

export const get_item_Scheduled = (item, { d1, d2 }, typeOfWork) => {
  let scheduled = 0;
  if (d2 === undefined) {
    scheduled = Number(item?.[d1]);
  } else {
    scheduled = Number(item?.[d1]) * Number(item?.[d2]);
  }
  return scheduled;
};

export const getScheduled = (params, { d1, d2 }, typeOfWork) => {
  return get_item_Scheduled(params?.data, { d1, d2 }, typeOfWork);
};

export const getPli_schedule_AND_totalProgress = (
  params,
  { d1, d2 },
  typeOfWork
) => {
  let totalProgress = params?.data?.["totalProgress"] || 0,
    scheduled = getScheduled(params, { d1, d2 }, typeOfWork);
  return { totalProgress, scheduled };
};

export const getPliRemaining = (params, { d1, d2 }) => {
  const { totalProgress, scheduled } = getPli_schedule_AND_totalProgress(
    params,
    { d1, d2 }
  );

  return !isNaN(scheduled - totalProgress) ? scheduled - totalProgress : "-";
};

//very important functions=========
export const progressForDimension = (params, dimensionName) =>
  parseInt(params.data["progress" + capitalizeFirstLetter(dimensionName)]);
export const totalProgressForDimension = (params, dimensionName) =>
  parseInt(params.data["totalProgress" + capitalizeFirstLetter(dimensionName)]);
export const progressFor2Dimensions = (params, d1, d2) =>
  progressForDimension(params, d1) * progressForDimension(params, d2);

export const progressForDimension_byItem = (item, dimensionName) =>
  parseInt(item["progress" + capitalizeFirstLetter(dimensionName)]);

export const progressFor2Dimensions_forItem = (item, d1, d2) =>
  progressForDimension_byItem(item, d1) * progressForDimension_byItem(item, d2);

export const totalProgress_forItem = (item) => parseInt(item["totalProgress"]);
//================================

export const get_ProgressDimensionsByServiceId = (serviceId) => {
  let d1, d2;
  if (PROGRESS_BY_LENGTH_SERVICES.includes(Number(serviceId))) {
    d1 = "length";
  } else if (PROGRESS_BY_LENGTH_x_HEIGHT_SERVICES.includes(Number(serviceId))) {
    d1 = "length";
    d2 = "height";
  } else if (PROGRESS_BY_LENGTH_x_WIDTH_SERVICES.includes(Number(serviceId))) {
    d1 = "length";
    d2 = "width";
  } else if (PROGRESS_BY_FLOOR_SERVICES.includes(Number(serviceId))) {
    d1 = hoist_dimension;
  } else {
    d1 = "other Scope";
  }
  return { d1, d2 };
};

export const PROGRESS_BY_LENGTH_SERVICES = [
  1, 4, 5, 6, 7, 8, 9, 12, 13, 15, 16, 17, 72,
];
export const PROGRESS_BY_LENGTH_x_HEIGHT_SERVICES = [
  2, 10, 11, 14, 18, 19, 61, 69, 73,
];
export const PROGRESS_BY_LENGTH_x_WIDTH_SERVICES = [20, 44];
export const PROGRESS_BY_FLOOR_SERVICES = [3, 38];

export const PROGRESS_COLUMN_NAMES = [
  "progressLength",
  "progressHeight",
  "progressWidth",
];
export const REMAINING_COLUMN_NAMES = [
  "remainingLength",
  "remainingHeight",
  "remainingWidth",
];
export const DIMENSIONS = ["length", "height", "width"];

export const percentageCellRender = (params) => (params?.value || 0) + "%";

export const hoist_dimension = "hoist_dimension";

export const JUMP_MAXIMAL_PROGRESS = 100;
