import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  DraftIcon,
  ImportantIcon,
  InboxIcon,
  SentIcon,
  TrashIcon,
} from "../../../assets";

function SidebarItems() {
  const { emails, drafts } = useSelector((state) => state.communication);

  const sidebarItems = useMemo(() => {
    const inboxNo =
      emails?.filter(
        ({ labels }) => labels?.includes("INBOX") && labels?.includes("UNREAD")
      )?.length || 0;

    const trashNo =
      emails?.filter(
        ({ labels }) => labels?.includes("TRASH") && labels?.includes("UNREAD")
      )?.length || 0;

    const importantNo =
      emails?.filter(
        ({ labels }) =>
          labels?.includes("IMPORTANT") && labels?.includes("UNREAD")
      )?.length ||
      0 +
        drafts?.filter(
          ({ labels }) =>
            labels?.includes("IMPORTANT") && labels?.includes("UNREAD")
        )?.length ||
      0;

    const sentNo =
      emails?.filter(
        ({ labels }) => labels?.includes("SENT") && labels?.includes("UNREAD")
      )?.length || 0;

    const draftNo = drafts?.length || 0;

    return [
      {
        key: "1",
        label: "Inbox",
        length: inboxNo,
        icon: <InboxIcon className="menu-item-icon" />,
      },
      {
        key: "2",
        label: "Trash",
        length: trashNo,
        icon: <TrashIcon className="menu-item-icon" />,
      },
      {
        key: "3",
        label: "Important",
        length: importantNo,
        icon: <ImportantIcon className="menu-item-icon" />,
      },
      {
        key: "4",
        label: "Sent",
        length: sentNo,
        icon: <SentIcon className="menu-item-icon" />,
      },
      {
        key: "5",
        label: "Draft",
        length: draftNo,
        icon: <DraftIcon className="menu-item-icon" />,
      },
    ];
  }, [emails, drafts]);

  return sidebarItems;
}

export default SidebarItems;
