import { groupBy } from "lodash";
import { fetchAllData, gsiQueryTable } from "../../../../utils";

const fetchToBeScheduledDataForeman = async (scheduleId) => {
  const fetchEst = await gsiQueryTable({
    tableName: "toBeScheduled",
    indexName: "scheduleId-index",
    filterKey: "scheduleId",
    filterValue: scheduleId,
  });
  return { toBeScheduled: groupBy(fetchEst, "estimationId"), est: fetchEst };
};

export default fetchToBeScheduledDataForeman;
