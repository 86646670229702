/**
 * This function checks for entries that contains employees
 * who does not match with any of the employees in the system
 *
 * @param {Array} crews List of all employees in the system
 * @param {any} gridApi Grid Api used to get all nodes in the grid table
 * @returns List of entries with unmatched employees
 */
function getNotFoundEmployees({ gridApi, crews, rowData }) {
  if (!gridApi) {
    return { noEmployeeId: [] };
  }

  let incorrectEmployees = {
    noEmployeeId: [],
  };

  // get all nodes from grid api and search for employees that does not match with employees in the system
  rowData?.forEach((data) => {
    if (data?.employeeFullName) {
      if (data?.employeeId) {
        if (!incorrectEmployees[data["employeeId"]]) {
          if (
            !crews?.find(({ employeeId }) => {
              return employeeId === data?.employeeId;
            })
          ) {
            incorrectEmployees[data["employeeId"]] = {
              accountName: data?.company,
              employeeFullName: data?.employeeFullName,
              employeeNumber: !!data?.employeeNumber
                ? data?.employeeNumber
                : (data?.employeeId || "").split("-")?.[1],
            };
          }
        }
      } else {
        if (
          !incorrectEmployees["noEmployeeId"].find(
            ({ employeeFullName }) =>
              employeeFullName === data?.employeeFullName
          )
        ) {
          incorrectEmployees["noEmployeeId"] = [
            ...incorrectEmployees["noEmployeeId"],
            {
              accountName: data?.company,
              employeeNumber: data?.employeeNumber,
              employeeFullName: data?.employeeFullName,
            },
          ];
        }
      }
    }
  });
  return incorrectEmployees;
}

export default getNotFoundEmployees;
