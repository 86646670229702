import { Form } from "antd";
import { Fragment, useEffect, useState } from "react";
import { BorderedTextCard } from "../../../../../SidebarPages/Fleet/components";
import { RenderDynamicComponents } from "../../../Components";
import { useRequestModalContext } from "../../store";
import { MondayButton } from "../../../../../commonComponents";
import { PlusIcon } from "../../../../../../assets";
import { LinkBtn } from "..";
import CustomTransferWrapper from "../../../../../commonComponents/CustomTransfer/CustomTransferWrapper";
import { useSelector } from "react-redux";
import RequestFormComments from "../RequestFormComments/RequestFormComments";
import {
  populateRequestForm,
  returnProppedRequest,
  useRequestFields,
  useRequestRecordSelect,
  validateEmailRef,
  typeOfWorkRequests,
} from "../../helpers";
import { filterTables, openInNewTab } from "../../../../../../utils";
import DynamicTeamModal from "../../../DynamicTeamModal/DynamicTeamModal";
import { useEditLogs, useProgramFields } from "../../../../../../hooks";
import { DocUploader } from "../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import RequestScheduleDays from "./components/ScheduleChange/RequestScheduleDays";
import { specificFormPopulation } from "./formPopulationFunctions";
import NewFieldModal from "../../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { Plus } from "../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { API } from "aws-amplify";
import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";
import GeofenceDrawRequest from "./components/GeofenceMap/GeofenceDrawRequest";

/**
 * Renders a form section for requesting a form.
 *
 * @component
 * @param {Object} form - The form object.
 * @param {boolean} [allFieldsDisabled=false] - Indicates if all fields are disabled.
 * @param {Array} uploadedFiles - The array of uploaded files.
 * @param {Function} onPickerSuccess - The callback function for successful file picker.
 * @param {Function} onDelete - The callback function for deleting a file.
 * @param {Function} [setChanges=()=>{}] - The function to set changes.
 * @returns {JSX.Element} The rendered component.
 */
const RequestFormSection = ({
  form,
  allFieldsDisabled = false,
  uploadedFiles = [],
  onPickerSuccess = () => {},
  onDelete = () => {},
  setChanges = () => {},
}) => {
  const {
    action = "",
    requestData,
    requestType = "",
    // setVisible = () => {},
    globalDisabled = false,
    originalRequestForm = null,
    dispatchRequestData = () => {},
    currentPageKey,
    topicCategories = [],
    inputFields: { [requestType]: inputFields = [] },
    ...restUseRequestModalContext
  } = useRequestModalContext();

  const { saveAddedLogs } = useEditLogs();

  const { fetchedData } = requestData || {};

  const { selectedMainField = null } = requestData;

  const [
    { proposedTypeOfWork = [] },
    { isDarkMode = false },
    { accessToken = "" },
    { userConfiguration = {} },
  ] = useSelector((state) => [
    state.proposedTypeOfWork,
    state.darkMode,
    state.accessToken,
    state.userConfig,
  ]);

  /**
   * Get the fields for the current request type by destructuring the fields from the program fields
   */
  const { ["Inspection Types"]: inspectionTypes } = useProgramFields();

  const { requestFields } = useRequestFields({ requestType });

  const {
    getFieldValue = () => {},
    setFieldsValue = () => {},
    getFieldsValue = () => {},
  } = form;

  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [parsedInputFields, setParsedInputFields] = useState([]);
  const [editData, setEditData] = useState(false);
  const [approvalsDynamicForm, setApprovalsDynamicForm] = useState({});

  let newEditLog = {
    recordId: "",
    recordName: "",
    topic: "ApprovalsDynamicForms",
    actionType: "",
    currentData: {},
    label: "",
    nameOfUser: userConfiguration.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedKeys: [],
    category: "Approvals Dynamic Forms",
  };

  const { onMainFieldSelect } = useRequestRecordSelect(requestType, {
    form,
    getFieldValue,
    setFieldsValue,
    getFieldValue,
    topicCategories,
    setRecordsLoading,
    userConfiguration,
  });

  /**
   * Handles the click event for the email reference link.
   */
  const onEmailRefClick = () => {
    const [error] = form.getFieldError("emailReference") || [];
    let emailReference = form.getFieldValue("emailReference");

    if (!emailReference.startsWith("https://")) {
      emailReference = "https://" + emailReference?.trim();
    }

    !!emailReference && !error && openInNewTab(emailReference);
  };

  useEffect(() => {
    if (!inputFields?.length) return [];

    const fields = inputFields || [];

    const allFields = requestFields({
      fields,
      getFieldValue,
      recordsLoading,
      setFieldsValue,
      currentPageKey,
      getFieldsValue,
      onMainFieldSelect,
      allFieldsDisabled,
      recordsLoading,
      setRecordsLoading,
    }).map((field) => {
      if (
        field?.type === "select" &&
        !field?.customOptions?.length &&
        !field?.options?.length
      ) {
        return {
          ...field,
          dropdownRender: (menu) => {
            return (
              <>
                {menu}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <MondayButton
                    Icon={<Plus />}
                    onClick={() => {
                      setEditData(field);
                      setNewFieldModal(true);
                    }}
                  >
                    Add Option
                  </MondayButton>
                </div>
              </>
            );
          },
        };
      } else {
        return field;
      }
    });
    setParsedInputFields(allFields);
  }, [
    requestType,
    inputFields,
    JSON.stringify(requestData?.fetchedData),
    JSON.stringify(requestData?.selectedTeam),
    JSON.stringify(requestData?.BIN),
    JSON.stringify(requestData?.selectedMainField),
    requestData?.safetyOptions,
    requestData?.inspectionOptions,
    recordsLoading,
    currentPageKey.current,
  ]);

  function onSaveFieldsChange(newState) {
    setParsedInputFields(newState.items);
    if (!editData?.defaultField && approvalsDynamicForm?.fieldId) {
      const updatedField = { ...editData };
      delete updatedField?.dropdownRender;
      delete updatedField?.style;
      delete updatedField?.onSelect;
      delete updatedField?.onClear;
      delete updatedField?.onSearch;
      delete updatedField?.onPopupScroll;

      const fieldOptions = {
        ...approvalsDynamicForm.fieldOptions,
        [requestType]: approvalsDynamicForm?.fieldOptions?.[requestType].map(
          (field) => (field.id === editData?.id ? editData : field)
        ),
      };
      API.patch(
        "programFields",
        `/programFields/${approvalsDynamicForm.fieldId}`,
        {
          body: {
            fieldOptions,
          },
        }
      )
        .then(() => {
          const oldField = inputFields.find((el) => el.id === updatedField.id);
          let result = getChangedData(updatedField, oldField);
          if (!!result) {
            result.prev.options = [];
            result.curr = {
              ...result.curr,
              required: result.curr.required ? "Required" : "Not Required",
            };
            result.prev = {
              ...result.prev,
              required: result.prev.required ? "Required" : "Not Required",
            };

            newEditLog.recordId = updatedField.id;
            newEditLog.recordName = updatedField.label;
            newEditLog.actionType = "Edit";
            newEditLog.currentData = {
              [requestType]: {
                [updatedField.label]: result.curr,
                dummyKey: undefined,
              },
            };
            newEditLog.previousData = {
              [requestType]: {
                [updatedField.label]: result.prev,
                dummyKey: undefined,
              },
            };
            newEditLog.updatedKeys.push(updatedField.label);
            saveAddedLogs(newEditLog);
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    }
  }

  useEffect(() => {
    /**
     * If the original request form is present, we populate the form with the data
     * from the original request form.
     */
    if (!!originalRequestForm?.requestId) {
      const {
        requestObject = {},
        comments = [],
        ...otherFields
      } = originalRequestForm || {};

      const proppedRequest = {
        ...requestObject,
        ...otherFields,
      };

      const { userRequesting = "" } = proppedRequest;

      const parsedRequest = returnProppedRequest({
        action,
        userRequesting,
        proppedRequest,
      });

      const { recordId = "", teamsConfiguration = [] } = parsedRequest;

      !!teamsConfiguration?.length &&
        dispatchRequestData({
          type: "SELECTED_TEAM",
          payload: teamsConfiguration,
        });

      /**
       * Specific form population for the request form
       */
      specificFormPopulation({
        recordId,
        requestType,
        fetchedData,
        parsedRequest,
        inspectionTypes,
        currentPageKey,
        selectedMainField: parsedRequest?.selectedMainField || {},
        userConfiguration,
        setRecordsLoading,
        dispatchRequestData,
      });

      setFieldsValue({
        ...populateRequestForm({
          requestForm: parsedRequest,
          inputFields,
        }),
        comments,
        proposedTypeOfWork: parsedRequest?.proposedTypeOfWork || [],
      });
    }
  }, [originalRequestForm, JSON.stringify(fetchedData)]);
  useEffect(() => {
    filterTables("programFields", "fieldName", "Approvals Dynamic Fields").then(
      (res) => {
        setApprovalsDynamicForm(res[0]);
      }
    );
  }, []);

  const onChangeAddressValue =
    requestType === "Project" || requestType === "Opportunity"
      ? Form.useWatch("jobsiteAddress", form)
      : false;

  const onChangeAccountName =
    requestType === "Project" || requestType === "Opportunity"
      ? Form.useWatch(
          `${
            requestType === "Project"
              ? "companyName"
              : requestType === "Opportunity"
              ? "client"
              : ""
          }`,
          form
        )
      : false;

  return (
    <Fragment>
      <Form
        {...{
          form,
          onFieldsChange: () => setChanges(true),
          "data-testid": "request-form-section",
        }}
      >
        <div className="request-form-section">
          <BorderedTextCard
            {...{
              title: "Details",
              className: "details-container",
            }}
          >
            {RenderDynamicComponents(parsedInputFields || [], { form })}

            {!!requestData?.selectedTeam?.length && (
              <MondayButton
                {...{
                  onClick: () => {
                    setTeamModalVisible(true);
                  },
                  Icon: <PlusIcon />,
                  disabled: allFieldsDisabled,
                }}
              >
                Team Members
              </MondayButton>
            )}

            <LinkBtn
              {...{
                name: "emailReference",
                onClick: onEmailRefClick,
                rules: [{ validator: validateEmailRef }],
              }}
            >
              Email Reference
            </LinkBtn>
          </BorderedTextCard>

          <div className="otherDetailsContainer">
            {typeOfWorkRequests?.includes(requestType) ? (
              <BorderedTextCard
                title="Proposed Type of Work"
                className="typeOfWorkBorderedTextCard"
              >
                <CustomTransferWrapper
                  {...{
                    name: "proposedTypeOfWork",
                    className: "typeOfWorkTransfer",
                    dataSource: (proposedTypeOfWork || [])?.map(
                      ({ workId = "", workName = "" }) => ({
                        key: workId,
                        title: workName,
                      })
                    ),
                    disabled: allFieldsDisabled,
                    required: true,
                  }}
                />
              </BorderedTextCard>
            ) : null}

            {requestType === "Schedule Change" &&
              !!selectedMainField?.scheduleDays?.length && (
                <RequestScheduleDays setChanges={setChanges} />
              )}

            {onChangeAddressValue && (
              <BorderedTextCard
                {...{
                  title: "Geofence",
                  className: "details-container",
                }}
              >
                <GeofenceDrawRequest
                  {...{ onChangeAddressValue, onChangeAccountName, setChanges }}
                />
              </BorderedTextCard>
            )}
          </div>
          <BorderedTextCard
            {...{
              title: "Comments",
              className: "details-container",
            }}
          >
            <div className="comments" style={{ width: "100%" }}>
              <RequestFormComments
                {...{
                  form,
                  setChanges() {},
                  requestId: originalRequestForm?.requestId || null,
                  requestType,
                  selectedSchedule:
                    requestType === "Schedule Change"
                      ? selectedMainField
                      : null,
                }}
              />
            </div>
          </BorderedTextCard>
        </div>

        <div className="uploaderSection">
          <DocUploader
            {...{
              uploadedFiles,
              onPickerSuccess,
              onDelete,
              folderId: "18D0dEt-mKLpJjB21J5E3kBjCv_2TJ1aN",
              disabled: globalDisabled || allFieldsDisabled,
              onChange() {
                setChanges(true);
              },
              responsiveWidth: 1450,
              checkWarnings: !allFieldsDisabled || !globalDisabled,
            }}
          />
        </div>
      </Form>

      {teamModalVisible && (
        <DynamicTeamModal
          {...{
            open: teamModalVisible,
            setOpen: setTeamModalVisible,
            selectedTeam: requestData?.selectedTeam || [],
            setSelectedTeam: (data) => {
              dispatchRequestData({
                type: "SELECTED_TEAM",
                payload: data,
              });
              form.setFieldsValue({ team: data || [] });
            },
            ClearOptions: () => {
              setTeamModalVisible(false);
            },
            proppedAllTeams: fetchedData?.teams || [],
          }}
        />
      )}
      {newFieldModal && (
        <NewFieldModal
          {...{
            open: newFieldModal,
            setVisible: setNewFieldModal,
            editData,
            setEditData,
            setChanges: () => {},
            setEditLogs: () => {},
            newEditLog,
            setNewLogsList: () => {},
            isDarkMode,
            setNotifications: () => {},
            setData: () => {},
            selectedCategory: { name: requestType, items: parsedInputFields },
            setSelectedCategory: onSaveFieldsChange,
          }}
        />
      )}
    </Fragment>
  );
};

export default RequestFormSection;
