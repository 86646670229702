import { useSelector } from "react-redux";
import "./TableTemplate.scss";

/**
 * @typedef {Object[]} HeaderType
 * @property {string} name - The name of the header item.
 * @property {string} flex - The flex value of the header item.
 *
 */

/**
 * TableTemplate component renders a table-like structure with a header and body.
 *
 * @param {Object} props - The properties object.
 * @param {HeaderType} props.header - An array of header items to be displayed.
 * @param {Array} props.data - An array of data items to be rendered in the table body.
 * @param {string} props.className - The class name to be added
 * @param {Function} props.renderItem - A function to render each item in the data array.
 * @param {Function} props.activeRow - A function to determine if a row is active.
 * @param {Function} props.onRowClick - A function to handle row click events.
 *
 * @returns {JSX.Element} The rendered table template component.
 */
function TableTemplate({
  header = [],
  data = [],
  renderItem = () => {},
  className = "",
  activeRow = (item) => false,
  onRowClick = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      data-testid="tableTemplate"
      className={`tableTemplate ${className ? className : ""} ${
        isDarkMode ? "tableTemplateDark" : ""
      }`}
    >
      <div className="tableTemplate-header">
        {header.map((item, index) => (
          <span
            style={{
              flex: item.flex,
            }}
            key={index}
          >
            {item.name}
          </span>
        ))}
      </div>
      <div className="tableTemplate-body">
        {data.length ? (
          data.map((item, index) => (
            <div
              key={index}
              onClick={() => onRowClick(item, index)}
              className={`tableTemplate-item ${
                activeRow(item) ? "active-row" : ""
              }`}
            >
              {renderItem(item, index)}
            </div>
          ))
        ) : (
          <div className="tableTemplate-item">No data available</div>
        )}
      </div>
    </div>
  );
}

export default TableTemplate;
