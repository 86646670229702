import { useSelector } from "react-redux";
import { ExportPreview } from "../../../../../../../commonComponents";
import getInitialTaskPdfData from "./helpers/getInitialTaskPdfData";
import convertTaskData from "./helpers/convertTaskData";
import { safetyExportApplyFilters } from "../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers";
import SortReportFields from "../../../../../../Safety/SafetyModal/components/SafetyExportPreview/components/SortReportFields";

export const commentStyles = {
  "comment-header-author": {
    color: "#333",
    "margin-bottom": "5px",
    width: "100%",
    margin: "30px 0 0 0",
  },
  "comment-header": {
    color: "#333",
    "margin-bottom": "5px",
    width: "100%",
  },
  "comment-content": {
    color: "#555",
  },
  "reply-header": {
    color: "#666",
    "margin-bottom": "10px",
  },
  "reply-header-1": {
    color: "#666",
    "margin-left": "20px",
  },
  "reply-header-2": {
    color: "#666",
    "margin-left": "40px",
  },
  "reply-header-3": {
    color: "#666",
    "margin-left": "60px",
  },
  "reply-header-4": {
    color: "#666",
    "margin-left": "80px",
  },
  "reply-header-5": {
    color: "#666",
    "margin-left": "100px",
  },
  "reply-header-6": {
    color: "#666",
    "margin-left": "120px",
  },
  "reply-content": {
    color: "#777",
  },
};

function TaskExportPreview({ openPDFPreview, setOpenPDFPreview, updatedTask }) {
  const { accessToken } = useSelector((state) => state.accessToken);

  return openPDFPreview ? (
    <ExportPreview
      {...{
        onCancel: () => setOpenPDFPreview(false),
        open: openPDFPreview,
        modalTitle: "Task PDF Preview",
        documentTitle: "Task " + updatedTask?.taskTitle,
        keyName: "task",
        applyFilters: safetyExportApplyFilters,
        asyncInitialData: true,
        getInitialData: async () =>
          await getInitialTaskPdfData(updatedTask, accessToken),
        convertData: convertTaskData,
        tabs: [
          {
            key: "sort",
            label: "Modify Fields",
            component: SortReportFields,
          },
        ],
        customStyles: {
          "dependency-section-header": {
            "font-size": "18px",
            display: "block",
            "font-weight": "bold",
            margin: "3rem 0 1rem 0",
          },
          "dependency-body-header": {
            "font-weight": "bold",
            display: "block",
            "margin-bottom": "10px",
          },
          ...commentStyles,
        },
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: "Title: " + updatedTask?.taskTitle,
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default TaskExportPreview;
