import { useState } from "react";
import { b64toBlob } from "../MergePdfs";
import { EditOutlined } from "@ant-design/icons";
import PageRangePicker from "../PageRangePicker/PageRangePicker";
import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";

const getFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const PickCustomPages = ({
  setCustomConfigPages,
  file,
  id,
  fileType = "local",
  configPages,
}) => {
  const [active, setActive] = useState(false);
  const [toRender, setToRender] = useState();

  const onFirstOpen = () => {
    setActive(true);
    if (fileType === "local") {
      getFileAsBase64(file.originFileObj)
        .then((base64String) => {
          const toReturn = b64toBlob(base64String, "application/pdf");
          const blobURL = URL.createObjectURL(toReturn);
          setToRender(blobURL);
        })
        .catch((error) => {
          console.error("Error reading file:", error);
        });
    } else {
      const blobURL2 = file?.blob;
      setToRender(blobURL2);
    }
  };

  const onConfirm = ({ pagesArr, pagesInfo = "" }) => {
    setCustomConfigPages((prev) => ({
      ...(prev || {}),
      [id]: {
        pagesArr,
        pagesInfo,
      },
    }));
    setActive(false);
  };

  return (
    <>
      {" "}
      <span
        title="Pick Custom Pages"
        style={{
          display: "flex",
          cursor: "pointer",
          //   color: "#1264A3",
          alignItems: "center",
        }}
        onClick={onFirstOpen}
      >
        <EditOutlined />
      </span>
      {active && (
        <NormalSizedModal
          {...{
            open: active,
            setOpen: setActive,
            onCancel: () => setActive(false),
            title: "Pick Custom Pages",
            closable: true,
            centered: true,
          }}
        >
          <div
            style={{
              width: "1200px",
              height: "800px",
              // height: "500px",
              // height: "800px",
            }}
          >
            <PageRangePicker
              onConfirm={onConfirm}
              configPages={configPages}
              file={file}
            />
            <br />
            {toRender && (
              <iframe
                title="PDF Viewer"
                style={{
                  width: "100%",
                  height: "700px",
                }}
                src={toRender}
              />
            )}
          </div>
        </NormalSizedModal>
      )}
    </>
  );
};

export default PickCustomPages;
