import React, { useState } from "react";
import FileItem from "../../../EmailBox/components/footer/EmailBoxFooter/documentation-types/file-item/FileItem";
import { FilePreviewModal } from "../../../../../Documentation/View/components";

import { uniqBy } from "lodash";

import "./attachments-view.scss";

function AttachmentsView({ attachments, handleMoreFilesClick }) {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState({ mimeType: "", name: "", src: "" });
  const uniqueAttachments = uniqBy(attachments, "blob");

  const handleOnClick = async (attachment) => {
    try {
      setVisible(true);
      const { mimeType, name, blob } = attachment;
      const src = blob.includes("data:application")
        ? blob
        : `data:${mimeType};base64,${blob}`;
      setFile({
        mimeType,
        name,
        src,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="attachments-container"
      onClick={(event) => event.stopPropagation()}
    >
      {uniqueAttachments?.length > 0 && (
        <>
          {uniqueAttachments.slice(0, 3).map((attachment) => (
            <FileItem
              key={attachment.id}
              file={attachment}
              link={attachment.link}
              onClick={() => handleOnClick(attachment)}
              className="attachment-item"
              placement="top"
            />
          ))}
          {uniqueAttachments.length > 3 && (
            <div className="handle-more-files" onClick={handleMoreFilesClick}>
              <span>+{uniqueAttachments?.length - 3}</span>
            </div>
          )}
        </>
      )}

      <FilePreviewModal
        {...{
          visible,
          setVisible: () => setVisible(false),
          file,
        }}
      />
    </div>
  );
}

export default AttachmentsView;
