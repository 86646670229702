import Swal from "sweetalert2";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useRef, useState } from "react";
import { MondayButton } from "../../../commonComponents";
import { SettingIcon } from "../../../Header/subcomponents";
import { LeftArrow } from "../../../SidebarPages/BasePage/src";
import { Notes } from "./../../../commonComponents/Notes/Notes";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";
import DocumentInfoDrawer from "./components/DocumentInfoDrawer/DocumentInfoDrawer";
import ProposalDocumentMerger from "../ProposalPages/FirstProposalPage/ProposalDocumentMerger";
import { MondayButtonIcon } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { proposalUpdateHandler } from "../../../SidebarPages/Estimations/components/ProposalBuilder/utils/requests";
import {
  CommentOutlined,
  EyeFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import WordDocxFactory from "../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/WordDocxFactory";
import { processWordHtml } from "../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";

import "./ProposalHeader.scss";

function ProposalHeader({ editorRef, onViewChange, onPreview }) {
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state?.userConfig);
  const [somethingVisible, setSomethingVisible] = useState(false);
  const { proposalData, setProposalData } = useProposalContext();

  const { proposedTypeOfWork: serviceDefs } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const wordRef = useRef(null);
  const gridData = proposalData?.takeOff?.services;
  const projectAddress = proposalData?.estimation?.jobSiteAddress;
  const accountName = proposalData?.estimation?.accountName;
  const versionId = proposalData?.takeOff?.id;

  const onSaveChanges = async () => {
    message.loading("Saving changes...", 1);
    // console.log("editorHtml", { val: editorRef?.current?.value });
    message.destroy();
    await proposalUpdateHandler({
      proposalId: proposalData?.proposalId,
      proposalData: { savedContent: editorRef?.current?.value || "" },
      wrappedHtml: proposalData?.parentTemplateContent || "",
    })
      .then((result) => {
        message.destroy();
        Swal.fire({
          title: "<strong>Proposal Saved</strong>",
          icon: "success",
          html: "Your changes have been saved! ",
        });
        // .then(() => {
        //   return navigate(-1);
        // });
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          title: "<strong>Proposal Not Saved</strong>",
          icon: "error",
          html: "Your changes have not been saved! ",
        });
        localStorage.setItem(
          `proposaldraft${proposalData?.proposalId}`,
          JSON.stringify(editorRef?.current?.currentContent)
        );
      });
  };

  const onDeleteProposal = async () => {};

  const onGoBack = () => {
    // setProposalData({});
    setProposalData({});
    navigate("/proposalBuilder");
  };

  return (
    <header
      className={`new_proposal_builder__header-wrapper ${
        isDarkMode ? "new_proposal_builder__header-wrapper" : ""
      }`}
    >
      <div className="proposal_builder_header_left">
        <MondayButtonIcon
          className="mondayButtonGrey"
          Icon={<LeftArrow />}
          name="Go Back"
          onClick={onGoBack}
        />
        {!!proposalData?.estimation ? (
          <h1 className="header__title">
            <span>
              {proposalData?.estimation?.jobSiteAddress || ""} - (
              {proposalData?.takeOff?.versionName || ""})
            </span>
            <div className="divider"></div>
            <div className="header-tag">
              <strong>Estimation #</strong>
              {proposalData?.estimation?.estimationNumber ||
                proposalData?.estimation?.quickbooksNumber ||
                "1"}
            </div>

            <div className="header-tag">
              <strong>Client:</strong>{" "}
              {proposalData?.estimation?.accountName || ""}
            </div>
          </h1>
        ) : (
          <h2>Loading data...</h2>
        )}
      </div>
      <div className="proposal_builder_header_right">
        {/* <WordDocxFactory
          {...{
            gridData,
            projectAddress,
            serviceDefs,
            accountName,
            versionId,
            wordRef,
          }}
        /> */}
        {/* <DocuSealForm /> */}
        <MondayButtonIcon
          name="Document Info"
          className="mondayButtonGrey"
          containerClassName="icon-button"
          onClick={() => setSomethingVisible("documentInfo")}
          Icon={
            <InfoCircleFilled style={{ color: "#231F20", fontSize: "20px" }} />
          }
        />
        <MondayButtonIcon
          name="Add Notes"
          Icon={<CommentOutlined />}
          className="mondayButtonGrey"
          containerClassName="icon-button"
          onClick={() => setSomethingVisible("notes")}
        />
        <MondayButtonIcon
          Icon={<SettingIcon />}
          name="Settings"
          className="mondayButtonGrey"
          onClick={() => {
            window.open(`/settings/generalTemplate`, "_blank");
            // navigate("/settings/generalTemplate")
          }}
        />
        <MondayButtonIcon
          Icon={<EyeFilled />}
          name="Preview Proposal"
          className="mondayButtonGrey"
          onClick={() => onPreview(true)}
          containerClassName="icon-button"
        />
        {/* <div className="more_options_holder">
          <Dropdown
            menu={{
              items,
            }}
          >
            <div className="more_options_icon">
              <ThreeDotsIcon />
            </div>
          </Dropdown>
        </div> */}
        {/* <div>
          <ProposalAssistant />
          </div>{" "} */}

        <ProposalDocumentMerger editorRef={editorRef} />
        <MondayButton
          className="mondayButtonGreen"
          hasIcon={false}
          onClick={onSaveChanges}
        >
          Save Proposal
        </MondayButton>
      </div>
      <DocumentInfoDrawer
        {...{
          visible: somethingVisible === "documentInfo",
          setVisible: setSomethingVisible,
        }}
      />
      {somethingVisible === "notes" && (
        <Notes
          noteModalName={`Proposal notes for ${proposalData?.estimation?.accountName}`}
          rowId={proposalData?.proposalId}
          topicCategory="Proposal"
          notesVisibility={somethingVisible === "notes"}
          setNotesVisibility={setSomethingVisible}
          // customIcon={<CommentOutlined />}
        />
      )}
    </header>
  );
}

export default ProposalHeader;
const items = [
  {
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://portal.corescaffold.com/"
      >
        Go back
      </a>
    ),
    key: "0",
  },
];
