import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

export function overviewFilters({
  onEmployeeSelect = () => {},
  rowData = [],
  matchDetails,
  form,
}) {
  const listOfEmployees = _.uniqBy(
    (rowData || []).map(({ employeeId, employeeFullName, crewId }) => ({
      employeeId,
      employeeFullName,
      crewId,
    })),
    ({ employeeId }) => employeeId
  );

  const listOfJobsites = _.uniqBy(
    (rowData || []).map(({ jobsiteMatch }) => ({
      jobName: jobsiteMatch?.jobName || "",
      jobAddress: jobsiteMatch?.jobAddress || "",
      jobsiteId: jobsiteMatch?.jobsiteId || "",
    })),
    ({ jobsiteId }) => jobsiteId
  ).filter(({ jobsiteId }) => !!jobsiteId);

  const payrollTypes = _.uniqBy(
    rowData
      ?.map((shift) =>
        shift?.payrollType
          ? {
              value: shift.payrollType,
              label: shift.payrollType,
            }
          : null
      )
      .filter(Boolean),
    ({ value }) => value
  );

  const roleOptions = _.uniqBy(
    (rowData || []).flatMap(({ employeeRole }) =>
      employeeRole ? { value: employeeRole, label: employeeRole } : []
    ),
    ({ value }) => value
  );
  const statusOptions = _.uniqBy(
    (rowData || []).flatMap(({ shiftStatus }) =>
      shiftStatus ? { value: shiftStatus, label: shiftStatus } : []
    ),
    ({ value }) => value
  );

  // const shiftTypeOptions = _.uniqBy(
  //   rowData.flatMap(({ shiftType }) =>
  //     shiftType ? { value: shiftType, label: shiftType } : []
  //   ),
  //   ({ value }) => value
  // );

  return [
    {
      label: "Select Employee",
      type: "select",
      customOptions: listOfEmployees
        ?.map(({ employeeId, employeeFullName }) => ({
          key: employeeId,
          value: employeeId,
          label: employeeFullName,
        }))
        ?.filter(({ value }) => !!value),
      onSelect: onEmployeeSelect,
      mode: "multiple",
      formItemName: "selectedEmployees",
      placeholder: "Select Employees...",
      getPopupContainer: document.body,
    },
    {
      label: "Select Jobsites",
      type: "select",
      mode: "multiple",
      formItemName: "selectedJobsites",
      placeholder: "Select Jobsites...",
      customOptions: listOfJobsites?.map(({ jobsiteId, jobName }) => ({
        key: jobsiteId,
        value: jobsiteId,
        label: jobName,
      })),
      getPopupContainer: document.body,
    },
    !matchDetails && {
      label: "Payroll Type",
      type: "select",
      mode: "multiple",
      formItemName: "payrollType",
      placeholder: "Select Payroll Type...",
      customOptions: payrollTypes,
    },
    // !matchDetails && {
    //   label: "Shift Type",
    //   type: "select",
    //   mode: "multiple",
    //   formItemName: "shiftType",
    //   placeholder: "Select Shift Type...",
    //   customOptions: shiftTypeOptions,
    // },
    !matchDetails && {
      label: "Role",
      type: "select",
      mode: "multiple",
      formItemName: "employeeRole",
      placeholder: "Select role...",
      customOptions: roleOptions,
    },
    !matchDetails && {
      label: "Status",
      type: "select",
      mode: "multiple",
      formItemName: "shiftStatus",
      placeholder: "Select status...",
      customOptions: statusOptions,
    },
    {
      label: "Time Frame",
      type: "rangepicker",
      formItemName: "timeFrame",
      onChange: (e) => {
        const dateRange = e.map((i) => parseInTz(i));
        form.setFieldValue("timeFrame", dateRange);
      },
      getPopupContainer: document.body,
      allowClear: true,
    },
  ].filter(Boolean);
}

export const overviewColumnDefs = [
  {
    headerName: "Full Name",
    field: "employeeFullName",
  },
  // {
  //   headerName: "Employee Id",
  //   field: "employeeId",
  // },
  {
    headerName: "Role",
    field: "employeeRole",
  },
  {
    headerName: "Clock In",
    field: "firstClockIn",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
    },
  },
  {
    headerName: "Jobsite Address",
    field: "jobsiteAddress",
  },
  {
    headerName: "SOW",
    field: "sow",
    valueGetter({ data }) {
      return (data?.sow || []).join(", ");
    },
  },
  // {
  //   headerName: "Payroll Type",
  //   field: "payrollType",
  // },
  // {
  //   headerName: "Reimbursement",
  //   field: "reimbursement",
  //   cellRenderer({ value }) {
  //     return formatCurrency(value || 0);
  //   },
  // },
  // {
  //   headerName: "Work Hours",
  //   field: "workHours",
  //   cellRenderer({ value }) {
  //     return formatNumber(value, { unit: "hour" });
  //   },
  //   pdfExportOptions: {
  //     modifyCellValue: (value) => {
  //       return formatNumber(value, { unit: "hour" });
  //     },
  //   },
  // },
  // {
  //   headerName: "Break Hours",
  //   field: "breakHours",
  //   cellRenderer({ value }) {
  //     return formatNumber(value, { unit: "hour" });
  //   },
  //   pdfExportOptions: {
  //     modifyCellValue: (value) => {
  //       return formatNumber(value, { unit: "hour" });
  //     },
  //   },
  // },
  // {
  //   headerName: "Overtime Hours",
  //   field: "overtimeHours",
  //   cellRenderer({ value }) {
  //     return formatNumber(value, { unit: "hour" });
  //   },
  //   pdfExportOptions: {
  //     modifyCellValue: (value) => {
  //       return formatNumber(value, { unit: "hour" });
  //     },
  //   },
  // },
  // {
  //   headerName: "Work Hours Total",
  //   field: "regAmount",
  //   cellRenderer({ data }) {
  //     let value = data?.regAmount;
  //     if (data?.ovhRegular) {
  //       value = data?.ovhRegular;
  //     }
  //     return formatCurrency(value || 0);
  //   },
  // },
  // {
  //   headerName: "OT. Hours Total",
  //   field: "otAmount",
  //   cellRenderer({ data }) {
  //     let value = data?.otAmount;
  //     if (data?.ovhOvertime) {
  //       value = data?.ovhOvertime;
  //     }
  //     return formatCurrency(value || 0);
  //   },
  // },
  // {
  //   headerName: "Total",
  //   field: "total",
  //   cellRenderer({ data }) {
  //     return formatCurrency(data?.total || 0);
  //   },
  //   pdfExportOptions: {
  //     modifyCellValue: (value) => {
  //       return formatCurrency(value || 0);
  //     },
  //   },
  // },
  // {
  //   headerName: "Total Overhead",
  //   field: "totalOvh",
  //   cellRenderer({ data }) {
  //     return formatCurrency(data?.totalOvh || 0);
  //   },
  //   pdfExportOptions: {
  //     modifyCellValue: (value) => {
  //       return formatCurrency(value || 0);
  //     },
  //   },
  // },
];
