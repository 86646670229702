import { Tooltip } from "antd";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { LogsButton, MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";

// this simple function is made with for loop for performance reasons
export function formatMembersOptions(allMembers, busyMembers, rowToEdit) {
  const onlyMembers = [];

  for (let i = 0; i < allMembers?.length; i++) {
    const member = allMembers[i];

    if (typeof member?.foreman === "string" || !member?.foreman) {
      let foundBusyMember = null;
      for (let j = 0; j < busyMembers.length; j++) {
        if (member?.crewId === busyMembers[j]?.crewId) {
          foundBusyMember = busyMembers[j];
          break;
        }
      }

      let foundInRowToEdit = null;
      if (rowToEdit && rowToEdit?.crewMembers) {
        for (let k = 0; k < rowToEdit?.crewMembers?.length; k++) {
          if (member?.crewId === rowToEdit?.crewMembers[k]?.crewId) {
            foundInRowToEdit = rowToEdit?.crewMembers[k];
            break;
          }
        }
      }

      if (foundBusyMember && !foundInRowToEdit) {
        onlyMembers.push(Object.assign(member, { disabled: true }));
      } else {
        onlyMembers.push(member);
      }
    }
  }
  return onlyMembers;
}

export function footerButtons({
  saveRef,
  cancelRef,
  rowToEdit,
  editMode = false,
  onSave = () => {},
  onCancel = () => {},
}) {
  return [
    <>
      <div className="createCrewFooter">
        <div ref={cancelRef}>
          <MondayButton
            {...{
              Icon: <XIcon />,
              onClick: onCancel,
              className: "mondayButtonRed",
            }}
          >
            Cancel
          </MondayButton>
        </div>
        <div ref={saveRef} className="editCrewFooter">
          {editMode && (
            <Tooltip title="Show logs">
              <LogsButton
                {...{
                  buttonLabel: "Logs",
                  logsTitle: `${rowToEdit?.crewTeamName} Logs`,
                  filtersObj: { recordId: rowToEdit?.crewTeamId },
                }}
              />
            </Tooltip>
          )}
          <MondayButton
            {...{
              onClick: onSave,
              Icon: <TickIcon />,
            }}
          >
            Save
          </MondayButton>
        </div>
      </div>
    </>,
  ];
}

export function crewTeamModalFields({
  clientsConfig,
  companies = [],
  foremanMembers = [],
  setSelectedForeman = () => {},
}) {
  return [
    {
      type: "input",
      required: true,
      label: "Crew Name",
      className: "crewTeamInput",
      placeholder: "Enter here...",
      formItemName: "crewTeamName",
    },
    {
      type: "select",
      required: true,
      label: "Subcontractor",
      formItemName: "company",
      className: "crewTeamInput",
      placeholder: "Select company...",
      getPopupContainer: document.body,
      customOptions: [
        {
          label: "Client Configuration",
          options: clientsConfig.flatMap(({ clientName, activeConfig }, key) =>
            activeConfig
              ? {
                  key,
                  value: clientName,
                  label: clientName,
                }
              : []
          ),
        },
        {
          label: "Subcontractors",
          options: companies.map(({ accountName = "" }, key) => ({
            key,
            value: accountName,
            label: accountName,
          })),
        },
      ],
    },
    {
      type: "select",
      required: true,
      label: "Status",
      className: "crewTeamInput",
      formItemName: "crewTeamStatus",
      placeholder: "Select status...",
      getPopupContainer: document.body,
      customOptions: [
        { key: 0, label: "Active", value: "Active" },
        { key: 1, label: "Out Of Service", value: "Out Of Service" },
      ],
    },
    {
      type: "select",
      label: "Foreman",
      className: "crewTeamInput",
      formItemName: "crewForeman",
      placeholder: "Select foreman...",
      getPopupContainer: document.body,
      onSelect: (e) => setSelectedForeman(JSON.parse(e)),
      filterOption: (input, option) => {
        return (option?.label?.key || "")
          .toLowerCase()
          .includes((input || "").toLowerCase());
      },
      customOptions: foremanMembers.map(
        ({ crewId = "", crewName = "", employeeId = "" }, key) => ({
          key,
          value: JSON.stringify({ crewId, crewName, employeeId }),
          label: (
            <div
              key={crewName}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{crewName}</div>
              {!!employeeId?.length && <div>({employeeId})</div>}
            </div>
          ),
        })
      ),
    },
  ];
}
