import { message } from "antd";
import { API } from "aws-amplify";
import { getChangedData } from "../../../Accounting/components/utilities";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotificationByAccess } from "../../../../../utils/notificationHelpers";

async function editCitation(
  citationId,
  citation,
  status,
  userConfiguration,
  saveAddedLogs,
  prevObj,
  currObj,
  authenticatedUser
) {
  await API.put("citations", `/citations/${citationId}`, {
    body: {
      citationName: citation.citationName,
      employeeId: citation.employeeId,
      citationObject: citation.citationObject,
      citationStatus: status,
      googleDriveUpload: citation?.googleDriveUpload || "",
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    ?.then(async () => {
      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });

      const changes = getChangedData(currObj, prevObj);
      if (!!changes) {
        saveAddedLogs({
          recordId: citationId,
          recordName: citation.citationName,
          category: "Safety Citation",
          actionType: "Edit",
          topic: citation?.citationCategory || "General",
          currentData: changes?.curr || {},
          previousData: changes?.prev || {},
          updatedKeys: Object.keys(changes.curr),
        });
      }

      if (status.toLowerCase() !== "draft") {
        broadcastNotification(
          "125",
          "onSafetyCitationEdit",
          [
            {
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotificationByAccess(
                userConfiguration,
                "Safety Citations"
              ),
            },
          ],
          citationId
        );
      }
    })
    .catch((err) => {
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
      console.error("Error editing safety", err);
    });
}

export default editCitation;
