import React, { createContext, useState, useContext, useEffect } from "react";
import { useGoogleSignIn } from "../../../../hooks/useGoogleSignIn";
import { Buffer } from "buffer";

const CommunicationContext = createContext();
export const useCommunication = () => {
  return useContext(CommunicationContext);
};

export const CommunicationProvider = ({ children }) => {
  // This reads from localStorage because it used in communication and notifications => communication tab as well so will be shown the same logged in user in both places.
  const [selectedEmail, setSelectedEmail] = useState(() => {
    const savedEmail = localStorage.getItem("selectedEmail");
    return savedEmail ? savedEmail : "company";
  });

  const [authData, setAuthData] = useState(null);

  // the hook which provides google authenticated user information
  const { googleAccessToken, userLoggedIn } = useGoogleSignIn();

  // used when user is logged in and it's changing between avatars of web  app account and her/his account and the email ui would update
  const changeSelectedEmail = (newSelectedEmail) => {
    setSelectedEmail(newSelectedEmail);
    localStorage.setItem("selectedEmail", newSelectedEmail);
  };

  useEffect(() => {
    // Effect to update authData when user logs in or selectedEmail changes
    if (userLoggedIn && selectedEmail && selectedEmail.includes("user")) {
      setAuthData(
        Buffer.from(JSON.stringify(googleAccessToken)).toString("base64")
      );
    } else {
      setAuthData(null);
    }
  }, [selectedEmail, userLoggedIn, googleAccessToken]);

  useEffect(() => {
    if (!userLoggedIn) {
      localStorage.removeItem("selectedEmail");
    }
  }, [userLoggedIn]);

  return (
    <CommunicationContext.Provider
      value={{
        selectedEmail,
        changeSelectedEmail,
        authData,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};
