export const getSelectedToEmail = (type, email, collapsed) => {
  if (collapsed) {
    return email?.snippet?.includes("--")
      ? email?.snippet?.split("--")[0]
      : email?.snippet?.split("On")[0];
  }

  // If type is a string, return the corresponding value from email object
  if (typeof email[type] === "string") {
    return email[type] || "";
  }

  // If type is an array, join its elements with comma
  if (Array.isArray(email[type])) {
    return email[type].join(", ");
  }

  // If type is a string but not found in email object, return type itself
  if (typeof type === "string") {
    return [email[type], type].filter((e) => e !== undefined).join(", ");
  }

  // If none of the above conditions are met, return an empty string
  return "";
};
