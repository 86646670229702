import { isNumber } from "chart.js/helpers";
import { stripHtmlTags } from "../../../../tools/exporters/retrieveDOCX";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfRowHasValidData } from "../../../../tools/formatters/validators";
import { groupBy } from "../../../../../../Accounting/components/utilities";
import { formatFloorHeight } from "../../../../../components/EstVersionsTab/components/CompareTakeOffVersions/utils";

export const processWordHtml = ({
  gridData,
  projectAddress,
  serviceDefs,
  accountName,
  estimation,
  versionId,
  showIds = true,

  splitServices = false,
  contentEditable = true,
}) => {
  let temp = [];
  // First we have to insert Address and Account Name
  temp.push(getAddressAndAccountName(projectAddress, accountName));
  let filteredServices = gridData?.filter((el) => el?.isScope !== true);
  // Now We continue with each service
  let tempServices = getPhasesConfigIfAny(
    filteredServices,
    estimation,
    versionId
  );

  if (!!tempServices) {
    filteredServices = tempServices;
  }
  let servicesOneByOne = [];
  getServicesAsHtml(
    filteredServices,
    serviceDefs,
    temp,
    showIds,
    servicesOneByOne,
    contentEditable
  );

  // Estimation Note
  if (hasContent(estimation?.estimationDescription)) {
    temp.push(
      `<p style="padding-left: 40px;"><span style="font-size: 14pt;"><strong>Estimation Notes:</strong></span></p>`
    );
    temp.push(
      `<div style="padding-left: 40px;">${estimation?.estimationDescription}</div>`
    );
  }
  if (splitServices) {
    return servicesOneByOne;
  }

  return temp?.join("");
};

export const getPhasesConfigIfAny = (
  filteredServices,
  estimation,
  versionId
) => {
  let preconfiguredSort =
    estimation?.versionServices?.[versionId]?.customWordOrder;

  let allPossiblePhases = [];
  let services = [];
  if (Array.isArray(filteredServices))
    filteredServices?.forEach((service) => {
      service.serviceOptions?.forEach((option) => {
        option?.forEach((elevation) => {
          if (!!elevation?.phase) {
            allPossiblePhases.push(elevation.phase);
          }
        });
      });
    });
  allPossiblePhases = [...new Set(allPossiblePhases)];
  allPossiblePhases.push("N/A");
  if (allPossiblePhases.length > 0) {
    allPossiblePhases?.forEach((phase) => {
      let hasIt = false;
      let temp = structuredClone(filteredServices);
      temp?.forEach((service) => {
        service.serviceOptions?.forEach((option, idx) => {
          let filtered = option.filter((elevation) => {
            return elevation?.phase === (phase !== "N/A" ? phase : undefined);
          });
          if (filtered.length > 0) {
            hasIt = true;
            services.push({
              ...service,
              serviceOptions: [filtered],
              optionIndex: idx,
              phase: phase,
              label:
                phase !== "N/A"
                  ? `${service?.label} - ${phase}`
                  : service?.label,
            });
          }
        });
      });
    });
  }
  let tempSorted;
  let retainSort = [];

  if (services.length > 0) {
    let grouped = groupBy(services, "phase");
    Object.entries(grouped)?.forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        value?.forEach((el) => {
          let tempSortIdx = filteredServices.findIndex(
            (e) => e?.serviceId.toString() === el?.serviceId.toString()
          );
          el.sortIdx = tempSortIdx;
        });
        retainSort.push([
          ...value.sort((a, b) => {
            return a.sortIdx > b.sortIdx ? 1 : -1;
          }),
        ]);
      }
    });
  }

  if (!!preconfiguredSort) {
    return services.sort((a, b) => {
      let aIdx = preconfiguredSort.findIndex(
        (el) => el?.label.toString() === a?.label.toString()
      );
      let bIdx = preconfiguredSort.findIndex(
        (el) => el?.label.toString() === b?.label.toString()
      );
      if (aIdx > bIdx) {
        return 1;
      } else if (aIdx < bIdx) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  return services.length > 0
    ? retainSort?.flat(1).sort((a, b) => {
        if (a.phase < b.phase) {
          return -1; // "a" should come before "b"
        } else if (a.phase > b.phase) {
          return 1; // "a" should come after "b"
        } else {
          // compare sortIdx
          if (a.sortIdx < b.sortIdx) {
            return -1; // "a" should come before "b"
          } else if (a.sortIdx > b.sortIdx) {
            return 1; // "a" should come after "b"
          } else {
            // If phases and sortIdx are equal, compare optionIdx
            if (a.optionIndex < b.optionIndex) {
              return -1; // "a" should come before "b"
            } else if (a.optionIndex > b.optionIndex) {
              return 1; // "a" should come after "b"
            } else {
              return 0; // No change in order
            }
          }
        }
      })
    : filteredServices;
};

export const getAddressAndAccountName = (projectAddress, accountName) => {
  return `<p style="padding-left: 40px;"><span style="font-size: 20pt;">${projectAddress}</span></p>
    <p style="padding-left: 40px;"><span style="font-size: 14pt;">(Client: ${accountName})</span></p>`;
};

//region getServicesAsHtml
export const getServicesAsHtml = (
  gridData,
  serviceDefs,
  toAdd,
  showIds,
  servicesOneByOne,
  contentEditable
) => {
  if (Array.isArray(gridData) && gridData?.length > 0) {
    let phases = gridData?.map((el) => el?.phase);
    gridData?.forEach((service) => {
      let serviceDataHtml = [];
      let serviceDef = serviceDefs?.find(
        (def) => def?.workId?.toString() === service?.serviceId?.toString()
      );

      // here i am going to add the phase
      if (
        !!service?.phase &&
        service?.phase !== "N/A" &&
        phases?.includes(service?.phase)
      ) {
        serviceDataHtml.push(
          `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${`#F6CB51`}; color: #fff;">${
            service?.phase
          }</span></p>`
        );
        phases = phases.filter((el) => el !== service?.phase);
      }

      // First we add the name
      serviceDataHtml.push(
        getServiceNameAccordingly(service, serviceDef, false)
      );

      service?.serviceOptions?.forEach((option, optionIndex) => {
        // Case where we have alternate elevation phase
        // if (optionIndex === 1) {
        //   serviceDataHtml.push(
        //     getServiceNameAccordingly(service, serviceDef, true)
        //   );
        // }
        if (Array.isArray(option)) {
          // Add the Elevation Name
          option?.forEach((elevation) => {
            serviceDataHtml.push(
              getElevationName(
                elevation?.elevationLabel,
                elevation?.elevationId,
                showIds
              )
            );
            // Navigate through each PLI
            let PLIs = elevation?.items || [];
            if (Array.isArray(PLIs) && PLIs?.length > 0) {
              PLIs?.forEach((pli, idx) => {
                if (checkIfRowHasValidData(pli) === false) return;
                serviceDataHtml.push(
                  getPLItem(
                    pli,
                    service?.serviceId,
                    service,
                    idx,
                    showIds,
                    contentEditable
                  )
                );
              });
            }
            getElevationExtras(serviceDataHtml, elevation, service);
          });

          // since for each service we will have different particulates
          // And Hoist differs more than others we have to differentiate
        }
      });

      getServiceExtras(serviceDataHtml, service);

      // Lastly we add it to the main array
      toAdd.push(serviceDataHtml.join(""));
      servicesOneByOne.push({
        serviceName: service?.label,
        serviceHtml: serviceDataHtml.join(""),
      });
    });
  }
};

const getServiceNameAccordingly = (service, serviceDef) => {
  let isAlternate = service?.optionIndex === 1;
  if (isAlternate !== true) {
    return `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${
      serviceDef?.wordDocColor || serviceDef?.colorCode || "#000"
    }; color: #fff;">${service?.label}</span></p>`;
  } else {
    return `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${
      serviceDef?.wordDocColor || serviceDef?.colorCode
    }; color: #fff;">${
      service?.label
    }</span><span style="color: #e03e2d; font-size: 14pt; font-weight: bold; background-color: yellow;">- Alternate Phase</span></p>`;
  }
};

const getElevationName = (elevation, id = "", showIds) => {
  return `<p style="padding-left: 80px;"><strong><u>─ ${elevation} ${
    showIds ? id : ""
  }</u></strong></p>
    `;
};

export const getPLItem = (
  item,
  serviceId,
  service,
  idx,
  showIds,
  contentEditable
) => {
  if (serviceId === 3) return ""; // Hoist no PLIS for now
  else if (serviceId === 38) return ""; // Hoist  no PLIS for now
  let temp = `<p contenteditable="${contentEditable}" style="padding-left: 120px; user-select:${
    contentEditable ? "auto" : "none"
  };"><strong>${showIds ? idx + 1 : ""} ─ </strong> ${
    formatDimensions(item || "") || ""
  } ${additionalPLIInfo(item, serviceId)} ${attachPLIDescription(item || "")} ${
    attachPLINote(item) || ""
  }</p>`;
  if (Array.isArray(item?.addons) && item?.addons?.length > 0) {
    temp += `<p contenteditable="${contentEditable}" style="padding-left: 140px;user-select:${
      contentEditable ? "auto" : "none"
    };"><strong>Includes:</strong></p>`;
    item?.addons?.forEach((addon, idx) => {
      temp += `<p contenteditable="${contentEditable}" style="padding-left: 160px;user-select:${
        contentEditable ? "auto" : "none"
      };"> <strong>${idx + 1} ─ </strong>${
        serviceId.toString() === "1"
          ? capitalizeFirstLetter(addon?.sideWalkShedtype?.toString() || "")
          : capitalizeFirstLetter(addon?.name?.name)
      } ${formatDimensions(addon || "")} ${additionalPLIAddonInfo(
        addon,
        serviceId
      )} ${attachPLIDescription(addon || "")}</p>`;
    });
  }
  return temp;
};

function additionalPLIAddonInfo(addon, serviceId) {
  let toReturn = "";
  if (addon?.quantity) {
    toReturn += `[Quantity: ${addon?.quantity}] `;
  }

  let content = addon?.notes || addon?.description || addon?.note || "";

  if (serviceId === 13) {
    if (!addon?.name?.name) {
      let temp =
        addon?.name?.values?.find((e) => e?.name === "Name")?.value || "";
      toReturn += ` (${temp}) `;
    }
    if (addon?.types) {
      toReturn += `[Type: ${addon?.types}] `;
    }
  }

  if (!!content) {
    if (serviceId !== 1) toReturn += ` (${content}) `;
  }

  return toReturn;
}

function evaluateMathExpression(fullExpression, dimension) {
  function evaluate(expression) {
    try {
      let result = eval(expression);
      if (result > 0) {
        return `${Math.round(result * 100) / 100}′${dimension}`;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  if (fullExpression) {
    if (fullExpression.toString().includes(">")) {
      let expressions = fullExpression.split(">");
      return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
    } else {
      return evaluate(fullExpression);
    }
  } else {
    return null;
  }
}

const formatDimensions = (pli, withDescription = false) => {
  if (withDescription) {
  }
  let { length = "", height = "", width = "", depth = "", diameter = "" } = pli;
  let formattedDimensions = [];
  for (const dimension of [
    evaluateMathExpression(length, "L"),
    evaluateMathExpression(height, "H"),
    evaluateMathExpression(width, "W"),
    evaluateMathExpression(depth, "D"),
    evaluateMathExpression(diameter, "D"),
  ]) {
    if (dimension != null) {
      formattedDimensions.push(dimension);
    }
  }

  let toReturn = formattedDimensions.join(" x ") || "";
  return withDescription
    ? toReturn + ` ${!!pli?.description ? "(" + pli?.description + ")" : ""}`
    : toReturn;
};

// IN HERE WE DEFINE EXTRA PARTICULARITIES FOR EACH SERVICE
const additionalPLIInfo = (pli, serviceId) => {
  let toReturn = "";

  // Scaffold
  if (serviceId === 2 || serviceId === 11) {
    if (forceToNumber(pli?.facadeHeight) > 0) {
      toReturn += `(${getMax(forceToNumber(pli?.facadeHeight))}H) `;
    }
    if (pli?.fromFloor) {
      toReturn +=
        serviceId === 11
          ? `from ${pli?.fromFloor} ${pli?.fromFloorNote ?? ""}`
          : ` from ${toOrdinalFloor(pli?.fromFloor)} ${
              pli?.fromFloorNote ?? ""
            }`;
    }
    if (pli?.toFloor) {
      toReturn +=
        serviceId === 11
          ? ` to ${pli?.toFloor} ${pli?.toFloorNote ?? ""} `
          : ` to ${toOrdinalFloor(pli?.toFloor)} ${pli?.toFloorNote ?? ""}`;
    }
    if (pli?.includedCorners) {
      toReturn += ` (including ${evaluateCornerExpression({
        value: pli?.includedCorners,
      })} corners)`;
    }
  }
  // Hoist
  else if (serviceId === 3) {
  }
  // Fence
  else if (serviceId === 4) {
    if (!!pli?.material) {
      toReturn += `${pli?.material}`;
    }
  }
  // Roof Protection
  else if (serviceId === 5) {
    if (!!pli?.stories) {
      toReturn += `${pli?.stories} story building`;
    }
    if (!!pli?.details) {
      toReturn += ` (${
        Array.isArray(pli?.details) && pli?.details?.length > 0
          ? pli?.details?.join(", ")
          : ""
      })`;
    }
  }
  // Elevated Roof Protection
  else if (serviceId === 44) {
    if (!!pli?.type) {
      toReturn += ` [${pli?.type}] `;
    }
    if (!!pli?.stories) {
      toReturn += `${pli?.stories} story building`;
    }
    if (!!pli?.details) {
      toReturn += ` (${
        Array.isArray(pli?.details) && pli?.details?.length > 0
          ? pli?.details?.join(", ")
          : ""
      })`;
    }
  }
  // Overhead Protection
  else if (serviceId === 6) {
    if (!!pli?.stories) {
      toReturn += `${pli?.stories} story building`;
    }
    if (!!pli?.type) {
      toReturn += `(${pli?.type})`;
    }
  }
  // Horizontal netting
  else if (serviceId === 8) {
    if (!!pli?.onFloor && isNumber(pli?.onFloor)) {
      toReturn += `(On ${toOrdinalFloor(pli?.onFloor - 1)} - ${toOrdinalFloor(
        pli?.onFloor
      )})`;
    }
    if (pli?.includingCorners) {
      toReturn += ` including Corners`;
    }
    if (!!pli?.type) {
      toReturn += ` [Type: ${pli?.type}] `;
    }
  }

  // Barriers
  else if (serviceId === 9) {
    if (!!pli?.material) {
      toReturn += ` ${pli?.material} `;
    }
    if (!!pli?.fenceType) {
      toReturn += ` ${pli?.fenceType} `;
    }
  }

  // Shoring
  else if (serviceId === 13) {
    if (!!pli?.type) {
      toReturn += ` [${pli?.type}] `;
    }
    if (!!pli?.number_of_shoring) {
      toReturn += ` [No. of shoring: ${pli?.number_of_shoring}] `;
    }
  }

  // DEBRIS CHUTE
  else if (serviceId === 15) {
    if (!!pli?.diameterInInch) {
      toReturn += ` ${pli?.diameterInInch}'' diameter `;
    }
    if (!!pli?.fromFloor) {
      toReturn += ` from ${toOrdinalFloor(pli?.fromFloor)} `;
    }
    if (!!pli?.toFloor) {
      toReturn += ` to ${toOrdinalFloor(pli?.toFloor)} `;
    }
    if (!!pli?.hoppers) {
      toReturn += ` [Hoppers: ${pli?.hoppers}] `;
    }
  }

  // Vertical Netting
  else if (serviceId === 18) {
    if (!!pli?.fromFloor) {
      toReturn += ` From ${toOrdinalFloor(pli?.fromFloor)} `;
    }
    if (!!pli?.toFloor) {
      toReturn += ` To ${toOrdinalFloor(pli?.toFloor)} `;
    }
  }
  // Needle Beam
  else if (serviceId === 7) {
    if (!!pli?.onFloor) {
      toReturn += ` at ${toOrdinalFloor(pli?.onFloor)} `;
    }
  }

  return toReturn;
};

const attachPLINote = (pli) => {
  if (hasContent(pli?.note?.note)) {
    return `<span style="color:red;">(${stripHtmlTags(
      pli?.note?.note
    )})</span>`;
  } else return "";
};

export const getElevationExtras = (myHtmlData, elevation, service) => {
  // Hoist extras
  if (service?.serviceId?.toString() === "3") {
    // Type of Hoist
    myHtmlData.push(
      `<p style="padding-left: 120px;"><span>${
        elevation?.type === "dual" ? "Dual Hoist" : "Single Hoist"
      }</span></p>`
    );
    // Location of the Hoist
    myHtmlData.push(
      `<p style="padding-left: 120px;"><span>Located in the ${
        elevation?.location
      } ${getNDANote(elevation)}</span></p>`
    );
    // Lift Height
    myHtmlData.push(`<p style="padding-left: 120px;"><span>
    Lift Height: ${elevation?.lift_height ?? "0"}′H [Mast: ${
      elevation?.mastHeight ?? "0"
    }] from (${toOrdinalFloor(
      getFirstStop(elevation?.items ?? [])
    )}) to (${toOrdinalFloor(
      getLastStop(elevation?.items ?? [])
    )})</span></p>`);
    // Stops
    let stopsCount = getStopCount(
      elevation?.items ?? [],
      elevation?.type || "single"
    );
    myHtmlData.push(`<p style="padding-left: 120px;"><span>
    Number of Stops: ${stopsCount.A > 0 ? `Cabin A: ${stopsCount.A}` : ""} ${
      stopsCount.B > 0 ? `Cabin B: ${stopsCount.B}` : ""
    } ${
      elevation?.stops_including?.length !== 0
        ? `including ${elevation?.stops_including?.join(", ")}`
        : ""
    }${
      elevation?.stops_excluding?.length !== 0
        ? ` excluding ${elevation?.stops_excluding?.join(", ")}`
        : ""
    }
    </span></p>`);

    configureHoistHtml(myHtmlData, elevation, service);

    // Hoist Note
    if (hasContent(elevation?.note)) {
      myHtmlData.push(
        `<div style="padding-left: 120px;">${elevation?.note}</div>`
      );
    }
  }

  if (service?.serviceId?.toString() === "38") {
    myHtmlData.push(`<p style="padding-left: 120px;"><span>
    Lift Height: ${elevation?.lift_height ?? "0"}′H`);
    // Hoist Note

    if (hasContent(elevation?.note)) {
      myHtmlData.push(
        `<div style="padding-left: 120px;">${elevation?.note}</div>`
      );
    }
  }

  ///
  if (hasContent(elevation?.elevationDescription)) {
    myHtmlData.push(
      `<div style="padding-left: 120px; color: red; font-weight: bold; text-decoration: underline;"><p>NOTE:</p></div>`
    );
    myHtmlData.push(
      `<div style="padding-left: 120px;">${elevation?.elevationDescription}</div>`
    );
  }
};

export const getServiceExtras = (myHtmlData, service) => {
  // We add the service note
  if (hasContent(service?.note)) {
    myHtmlData.push(
      `<div style="padding-left: 120px; color: red; font-weight: bold; text-decoration: underline;">NOTE:</div>`
    );
    myHtmlData.push(`<div style="padding-left: 120px;">${service?.note}</div>`);
  }
};

const attachPLIDescription = (pli) => {
  if (hasContent(pli?.description?.note)) {
    return `<span>${stripHtmlTags(pli?.description?.note)}</span>`;
  } else return "";
};

const configureHoistHtml = (myHtmlData, elevation, service) => {
  const addons = elevation?.items?.flatMap((item) => {
    return item?.addons?.map((addon) => {
      return addon?.name?.values?.find((val) => val?.name === "Name")?.value;
    });
  });
  const myDataHolder = {};
  let applicableAddons = [];
  const tempData = elevation?.items?.forEach((item) => {
    myDataHolder[item?.floor] = {};
    let currAddons = item?.addons;
    if (Array.isArray(currAddons)) {
      currAddons?.forEach((addon) => {
        let tempName = addon?.name?.values?.find(
          (el) => el?.name === "Name"
        ).value;
        applicableAddons.push(tempName);
        if (!applicableAddons.includes(tempName)) {
          myDataHolder[item?.floor] = {
            ...(myDataHolder[item?.floor] || {}),
            [tempName]: [],
          };
        }

        let currAddon = {
          name: tempName,
          ...addon,
        };
        myDataHolder[item?.floor] = {
          ...(myDataHolder[item?.floor] || {}),
          [tempName]: [
            ...(myDataHolder[item?.floor]?.[tempName] || []),
            currAddon,
          ],
        };
      });
    }
  });

  function groupFloors(floors) {
    const groups = {};

    for (const floor in floors) {
      for (const addon in floors[floor]) {
        for (const length of floors[floor][addon]) {
          if (!groups[addon]) {
            groups[addon] = {};
          }
          if (!groups[addon][length]) {
            groups[addon][length] = [];
          }
          groups[addon][length].push(`${toOrdinalFloor(floor)}`);
        }
      }
    }

    let result = "";

    for (const addon in groups) {
      for (const length in groups[addon]) {
        result += `<li style="margin-left: 120px;">${addon} ${length} - Floor: ${groups[
          addon
        ][length].join(", ")}</li> `;
      }
    }

    return result.trim();
  }

  Object.entries(myDataHolder).forEach(([key, value]) => {
    // iterate through floors
    if (Object.keys(value).length > 0) {
      Object.entries(value).forEach(([addonKey, addonValue]) => {
        myDataHolder[key][addonKey] = addonValue?.map((el) =>
          formatDimensions(el, true)
        );
      });
    }
  });
  const myTempHtml = [];
  let tempHtmlHolder = groupFloors(myDataHolder);
  if (!!tempHtmlHolder) {
    myTempHtml.push(
      `<p style="padding-left: 120px;"><strong>Includes:</strong></p>`
    );
    myTempHtml.push(
      `<p style="padding-left: 140px;"><span>${tempHtmlHolder}</span></p>`
    );
  }

  let tempAllFloors = "";

  // Function to convert a number to its ordinal form (e.g., 1st, 2nd, 3rd)

  // Call the function
  const result = groupBy(
    elevation?.items
      ?.sort((a, b) => forceToNumber(a?.floor) - forceToNumber(b?.floor))
      .map((el) => ({ ...el, height: el?.floor_height })) || [],
    "height"
  );
  let sortedFloors = elevation?.items
    ?.sort((a, b) => forceToNumber(a?.floor) - forceToNumber(b?.floor))
    .map((el) => el.floor);
  do {
    Object.entries(result).forEach(([key, value]) => {
      let floorsArr = value?.map((el) => el?.floor) || [];
      if (floorsArr.includes(sortedFloors[0])) {
        sortedFloors = sortedFloors.filter((el) => {
          return !floorsArr.includes(el);
        });
        if (floorsArr?.length > 0) {
          tempAllFloors += `<li style="margin-left: 120px;">Floor - ${floorsArr
            ?.map((f) => toOrdinalFloor(f))
            .join(", ")}<strong style="color:red;"> [${formatFloorHeight(
            key
          )} H]</strong></li>`;
        }
      }
    });
  } while (sortedFloors?.length > 0);

  // Object.entries(result).forEach(([key, value]) => {
  //   tempAllFloors += `<li style="margin-left: 120px;">Floor - ${value
  //     ?.map((el) => el?.floor)
  //     .join(", ")} <strong style="color:red;">[${formatFloorHeight(
  //     key
  //   )} H]</strong></li> `;
  // });

  // Output the result

  // const myTempHtml = [];

  // Object.values(htmlPush).forEach((html) => {
  //   myTempHtml.push(html);
  // });

  // console.log("htmlWeHave", groupFloors());

  // let myData = [];
  // Object.keys(myDataHolder).forEach((key) => {
  //   let foundFloor = elevation?.items?.find(
  //     (item) => item?.floor.toString() === key.toString()
  //   );
  //   let initialObj = {
  //     floor: key,
  //     dual: foundFloor?.dual,
  //     stop: foundFloor?.stop,
  //   };
  //   if (Object.keys(myDataHolder[key]).length > 0) {
  //     let floorAddons = myDataHolder[key];
  //     if (Object.keys(floorAddons).length > 0) {
  //       Object.keys(floorAddons).forEach((addonKey) => {
  //         let currAddon = floorAddons[addonKey];
  //         if (Array.isArray(currAddon)) {
  //           if (currAddon.length > 0) {
  //             initialObj = {
  //               ...initialObj,
  //               [addonKey]: currAddon
  //                 .map((addon, idx) => {
  //                   return idx + 1 + ". [ " + formatDimensions(addon) + " ]";
  //                 })
  //                 .join(", "),
  //             };
  //           }
  //         }
  //       });
  //     }
  //   }
  //   console.log("myDataHolder", { myDataHolder, myData });

  //   let addonsWeHave = {};
  //   let addonNames = [];

  //   if (Array.isArray(myData) && myData.length > 0) {
  //     myData.forEach((data) => {
  //       addonsWeHave[`${data?.floor}`] = {};
  //       Object.keys(data).forEach((key) => {
  //         if (key !== "floor" && key !== "dual" && key !== "stop") {
  //           if (!addonsWeHave[myData?.floor]?.[key]) {
  //             addonsWeHave[data?.floor] = {
  //               ...(addonsWeHave[data?.floor] || {}),
  //               [key]: [formatDimensions(data[key])],
  //             };
  //           } else {
  //             addonsWeHave[data?.floor][key].push(formatDimensions(data[key]));
  //           }

  //           if (!addonNames.includes(key) && !!key) {
  //             addonNames.push(key);
  //           }
  //         }
  //       });
  //     });
  //   }

  // });
  myHtmlData.push(...myTempHtml);
  myHtmlData.push(
    `<p style="padding-left: 120px;"><strong>Floor Heights:</strong></p>`
  );
  myHtmlData.push(
    `<p style="padding-left: 140px;"><span>${tempAllFloors}</span></p>`
  );
};

function hasContent(str) {
  try {
    if (typeof str !== "string") {
      return false;
      throw new Error("Input must be a string");
    }

    const regex = /(<([^>]+)>)/gi;
    const content = str.replace(regex, "");

    if (typeof content !== "string") {
      throw new Error("Unexpected error: content is not a string");
    }

    return Boolean(content.trim());
  } catch (error) {
    console.error(error.message);
    return false; // Return false if an error occurs
  }
}
function getMax(expression) {
  function evaluate(expression) {
    try {
      // eslint-disable-next-line
      let result = eval(expression);
      if (result > 0 && result !== Infinity) {
        return `${Math.round(result * 100) / 100}′`;
      } else if (result === Infinity) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        return "NaN";
      }
    }
  }

  if (expression.toString().includes(">")) {
    let expressions = expression?.split(">");
    return evaluate(expressions[1]);
  } else {
    return evaluate(expression);
  }
}
function toOrdinalFloor(value) {
  if (value) {
    let prevValue = value;
    value = parseInt(value);
    if (isNaN(value)) return prevValue;
    const j = value % 10,
      k = value % 100;
    if (value === 0) return "Ground Floor";
    else if (value === -1) return "Cellar";
    else if (value === -2) return "Sub Cellar";
    else if (j === 1 && k !== 11) {
      return value + "st floor level";
    } else if (j === 2 && k !== 12) {
      return value + "nd floor level";
    } else if (j === 3 && k !== 13) {
      return value + "rd floor level";
    } else {
      return value + "th floor level";
    }
  } else {
    return "-";
  }
}
function evaluateCornerExpression(params) {
  let fullExpression = params?.value;
  if (fullExpression) {
    if (fullExpression.toString().includes("x")) {
      let expressions = fullExpression?.toString().split("x");
      if (expressions.length === 2) {
        return `${expressions[0]} x ${getMax(expressions[1])}`;
      } else {
        return "Bad format.";
      }
    } else {
      return `${getMax(fullExpression)}`;
    }
  } else {
    return "-";
  }
}
function getStopCount(items, type) {
  let stopsCount = {
    A: 0,
    B: 0,
  };
  items.forEach((item) => {
    if (item?.stop === true) {
      stopsCount.A++;
    }
    if (item?.dual === true && type === "dual") {
      stopsCount.B++;
    }
  });
  return stopsCount;
}

function getFirstStop(items) {
  let firstStop = 0;
  for (let i = 0; i < items.length; i++) {
    if (items[i]?.stop === true) {
      firstStop = items[i]?.floor;
      break;
    }
  }

  return firstStop;
}

function getLastStop(items) {
  let lastStop = 0;
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i]?.stop === true) {
      lastStop = items[i]?.floor;
      break;
    }
  }

  return lastStop;
}

function getNDANote(elevation) {
  let temp = "";
  if (elevation?.access === "nonDirect") {
    temp = `<span>/ Non Direct Access${stripHtmlTags(
      !!elevation?.accessNote ? ` (${elevation?.accessNote})` : ""
    )}</span>`;
  } else if (elevation?.access === "direct") {
    temp = `<span> / Direct Access</span>`;
  }
  return temp;
}
function capitalizeFirstLetter(str = "") {
  // Check if the input string is empty
  if (str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1) + ": ";
}
