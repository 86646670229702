import { Button } from "antd";
// import { ArrowLeftIcon, ArrowRightIcon } from "src/assets/icons";
import { useResponsive } from "../../../hooks";

import "./pagination.scss";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../assets";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const { mobile } = useResponsive();

  if (totalPages === 0) {
    return null;
  }

  return (
    <div className="pagination-container" data-testid="pagination-component">
      {!mobile && (
        <div className="pagination-info-container">
          <p>
            {totalPages === 0
              ? `${0} - ${0} of ${0}`
              : `${currentPage} - ${itemsPerPage} of ${totalPages}`}
          </p>
        </div>
      )}
      <div className="pagination-actions" data-testid="">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={isFirstPage}
          icon={<ArrowLeftIcon />}
          data-testid="previous-page-trigger"
        />

        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={isLastPage}
          icon={<ArrowRightIcon />}
          data-testid="next-page-trigger"
        />
      </div>
    </div>
  );
};

export default Pagination;
