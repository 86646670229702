import { message } from "antd";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, { useState, useContext } from "react";

import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";
import { LoadableComp } from "../../../../SidebarPages/XComponents";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { generateNewLog } from "../../../../../hooks/useLogManagement";
import { columnDefs, chartingOptions, gridFilters } from "./AgGridData";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { TickIcon } from "../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../SidebarPages/DynamicView/src";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";

const CrewsTab = ({ accessRight }) => {
  const { crewTeams, setCrewTeams } = useContext(PayrollContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [gridApi, setGridApi] = useState();
  const [rowToEdit, setRowToEdit] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(false);
  const [newModalVisible, setNewModalVisible] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onEditClick(data) {
    setRowToEdit(data);
    setNewModalVisible(true);
  }

  function onDelete() {
    API.del("crewTeams", `/crewTeams/${teamToDelete?.crewTeamId}`)
      .then(() => {
        setCrewTeams((prev) =>
          prev.filter((el) => el?.crewTeamId !== teamToDelete?.crewTeamId)
        );
        generateNewLog("Delete", {}, teamToDelete, []);
        broadcastNotification(
          "106",
          "onDeleteCrewTeam",
          [
            {
              common: userConfiguration.nameOfUser,
              commonNext: teamToDelete?.crewTeamName,
            },
            {
              recordId: teamToDelete?.crewTeamId,
              userName: userConfiguration.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          "/"
        );
        setTeamToDelete(false);
        message.success(
          `${teamToDelete?.crewTeamName} have been deleted successfully!`
        );
      })
      .catch((err) => {
        message.error("There was a problem deleting this team!");
        console.log("Error: ", err);
      });

    if (teamToDelete?.crewForeman?.crewId) {
      API.put("crews", `/crews/${teamToDelete?.crewForeman?.crewId}`, {
        body: {
          members: [],
        },
      }).catch((error) => console.log("Error updating Employee: ", error));
    }
  }

  return (
    <>
      <LoadableComp loading={false}>
        <PayrollLayout
          {...{
            rowToEdit,
            onEditClick,
            gridFilters,
            setRowToEdit,
            chartingOptions,
            paginationPageSize,
            rowData: crewTeams,
            title: "Crew Teams",
            dataIdKey: "crewTeamId",
            setRowData: setCrewTeams,
            hasNew: accessRight?.write,
            suppressDoubleClickEdit: true,
            modalVisible: newModalVisible,
            setModalVisible: setNewModalVisible,
            getGridApi: (param) => {
              setGridApi(param);
            },
            columnDefs: columnDefs({
              gridApi,
              onEditClick,
              rowData: crewTeams,
              isDarkMode: isDarkMode,
              onDeleteTeam: setTeamToDelete,
              writeAccess: accessRight?.write,
            }),
          }}
        />
        {!!teamToDelete && (
          <WarningModal
            closable={true}
            visible={teamToDelete}
            title="Warning Message"
            setVisible={setTeamToDelete}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>
                Are you sure you want to Delete{" "}
                <b>{teamToDelete?.crewTeamName}</b>?
              </p>
              <div className="buttons">
                <MondayButton
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                  onClick={() => setTeamToDelete(false)}
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={onDelete}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
      </LoadableComp>
    </>
  );
};

CrewsTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
};

export default CrewsTab;
