import Swal from "sweetalert2";
import { Divider } from "antd";
import { v4 as uuid } from "uuid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlusIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { TrashIcon } from "../../../../../SidebarPages/Communication/assets";
import { apiRoutes, fetchData } from "../../../../../SidebarPages/Fleet/utils";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";
import SignatureModal from "../../../../../commonComponents/Signature/components/SignatureModal/SignatureModal";

import "./SignatureBuilder.scss";

function SignatureBuilder({ editorRef }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { proposalData, setProposalData, signatures, setSignatures } =
    useProposalContext();

  const [visible, setVisible] = useState(false);

  const onInsertSignature = async () => {
    // const base64 = signatureRef?.getTrimmedCanvas()?.toDataURL("image/png");
    let ip = await fetchData(apiRoutes.getIp);
    // ip = console.log("onInsertSignature", ip);
    editorRef.current.component.selection.insertHTML(
      `<img src="${base64}" alt="Signed by ${
        userConfiguration?.nameOfUser
      }, 07/09/2024, [${ip || "no_ip_found"}]" />`
    );
    Swal.fire("Signature Proposal Inserted!", "", "success");
  };

  function onDragEnd(event, item) {
    const x = event.clientX;
    const y = event.clientY;
    const elements = document.elementsFromPoint(x, y);
    const isOverTarget = elements?.some((element) =>
      element?.classList?.contains("jodit-workplace")
    );
    event.preventDefault();
    if (editorRef.current && isOverTarget) {
      const dateNow = new Date().toLocaleDateString();
      const signatureToDrop = `<img src="${item}" alt="Signed by ${userConfiguration?.nameOfUser}, ${dateNow}" />`;

      editorRef.current.component.selection.insertHTML(signatureToDrop);
    }
  }

  return (
    <div className="signature-builder">
      <MondayButton Icon={<PlusIcon />} onClick={() => setVisible(true)}>
        Add Signature
      </MondayButton>
      <Divider />
      {visible && (
        <SignatureModal
          {...{
            visible,
            setVisible,
            setSignature: (sign) =>
              !!sign && setSignatures((prev) => ({ ...prev, [uuid()]: sign })),
            editSignIn: false,
          }}
        />
      )}

      <div className="signature-builder">
        {Object.keys(signatures).map((key) => {
          return (
            <div key={key} className="single-sign">
              <img
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("text/html", "<span></span>");
                }}
                onDragEnd={(e) => {
                  onDragEnd(e, signatures[key]);
                }}
                src={signatures[key]}
                alt=""
              />
              <span
                onClick={() =>
                  setSignatures((prev) => {
                    delete prev[key];
                    return { ...prev };
                  })
                }
                className="delete-sign"
              >
                <TrashIcon />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SignatureBuilder;
