import "./AvatarImages.scss";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import AvatarImage from "./AvatarImage";

/* 
  Please do not change this component with your props
  Component uses dispatch to save each of images to not fetch the same image 
*/
const AvatarImages = ({
  maxCount = 5,
  size = 32,
  cognitoUserIds,
  nameOfUsers = [],
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const foundUsers =
    userConfiguration?.allUsers?.Items?.filter((user) =>
      Array.isArray(cognitoUserIds)
        ? cognitoUserIds.includes(user.cognitoUserId)
        : nameOfUsers.includes(user.nameOfUser)
    ) || {};

  return (
    <div className="avatar-images-group">
      <Avatar.Group
        max={{
          count: maxCount,
          popover: { overlayClassName: "avatar-images-max-popover" },
          style: {
            backgroundColor: isDarkMode ? "#2a2b3a" : "#f8f8fa",
            color: isDarkMode ? "#fff" : "#323338",
            fontSize: "14px",
          },
        }}
        size={size}
      >
        {foundUsers.map(({ cognitoUserId }) => (
          <AvatarImage key={cognitoUserId} {...{ cognitoUserId, size }} />
        ))}
      </Avatar.Group>
    </div>
  );
};

export default AvatarImages;
