import { PriceSchemesType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { pliTaxAmountCalculate } from "../controllers/CellEditingEvents";
import { getMaxNoFeet } from "./getMax";

const availableDimensions = ["width", "height", "length"];

export const priceFormatter = (params) => {
  let value = parseFloat(params.value);
  if (params.value?.toString() === "-") return "-";

  if (params.colDef?.field === "rent") {
    if (isNaN(value) || value === Infinity || value < 0) {
      //number 0 or higher are allowed
      // return "INVALID"
      return "-";
    }
  } else {
    //ppu price
    if (isNaN(value) || value === Infinity || value <= 0) {
      //0 is not allowed
      // return "INVALID"
      return "-";
    }
  }

  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
    .format(value)
    .substring(1);
};

/**
 * Calculate price from PPU
 * @param serviceId {number}
 * @param rowData {SimplePLI} PLI data in a table row
 * */
export const calculatePrice = (
  serviceId,
  rowData,
  servicePricingConfig = {}
) => {
  const { priceSchemesType, priceScheme } = servicePricingConfig;
  const { dimension } = priceScheme || {};

  // Helper to force numbers with precision
  const forceToNumber = (value, decimals = 2) => {
    const num = parseFloat(value || 0);
    return isNaN(num) ? 0 : parseFloat(num.toFixed(decimals));
  };

  const ppu = forceToNumber(rowData.ppu);
  if (ppu === 0) return "-"; // Return early if ppu is not valid

  const getSqFt = (height, length) =>
    forceToNumber(height) * forceToNumber(length);

  switch (serviceId) {
    case 1: {
      const length = forceToNumber(rowData?.length);
      return forceToNumber(ppu * length);
    }
    case 2: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 4: {
      const length = forceToNumber(rowData?.length);
      return forceToNumber(ppu * length);
    }
    case 5: {
      const length = forceToNumber(rowData?.length);
      const width = forceToNumber(rowData?.width);
      return forceToNumber(ppu * length * width);
    }
    case 6: {
      const length = forceToNumber(rowData?.height);
      const width = forceToNumber(rowData?.width);
      return forceToNumber(ppu * length * width);
    }
    case 7: {
      const length = getMaxNoFeet(rowData.length);
      return forceToNumber(ppu * length);
    }
    case 8: {
      const length = getMaxNoFeet(rowData.length);
      return forceToNumber(ppu * length);
    }
    case 9: {
      const length = forceToNumber(rowData?.length);
      return forceToNumber(ppu * length);
    }
    case 10: {
      const sqft = getSqFt(rowData.width, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 11:
    case 12:
    case 80: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 13: {
      const num = forceToNumber(rowData?.number_of_shoring);
      return forceToNumber(ppu * num);
    }
    case 14: {
      const length = getMaxNoFeet(rowData.length);
      const height = getMaxNoFeet(rowData.height);
      return forceToNumber(ppu * length * height, 2);
    }
    case 17: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 18:
    case 44: {
      const sqft = getSqFt(rowData.width, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 19: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 73: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 81:
    case 85: {
      const sqft = getSqFt(rowData.height, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    case 86: {
      const sqft = getSqFt(rowData.width, rowData.length);
      return forceToNumber(ppu * sqft);
    }
    default:
      if (priceSchemesType !== PriceSchemesType.TABLE) return;

      const restOfDimensions = availableDimensions.filter(
        (d) => d !== dimension
      );

      const dimensionValueToMultiply = restOfDimensions
        .map((d) => rowData?.[d])
        .filter((v) => v !== undefined)
        .reduce((acc, curr) => acc * forceToNumber(curr), 1);

      return forceToNumber(ppu * dimensionValueToMultiply);
  }
};

export const calculatePPU = (serviceId, rowData) => {
  let height;
  let price = parseFloat(rowData.price);
  if (isNaN(price)) return "-"; //if price is not ready

  switch (serviceId) {
    case 1:
    case 4:
    case 6:
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.height)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );
    case 7:
    case 9:
    case 10:
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.length)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );

    case 11:
    case 12: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 80: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 15:
    case 18: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 81: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 85: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 86: {
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.length)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );
    }
    case 73: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 79: {
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.length)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );
    }
    case 2: {
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    }
    case 20: {
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    }
    case 5:

    case 8:
    case 14:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.width))) *
            100
        ) / 100
      );
    case 19:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    case 73:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    case 69:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    case 44:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.width))) *
            100
        ) / 100
      );
    case 70:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
      break;
    default:
      break;
  }
};
/**
 * @param service {Service}
 * @param pli {SidewalkShedPLI}
 * */
export const calculateRent = (service, pli, optionIndex) => {
  let total =
    optionIndex === 1
      ? Math.round(pli.price * service.alternateRent) / 100
      : Math.round(pli.price * service.rent) / 100;
  return !!total & !isNaN(total) ? total : 0;
};

export const calculateTaxAmount = (params) => {
  const { pli = {} } = params;
  const { price = 0, taxRate = 0 } = pli;
  return forceToNumber(price) * forceToNumber(taxRate);
};

export const calculateTotalPrice = (pli) => {
  let data = pli || {};
  const { pliAddonTotal = 0, price = 0 } = data;
  const taxAmount = pliTaxAmountCalculate(data);
  let temp1 = forceToNumber(pliAddonTotal);
  let temp2 = forceToNumber(price);
  let temp3 = forceToNumber(taxAmount);

  return temp1 + temp2 + temp3;
};

// const definers = {
//   1: sidewalkShed,
//   2: scaffold,
//   3: hoist,
//   4: fence,
//   5: roofProtection,
//   6: overheadProtection,
//   7: needleBeam,
//   8: horizontalNetting,
//   9: barriers,
//   10: workingPlatform,
//   11: systemScaffold,
//   12: stairTower,
//   13: shoring,
//   14: plywoodEnclosure,
//   15: debrisChute,
//   16: turnStiles,
//   17: hangingScaffold,
//   18: verticalNetting,
//   19: windowProtection,
//   20: platformScaffold,
// };
