import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import AddDepartment from "./AddDepartment";

function DepartmentPageHeader({ departmentsList, addMultipleRolesHandler }) {
  return (
    <div className="dep-page-header">
      <InfoText
        {...{
          text: "Get a full overview, from departments to role responsibilities and individual user profiles. Select a department to filter it’s users.",
          style: { maxWidth: "unset" },
        }}
      />

      <div className="dep-page-header-buttons">
        <AddDepartment {...{ departmentsList, addMultipleRolesHandler }} />
      </div>
    </div>
  );
}

export default DepartmentPageHeader;
