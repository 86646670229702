import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderFilter from "./HeaderFilter/HeaderFilter";
import ProposalCard from "./ProposalCards/ProposalCard";
import { fetchData } from "../../SidebarPages/Fleet/utils";
import React, { useEffect, useMemo, useState } from "react";
import { SmallLoadableComp } from "../../Sidebars/components";
// import BodyStatistics from "./BodyStatistics/BodyStatistics";
import HeaderStatistics from "./HeaderStatistics/HeaderStatistics";
import { CardTemplate } from "../../pages/Settings/settingsComponents/Management/EditUser/components";

import "./styles/WaitingRoomProposal.scss";

function WaitingRoomProposal() {
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    jobSiteAddress: undefined,
    proposalStatus: undefined,
  });

  function handleFilterChange(key, value) {
    if (key === "reset") {
      setFilters({
        jobSiteAddress: undefined,
        proposalStatus: undefined,
      });
    } else {
      setFilters({
        ...filters,
        [key]: value,
      });
    }
  }

  const filteredCards = useMemo(() => {
    if (!filters?.jobSiteAddress && !filters?.proposalStatus) {
      return rowData;
    }
    return (
      rowData?.filter((item) => {
        return (
          item?.otherDetails?.jobSiteAddress?.includes(
            filters?.jobSiteAddress || ""
          ) && item?.proposalStatus.includes(filters?.proposalStatus || "")
        );
      }) || []
    );
  }, [rowData, filters]);

  useEffect(() => {
    setLoading(true);
    fetchData("proposalBuilds")
      .then((data) => {
        setRowData(data?.sort((a, b) => b?.createdAt - a?.createdAt));
        setLoading(false);
      })
      .catch((error) => {
        console.error("WaitingRoomProposal ~ fetchData ~ error", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className={`waiting-room-proposal ${isDarkMode ? "dark" : ""}`}>
      <HeaderStatistics
        {...{
          title: "PROPOSAL BUILDER",
          description:
            "Here, you can view current proposals and create new ones.",
          onGoBack: () => {
            navigate("/");
          },
        }}
      />
      <div className="waiting-room-proposal_body">
        <HeaderFilter
          {...{
            handleFilterChange,
            filters,
            rowData,
          }}
        />
        {/* <div className="waiting-room-proposal_body-statistics">
          <BodyStatistics
          {...{
            rowData: filteredCards,
            }}
            />
            </div> */}
        <CardTemplate
          {...{
            title: "Proposals List",
            isDarkMode,
            className: "waiting-room-proposal_body-cards",
          }}
        >
          <SmallLoadableComp loading={loading}>
            {filteredCards.map((item, index) => {
              return (
                <ProposalCard
                  key={index}
                  title={item?.proposalStatus ?? item?.otherDetails?.title}
                  item={item}
                />
              );
            })}
          </SmallLoadableComp>
        </CardTemplate>
      </div>
    </div>
  );
}

export default WaitingRoomProposal;
