export const fingerCheckEndpoints = {
  payroll:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/payroll",
  payrollHr:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/payrollHr",
  employees:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/employees",
  employeeRates:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/employeeRates",
  liveReport:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/liveReport",
  employeeReport:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/employeeReport",
  getLiveEntries:
    "https://leadmanager-sockets.ue.r.appspot.com/api/payroll/getLiveEntries",
  getPaidHours:
    "https://leadmanager-sockets.ue.r.appspot.com/api/fingerCheck/fingerCheckPaidHours",
};

// export const fingerCheckEndpoints = {
//   payroll: "http://localhost:8080/api/fingerCheck/payroll",
//   payrollHr: "http://localhost:8080/api/fingerCheck/payrollHr",
//   employees: "http://localhost:8080/api/fingerCheck/employees",
//   employeeRates: "http://localhost:8080/api/fingerCheck/employeeRates",
//   liveReport: "http://localhost:8080/api/fingerCheck/liveReport",
//   employeeReport: "http://localhost:8080/api/fingerCheck/employeeReport",
//   getLiveEntries: "http://localhost:8080/api/payroll/getLiveEntries",
//   getPaidHours: "http://localhost:8080/api/fingerCheck/fingerCheckPaidHours",
// };
