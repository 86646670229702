import { API } from "aws-amplify";
import { showSuccessMsg } from "../../../../../../../../utils";
import {
  programFields as dispatchProgramFields,
  userGroups,
} from "../../../../../../../../actions";
import { message } from "antd";
import broadcastNotification from "../../../../../../../../helpers/controllers/broadcastNotification";
import { Patch } from "../../../../../../../../utils/functions";

const addMultipleRoles = async (
  newRoles = [],
  selectedDepartment,
  dispatch,
  userGroupsArr = [],
  callbackFn = () => {},
  createDepartment = false,

  programFields,
  userConfiguration
) => {
  if (!newRoles.length || !selectedDepartment) {
    message.error("Please fill out all fields before submitting.");
    return;
  }

  const pref =
    userGroupsArr?.find((group) => group?.groupName === "Super Admin")?.[
      "groupConfigs"
    ]?.["preferences"] || {};

  const rolePromises = newRoles.map((roleName) =>
    API.post("userGroups", "/userGroups", {
      body: {
        departmentName: selectedDepartment,
        groupName: roleName,
        groupConfigs: {
          preferences: pref,
        },
      },
    })
  );

  try {
    const results = await Promise.all(rolePromises);
    dispatch(userGroups([...userGroupsArr, ...results]));
    showSuccessMsg({ content: "Roles created successfully!" });
    if (createDepartment) {
      message.loading("Creating department...");
      await createDepartmentFn(
        selectedDepartment,
        "#ccc",
        newRoles,
        programFields,
        dispatch,
        userConfiguration
      );
    }
    callbackFn();
  } catch (error) {
    console.error("Error adding multiple roles:", error);
    message.error("An error occurred while creating roles.");
    callbackFn();
  }
};

export default addMultipleRoles;

async function createDepartmentFn(
  newDepartmentName,
  color,
  roles = [],
  programFields,
  dispatch,
  userConfiguration
) {
  const newDepartment = {
    departmentName: newDepartmentName,
    roles,
    color,
  };

  const departmentsList =
    programFields.find(({ fieldName }) => fieldName === "Departments") || {};

  const departmentsColors =
    programFields.find(({ fieldName }) => fieldName === "Department Colors") ||
    {};

  const updatedFieldOptions = [...departmentsList.fieldOptions, newDepartment];

  const updatedDepartmentColors = {
    ...departmentsColors.fieldOptions,
    [newDepartmentName.toLowerCase()]: color,
  };

  const updatedProgramFields = programFields.map((item) => {
    if (item.fieldName === "Departments") {
      item.fieldOptions = updatedFieldOptions;
    }
    if (item.fieldName === "Department Colors") {
      item.fieldOptions = updatedDepartmentColors;
    }
    return item;
  });

  await Promise.all([
    Patch("programFields", departmentsColors.fieldId, {
      fieldOptions: updatedDepartmentColors,
    }),
    Patch("programFields", departmentsList.fieldId, {
      fieldOptions: updatedFieldOptions,
    }),
  ]).then(() => {
    dispatch(dispatchProgramFields(updatedProgramFields));
    const common = userConfiguration.nameOfUser;
    const commonNext = newDepartmentName;
    const userName = userConfiguration.nameOfUser;
    const currentUser = userConfiguration.cognitoUserId;

    broadcastNotification(
      "64",
      "onCreateDepartment",
      [
        { common, commonNext },
        { userName, currentUser },
      ],
      ""
    );
    message.success("Department created successfully!");
  });
}
