import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";
// import { useRedux } from "../../../../../SidebarPages/hooks";
// import Swal from "sweetalert2";
import { MondayButton } from "../../../../../commonComponents";
// import { OpenedEyeIcon } from "../../../../../../assets";
// import { fetchAllData } from "src/components/SidebarPages/Fleet/utils/fetchAllData";
// import { groupBy } from "src/components/SidebarPages/Accounting/components/utilities";
import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { DriveIcon } from "src/components/SidebarPages/Communication/assets";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";
import ProposalDocumentSelector from "./ProposalDocumentSelector";

function PdfsFinderFromDocuments({ customFilesChanger, customFiles }) {
  const [visible, setVisible] = useState(false);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { getFilesByFolderId, ...rest } = driveApi({ accessToken });
  const { proposalData } = useProposalContext();

  const [labyrinthData, setLabyrinthData] = useState({ folderOptions: [] });

  const onInitialize = async () => {
    let docId = proposalData?.estimation?.googleDriveFolderIds?.docObject || "";
    if (!!docId) {
      await getFilesByFolderId(docId).then(async (res) => {
        let files = await res?.json().then((res) => res?.files || []);
        setLabyrinthData((prev) => ({ ...(prev || {}), folderOptions: files }));

        for (const file of files) {
          if (file?.id) {
            await getFilesByFolderId(file?.id).then(async (res) => {
              let files2 = await res?.json().then((res) => {
                setLabyrinthData((prev) => {
                  let tempOptions = prev;
                  tempOptions?.folderOptions?.map((option) => {
                    if (option?.id === file?.id) {
                      option.files = res?.files;
                      return option;
                    } else return option;
                  });
                  return tempOptions;
                });
              });
            });
          }
        }
      });
    }
  };

  const onGetRespectiveFiles = async () => {
    setLabyrinthData((prev) => {
      let tempOptions = prev?.folderOptions || [];
      for (const option of tempOptions) {
        if (option?.files) {
          for (const file of option?.files) {
            if (file?.id) {
              rest.getImageSrc(file?.id).then((res) => {
                file.blob = res.blob;
                file.src = res.src;
                file.category = option?.name;
              });
            }
          }
        }
      }
      return { ...prev, folderOptions: tempOptions };
    });
  };
  const onOpenModal = async () => {
    // Initialize files
    // await onInitialize();
    // await onGetRespectiveFiles();
    setVisible(true);
  };

  const onSave = async (selectedToSave) => {
    console.log("selectedToSave", selectedToSave);
    // if (Array.isArray(selectedToSave)) {
    //   let selectedFiles = [];
    //   labyrinthData?.folderOptions?.forEach((folder) => {
    //     if (folder?.files) {
    //       folder?.files?.forEach((file) => {
    //         if (selectedToSave.includes(file?.id)) selectedFiles.push(file);
    //       });
    //     }
    //   });
    customFilesChanger(selectedToSave);
    setVisible(false);
    // }
  };
  const onDiscard = () => {
    setVisible(false);
  };

  const groupApplicationsByProjectAndSov = (applications) => {
    let temp = {};
    let TOTALITIES = {
      CURRENT_PAYMENT: 0,
      CURRENT_RETAINAGE: 0,
    };
    for (const application of applications) {
      let { projectId = "", scheduleId = "" } = application;
      if (!temp[`${projectId}-${scheduleId}`]) {
        temp[`${projectId}-${scheduleId}`] = [];
      } else temp[`${projectId}-${scheduleId}`].push(application);
    }
    if (Object.keys(temp).length > 0) {
      Object.entries(temp).forEach(([key, value]) => {
        let tempArr = value || [];
        if (tempArr.length > 0) {
          tempArr.sort((a, b) => a?.applicationNo - b?.applicationNo);
          // get last req
          let lastReq = tempArr[tempArr.length - 1];
          TOTALITIES.CURRENT_PAYMENT += forceToNumber(
            lastReq?.totalities?.currentPaymentDue || 0
          );
          TOTALITIES.CURRENT_RETAINAGE += forceToNumber(
            lastReq?.totalities?.currentRetainage || 0
          );
          console.log("LAST REQ", { TOTALITIES, lastReq });
          // temp[key] = lastReq;
        }
      });
    }
  };

  return (
    <div>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<DriveIcon />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpenModal();
        }}
      >
        Drive Files
      </MondayButton>
      {/* <Select
        // defaultValue=""
        placeholder="Select documents"
        style={{ width: 120 }}
        // onChange={onChange}
        options={labyrinthData?.folderOptions?.map((option) => ({
          value: option?.id,
          label: option?.name,
        }))}
      /> */}
      {visible && (
        <NormalSizedModal
          {...{
            visible,
            setVisible,
            closable: true,
            title: "Documents from Drive",
            centered: true,
            footerModal: null,
            onCancel: () => setVisible(false),
          }}
        >
          <ProposalDocumentSelector {...{ proposalData, onSave, onDiscard }} />
          {/* <TableFiles
          {...{
            labyrinthData,
            onSave,
            onDiscard,
          }}
        /> */}
        </NormalSizedModal>
      )}
    </div>
  );
}

export default PdfsFinderFromDocuments;

const TableFiles = ({ labyrinthData, onSave, onDiscard }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [flattenedFiles, setFlattenedFiles] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    let temp = labyrinthData?.folderOptions?.reduce(
      (acc, { files = [], ...rest }) => {
        files.forEach((file) =>
          acc.push({
            ...file,
            ...rest,
            filename: file.name,
            key: file.id,
            folderName: rest?.name,
            moreInfo: (
              <a onClick={() => window.open(file?.blob, "_blank")}>View File</a>
            ),
          })
        );
        return acc;
      },
      []
    );
    setFlattenedFiles(temp);
  }, [labyrinthData]);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{
        width: "1200px",
        mouseEvents: "none",
      }}
    >
      <Table
        rowSelection={rowSelection}
        columns={[
          {
            title: "File Name",
            dataIndex: "filename",
          },
          {
            title: "Category/Folder",
            dataIndex: "folderName",
          },
          {
            title: "More Information",
            dataIndex: "moreInfo",
          },
        ]}
        dataSource={flattenedFiles}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {" "}
        <MondayButton
          hasIcon={false}
          className="mondayButtonRed"
          onClick={onDiscard}
        >
          Discard Changes
        </MondayButton>
        <MondayButton
          hasIcon={false}
          disabled={labyrinthData?.folderOptions?.length === 0}
          onClick={() => onSave(selectedRowKeys)}
        >
          Save Changes
        </MondayButton>
      </div>
    </div>
  );
};
