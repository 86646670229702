import React, { useState, useContext, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { Mentions, Badge, Form, Upload, Popover, Button } from "antd";

import { useMediaQuery } from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";
import { StoreType } from "../../../../SidebarPages/FleetMaintenanceView/types";
import VirtualAssistantContext from "../../data/VirtualAssistantContext";
import { AttachmentsIcon } from "../../../../../assets";
import HoverButton from "../../../HoverButton/HoverButton";
import { showSwalErrorMessage } from "../../../../../utils/swalWindowsMessages";
import { SendIcon } from "../../../../../assets";

import "./AssistantInput.scss";
import axios from "axios";
import AttachmentsView from "../AttachmentsView/AttachmentsView";

function AssistantInput() {
  const { userConfiguration } = useSelector(
    /** @param {StoreType} state */
    (state) => state.userConfig
  );

  const { status, messages, sendMessage } = useContext(VirtualAssistantContext);
  const chatFullHeight = useMediaQuery("(max-height: 767px)");

  const [sendDisabled, setSendDisabled] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const [form] = Form.useForm();

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const inputRef = useRef(null);

  const isBotTyping =
    status === "Online" &&
    messages.find((message) => message.messageStatus === "typing");

  const isOffline = status === "Offline";
  const isDisabled =
    (sendDisabled && !attachments.length) || isOffline || isBotTyping;

  const userOptions = useMemo(() => {
    if (!userConfiguration) {
      return [];
    }

    return userConfiguration.allUsers?.Items?.map(
      ({ cognitoUserId, nameOfUser, identityId }) => ({
        label: nameOfUser,
        value: nameOfUser,
        key: cognitoUserId || identityId,
      })
    );
  }, [userConfiguration]);

  /**
   *
   * @param {string} value
   */
  function onInputChange(value) {
    if (!value?.trim()) {
      if (!attachments.length && !sendDisabled) {
        setSendDisabled(true);
      }
    } else {
      if (sendDisabled) {
        setSendDisabled(false);
      }
    }
  }

  async function sendMessageHandler() {
    if (!isBotTyping) {
      const inputContent = form.getFieldValue("message");
      sendMessage(inputContent);
      form.resetFields();
    }
  }

  /**
   * @param {string} text
   * @param {"@"|"/"} prefix
   */
  function onSearch(text, prefix) {
    if (prefix === "@") {
      setSearchOptions(userOptions);
    } else if (prefix === "/") {
      setSearchOptions([]);
    }
  }

  const onMentionsKeyDown = (event) => {
    if (event.key === "Enter")
      if (event.shiftKey) {
        const textarea = event.target;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const value = textarea.value;

        textarea.value =
          value.substring(0, start) + "\n" + value.substring(end);
        textarea.selectionStart = textarea.selectionEnd = start + 1;
        event.preventDefault();
      } else {
        sendMessageHandler();
      }
  };

  const uploadProps = {
    name: "file",
    action: `http://localhost:8080/api/uploadAttachment`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        setAttachments((prev) => [...prev, info.file.response.data]);
      } else if (info.file.status === "error") {
        showSwalErrorMessage({
          text: "There was a problem uploading file. Please try again.",
        });
      }
    },
    showUploadList: false,
  };

  const onDeleteAttachment = async (file) => {
    try {
      await axios.post(`http://localhost:8080/api/deleteAttachment`, {
        filename: file.filename,
      });
      setAttachments((prev) =>
        prev.filter((item) => item.filename !== file.filename)
      );
    } catch (error) {
      showSwalErrorMessage({
        text: "There was a problem deleting file. Please try again.",
      });
    }
  };

  return (
    <div className="assistant-chat-input" ref={inputRef}>
      <Form form={form}>
        <div
          className={`assistant-input ${
            status === "Offline" ? "input-offline" : ""
          }`}
        >
          {chatFullHeight ? (
            <Badge count={attachments.length} showZero={false} size="small">
              <div className="chat-attachments" data-testid="attachments-icon">
                <Upload {...uploadProps} multiple>
                  <AttachmentsIcon height={24} width={24} />
                </Upload>
              </div>
            </Badge>
          ) : null}
          <Form.Item name="message" className="chat-input-container">
            <Mentions
              placeholder={
                status === "Offline"
                  ? "We're trying to reconnect, please wait..."
                  : "Type here..."
              }
              onChange={onInputChange}
              className="chat-input"
              prefix={["@", "/"]}
              onSearch={onSearch}
              options={searchOptions}
              onKeyDown={onMentionsKeyDown}
              variant="borderless"
              disabled={status === "Offline"}
            />
          </Form.Item>
          {chatFullHeight ? (
            <div
              className={`chat-send ${
                status === "Offline" || sendDisabled || isBotTyping
                  ? "send-disabled"
                  : "send-enabled"
              }`}
            >
              <SendIcon />
            </div>
          ) : null}
          {!chatFullHeight ? (
            <div className="chat-input-actions">
              <div className="chat-attachments" data-testid="attachments-icon">
                <Upload {...uploadProps} disabled={isOffline || true}>
                  <AttachmentsIcon
                    height={24}
                    width={24}
                    className={
                      isOffline || true ? "send-icon-disabled" : "send-icon"
                    }
                  />
                </Upload>
                {!!attachments.length && (
                  <AttachmentsView
                    disabled={isDisabled}
                    items={attachments}
                    onDelete={onDeleteAttachment}
                  >
                    <span
                      className="attachmentsCount"
                      data-test-id="attachments-hover-button"
                    >
                      {`${attachments.length} Attachments`}
                    </span>
                  </AttachmentsView>
                )}
              </div>
              <div
                className={`chat-send ${
                  isDisabled ? "send-disabled" : "send-enabled"
                }`}
                onClick={sendMessageHandler}
              >
                <SendIcon className="send-icon" />
              </div>
            </div>
          ) : null}
        </div>
      </Form>
    </div>
  );
}

export default AssistantInput;
