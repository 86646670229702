import { Modal } from "antd";
import React from "react";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import "./CrewsInOtherSchedules.scss";
import { AgGridReact } from "ag-grid-react";
const CrewsInOtherSchedules = ({
  visible = false,
  crews = [],
  onCancel = () => {},
  scheduleDay = {},
}) => {
  const columnDefs = [
    {
      field: "scheduleAddress",
    },
    {
      field: "typeOfWork",
    },
    {
      field: "scheduleName",
    },
    {
      field: "startTime",
      cellRenderer: (params) => params?.value,
      valueGetter: (params) =>
        params?.data?.startTime
          ? dayjsNY(params?.data?.startTime).format("hh:mm A")
          : null,
    },
    {
      field: "endTime",
      cellRenderer: (params) => params?.value,
      valueGetter: (params) =>
        params?.data?.endTime
          ? dayjsNY(params?.data?.endTime).format("hh:mm A")
          : null,
    },
  ];

  return (
    <div>
      <Modal
        {...{
          open: visible,
          centered: true,
          title: `${crews?.[0]?.crewName} - ${dayjsNY(
            scheduleDay?.startDate
          ).format("MM/DD/YYYY")}`,
          onCancel,
          footer: null,
          closeIcon: <XIcon />,
          className: "crews-in-other-schedules",
          width: 1600,
        }}
      >
        <div
          className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
          style={{ width: "100%", height: 600 }}
        >
          <AgGridReact
            {...{
              columnDefs,
              rowData: crews,
              animateRows: true,
              suppressDragLeaveHidesColumns: true,
              suppressRowClickSelection: true,
              rowSelection: "multiple",
              rowGroupPanelShow: "always",
              pivotPanelShow: "always",
              rowHeight: 45,
              headerHeight: 32,
              groupHeaderHeight: 32,
              floatingFiltersHeight: 20,
              enableCellChangeFlash: true,
              pivotHeaderHeight: 32,
              pivotGroupHeaderHeight: 32,
              defaultColDef: {
                resizable: true,
                enablePivot: true,
                enableColResize: true,
                enableRowGroup: true,
                editable: false,
                sortable: true,
                flex: 1,
                filter: true,
                pivot: true,
                enableRowGroup: true,
                enableValue: true,
              },
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CrewsInOtherSchedules;
