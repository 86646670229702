import { Dayjs } from "dayjs";
import { utils, write } from "xlsx";
import { useSelector } from "react-redux";
import { Form, FormInstance, message, Tooltip } from "antd";
import { UIEvent, useContext, useMemo, useState } from "react";

import { ExcelIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import { EmployeeReportType } from "../../payrollLiveTypes";
import { MondayButton } from "../../../../commonComponents";
import CardComponent from "src/components/CardComponent/CardComponent";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "../LateClockIn/LateClockIn.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

const initialIndex = {
  end: 15,
  start: 0,
};

function NoPunchEmployees(props: Props) {
  const { form } = props;
  const { groupedEntries, employeesReport, controlPanelForm } =
    useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  const searchValue = Form.useWatch("searchField", form);
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = lateEntires.length - scrollStep;
    const maxEndIndex = lateEntires.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 64;

    if (indexPosition >= 5 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let endIndex = prev.end + scrollStep;
        let startIndex = prev.start + scrollStep;
        return {
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
        };
      });
    }

    if (indexPosition <= 0.4) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  const lateEntriesList = useMemo(() => {
    return employeesReport.filter((el) => el?.liveStatus === "No Punch");
  }, [groupedEntries]);

  function onExcelExport() {
    setExportDisabled(true);
    message.loading({ key: "excelExport", content: "Loading", duration: 0 });
    if (!lateEntriesList?.length) {
      message.warning({
        duration: 2,
        key: "excelExport",
        content: "There are no data to export.",
      });
      setExportDisabled(false);
      return;
    }

    try {
      const workbook = utils.book_new();
      const sheetData = (lateEntriesList || []).map((el) => {
        const employeeId = (el?.employeeId || "").split("-")?.[1];
        return {
          "Employee Id": employeeId || "",
          "Employee Name": el?.employeeName || "",
          Foreman: el?.crewForeman || "",
          Crew: el?.crewTeamName || "",
          Date: `${selectedDate?.format?.("MM/DD/YYYY")}`,
          Notes: "",
        };
      });

      const workSheet = utils.json_to_sheet(sheetData);
      utils.book_append_sheet(workbook, workSheet);

      const fileTitle = `No Punch Employees ${selectedDate?.format?.(
        "MM-DD-YYYY"
      )}`;

      const excelBufferNoJob = write(workbook, {
        type: "base64",
        bookType: "xls",
      });

      const file = {
        name: fileTitle + ".xls",
        type: "application/vnd.ms-excel",
        blob: `base64,${excelBufferNoJob}`,
      };

      const url = `data:${file.type};${file.blob}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setExportDisabled(false);
      message.success({
        duration: 2,
        key: "excelExport",
        content: "File exported successfully!",
      });
    } catch (error) {
      setExportDisabled(false);
      message.error({
        duration: 2,
        key: "excelExport",
        content: "Could not create excel file.",
      });
    }
  }

  const lateEntires = useMemo(() => {
    let filteredGroupedEntries: Array<EmployeeReportType> = [];

    for (let i = 0; i < lateEntriesList.length; i++) {
      let pass = true;

      const report = lateEntriesList[i];

      if (
        !!report.employeeName &&
        !report.employeeName
          .toLowerCase()
          .includes((searchValue || "").toLowerCase())
      ) {
        // search matches report name
        pass = false;
      }

      if (pass) {
        filteredGroupedEntries.push(report);
      }
    }

    return filteredGroupedEntries.map((rep, i) => {
      return (
        <div className={`report-card`} key={i}>
          <div className="report-header">
            <label>{rep?.employeeName}</label>
          </div>
          <div className="report-body">
            <label>{rep.employeeId}</label>
            {/* <MondayButton className="mondayButtonBlue">Approve</MondayButton> */}
          </div>
        </div>
      );
    });
  }, [lateEntriesList, searchValue, punchEnd, punchStart]);

  return (
    <CardComponent
      key="late-clock-in"
      className="late-reports-card"
      title={
        <div className="custom-header">
          <label>No Punch Entries</label>
          <Tooltip title="Export No Punch Employees">
            <MondayButton
              onClick={onExcelExport}
              disabled={exportDisabled}
              Icon={<ExcelIcon height={23} width={23} />}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            />
          </Tooltip>
        </div>
      }
    >
      <>
        <div className="cards-wrapper" onScroll={onScroll}>
          {lateEntires?.length ? (
            lateEntires.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="no-data">No Punch Employees</div>
          )}
        </div>
        <span className="reports-count">
          {lateEntires?.length} Total No Punch Employees
        </span>
      </>
    </CardComponent>
  );
}

export default NoPunchEmployees;
