import PropTypes from "prop-types";
import { uniq, groupBy } from "lodash";
import { Modal, Form, Table, message } from "antd";
import React, { useRef, useState, useMemo, useEffect, useContext } from "react";

import {
  onMassEntry,
  onShiftEdit,
  onShiftCreate,
  onDateRangeShiftCreate,
} from "./shiftSaveHandlers";
import {
  getSections,
  employeeKeys,
  camelToTitle,
  findDuplicate,
  getEditFields,
  employeesField,
  getFooterButtons,
  findTeamForEmployee,
  shiftNextDayWarning,
  checkShiftTimeConflicts,
} from "./editShiftModalData";
import {
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../../../commonComponents";
import DegModalContext from "../DegModalContext";
import { InfoIcon } from "../../../../../../../../assets";
import { OVERHEAD_SHIFT_TYPES } from "../../degModalData";
import { filterTables } from "../../../../../../../../utils";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";
import { existingShiftsColumns } from "../TeamShiftsModal/teamShiftModalData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../../SidebarPages/DynamicView/src";
import { BorderedTextCard } from "../../../../../../../SidebarPages/Fleet/components";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import {
  MAP_DARK,
  MAP_DEFAULT,
} from "../../../../../../../SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import PayrollActivityMap from "../../../../Activity/components/PayrollActivityMap/PayrollActivityMap";

import "./ShiftEditModal.scss";

const TITLE_MODES = {
  EDIT: "Shift Edit",
  MASS_ENTRY: "Mass Entry",
  NEW: "New Shift",
};

/**
 * @typedef props
 * @property {boolean} open
 * @property {() => void} onCancel
 * @property {Array<string>} selectedMembers
 * @property {"EDIT" | "NEW" | "MASS_ENTRY"} mode
 * */

/**
 * @param {props} props
 */
function ShiftEditModal({
  open,
  shift = {},
  mode = "EDIT",
  onCancel = () => {},
  selectedMembers = [],
}) {
  const {
    crews,
    rowData,
    jobsites,
    setCrews,
    analytics,
    rowToEdit,
    crewTeams,
    isDarkMode,
    setRowData,
    entriesLogs,
    shiftsGridApi,
    jobsiteServices,
    scheduleMatching,
    allServiceOptions,
    addActionToRegister,
    setScheduleMatching,
    defaultServicesPerProject,
  } = useContext(DegModalContext);
  const [employeeMatch] = useState(
    crews?.find(({ crewId, employeeId }) => {
      const employeeMatch =
        shift?.crewId === crewId || shift?.employeeId === employeeId;

      return employeeMatch;
    })
  );
  const [sowList, setSowList] = useState([]);
  const [alteredRate, setAlteredRate] = useState();
  const [logsVisible, setLogsVisible] = useState(false);
  const [matchedJobsite, setMatchedJobsite] = useState();
  const [regularShift, setRegularShift] = useState(true);
  const [existingShifts, setExistingShifts] = useState([]);
  const [scheduleMatch, setScheduleMatch] = useState(false);
  const [globalDisabled, setGlobalDisabled] = useState(false);
  const [shiftsConflicts, setShiftsConflict] = useState(false);
  const [warningChangeRate, setWarningChangeRate] = useState(false);
  const [existingShiftsWarning, setExistingShiftsWarning] = useState(false);
  const [selectedTeam] = useState(
    findTeamForEmployee(crewTeams, shift?.crewId, shift?.employeeId)
  );
  const [nextDayStatuses, setNextDayStatuses] = useState({
    clockOut: false,
    lunchEnd: false,
    lunchStart: false,
  });

  const sowRef = useRef();

  const { loading, postEntries, updateEntries, removeEntries } =
    useEntriesApi();

  const [form] = Form.useForm();
  const selectedDate = Form.useWatch("punchDate", form);
  const formRate = parseFloat(Form.useWatch("rate", form));
  const lunchIncluded = Form.useWatch("lunchIncluded", form);
  const dateRangeCreation = Form.useWatch("dateRangeCreation", form);

  const items =
    shift?.entries?.length === 1
      ? [{ label: "HR", key: 0 }]
      : shift?.entries?.length > 2
      ? [
          { label: "ID", key: 0 },
          { label: "OL", key: 1 },
          { label: "IL", key: 2 },
          { label: "OD", key: 3 },
        ]
      : [
          { label: "ID", key: 0 },
          { label: "OD", key: 1 },
        ];

  function updateSowList({ currentJobsite }) {
    // schedule service data or project services
    const servicesData =
      jobsiteServices?.[currentJobsite?.jobsiteId] ||
      defaultServicesPerProject?.[currentJobsite?.jobsiteId];

    // services of schedule day in timeStamp or project services
    const servicesInTimeStamp =
      servicesData?.find?.(
        ({ dateRange }) =>
          dateRange?.[0] <= shift?.firstClockIn &&
          shift?.firstClockIn <= dateRange?.[1]
      )?.scheduleServices ||
      defaultServicesPerProject?.[currentJobsite?.jobsiteId];

    if (servicesData && servicesInTimeStamp) {
      // if services of this jobsite are found setSowList
      let updatedSowList = uniq(
        servicesInTimeStamp.concat(currentJobsite?.services)
      );
      setSowList(updatedSowList);
    } else if (
      currentJobsite?.projectId &&
      !jobsiteServices?.[currentJobsite?.jobsiteId]
    ) {
    } else {
      setSowList([]);
    }
  }

  function onShowLogs(e) {
    const selectedEntry = shift?.entries?.[e?.key];

    if (e?.key) {
      setLogsVisible(selectedEntry);
    }
  }

  function showAllServices() {
    const allOptions = allServiceOptions.map(({ serviceName }) => serviceName);
    setSowList(allOptions);
  }

  function onDateSelect(date) {
    let selectedDate = parseInTz(date);
    form.setFieldValue("punchDate", selectedDate);
    const { clockIn, clockOut, lunchStart, lunchEnd } = form.getFieldsValue();
    const servicesOfJobsite = jobsiteServices?.[
      form.getFieldValue("jobsite")
    ]?.find(
      ({ dateRange }) =>
        dateRange[0] <= selectedDate.valueOf() &&
        dateRange[1] >= selectedDate.valueOf()
    )?.scheduleServices;

    if (servicesOfJobsite?.length) {
      setSowList(servicesOfJobsite);
    }

    if (clockIn) {
      form.setFieldValue("clockIn", setHourMinute(selectedDate, clockIn));
    }
    if (clockOut) {
      if (nextDayStatuses["clockOut"]) {
        form.setFieldValue(
          "clockOut",
          setHourMinute(selectedDate.add(1, "day"), clockOut)
        );
      } else {
        form.setFieldValue("clockOut", setHourMinute(selectedDate, clockOut));
      }
    }
    if (lunchStart) {
      if (nextDayStatuses["lunchStart"]) {
        form.setFieldValue(
          "lunchStart",
          setHourMinute(selectedDate.add(1, "day"), lunchStart)
        );
      } else {
        form.setFieldValue(
          "lunchStart",
          setHourMinute(selectedDate, lunchStart)
        );
      }
    }
    if (lunchEnd) {
      if (nextDayStatuses["lunchEnd"]) {
        form.setFieldValue(
          "lunchEnd",
          setHourMinute(selectedDate.add(1, "day"), lunchEnd)
        );
      } else {
        form.setFieldValue("lunchEnd", setHourMinute(selectedDate, lunchEnd));
      }
    }
  }

  function onTimeSelect(time, picker) {
    let dateToConsider = selectedDate || dayjsNY();
    let clockIn = form.getFieldValue("clockIn");
    let selectedTime = dayjsNY(time);
    let timeToSet = undefined;

    if (clockIn) {
      clockIn = setHourMinute(dateToConsider, clockIn);
    }

    if (nextDayStatuses[picker]) {
      timeToSet = setHourMinute(dateToConsider.add(1, "d"), selectedTime);
      form.setFieldValue(picker, timeToSet);
    } else {
      selectedTime = setHourMinute(dateToConsider, selectedTime);
      if (clockIn) {
        if (clockIn.valueOf() > selectedTime.valueOf()) {
          let nextDate = dateToConsider.add(1, "d");
          setGlobalDisabled(true);
          shiftNextDayWarning({
            onConfirm() {
              let pickersToChange = [picker];
              let tmpStatuses = { ...nextDayStatuses };
              if (picker === "lunchStart") {
                pickersToChange.push("lunchEnd");
                pickersToChange.push("clockOut");
              } else if (picker === "lunchEnd") {
                pickersToChange.push("clockOut");
              }
              pickersToChange.forEach((p) => {
                tmpStatuses[p] = true;
                let timeToChange =
                  p === picker ? selectedTime : form.getFieldValue(p);
                if (p === picker) {
                  timeToSet = setHourMinute(nextDate, timeToChange);
                }

                if (timeToChange) {
                  form.setFieldValue(p, setHourMinute(nextDate, timeToChange));
                }
                checkShiftTimeConflicts({
                  form,
                  picker,
                  time: timeToSet?.valueOf(),
                });
              });
              setNextDayStatuses(tmpStatuses);
              setGlobalDisabled(false);
            },
            onDecline() {
              setGlobalDisabled(false);
              form.setFieldValue(picker, undefined);
            },
          });
          return;
        } else {
          form.setFieldValue(picker, selectedTime);
          timeToSet = selectedTime;
        }
      } else {
        form.setFieldValue(picker, selectedTime);
        timeToSet = selectedTime;
      }
    }

    checkShiftTimeConflicts({ form, picker, time: timeToSet?.valueOf() });
  }

  function onJobsiteSelect(jobId) {
    let jobMatch = jobsites?.find(({ jobsiteId }) => jobsiteId === jobId);
    let rate = undefined;

    const tmpSchedule = scheduleMatching?.[jobId];
    if (tmpSchedule) {
      setScheduleMatch(tmpSchedule);
    } else if (jobMatch?.projectId) {
      filterTables("scheduling", "projectId", jobMatch?.projectId)
        .then((res) => {
          if (res?.length) {
            setScheduleMatch(res?.[0]);
          }
        })
        .catch((error) =>
          console.log("Error getting schedule match for jobsite: ", error)
        );
    }

    if (!!jobMatch && jobMatch?.payrollType === "Prevailing Wage") {
      const { rates } = jobMatch;

      let ratePerRole = rates?.[shift?.employeeRole];
      if (ratePerRole) {
        rate = ratePerRole;
      }
    }

    if (!rate) {
      form.setFieldValue("rate", employeeMatch?.employeeRate);
    } else {
      form.setFieldValue("rate", rate);
    }
    form.setFieldValue("sow", []);
    setMatchedJobsite(jobMatch);
    setAlteredRate(rate);
    updateSowList({ currentJobsite: jobMatch });

    sowRef?.current?.focus?.();
    setTimeout(() => {
      if (!sowList?.length) {
        const showAllSowBtn = document.getElementById("show-services-btn");
        showAllSowBtn.click();
      }
    }, 200);
  }

  function onLeaveSelect(leaveReason) {
    let rate = undefined;
    if (employeeMatch) {
      if (leaveReason === "Sick Leave") {
        rate = Number(employeeMatch?.employeeRate) * 0.8;
      } else if (leaveReason === "Unpaid Time Off") {
        rate = 0;
      }
    }
    setAlteredRate(rate);
    const saveButton = document.getElementById("shift-save-btn");
    if (saveButton) {
      saveButton.focus();

    }
  }

  function onTypeChange(e) {
    let isRegular = e?.includes("Regular");
    setRegularShift(isRegular);

    let selectedDate = parseInTz(
      form.getFieldValue("punchDate") || dayjsNY()
    ).startOf("D");
    const { firstClockIn, workHours, breakHours, overtimeHours } = shift;

    let clockIn = isRegular
      ? firstClockIn
        ? dayjsNY(firstClockIn)
        : null
      : selectedDate.hour(7).minute(0).second(0);

    let clockOut = null;

    if (clockIn) {
      clockOut = isRegular
        ? dayjsNY(firstClockIn).add(
            workHours + breakHours + overtimeHours,
            "hour"
          )
        : selectedDate.hour(15).minute(0).second(0);
    }

    form.setFieldsValue({
      sow: [],
      clockIn,
      clockOut,
      reason: null,
      lunchEnd: null,
      lunchStart: null,
    });

    setNextDayStatuses({
      lunchEnd: false,
      lunchStart: false,
      clockOut:
        clockOut.startOf("D").valueOf() > clockIn.startOf("D").valueOf(),
    });

    if (isRegular) {
      if (
        matchedJobsite &&
        matchedJobsite?.["payrollType"] === "Prevailing Wage"
      ) {
        let rate = undefined;
        const { rates } = matchedJobsite;
        let ratePerRole = rates[shift?.employeeRole];
        if (ratePerRole) {
          rate = ratePerRole;
        }
        setAlteredRate(rate);
      } else {
        setAlteredRate();
      }
    } else {
      setAlteredRate();
    }
  }

  const employeeFormField = useMemo(() => {
    return employeesField({ crews, globalDisabled });
  }, [crews, globalDisabled]);

  const formFields = useMemo(() => {
    return getSections({ regularShift }).map((section, key) => (
      <BorderedTextCard
        key={key}
        title={section}
        isDarkMode={isDarkMode}
        className={section === "Shift Address" ? "shiftCard" : ""}
      >
        {RenderDynamicComponents(
          getEditFields({
            mode,
            form,
            crews,
            sowRef,
            sowList,
            jobsites,
            onDateSelect,
            onTimeSelect,
            regularShift,
            onTypeChange,
            selectedDate,
            shiftsGridApi,
            lunchIncluded,
            onLeaveSelect,
            globalDisabled,
            matchedJobsite,
            onJobsiteSelect,
            nextDayStatuses,
            shiftsConflicts,
            showAllServices,
            dateRangeCreation,
            setNextDayStatuses,
          })[section],
          { form }
        )}
      </BorderedTextCard>
    ));
  }, [
    mode,
    form,
    crews,
    sowList,
    jobsites,
    selectedDate,
    regularShift,
    shiftsGridApi,
    lunchIncluded,
    globalDisabled,
    matchedJobsite,
    nextDayStatuses,
    dateRangeCreation,
  ]);

  async function onSave({ changeRateForEmployee = false }) {
    form.validateFields().then((fields) => {
      if (mode === "NEW" && dateRangeCreation) {
        onDateRangeShiftCreate({
          form,
          crews,
          jobsites,
          setCrews,
          onCancel,
          formRate,
          crewTeams,
          rowToEdit,
          setRowData,
          postEntries,
          scheduleMatch,
          setExistingShifts,
          addActionToRegister,
          changeRateForEmployee,
          checkForExisting: true,
          setExistingShiftsWarning,
          employeesHoursPerDay: analytics?.employeesHoursPerDay,
        });
      } else if (mode === "NEW") {
        onShiftCreate({
          form,
          crews,
          formRate,
          onCancel,
          jobsites,
          setCrews,
          rowToEdit,
          crewTeams,
          setRowData,
          postEntries,
          scheduleMatch,
          setExistingShifts,
          addActionToRegister,
          changeRateForEmployee,
          checkForExisting: true,
          setExistingShiftsWarning,
          employeesHoursPerDay: analytics?.employeesHoursPerDay,
        });
      } else if (mode === "EDIT") {
        onShiftEdit({
          shift,
          crews,
          fields,
          rowData,
          formRate,
          setCrews,
          onCancel,
          rowToEdit,
          setRowData,
          alteredRate,
          postEntries,
          selectedTeam,
          updateEntries,
          removeEntries,
          employeeMatch,
          scheduleMatch,
          matchedJobsite,
          addActionToRegister,
          changeRateForEmployee,
        });
      } else {
        onMassEntry({
          form,
          crews,
          rowData,
          jobsites,
          onCancel,
          crewTeams,
          rowToEdit,
          setRowData,
          postEntries,
          updateEntries,
          removeEntries,
          scheduleMatch,
          shiftsGridApi,
          addActionToRegister,
        });
      }

      if (!scheduleMatching?.[matchedJobsite?.jobsiteId]) {
        setScheduleMatching((prev) => ({
          ...prev,
          [matchedJobsite?.jobsiteId]: scheduleMatch,
        }));
      }
    });
  }

  useEffect(() => {
    if (!employeeMatch && mode === "EDIT") {
      message.error("Employee not found!");
    }
  }, [employeeMatch]);

  useEffect(() => {
    const {
      sow,
      hrShift,
      workHours,
      breakHours,
      reason = "",
      firstClockIn,
      jobsiteMatch,
      employeeRate,
      overtimeHours,
      punchDate: shiftDate,
      lunchStart: lunchTime,
      shiftStatus = "Draft",
    } = shift;

    let jobsite = jobsiteMatch?.jobsiteId || null;
    let jobMatch = undefined;

    if (jobsite) {
      jobMatch = jobsites?.find(({ jobsiteId }) => jobsiteId === jobsite);
      const tmpSchedule = scheduleMatching?.[jobsite];
      if (tmpSchedule) {
        setScheduleMatch(tmpSchedule);
      } else {
        filterTables("scheduling", "projectId", jobMatch?.projectId).then(
          (res) => {
            if (res?.length) {
              setScheduleMatch(res[0]);
              setScheduleMatching((prev) => ({
                ...prev,
                [jobMatch?.projectId]: res[0],
              }));
            }
          }
        );
      }
    }

    let clockIn = undefined;
    let clockOut = undefined;
    let lunchEnd = undefined;
    let punchDate = undefined;
    let lunchStart = undefined;

    if (firstClockIn) {
      clockIn = dayjsNY(firstClockIn);
      punchDate = clockIn.startOf("D");
      clockOut = clockIn.add(workHours + breakHours + overtimeHours, "hour");
    } else {
      if (shiftDate) {
        punchDate = dayjsNY(shiftDate).startOf("D");
      } else {
        punchDate - dayjsNY().startOf("D");
      }
    }

    if (lunchTime) {
      lunchStart = dayjsNY(lunchTime);
      lunchEnd = lunchStart.add(breakHours, "hour");
    }

    let tmpNextDayStatuses = { ...nextDayStatuses };
    if (clockOut) {
      if (clockOut.startOf("D").valueOf() > clockIn.startOf("D").valueOf()) {
        tmpNextDayStatuses["clockOut"] = true;
      }
    }

    if (lunchStart) {
      if (lunchStart.startOf("D").valueOf() > clockIn.startOf("D").valueOf()) {
        tmpNextDayStatuses["lunchStart"] = true;
      }
    }

    if (lunchEnd) {
      if (lunchEnd.startOf("D").valueOf() > clockIn.startOf("D").valueOf()) {
        tmpNextDayStatuses["lunchEnd"] = true;
      }
    }

    form.setFieldsValue({
      sow,
      reason,
      jobsite,
      clockIn,
      lunchEnd,
      clockOut,
      punchDate,
      lunchStart,
      shiftStatus,
      selectedMembers,
      rate: employeeRate,
      lunchIncluded: !!breakHours,
      shiftType: hrShift ? "HR Shift" : "Regular Shift",
    });

    if (jobMatch) {
      setMatchedJobsite(jobMatch);
      updateSowList({ currentJobsite: jobMatch });
      let tmpRate = undefined;
      if (jobMatch?.payrollType === "Prevailing Wage") {
        const { rates } = jobMatch;
        let ratePerRole = rates[shift?.employeeRole];
        if (ratePerRole) {
          tmpRate = ratePerRole;
        }
      }
      setAlteredRate(tmpRate);
    }

    if (hrShift) {
      if (Number(employeeRate) !== Number(employeeMatch?.employeeRate)) {
        setAlteredRate(Number(employeeMatch?.employeeRate));
      }
    }

    let arrayOfTimeFrame = [];

    for (const node of shiftsGridApi
      ?.getSelectedNodes?.()
      ?.filter?.(
        ({ data }) => !OVERHEAD_SHIFT_TYPES?.includes(data?.shiftType)
      ) || []) {
      const { data } = node;
      let frame = [data?.firstClockIn, data?.clockOut];
      if (
        arrayOfTimeFrame.some(
          (timeFrame) =>
            (timeFrame?.[0] <= data?.firstClockIn &&
              data?.firstClockIn < timeFrame?.[1]) ||
            (timeFrame?.[0] <= data?.clockOut &&
              data?.clockOut < timeFrame?.[1])
        )
      ) {
        setShiftsConflict(true);
        break;
      } else {
        arrayOfTimeFrame.push(frame);
      }
    }

    setRegularShift(!hrShift);
    setNextDayStatuses(tmpNextDayStatuses);
  }, []);

  useEffect(() => {
    const firstInput = document.querySelector(
      mode === "EDIT"
        ? "[data-testid='shiftType']"
        : mode === "MASS_ENTRY" && shiftsConflicts
        ? "[data-testid='shiftType']"
        : "[data-testid='selectedMembers']"
    );
    if (firstInput?.firstChild) {
      firstInput.firstChild.click();
    }
  }, [mode, shiftsConflicts]);

  useEffect(() => {
    if (mode === "MASS_ENTRY") {
      const selectedNodes = shiftsGridApi
        ?.getSelectedNodes?.()
        ?.flatMap(({ data }) =>
          OVERHEAD_SHIFT_TYPES?.includes(data?.shiftType) ? [] : data
        );
      const selectedTypes = selectedNodes.map((node) => node.shiftType);
      if (
        selectedTypes.filter((shiftType) => shiftType === "HR Shift")
          ?.length === selectedNodes?.length
      ) {
        form.setFieldValue("shiftType", "HR Shift");
        onTypeChange("HR Shift");
      }

      let services = [];
      const uniqueJobsites = groupBy(
        selectedNodes,
        ({ jobsiteId }) => jobsiteId
      );
      for (const jobsiteId in uniqueJobsites) {
        const scheduleDataOfJob = jobsiteServices?.[jobsiteId];
        const nodes = uniqueJobsites[jobsiteId];

        for (const entry of nodes) {
          if (scheduleDataOfJob) {
            const servicesOfJob = scheduleDataOfJob.find(
              ({ dateRange }) =>
                dateRange[0] <= entry?.firstClockIn &&
                entry?.firstClockIn <= dateRange[1]
            );
            const jobsiteDefaultServices = entry?.jobsiteMatch?.services || [];

            if (servicesOfJob?.scheduleServices?.length) {
              services = services
                .concat(servicesOfJob?.scheduleServices)
                .concat(jobsiteDefaultServices);
            }
          }
        }
      }
      const tmpSowList = uniq(services);
      setSowList(tmpSowList);
    }
  }, [mode]);
  console.log("shiftsConflicts: ", shiftsConflicts);
  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        closeIcon: <XIcon />,
        destroyOnClose: true,
        closable: !globalDisabled,
        maskClosable: !globalDisabled,
        ["data-testid"]: "shift-edit-modal",
        title: TITLE_MODES?.[mode] || "New Shift",
        className: `shiftEditModalContainer ${
          isDarkMode && "shiftEditModalContainerDark"
        }`,
        footer: getFooterButtons({
          form,
          mode,
          items,
          loading,
          onCancel,
          onShowLogs,
          isDarkMode,
          employeeMatch,
          globalDisabled,
          onSave: () => {
            const rateToConsider =
              shift?.payrollType === "Prevailing Wage"
                ? shift.jobsiteMatch?.rates?.[shift.employeeRole]
                : shift?.employeeRate;

            if (formRate && formRate !== rateToConsider && mode === "EDIT") {
              setWarningChangeRate(true);
            } else {
              onSave({ changeRateForEmployee: false });
            }
          },
        }),
      }}
    >
      {mode === "EDIT" && (
        <div className="employee-info-section">
          <span className="info-title">Employee Information</span>
          {employeeKeys.map((key, i) => {
            let cls =
              key === "employeeRate" && !isNaN(alteredRate)
                ? "oldCurrency"
                : "";

            let data =
              key === "employeeRate"
                ? formatCurrency(
                    Number(employeeMatch?.employeeRate) || shift[key]
                  )
                : key === "crewTeamName"
                ? selectedTeam?.[key] || "N/A"
                : key === "crewForeman"
                ? selectedTeam?.[key]?.["crewName"] || "N/A"
                : shift[key];

            return (
              <div className={`emp-info ${cls}`} key={i}>
                <b>{camelToTitle(key)}:&nbsp;</b>
                <span>{data}</span>
              </div>
            );
          })}
          {!isNaN(alteredRate) && (
            <div className="emp-info newCurrency">
              <b>Altered Rate:&nbsp;</b>
              <span>{formatCurrency(alteredRate)}</span>
            </div>
          )}
        </div>
      )}
      {mode === "NEW" && (
        <Form form={form} className="employee-info-section">
          {RenderDynamicComponents(employeeFormField, {
            form,
          })}
        </Form>
      )}
      <Form
        form={form}
        style={mode === "NEW" ? { border: "none" } : {}}
        className={mode === "MASS_ENTRY" ? "no-info" : "with-info"}
      >
        <div className="warningContainer">
          {mode === "MASS_ENTRY" &&
            findDuplicate(
              shiftsGridApi
                ?.getSelectedNodes?.()
                ?.map(({ data }) => data?.employeeId)
            ).hasDuplicates && (
              <div className="warningInfo">
                <InfoIcon />
                There are multiple shifts selected for the same employee!
              </div>
            )}
          {mode === "MASS_ENTRY" &&
            shiftsGridApi?.getSelectedNodes()?.find?.(({ data }) => {
              return data?.shiftType === "HR Shift";
            }) && (
              <div className="warningInfo">
                <InfoIcon />
                In selected Shifts there is a HR Shift!
              </div>
            )}
          {mode === "MASS_ENTRY" && shiftsConflicts && (
            <div className="warningInfo">
              <InfoIcon />
              Employee cannot be changed since it would cause conflicts with the
              selected shifts!
            </div>
          )}
        </div>

        {formFields}
        {!!matchedJobsite && regularShift && (
          <PayrollActivityMap
            {...{
              radius: matchedJobsite?.locationRadius,
              geoFenceInfo: matchedJobsite?.geofenceInfo,
              defaultAddress: matchedJobsite?.jobAddress,
              defaultMarker: matchedJobsite?.addressPosition,
              mapOptions: {
                styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
              },
            }}
          />
        )}
      </Form>
      {existingShiftsWarning && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={existingShiftsWarning}
          setVisible={setExistingShiftsWarning}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>
              There are shift conflicts between the existing shifts and the ones
              you are trying to add. Do you still wish to create the shifts?
            </p>
            <div>
              <Table
                dataSource={existingShifts}
                columns={existingShiftsColumns}
              />
            </div>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setExistingShiftsWarning(false)}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  onShiftCreate({
                    form,
                    crews,
                    rowData,
                    onCancel,
                    formRate,
                    setCrews,
                    jobsites,
                    crewTeams,
                    rowToEdit,
                    setRowData,
                    postEntries,
                    scheduleMatch,
                    setExistingShifts,
                    addActionToRegister,
                    checkForExisting: false,
                    setExistingShiftsWarning,
                    changeRateForEmployee: false,
                    employeesHoursPerDay: analytics?.employeesHoursPerDay,
                  });
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {existingShiftsWarning && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={existingShiftsWarning}
          setVisible={setExistingShiftsWarning}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>
              There are shift conflicts between the existing shifts and the ones
              you are trying to add. Do you still wish to create the shifts?
            </p>
            <div>
              <Table
                dataSource={existingShifts}
                columns={existingShiftsColumns}
              />
            </div>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setExistingShiftsWarning(false)}
              >
                No
              </MondayButton>
              <MondayButton
                Icon={<TickIcon />}
                onClick={() => {
                  onShiftCreate({
                    form,
                    crews,
                    rowData,
                    onCancel,
                    formRate,
                    setCrews,
                    jobsites,
                    crewTeams,
                    rowToEdit,
                    setRowData,
                    postEntries,
                    scheduleMatch,
                    setExistingShifts,
                    addActionToRegister,
                    checkForExisting: false,
                    setExistingShiftsWarning,
                    changeRateForEmployee: false,
                    employeesHoursPerDay: analytics?.employeesHoursPerDay,
                  });
                }}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {warningChangeRate && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={warningChangeRate}
          setVisible={setWarningChangeRate}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              Do you wish to set this rate ({formRate}) for the selected
              employees or for this shift only?
            </p>
            <div className="buttons" style={{ gap: 10 }}>
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => {
                  setWarningChangeRate(false);
                  form.setFieldValue("rate", null);
                }}
              >
                {`Don't Change Rate`}
              </MondayButton>
              <MondayButton
                Icon={<TickIcon width={17} height={17} />}
                onClick={() => onSave({ changeRateForEmployee: false })}
              >
                Change Shift Rate
              </MondayButton>
              <MondayButton
                Icon={<TickIcon width={17} height={17} />}
                onClick={() => onSave({ changeRateForEmployee: true })}
              >
                Change Employee Rate
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            title: `Entry Logs`,
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: entriesLogs[logsVisible],
          }}
        />
      )}
    </Modal>
  );
}

ShiftEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedMembers: PropTypes.array,
  mode: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  shift: {
    sow: PropTypes.array,
    degId: PropTypes.string,
    hrShift: PropTypes.bool,
    total: PropTypes.number,
    entries: PropTypes.array,
    crewId: PropTypes.string,
    reason: PropTypes.string,
    company: PropTypes.string,
    shiftId: PropTypes.string,
    clockOut: PropTypes.number,
    geofence: PropTypes.string,
    duration: PropTypes.number,
    otAmount: PropTypes.number,
    totalOvh: PropTypes.number,
    uploadId: PropTypes.string,
    jobsiteId: PropTypes.string,
    ovhAmount: PropTypes.number,
    punchDate: PropTypes.object,
    workHours: PropTypes.number,
    punchTime: PropTypes.object,
    regAmount: PropTypes.number,
    shiftType: PropTypes.string,
    employeeId: PropTypes.string,
    salaryType: PropTypes.string,
    breakHours: PropTypes.number,
    crewTeamId: PropTypes.string,
    lunchStart: PropTypes.number,
    ovhRegular: PropTypes.number,
    uploadName: PropTypes.string,
    scheduleId: PropTypes.string,
    shiftStatus: PropTypes.string,
    ovhOvertime: PropTypes.number,
    payrollType: PropTypes.string,
    companyName: PropTypes.string,
    employeeRate: PropTypes.number,
    employeeRole: PropTypes.string,
    firstClockIn: PropTypes.number,
    overtimeHours: PropTypes.number,
    reimbursement: PropTypes.number,
    scheduleDayId: PropTypes.string,
    jobsiteAddress: PropTypes.string,
    distanceFromJob: PropTypes.number,
    scheduleAddress: PropTypes.string,
    employeeFullName: PropTypes.string,
    punchCoordinates: { lat: PropTypes.number, lng: PropTypes.number },
    jobsiteMatch: {
      jobName: PropTypes.string,
      jobsiteId: PropTypes.string,
      jobAddress: PropTypes.string,
      googleSheetLink: PropTypes.string,
    },
  },
};

export default ShiftEditModal;
