import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Form, message, Tour, Tooltip, Flex } from "antd";
import "./OpportunityModal.scss";
import {
  DropdownWrapper,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import {
  filterTables,
  getCognitosForNotification,
  getObjectChanges,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import {
  OPPORTUNITY_INFORMATION,
  PROJECT_INFORMATION,
  JOBSITE_ADDRESS,
  fieldsJSON,
  body,
} from "./opportunityModalData";
import {
  apiRoutes,
  fetchData,
  getCoordinatesAndZip,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import {
  compareIncluding,
  getLatLngByAddress,
  getRandomColor,
} from "../../../SidebarPages/utils";
import dayjs from "dayjs";
import {
  MondayButton,
  ProgressComponent,
  SortModalFields,
  Stepper,
} from "../../../commonComponents";
import { API } from "aws-amplify";
import { findCommons, findUnCommons } from "../../../../utils";
import { AlternativeAddresses } from "../Components";
import LeadModal from "../LeadModal/LeadModal";
import { ClientModal, ContactModal } from "..";
import { CompanyNameDropdown } from "./components";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { difference, isUndefined, omitBy } from "lodash";
import { useDuplicatePrevention } from "../../../SidebarPages/hooks";
import { ContactsDropDown } from "../ProjectModal/components/OverviewStep/components";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { getAddressComponent } from "../../../SidebarPages/Fleet/utils";
import { quickSort } from "../../../../helpers/SortingAlgorithms";
import { getBinNumber } from "../../../SidebarPages/Projects/DobTab/utils";
import isEmpty from "lodash/isEmpty";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { DropDownArrow } from "../../../SidebarPages/Fleet/components/InputComponent/assets";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { ArrowRightIcon } from "../../../../assets";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { TransferIconNew } from "../../../SidebarPages/DynamicView/src";
import { useEditLogs, useProgressComponent } from "../../../../hooks";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { getSalesTax } from "../../../commonComponents/NextSteps/helpers/getSalesTax";
import {
  JobsiteAddress,
  OpportunityInformation,
  ProjectInfo,
  RecordType,
  TypeOfWork,
} from "./components/OpportunitySteps";
import { driveApi } from "../../../../integrations/DriveRequest";

const findProgramField = (programFields, fieldName) => {
  return (
    programFields.find((field) => field.fieldName === fieldName)
      ?.fieldOptions || []
  );
};

const initialTypeValues = {
  object: {},
  array: [],
  string: "",
  number: 0,
  boolean: false,
};

const getDefaultGeofence = (opportunityAddress, lat, lng) => {
  return [
    {
      shapeId: "807976ac-9250-49cd-bde6-693109cd0457",
      type: "Circle",
      title: opportunityAddress,
      description: "",
      createdAt: Date.now(),
      createdBy: "Lulzim Zenelaj",
      geoFenceInfo: [{ lat, lng, circleRadius: 30 }],
    },
  ];
};

const OpportunityModal = ({
  visible,
  isEditOpportunity = false,
  setVisible,
  rowData,
  oppObject = {},
  setRowData = () => {},
  // changeOrderObject,
  refreshTable = () => {},
  changeOrderData,
  onMultiStepCancel = () => {},
  estimationChangeOrder = false,
  // isNextStep,
  requestId = "",
  afterSaveRequest = async () => {},
}) => {
  const { accountName } = oppObject;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [projectExecutives, setProjectExecutives] = useState([]);
  const [clients, setClients] = useState([]);
  const [leads, setLeads] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [taxRatePopOverVisible, setTaxRatePopOverVisible] = useState(false);
  const [alternativeAddressesVisible, setAlternativeAddressesVisible] =
    useState(false);
  const [opportunityAddressState, setOpportunityAddress] = useState("");
  const [addLatLngAndZip, setAddLatLngAndZip] = useState();
  const [contacts, setContacts] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [altAddressesLatLng, setAltAddressesLatLng] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [leadModalVisible, setLeadModalVisible] = useState(false);
  const [clientModalVisible, setClientModalVisible] = useState();
  const [fetchedOpportunities, setFetchedOpportunities] = useState([]);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [dobInfoModalVisible, setDobInfoModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [teams, setTeams] = useState([]);
  const [open, setOpen] = useState(false);
  const [dobInformation, setDobInformation] = useState({});
  const [uploadFile, setUploadFile] = useState();
  const [requiredBidDueDate, setRequiredBidDueDate] = useState(true);
  const [docConfiguration, setDocConfiguration] = useState({});
  const [isChangeOrderState, setIsChangeOrder] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [geoFenceInfo, setGeoFenceInfo] = useState(
    changeOrderData?.geoFenceInfo || oppObject?.geoFenceInfo || []
  );
  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.opportunityModal || {}
  );
  const [currentStep, setCurrentStep] = useState(0);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Opportunities",
    actionType: "Create",
  });
  const { saveAddedLogs } = useEditLogs();
  const { updateFolderName, getDriveItem } = driveApi({ accessToken });

  const driveFunctions = useCreateDriveFolders("Opportunities");
  const opportunityTeam = Form.useWatch("opportunityTeam", form) || [];
  const [selectedTeam, setSelectedTeam] = useState([]);
  const alternativeAddresses =
    Form.useWatch("alternativeAddresses", { form, preserve: true }) || [];
  const oppAddressForm = Form.useWatch("opportunityAddress", {
    form,
    preserve: true,
  });
  const opportunityAddress = opportunityAddressState || oppAddressForm || "";

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Opportunity"
    )?.videoLink;

  // const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const lat =
    addLatLngAndZip?.coordinates?.lat || oppObject?.opportunityLatitude || null;

  const lng =
    addLatLngAndZip?.coordinates?.lng ||
    oppObject?.opportunityLongitude ||
    null;

  const finalGeofence = geoFenceInfo.length
    ? geoFenceInfo
    : getDefaultGeofence(opportunityAddress, lat, lng);

  const selectedClient =
    clients?.find(({ accountId }) => accountId === selectedCompanyName) || {};

  const selectOptions = {
    opportunityStages: findProgramField(programFields, "Statuses Of Categories")
      .Opportunities,
    taxExemption: findProgramField(programFields, "Tax Exempt options"),
    salesPerson: findProgramField(programFields, "Sales person"),
    recordTypes: findProgramField(programFields, "Opportunity Record Type"),
    laborTypes: findProgramField(programFields, "Labor Types"),
    insuranceTypes: findProgramField(programFields, "Insurance Types"),
    preQualifications: findProgramField(
      programFields,
      "Prequalification Stages"
    ),
    sources: findProgramField(programFields, "Opportunity Source"),
  };

  const {
    accountId,
    primaryContactId,
    googleDriveFolderIds: clientDriveId = [],
    teamsConfiguration: clientTeamsConfiguration = [],
    projectExecutive: clientProjectExecutive,
    leadSalePerson: clientLeadSalePerson,
  } = selectedClient;

  const selectedLead =
    leads?.find(({ leadId }) => leadId === selectedCompanyName) || {};

  const leadId = selectedLead?.leadId;

  const {
    googleDriveFolderIds: leadDriveId = [],
    teamsConfiguration: leadTeamsConfiguration,
    projectExecutive: leadProjectExecutive,
    leadSalePerson,
  } = selectedLead;

  const defaultOppTeam =
    oppObject.opportunityTeam ||
    (Array.isArray(clientTeamsConfiguration) && clientTeamsConfiguration.length
      ? clientTeamsConfiguration
      : Array.isArray(leadTeamsConfiguration) && leadTeamsConfiguration.length
      ? leadTeamsConfiguration
      : []
    ).map(({ value }) => value);

  const initialValues = {
    ...oppObject,
    contacts: oppObject?.contacts?.map(({ id }) => id),
    primaryContact: oppObject?.primaryContact?.id,
    proposalStartDate: oppObject.proposalStartDate
      ? dayjs(oppObject.proposalStartDate)
      : null,
    requestReceivedDate: oppObject.requestReceivedDate
      ? dayjs(oppObject.requestReceivedDate)
      : null,
    bidDueDate: oppObject.bidDueDate ? dayjs(oppObject.bidDueDate) : null,
    ...(!changeOrderData && {
      opportunityTeam: defaultOppTeam,
      salesPerson:
        oppObject.salesPerson || clientLeadSalePerson || leadSalePerson,
      projectExecutive:
        oppObject.projectExecutive ||
        clientProjectExecutive ||
        leadProjectExecutive ||
        "",
    }),
  };

  const primaryContact = useMemo(
    () => contacts.find(({ contactId }) => contactId === primaryContactId),
    [primaryContactId]
  );

  const onDuplication = () => {
    if (isEditOpportunity && oppObject.opportunityName === opportunityAddress) {
      return;
    }

    message.warning("There is an existing opportunity with this information!");
    form.resetFields(["accountName"]);
    setSelectedCompanyName("");
  };

  const existingOpportunity = useDuplicatePrevention({
    listOfRecords: fetchedOpportunities,
    filter: !isEmpty(selectedClient)
      ? {
          accountId: selectedClient?.accountId,
          opportunityAddress,
        }
      : !isEmpty(selectedLead)
      ? {
          leadId,
          opportunityAddress,
        }
      : {},
    notEqualFilter: { isChangeOrder: true },
    compareIncludingKeys: !isEmpty(selectedClient)
      ? ["opportunityAddress", "accountId"]
      : !isEmpty(selectedLead)
      ? ["opportunityAddress", "leadId"]
      : [],
    onDuplication,
    doNotPrevent: !!changeOrderData,
    startFiltering: !!opportunityAddress || !!selectedCompanyName,
  });

  const driveParentId =
    !!selectedCompanyName && !!accountId
      ? clientDriveId?.opportunitiesObject
      : leadDriveId?.opportunitiesObject;

  const parentFolderId =
    driveFunctions?.parentFolderId ||
    oppObject?.googleDriveFolderIds?.oppObject;

  const filteredContacts = useMemo(
    () =>
      contacts?.filter(({ contactAccountName }) =>
        isEmpty(selectedClient)
          ? contactAccountName === selectedLead?.leadCompany
          : contactAccountName === selectedClient?.accountName
      ),
    [selectedClient, selectedLead, contacts]
  );

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;
  const bodyObject = () =>
    body({
      form,
      // zipCode: addLatLngAndZip?.zipCode,
      zipCode: form.getFieldValue("opportunityAddPostalCode"),
      accountId,
      leadId,
      clients,
      leads,
      // aptNumber: form.getFieldValue("opportunityAptNumber"),
      requestReceivedDate: form.getFieldValue("requestReceivedDate"),
      //   projectId: changeOrderData?.projectId,
      googleDriveUploads: [],
      googleDriveFolderIds: oppObject?.googleDriveFolderIds || {
        ...(driveFunctions?.allFolders || {}),
      },
      opportunityLatitude: lat,
      opportunityLongitude: lng,
      opportunityPlaceId:
        oppObject?.opportunityPlaceId || addLatLngAndZip?.place_id,
    });

  const onTaxExemptSelect = async (value) => {
    let taxRate = 0;

    if (value === "Partial") {
      setTaxRatePopOverVisible(true);
      taxRate = await getSalesTax({ zipCode: addLatLngAndZip?.zipCode });
    } else if (value === "Yes") {
      // setTaxRatePopOverVisible(false);
      taxRate = 0;
    } else {
      taxRate = await getSalesTax({ zipCode: addLatLngAndZip?.zipCode });
    }
    console.log("taxRate", taxRate);
    form.setFieldsValue({ taxRate: taxRate * 100 });
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];
    for (const item of uploadedFiles) {
      const fileNameObj = { ...item, uploadedAt: dayjs() };
      uploadedFileNames.push(fileNameObj);
    }
    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );
  };

  const checkAccess = useMemo(() => {
    function findAccess(titleName) {
      return userConfiguration?.routeConfig?.find(
        ({ title }) => title === titleName
      );
    }
    let clientAccess = findAccess("Accounts");
    let leadAccess = findAccess("Leads");
    return {
      clientAccess,
      leadAccess,
    };
  }, [userConfiguration]);

  const ClearOptions = () => {
    setOpen(false);
  };

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("opportunityTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  //region FIELDS DATA
  const inputFields = fieldsJSON({
    form,
    accountName,
    uploadFile,
    isDarkMode,
    requiredBidDueDate,
    fetchedOpportunities,
    hasEstimations: isEditOpportunity && !!oppObject?.estimations?.length,
    projectExecutives: projectExecutives?.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    ),
    projectManagersOptions:
      programFields
        ?.find((prog) => prog.fieldName === "Project Managers")
        ?.fieldOptions?.map((manager) => ({
          ...manager,
          label: manager.nameOfUser,
          value: manager.nameOfUser,
        })) || [],
    clients: clients
      ?.map(({ accountName, accountId }) => ({
        label: accountName,
        value: accountId,
      }))
      ?.filter(Boolean),
    leads: leads
      ?.filter(({ leadStatus }) => leadStatus !== "Converted")
      ?.map(({ leadCompany, leadId }) => ({
        label: leadCompany,
        value: leadId,
      }))
      ?.filter(Boolean),
    opportunityStages: selectOptions?.opportunityStages
      ?.filter(({ status }) => status)
      .map(({ statusName }) => statusName),
    sources: selectOptions.sources,
    contacts: filteredContacts,
    isContactDisabled: !selectedCompanyName,
    accessToken,
    fileNames,
    parentId: driveFunctions?.allFolders?.["Scope Sheet/Bid Form"],
    taxRatePopOverVisible,
    changeOrderData,
    onPickerSuccess,
    onTaxExemptSelect,
    alternativeAddressesVisible,
    setSelectedCompanyName,
    setOpportunityAddress,
    opportunityTeam: teams?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    onSelect,
    onDeselect,
    recordTypes: selectOptions?.recordTypes?.map(({ type }) => type),
    salesPerson: selectOptions?.salesPerson,
    taxExemptOptions: selectOptions?.taxExemption?.map(({ name }) => name),
    laborTypes: selectOptions.laborTypes,
    insuranceTypes: selectOptions.insuranceTypes,
    checkAccess,
    AlternativeAddressesComp: (
      <AlternativeAddresses
        form={form}
        setVisible={setAlternativeAddressesVisible}
      />
    ),
    AlternativeAddressesTitle: (
      <span className="opportunityModalPopoverTitle">
        Alternative Addresses
      </span>
    ),
    companyNameDropdown: (menu) => (
      <CompanyNameDropdown
        {...{ menu, setClientModalVisible, setLeadModalVisible, checkAccess }}
      />
    ),
    contactsDropDown: (menu) => (
      <ContactsDropDown {...{ menu, setContactModalVisible }} />
    ),
    setAlternativeAddressesVisible,
  });

  const {
    [PROJECT_INFORMATION]: projectInfoFields,
    [OPPORTUNITY_INFORMATION]: oppInfoFields,
    [JOBSITE_ADDRESS]: jobAddres,
  } = inputFields;

  oppInfoFields?.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  const resetForm = () => {
    const formItemNames = Object.keys(form.getFieldsValue());

    //if we are creating inside a record, do not reset the record values
    !!accountName
      ? form.resetFields(difference(formItemNames, Object.keys(oppObject)))
      : form.resetFields();

    setSelectedCompanyName("");
    setOpportunityAddress("");
    setAddLatLngAndZip("");
    setTaxRatePopOverVisible(false);
    driveFunctions?.reset();
    setFileNames([]);
    setAltAddressesLatLng([]);
    setCancelModalVisible(false);
    setSaving(false);
  };

  const onCancel = () => {
    onMultiStepCancel(0);
    !isEditOpportunity &&
      driveFunctions.delete().catch((e) => {
        console.error(e);
      });
    setFormChanged(false);
    setVisible(false);
    setCancelModalVisible(true);
    resetForm();
    // onMultiStepCancel(0);
  };

  // const onCancelModal = () => {
  //   setVisible(false);
  // };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const validateOppForm = async (onSuccessFunc) => {
    const oppAddress = form.getFieldValue("opportunityAddress") || "";
    if (oppAddress?.replace(/\s/g, "").length >= 3) {
      await validateForm(form, onSuccessFunc);
    } else {
      message.error(
        "The Jobsite Address needs to be more than 3 characters long!"
      );
    }
  };

  const addBorough = () => {
    const formattedAddDetails = addLatLngAndZip?.address_components?.reduce(
      (acc, { long_name, types = [] }) => ({
        ...acc,
        [types[0]]: long_name,
      }),
      {}
    );

    return formattedAddDetails?.political || formattedAddDetails?.locality;
  };

  const handleSaveDocumentation = async ({
    opportunityId,
    opportunityAddress,
  }) => {
    const folderId = driveFunctions?.allFolders?.["Scope Sheet/Bid Form"];
    const googleDriveUploads = fileNames.flatMap(({ files }) => files);

    if (googleDriveUploads.length && folderId && !!uploadFile) {
      await API.post("documentation", "/documentation", {
        body: {
          categoryName: "Opportunities",
          docLogs: [
            {
              nameOfUser: userConfiguration?.nameOfUser,
              updatedAt: Date.now(),
              updatedKeys: [{ label: "Created" }],
            },
          ],
          docObject: docConfiguration.docObject,
          docStatus: "On Hold",
          docType: "Scope Sheet/Bid Form",
          expirationDate: "",
          folderId,
          googleDriveUploads,
          recordId: opportunityId,
          recordName: opportunityAddress,
          renewalId: "",
          requestedDate: "",
        },
      })
        .then((res) => {
          saveAddedLogs({
            recordId: res?.docId,
            recordName: `${res?.docType} ${!!res?.renewalId && "(Renewal)"}`,
            category: "Documentation",
            topic: res?.recordName,
          });
        })
        .catch((e) => console.error(e));
    } else {
      await driveFunctions.delete("Scope Sheet/Bid Form");
    }
  };

  const contactIdObj = filteredContacts.reduce(
    (
      acc,
      {
        contactFirstName = "",
        contactLastName = "",
        contactId,
        contactEmail = "",
        contactRole = "",
      }
    ) => ({
      ...acc,
      [contactId]: {
        id: contactId,
        name: `${contactFirstName} ${contactLastName}`.trim() || contactEmail,
        email: contactEmail,
        role: contactRole,
      },
    }),
    {}
  );

  //region ONSAVE
  const onSave = async (action) => {
    let res;
    const {
      primaryContact,
      contacts = [],
      opportunityStage: formOpportunityStage,
    } = form.getFieldsValue();

    const { opportunityTeam, projectExecutive, ...otherBodyValues } =
      bodyObject();
    let opportunityLatitude = otherBodyValues?.opportunityLatitude;
    let opportunityLongitude = otherBodyValues?.opportunityLongitude;

    if (!opportunityLatitude) {
      const locationData = await getLatLngByAddress(
        otherBodyValues.opportunityAddress
      ).catch((err) => {
        console.log("Error getting coordinates for Opportunity", err);
        message.error("Coordinates for this address were not found.");
        return;
      });
      if (locationData?.coordinates?.lat) {
        opportunityLatitude = locationData.coordinates.lat;
        opportunityLongitude = locationData.coordinates.lng;
      }
    }

    function findAccountManager() {
      if (!projectExecutive) return "";
      let foundAccountManager = projectExecutives?.find(
        (el) =>
          el?.firstName === projectExecutive?.split(" ")[0] &&
          el?.lastName === projectExecutive?.split(" ")[1]
      ).accountManager[0];
      return foundAccountManager || "";
    }

    showLoadingMsg();
    // let changeOderObj = {
    //   opportunityStage: "Change Order",
    //   opportunityType: "changeOrder",
    //   projectId: changeOrderData?.projectId,
    // };

    setSaving(true);

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam,
      [],
      true
    );

    let body = {
      ...otherBodyValues,
      projectExecutive,
      geoFenceInfo: finalGeofence,
      borough: addBorough(),
      accountManager: findAccountManager(),
      teamsConfiguration,
      opportunityLatitude,
      opportunityLongitude,
      // projectId: changeOrderData?.projectId,
      primaryContact: contactIdObj?.[primaryContact],
      contacts: contacts?.map?.((contact) => contactIdObj?.[contact]),
      binNumber: dobInformation?.binNumber || "",
      dobAddress: dobInformation?.dobAddress || "",
      dobBlock: dobInformation?.dobBlock || "",
      dobLot: dobInformation?.dobLot || "",
      isChangeOrder: !!changeOrderData === true,
      opportunityStage: !!changeOrderData
        ? "New - Not yet started"
        : formOpportunityStage,
      // opportunityType: !!changeOrderData,
      projectId: !!changeOrderData ? changeOrderData?.projectId : "",
      alternativeAddresses: altAddressesLatLng.map(
        ({
          formatted_address,
          coordinates = {},
          // address_components = [],
        }) => ({
          address: formatted_address,
          lat: coordinates?.lat,
          lng: coordinates?.lng,
          // aptNumber:
          //   address_components?.find((el) => el.types[0] === "subpremise")
          //     ?.long_name || "",
        })
      ),
    };
    try {
      if (Object.keys(body.googleDriveFolderIds).length === 0) {
        const parentId = !!changeOrderData
          ? changeOrderData?.googleDriveFolderIds?.changeOrderObject
          : driveParentId;

        const parentFolderName = !!changeOrderData
          ? `${opportunityAddress} ${dayjs.tz().format("DD/MM/YYYY HH:mm:ss")}`
          : opportunityAddress;

        const { folders } = await driveFunctions.create({
          parentFolderName,
          parentId,
        });
        body.googleDriveFolderIds = folders;
      }
    } catch (error) {
      console.log(error);
      body.googleDriveFolderIds = null;
    }

    console.log({ body });

    setVisibleCreationProgress({ action });
    updateProgressStatus({ updatingRecord: "executing" });
    await API.post(apiRoutes.opportunities, `/${apiRoutes.opportunities}`, {
      body: { ...body },
    })
      .then(async (r) => {
        setVisibleCreationProgress({ ...r, action });
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        if (!!requestId) {
          await afterSaveRequest({
            newRecordId: r?.opportunityId,
            recordName: r?.opportunityAddress,
            // requestId,
            path: "opportunities",
            // cognitoUserId: userConfiguration?.cognitoUserId,
          });
        }
        saveAddedLogs({
          recordId: r?.opportunityId,
          recordName: r?.opportunityAddress,
          category: "Opportunities",
          topic: r?.isChangeOrder ? "Change Order" : "",
        });

        await handleSaveDocumentation(r);

        console.log({ r });

        //updating lead where is there an selected lead
        if (leadId) {
          API.patch(apiRoutes.leads, `/${apiRoutes.leads}/${r?.leadId}`, {
            body: { leadStatus: "In Process" },
          }).catch((e) => {
            if (r?.leadId && r?.leadId !== "")
              message.error("Lead status was not updated successfully!");
          });
        }

        showSuccessMsg();
        resetForm();
        refreshTable([r]);
        res = r;
        // await broadcastNotification(
        broadcastNotification(
          "2",
          "onCreation",
          [
            { common: user },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          r.opportunityId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      })
      .catch((error) => {
        console.error({ error });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
    return res;
  };

  const hasFolderCreated = async () => {
    const driveRes = await getDriveItem(parentFolderId).then((res) =>
      res.json()
    );

    return !driveRes?.error;
  };

  //region Update Opportunity
  const onUpdateOpportunity = async () => {
    const { opportunityStatus, ...opportunityBody } = bodyObject();

    const formContacts = form.getFieldValue("contacts") || [];
    const primaryContact = form.getFieldValue("primaryContact");

    const newOppBody = {
      ...opportunityBody,
      alternativeAddresses: altAddressesLatLng.map(
        ({ formatted_address, coordinates = {} }) => ({
          address: formatted_address,
          lat: coordinates?.lat,
          lng: coordinates?.lng,
        })
      ),
      geoFenceInfo: finalGeofence,
      primaryContact: contactIdObj?.[primaryContact],
      contacts: formContacts?.map((contact) => contactIdObj?.[contact]),
    };

    const updatedOpp = Object.entries(
      getObjectChanges(oppObject, newOppBody)
    ).reduce((acc, [key, value]) => {
      const prevValue = oppObject?.[key];
      const finalType = Array.isArray(prevValue) ? "array" : typeof prevValue;

      return {
        ...acc,
        [key]: value === undefined ? initialTypeValues[finalType] : value,
      };
    }, {});

    const finalUpdatedOpp = omitBy(updatedOpp, isUndefined);

    if (Object.values(finalUpdatedOpp).length) {
      await API.patch(
        apiRoutes.opportunities,
        `/${apiRoutes.opportunities}/${oppObject.opportunityId}`,
        { body: finalUpdatedOpp }
      )
        .then(async () => {
          afterSaveRequest(finalUpdatedOpp);

          if (finalUpdatedOpp?.opportunityAddress) {
            if (await hasFolderCreated()) {
              updateFolderName(
                parentFolderId,
                finalUpdatedOpp.opportunityAddress
              );
            }
          }

          saveAddedLogs({
            recordId: oppObject.opportunityId,
            recordName: oppObject.opportunityAddress,
            category: "Opportunities",
            actionType: "Setup Update",
            updatedKeys: Object.keys(finalUpdatedOpp),
          });
        })
        .catch(async (error) => {
          message.error("There was a problem updating project!");
          console.log("updateProject Error: ", error);
          throw error;
        });
    }

    handleSaveDocumentation({ ...oppObject, ...finalUpdatedOpp });

    setVisible(false);
  };

  const onSaveAndContinue = () => {
    if (uploadFile) {
      if (fileNames[0]?.files?.length) {
        onSave("onSaveAndContinue");
      } else {
        message.error("Please upload a file");
      }
    } else {
      onSave("onSaveAndContinue");
    }
  };

  const onFinish = () => {
    if (uploadFile) {
      if (fileNames[0]?.files?.length) {
        onSave("onFinish");
      } else {
        message.error("Please upload a file");
      }
    } else {
      onSave();
    }
  };

  const onOptionClick = ({ key }) => {
    compareIncluding(key, "Save & Close")
      ? validateOppForm(onFinish)
      : validateOppForm(onSave);
  };

  const getArraysLatLng = async (arr = []) =>
    await Promise.all(
      arr.map(
        async (el) =>
          await {
            ...(await getCoordinatesAndZip(el)),
            color: getRandomColor(),
          }
      )
    );

  const fetchClients = async () => {
    filterTables("accounts", "accountRecordType", "Client").then((r) => {
      setClients(
        quickSort(r, 0, r.length - 1, "createdAt", "descending")?.filter(
          (acc) => acc?.activeClient === true
        )
      );
    });
  };

  const fetchContacts = async () => {
    await fetchData("contacts").then((res) => {
      setContacts(quickSort(res, 0, res.length - 1, "createdAt", "descending"));
    });
  };

  const onLeadCreation = async (newLead) => {
    setLeads((prev) => newLead.concat(prev));
    fetchContacts();
  };

  useEffect(() => {
    if (!changeOrderData) return;

    const {
      projectName,
      primaryContact,
      contacts = [],
      accountName,
      taxRate,
      taxExempt,
      laborRequirement,
      insuranceRequirement,
      projectExecutive,
      projectManager,
      salesPerson,
      dobTextArea,
      dobAddress,
      dobBlock,
      dobLot,
      teamsConfiguration: changeOrderTeamsConfiguration,
      isChangeOrder: changeOrderIsChangeOrder,
      proposalStartDate,
      bidDueDate,
      proposedTypeOfWork = [],
    } = changeOrderData;
    form.setFieldsValue({
      opportunityAddress: projectName,
      opportunityName: rowData?.[0]?.opportunityName || projectName,
      alternativeAddresses: changeOrderData?.alternativeAddresses?.map(
        (el) => el?.address
      ),
      primaryContact: primaryContact?.id,
      contacts: contacts
        ?.filter?.((el) => el?.id !== primaryContact?.id)
        ?.map?.((el) => el?.id),
      taxRate: +(taxRate || 0) * 100,
      taxExempt,
      laborRequirement,
      projectExecutive,
      projectManager: projectManager?.map((manager) =>
        typeof manager === "string"
          ? manager
          : {
              ...manager,
              label: manager.nameOfUser,
              value: manager.nameOfUser,
            }
      ),
      salesPerson,
      dobTextArea,
      accountName:
        clients?.find((el) => el?.accountName === accountName)?.accountId ||
        leads?.find((el) => el?.leadCompany === accountName)?.leadId ||
        accountName,
      dobAddress,
      dobBlock,
      dobLot,
      insuranceRequirement: insuranceRequirement?.length
        ? insuranceRequirement
        : undefined,
      opportunityTeam: changeOrderTeamsConfiguration
        ?.map((el) => el?.value)
        ?.filter(Boolean) || ["Team"],
      proposalStartDate: !!proposalStartDate
        ? dayjs(proposalStartDate)
        : undefined,
      bidDueDate: !!bidDueDate ? dayjs(bidDueDate) : undefined,
      proposedTypeOfWork,
    });

    //splits the address into smaller components
    getAddressComponent(projectName, false, "").then((res) => {
      // form.setFieldValue("opportunityAptNumber", res.aptNumber);
      form.setFieldValue("opportunityAddCity", res.city);
      form.setFieldValue("opportunityAddState", res.state);
      form.setFieldValue("opportunityAddStreet", res.street);
      form.setFieldValue("opportunityAddPostalCode", res.zipCode);
    });

    setOpportunityAddress(projectName);
    setSelectedCompanyName(
      clients?.find((el) => el?.accountName === accountName)?.accountId ||
        leads?.find((el) => el?.leadCompany === accountName)?.leadId ||
        accountName
    );
    setIsChangeOrder(changeOrderIsChangeOrder);
    setSelectedTeam(changeOrderTeamsConfiguration);
  }, [changeOrderData, clients, leads]);

  useEffect(() => {
    if (visible) {
      Promise.all([
        fetchData("leads"),
        filterTables("accounts", "accountRecordType", "Client"),
        fetchData("contacts"),
        fetchData(apiRoutes.projectExecutives),
        fetchAllData("opportunities", "opportunities", "opportunityId"),
      ]).then(
        ([
          leadsRes,
          accountsRes,
          contactsRes,
          projectExecutivesRes,
          opportunitiesRes,
        ]) => {
          setLeads(
            quickSort(
              leadsRes,
              0,
              leadsRes?.length - 1,
              "createdAt",
              "descending"
            )?.filter((lead) => lead?.leadStatus !== "Unqualified") || []
          );
          setClients(
            quickSort(
              accountsRes,
              0,
              accountsRes.length - 1,
              "createdAt",
              "descending"
            )?.filter((acc) => acc?.activeClient === true)
          );
          setContacts(
            quickSort(
              contactsRes,
              0,
              contactsRes.length - 1,
              "createdAt",
              "descending"
            )
          );
          setProjectExecutives(projectExecutivesRes);
          setFetchedOpportunities(opportunitiesRes);
        }
      );
    }

    Promise.all([
      API.get("teams", "/teams"),
      filterTables("docConfiguration", "categoryName", "Opportunities"),
    ]).then(([teamsRes, docConfigurationRes]) => {
      setTeams(teamsRes);

      const scopeSheetConf =
        docConfigurationRes[0].documentationsAvailable.find(
          (el) => el.docName === "Scope Sheet/Bid Form"
        );
      setDocConfiguration({
        docObject: scopeSheetConf.docObject,
        docStatuses: scopeSheetConf.docStatuses,
      });
    });
  }, []);

  useEffect(() => {
    if (!!opportunityAddress) {
      getCoordinatesAndZip(opportunityAddress).then((locInfo) => {
        setAddLatLngAndZip(locInfo);
        // const taxExempt = form.getFieldValue("taxExempt") || "";
        // const taxRate = await getSalesTax({ zipCode: locInfo.zipCode });
        // const resTaxRate = parseFloat(taxRate) * 100;
        // form.setFieldsValue({
        //   taxRate: compareIncluding(taxExempt, "yes") ? 0 : resTaxRate,
        // });
        // setTaxRate(resTaxRate);
      });

      form.setFieldsValue({ opportunityName: opportunityAddress });
    }
  }, [opportunityAddress]);

  useEffect(() => {
    !!alternativeAddresses?.length
      ? getArraysLatLng(alternativeAddresses?.map((el) => el.address)).then(
          (r) => {
            const commonItems = findCommons(
              altAddressesLatLng,
              r,
              "coordinates"
            );
            const newItems = findUnCommons(
              r,
              altAddressesLatLng,
              "coordinates"
            );
            setAltAddressesLatLng([...commonItems, ...newItems]);
          }
        )
      : setAltAddressesLatLng([]);
  }, [alternativeAddresses.length]);

  useEffect(() => {
    (async () => {
      if (
        !driveParentId ||
        !opportunityAddress ||
        (!isEditOpportunity &&
          Object.keys(existingOpportunity || {}).length !== 0)
      ) {
        return;
      }

      if (await hasFolderCreated()) {
        return;
      }

      const parentFolderName = !!changeOrderData
        ? `${opportunityAddress} ${dayjs.tz().format("DD/MM/YYYY HH:mm:ss")}`
        : opportunityAddress;

      const parentId = !!changeOrderData?.isChangeOrder
        ? changeOrderData?.googleDriveFolderIds?.changeOrderObject
        : driveParentId;

      driveFunctions
        .create({ parentFolderName, parentId })
        .then(({ folders }) => {
          API.patch(
            apiRoutes.opportunities,
            `/${apiRoutes.opportunities}/${oppObject.opportunityId}`,
            { body: { googleDriveFolderIds: folders } }
          ).then(() => {
            setRowData((prev) => ({ ...prev, googleDriveFolderIds: folders }));
          });
        });
    })();
  }, [opportunityAddress, driveParentId, existingOpportunity]);

  useEffect(() => {
    if (!isEditOpportunity) {
      !!primaryContact && console.log("Setting Fields Value");
      form.setFieldsValue({ primaryContact });
    }
  }, [primaryContact]);

  useEffect(() => {
    !!driveParentId &&
      !!selectedCompanyName &&
      !!opportunityAddress &&
      (async () => {
        const binNumber = await getBinNumber(opportunityAddress);
        fetch(
          `https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNumber}`
        )
          .then((resp) =>
            resp
              .json()
              .then((r) => {
                if (r.length) {
                  let dobAddress =
                    r[0]?.street_name && r[0]?.borough
                      ? `${r[0].street_name} , ${r[0].borough}`
                      : "";
                  setDobInformation({
                    binNumber: binNumber || "",
                    dobAddress: dobAddress,
                    dobBlock: r[0]?.block || "",
                    dobLot: r[0]?.lot || "",
                  });
                } else throw new Error("No data found");
              })
              .catch((e) => {
                message.info({
                  content: "No dob information was found!",
                  key: "dobInfo",
                  duration: 2,
                });
              })
          )
          .catch(() => {
            message.info({
              content:
                "Something went wrong with dob information, please contact the responsible department!",
              key: "dobInfo",
              duration: 3,
            });
          });
      })();
  }, [driveParentId, selectedCompanyName, opportunityAddress]);

  useEffect(() => {
    if (changeOrderData?.projectName) {
      getAddressComponent(changeOrderData.projectName, false, "").then(
        (addressObj) => {
          form.setFieldsValue({
            opportunityAddStreet: addressObj?.street,
            opportunityAddCity: addressObj?.city,
            opportunityAddState: addressObj?.state,
            opportunityAddPostalCode: addressObj?.zipCode,
          });
        }
      );
    }
  }, [changeOrderData?.projectName]);

  const tutorialSteps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "opportunityModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Record Type": document.querySelector(".bodyTypeRadio"),
      "Required Fields": document.querySelector(".opportunityAddress"),
      "Jobsite Address": document.querySelector(".opportunityAddressInput"),
      "Alternative Address": document.querySelector(
        ".alternativeAddressButton"
      ),
      "Opportunity Information": document.querySelector(
        ".opportunityInformation"
      ),
      "Company Name": document.querySelector(
        ".opportunityInformation__Company"
      ),
      "Project Information": document.querySelector(".projectInfosContainer"),
      "Proposed Type Of Work": document.querySelector(".proposedTypeOfWork"),
      "Work Detail": document.querySelector(".workTypeContainer"),
      "Create Opportunity": document.querySelector(".finishButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const tourSteps =
    currentStep === 0
      ? mapRefs(tutorialSteps.slice(0, 1))
      : currentStep === 1
      ? mapRefs(tutorialSteps.slice(1, 4))
      : currentStep === 2
      ? mapRefs(tutorialSteps.slice(4, 6))
      : currentStep === 3
      ? mapRefs(tutorialSteps.slice(6, 7))
      : currentStep === 4
      ? mapRefs(tutorialSteps.slice(7, 9))
      : [];

  const steps = [
    !changeOrderData && {
      title: "Record Type",
      Comp: RecordType,
    },
    {
      title: JOBSITE_ADDRESS,
      Comp: JobsiteAddress,
    },
    {
      title: OPPORTUNITY_INFORMATION,
      Comp: OpportunityInformation,
    },
    {
      title: PROJECT_INFORMATION,
      Comp: ProjectInfo,
    },
    {
      title: "Proposed Type Of Work",
      Comp: TypeOfWork,
    },
  ]
    .filter(Boolean)
    .map((step) => ({
      ...step,
      form,
      inputFields,
      setFormChanged,
      setDobInformation,
      dobInfoModalVisible,
      setDobInfoModalVisible,
      opportunityAddress,
      selectedTeam,
      setOpen,
      uploadFile,
      setUploadFile,
      requiredBidDueDate,
      setRequiredBidDueDate,
      geoFenceInfo: finalGeofence,
      setGeoFenceInfo,
      selectedLead,
      addLatLngAndZip,
      altAddressesLatLng,
      coordinates: { lat, lng },
    }));

  useEffect(() => {
    if (!Object.keys(oppObject).length) {
      return;
    }

    setOpportunityAddress(oppObject.opportunityName);

    if (!!oppObject?.accountId) {
      setSelectedCompanyName(oppObject.accountId);
    }

    if (!isEditOpportunity?.stepTitle) {
      return;
    }

    const foundStepIndex = steps.findIndex(
      ({ title }) => title === isEditOpportunity.stepTitle
    );

    if (foundStepIndex >= 0) {
      setCurrentStep(foundStepIndex);
      return;
    }

    message.warning(
      isEditOpportunity.stepTitle + " step not found or its disabled!"
    );
  }, [JSON.stringify(oppObject), steps?.length]);

  const onNext = () => {
    validateForm(form, () => setCurrentStep((prev) => prev + 1));
  };

  return (
    <>
      <NormalSizedModal
        visible={visible}
        centered
        title={
          <CustomModalHeader
            title={
              isEditOpportunity
                ? oppObject?.opportunityAddress
                : !!isChangeOrderState
                ? "New Change Order"
                : "New Opportunity"
            }
            hasButton={!!isChangeOrderState ? false : true}
            onClick={() => {
              setTourOpen(true);
            }}
            headerInfo={() => {
              return (
                userConfiguration.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
          />
        }
        className={`opportunityModalContainer ${
          isDarkMode && "opportunityModalContainerDark"
        }`}
        closable={true}
        closeIcon={<XIcon fill="#323338" />}
        onCancel={onCancel}
        footerModal={
          <Flex gap={20} justify="space-between">
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() => {
                if (formChanged || !!changeOrderData) {
                  setCancelModalVisible(true);
                } else {
                  setVisible(false);
                  onMultiStepCancel(0);
                }
              }}
              tooltipCategory="Opportunities"
              tooltipKey="cancel"
            >
              Cancel
            </MondayButton>

            <Flex gap={20} justify="space-between">
              {currentStep >= 1 && (
                <MondayButton
                  buttonStyle={{ width: "150px" }}
                  className="mondayButtonBlue"
                  hasIcon={false}
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  tooltipCategory="Opportunities"
                  tooltipKey="backStep"
                >
                  Back
                </MondayButton>
              )}

              {steps.length - 1 === currentStep ? (
                estimationChangeOrder ? (
                  <MondayButton
                    buttonStyle={{ width: "150px" }}
                    className="mondayButtonBlue finishButton"
                    Icon={<TickIcon />}
                    onClick={() => !saving && validateOppForm(onFinish)}
                    tooltipCategory="Opportunities"
                    tooltipKey="proceed"
                  >
                    Proceed
                  </MondayButton>
                ) : (
                  <DropdownWrapper
                    dropdownOptions={
                      isEditOpportunity ? [] : ["Save & New", "Save & Close"]
                    }
                    onClick={onOptionClick}
                    dropdownDisabled={!!saving}
                  >
                    <MondayButton
                      buttonStyle={{ width: "150px" }}
                      disabled={!!saving}
                      className="mondayButtonBlue finishButton"
                      {...(isEditOpportunity
                        ? { hasIcon: false }
                        : { Icon: <DropDownArrow /> })}
                      onClick={() =>
                        isEditOpportunity
                          ? onUpdateOpportunity()
                          : validateOppForm(onSaveAndContinue)
                      }
                      tooltipCategory="Opportunities"
                      tooltipKey="finish"
                    >
                      Finish
                    </MondayButton>
                  </DropdownWrapper>
                )
              ) : (
                <MondayButton
                  buttonStyle={{ width: "150px" }}
                  className="mondayButtonBlue finishButton"
                  Icon={<ArrowRightIcon />}
                  onClick={onNext}
                  tooltipCategory="Opportunities"
                  tooltipKey="nextStep"
                >
                  Next
                </MondayButton>
              )}
            </Flex>
          </Flex>
        }
      >
        <Form
          initialValues={initialValues}
          form={form}
          onFieldsChange={() => setFormChanged(true)}
          style={{ height: "100%" }}
        >
          <Flex vertical gap={20} className="opportunityModalStepper">
            <Stepper
              {...{
                currentStep,
                setCurrentStep,
                steps,
                componentContainerClassName: "stepContentWrapper",
              }}
            />
          </Flex>
        </Form>
      </NormalSizedModal>
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            ClearOptions,
            selectedTeam,
            setSelectedTeam,
            proppedAllTeams: teams,
            onSave: (data) => {
              form.setFieldValue("opportunityTeam", data || []);
            },
          }}
        />
      )}
      {leadModalVisible && (
        <LeadModal
          saveOnly
          {...{
            visible: leadModalVisible,
            setVisible: setLeadModalVisible,
            preventContinue: true,
            accessToken,
            refreshTable: onLeadCreation,
          }}
        />
      )}
      {clientModalVisible && (
        <ClientModal
          saveOnly
          {...{
            visible: clientModalVisible,
            setVisible: setClientModalVisible,
            preventContinue: true,
            accessToken,
            creatingOnProjectView: true,
            refreshTable: fetchClients,
          }}
        />
      )}
      {contactModalVisible && (
        <ContactModal
          saveOnly
          {...{
            visible: contactModalVisible,
            setVisible: setContactModalVisible,
            preventContinue: true,
            contact: {
              ...(!!accountId
                ? selectedClient
                : {
                    accountName: selectedLead?.leadCompany,
                    accountPhone: selectedLead?.leadPhone,
                  }),
              contactType: !!accountId ? "Client Contact" : "Lead Contact",
            },
            [!!accountId
              ? "Client Contact"
                ? "propClient"
                : "Lead Contact"
                ? "propLead"
                : "propClient"
              : "propLead"]: {
              aptNumber: selectedClient?.aptNumber || selectedLead?.aptNumber,
              [!!accountId
                ? "Client Contact"
                  ? "shippingAddress"
                  : "Lead Contact"
                  ? "leadAddress"
                  : "shippingAddress"
                : "leadAddress"]:
                selectedClient?.shippingAddress || selectedLead?.leadAddress,
            },
            refreshTable: ([newContact]) => {
              setContacts((prev) => [newContact, ...prev]);
            },
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Opportunity Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Opportunity",
            rowData: oppInfoFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "opportunityModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Opportunities",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              const { action, opportunityId } = visibleCreationProgress;
              if (!!action) {
                setVisible(false);
                action === "onSaveAndContinue" &&
                  opportunityId &&
                  navigate(`/opportunities/${opportunityId}`);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default OpportunityModal;
