import { Modal, message } from "antd";
import { InputComponent } from "../../Fleet/components";
import { useProgramFields } from "../../../../hooks";
import { XIcon } from "../../Communication/assets";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../commonComponents";
import { PlusIcon } from "../../../../assets";
import NewToDo from "./NewToDo";
import {
  getSelectedBaseRecords,
  handlePopupScroll,
  searchFetchInput,
} from "../../../../utils/searchFetchForInput";
import IndividualLoader from "../../../IndividualLoader";

import "./NewToDo.scss";
import { filterRowDataByTeamConfig } from "../../../../utils";
import { uniq, uniqBy } from "lodash";
import { getRecordPrefix, getRecordSuffix } from "./helpers";

/**
 *
 * @param {Boolean} visible - whether the modal is visible or not
 * @param {Function} setVisible - function to set the visibility of the modal
 * @param {Function} setRowData - function to set the row data
 * @returns
 */
const PreTodoModal = ({
  visible = false,
  selectedCategory,
  setVisible = () => {},
  setRowData = () => {},
}) => {
  const { "To Do Categories": todoCategories } = useProgramFields();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [category, setCategory] = useState(selectedCategory);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [renderToDos, setRenderToDos] = useState(false);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [records, setRecords] = useState({});

  const currentPageKey = useRef({});

  const getRouteConfig = (view) => {
    return userConfiguration?.routeConfig?.find(({ title }) => title === view);
  };

  const reducedTopicCategories = useMemo(() => {
    if (!Array.isArray(topicCategories)) {
      return {};
    }

    return topicCategories.reduce((acc, curr) => {
      if (
        !curr ||
        !curr.categoryName ||
        !curr.primaryKey ||
        !curr.apiName ||
        !curr.rowName
      ) {
        return acc;
      }

      return {
        ...acc,
        [curr.categoryName]: {
          idKey: curr.primaryKey,
          table: curr.apiName,
          arrayKey: curr.apiName,
          searchKey:
            curr.categoryName === "Work Orders"
              ? curr.primaryKey
              : curr.rowName,
          lazyFetchKeys: Array.isArray(curr.lazyFetchKeys)
            ? [...curr.lazyFetchKeys]
            : [],
        },
      };
    }, {});
  }, [topicCategories]);

  const handleError = (err) => {
    // setRecords((prev) => ({
    //   ...prev,
    //   [category === "Accounts" ? "Clients" : category]: [],
    // }));
    setRecordsLoading(false);
    // currentPageKey.current = null;
    console.error(err);
    message.error("Something went wrong");
  };

  const customOptions = todoCategories
    ?.filter((el) => el?.hasToDo && getRouteConfig(el?.title))
    ?.map((el) => ({
      label: el?.title,
      value: el?.title,
    }));

  /**
   * Handles the selection of a category.
   *
   * @param {string} value - The selected category value.
   * @returns {Promise<void>} - A promise that resolves when the category selection is handled.
   */
  const onSelectCategory = async (value) => {
    if (value === category) return;

    const valueToUse =
      value === "Accounts"
        ? "Clients"
        : value === "Fleets Inspections"
        ? "Fleet Inspections"
        : value === "Safety Inspection"
        ? "Safety"
        : value;

    setCategory(value);
    if (records?.[valueToUse]?.length > 0) return;

    setSelectedRecord({
      recordId: null,
      recordName: null,
    });

    if (!reducedTopicCategories[valueToUse]) {
      return handleError("No category found");
    }

    await getSelectedBaseRecords({
      ...reducedTopicCategories[valueToUse],
      setLoading: setRecordsLoading,
      setRecords(res) {
        setRecords((prev) => ({
          ...prev,
          [valueToUse]: filterRowDataByTeamConfig(userConfiguration, res || []),
        }));
      },
      userConfiguration,
      setCurrentPageKey: (val) => {
        Object.assign(currentPageKey.current, {
          [valueToUse]: val,
        });
      },
      keysToInclude: uniq([
        ...reducedTopicCategories[valueToUse]?.lazyFetchKeys,
        reducedTopicCategories[valueToUse]?.searchKey,
        reducedTopicCategories[valueToUse]?.idKey,
        "teamsConfiguration",
        "createdAt",
        "accountName",
        "leadName",
        "estimationNumber",
        "scheduleName",
      ]),
      customSetRecords: true,
    }).catch(handleError);
  };

  /**
   * Handles the scroll event on the popup.
   *
   * @param {Event} e - The scroll event object.
   * @returns {Promise<void>} - A promise that resolves when the scroll event is handled.
   */
  const onPopupScroll = async (e) => {
    const valueToUse = category === "Accounts" ? "Clients" : category;

    if (!reducedTopicCategories[valueToUse]) {
      return handleError("No category found");
    }

    await handlePopupScroll({
      e,
      ...reducedTopicCategories[valueToUse],
      setLoading: setRecordsLoading,
      currentPageKey: currentPageKey.current?.[valueToUse] || "",
      setRecords(res) {
        setRecords((prev) => ({
          ...prev,
          [valueToUse]: filterRowDataByTeamConfig(
            userConfiguration,
            uniqBy(
              [...(prev?.[valueToUse] || []), ...res],
              reducedTopicCategories[valueToUse]?.idKey
            ) || []
          ),
        }));
      },
      userConfiguration,
      setCurrentPageKey: (val) => {
        Object.assign(currentPageKey.current, {
          [valueToUse]: val,
        });
      },
      keysToInclude: uniq([
        ...reducedTopicCategories[valueToUse]?.lazyFetchKeys,
        reducedTopicCategories[valueToUse]?.searchKey,
        reducedTopicCategories[valueToUse]?.idKey,
        "teamsConfiguration",
        "createdAt",
        "accountName",
        "estimationNumber",
        "scheduleName",
        // ks,
      ]),
      customSetRecords: true,
    }).catch(handleError);
  };

  /**
   * Handles the search functionality.
   *
   * @param {string} searchValue - The value to search for.
   * @returns {Promise<void>} - A promise that resolves when the search is complete.
   */
  const onSearch = async (searchValue) => {
    const valueToUse = category === "Accounts" ? "Clients" : category;

    if (!reducedTopicCategories[valueToUse]) {
      return handleError("No category found");
    }

    if (searchValue?.trim()?.length > 3) {
      await searchFetchInput({
        ...reducedTopicCategories[category],
        searchValue,
        setLoading: setRecordsLoading,
        setRecords(res) {
          setRecords((prev) => ({
            ...prev,
            [valueToUse]: filterRowDataByTeamConfig(
              userConfiguration,
              uniqBy(
                [...(prev?.[valueToUse] || []), ...res],
                reducedTopicCategories[valueToUse]?.idKey
              ) || []
            ),
          }));
        },
        userConfiguration,
        keysToInclude: [
          ...reducedTopicCategories[valueToUse]?.lazyFetchKeys,
          reducedTopicCategories[valueToUse]?.searchKey,
          reducedTopicCategories[valueToUse]?.idKey,
          "teamsConfiguration",
          "createdAt",
          "accountName",
          "estimationNumber",
          "scheduleName",
        ],
        customSetRecords: true,
      }).catch(handleError);
    }
  };

  /**
   * Renders the dropdown menu for the PreTodoModal component.
   *
   * @param {React.ReactNode} menu - The menu to be rendered.
   * @returns {React.ReactNode} The rendered dropdown menu.
   */
  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  /**
   * Represents the options for the records based on the selected category.
   *
   * @type {Array<Object>}
   */
  const recordOptions = useMemo(() => {
    const categoryToUse = category === "Accounts" ? "Clients" : category;

    return records?.[categoryToUse]?.map((el) => {
      let { searchKey } = reducedTopicCategories?.[categoryToUse];
      let { idKey } = reducedTopicCategories?.[categoryToUse];

      return {
        label:
          getRecordPrefix(el, categoryToUse) +
          el?.[searchKey] +
          getRecordSuffix(el, categoryToUse),
        value: el?.[idKey],
        recordName: el?.[searchKey],
        key: el?.[idKey],
      };
    });
  }, [records, category]);

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        closeIcon={<XIcon />}
        centered={true}
        title={"To Do"}
        footer={[
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            key={"toDoFooter"}
          >
            <MondayButton
              {...{
                onClick: () => setVisible(false),
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                onClick: () => {
                  setRenderToDos(true);
                },
                Icon: <PlusIcon />,
              }}
              className={
                !selectedRecord ? "mondayButtonGray" : "mondayButtonGreen"
              }
              disabled={!selectedRecord?.recordId}
            >
              Create
            </MondayButton>
          </div>,
        ]}
        className={`preTodoModal ${isDarkMode && "preTodoModalDark"}`}
      >
        <InputComponent
          {...{
            type: "select",
            customOptions,
            label: "Category",
            placeholder: "Select an option...",
            onSelect: onSelectCategory,
            dataTestid: "category-select",
          }}
        />
        {!!category ? (
          <InputComponent
            {...{
              onSelect: (value, { key: recordId, recordName }) => {
                value && setSelectedRecord({ recordId, recordName });
              },
              onClear: () => {
                setSelectedRecord(null);
              },
              label: "Related To",
              type: "select",
              customOptions: recordOptions,
              onPopupScroll,
              onSearch,
              dropdownRender,
              dataTestid: "related-to-select",
              initialValue: selectedRecord?.recordId,
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
      {!!renderToDos ? (
        <NewToDo
          {...{
            visible,
            setVisible,
            recordId: selectedRecord?.recordId,
            recordName: selectedRecord?.recordName,
            userConfiguration,
            isCreate: true,
            category: category.categoryName,
            category,
            setRowData,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PreTodoModal;
