import { API } from "aws-amplify";
import { showSuccessMsg } from "../../../../../../../../utils";
import { Groups } from "../../../RolesData";
import { userGroups } from "../../../../../../../../actions";
import { message } from "antd";

const addRole = async (
  roleName,
  selectedDepartment,
  dispatch,
  userGroupsArr
) => {
  if (!roleName || !selectedDepartment) {
    message.error("Please fill out all fields before submitting.");
    return;
  }
  const departmentsParentGroups = Groups({
    groups: userGroupsArr.filter(
      (group) =>
        group.departmentName.toLowerCase() === "Executive".toLowerCase()
    ),
  });

  await API.post("userGroups", "/userGroups", {
    body: {
      departmentName: selectedDepartment,
      groupName: roleName,
      groupConfigs: {
        preferences: departmentsParentGroups?.find((e) => e["Super Admin"])[
          "Super Admin"
        ]["Notification Preferences"],
      },
    },
  }).then((result) => {
    dispatch(userGroups([...userGroupsArr, result]));
    showSuccessMsg({ content: "Role created successfully!" });
  });
};

export default addRole;
