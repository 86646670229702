import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import MondayButton from "../MondayButton/MondayButton";
import "./RichTextEditor.scss";
const RichTextEditor = ({
  value,
  onChange,
  disabled,
  initialValue,
  placeholder,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const preserveSpaces = (html) => html?.replace(/ /g, "&nbsp;");

  return (
    <div className="richTextComp">
      <ReactQuill
        {...{
          value: preserveSpaces(value) || preserveSpaces(initialValue),
          disabled,
          onChange: onChange,
          placeholder: placeholder || "",
        }}
      />
    </div>
  );
};

export default RichTextEditor;
