import { Badge, Tooltip } from "antd";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { requestAllPages } from "../../../../../utils/paginationFetchData";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import { ChangeOrderIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { fetchData } from "../../../Fleet/utils";
import { ProgressStatistics } from "../../../Scheduling/helpers/totals";
import "./EstimationProgressOverview.scss";

import { checkIfServiceIsHoist } from "../../../Estimations/DataEntryGrid/models/Service";
import { useSelector } from "react-redux";
import { filterTables } from "src/utils";
import { onlyEstWithScopeFalse } from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

const EstimationProgressOverview = (props) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const isChangeOrder = (service) => service?.isChangeOrder || false;
  const [fetchedEst, setFetchedEst] = useState({});
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  let proceedOnlyWHoist = {};
  Object.entries(props?.params?.scheduleProps?.projects?.services || {})?.map(
    ([key, value]) => {
      proceedOnlyWHoist[key] = value?.filter((el) => checkIfServiceIsHoist(el));
    }
  );

  useEffect(() => {
    const fetchedEstimations = async () => {
      const promises = Object.entries(
        props?.params?.rowData?.toBeScheduled || {}
      )
        ?.filter?.(([key, value]) => value?.length > 0)
        ?.map?.(async ([key]) => {
          const estimation = await API.get(
            "estimations",
            `/estimations/${key}`
          );

          return [key, estimation];
        });

      const fetchedEst = Object.fromEntries(await Promise.all(promises));
      setFetchedEst(fetchedEst);
    };

    fetchedEstimations();
  }, []);

  return (
    <div
      className={`est-container-data ${
        isDarkMode && "est-container-data-dark"
      }`}
    >
      {Object?.entries(
        onlyEstWithScopeFalse(props?.params?.rowData?.toBeScheduled)
      )?.map?.(([key, value], i) => {
        if (value?.length === 0) return null;
        const currentEst = fetchedEst?.[key] || {};
        const estimationNr =
          currentEst?.quickbooksNumber || currentEst?.estimationNumber || i + 1;
        return (
          <Badge count={null} offset={[-30, 10]} color={"#EE8A35"}>
            <div className="serviceEstContainer-data">
              <div style={{ paddingLeft: 10 }}>
                <Tooltip
                  title={currentEst?.isChangeOrder ? "Change Order" : null}
                >
                  <div className="estimation-label-header">
                    <span>
                      {`Estimation ${estimationNr} - ${`
                  ${
                    parseFloat(
                      ProgressStatistics.estimationTotal(value)
                        .progressPercentage
                    ) || 0
                  }% Progress
                `}`}
                    </span>
                    {isChangeOrder(value?.[0]) ? (
                      <ChangeOrderIcon fill="red" />
                    ) : null}
                  </div>
                </Tooltip>
              </div>
              <div className="servicePercent-data">
                {value?.map?.((el) => {
                  return (
                    <div className="servicePercent-container">
                      <CustomProgressBar
                        progressDone={
                          Number(
                            ProgressStatistics.serviceTotal(el)
                              .progressPercentage
                          ) || 0
                        }
                      />
                      <div className="servicePercent-label">
                        {/* {newServicesAdded(
                          props?.params?.unChangedRowData?.unchanged
                            ?.toBeScheduled || {},
                          props?.params?.scheduleProps?.projects?.services || {}
                        )?.[key]?.includes(el?.serviceId?.toString()) ? (
                          <>
                            <span className="newTitle">(New)</span>{" "}
                            <span>{el?.label}</span>
                          </>
                        ) : ( */}
                        <span>{el?.label}</span>
                        {/* )} */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Badge>
        );
      })}
    </div>
  );
};

export default EstimationProgressOverview;
