import { Button, Tooltip } from "antd";
import { InputComponent } from "../../../../Fleet/components";
import { InboxIcon, RefreshIcon, TrashIcon } from "../../../assets";
import { getReadEmails, getUnreadEmails } from "../../../functions";
import MoreActions from "./more-actions/MoreActions";
import { useSelector } from "react-redux";
import { useResponsive } from "../../../../../../hooks";

import "./communication-content-header.scss";
import { useDispatch } from "react-redux";
import {
  setCheckedList,
  setCurrentPage,
} from "../../../../../../actions/communicationActions";
import Pagination from "../../../../../commonComponents/Pagination/Pagination";

function CommunicationContentHeader({
  filteredEmails,
  checkedList,
  setCheckedList,
  category,
  itemsPerPage,
  totalItems,
  // handlePageChange,
  handleMoveTo,
  handleMarkAs,
  fetchData,
}) {
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state) => state.communication);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { mobile } = useResponsive();

  // updates the checked list based on what was selected
  const handleSelectChange = (value) => {
    if (value === "all") {
      setCheckedList(filteredEmails);
    } else if (value === "unread") {
      setCheckedList(getUnreadEmails(filteredEmails));
    } else if (value === "read") {
      setCheckedList(getReadEmails(filteredEmails));
    } else {
      setCheckedList([]);
    }
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  return (
    <div
      data-testid="communication-content-header"
      className={`communication-content-header ${mobile && "mobile"} ${
        isDarkMode ? "dark-mode " : ""
      }`}
    >
      <div className="communication-content-header-left">
        {filteredEmails?.length > 0 && (
          <InputComponent
            allowClear
            type="select"
            dropdownClassName={isDarkMode && "darkDropDown"}
            className="select-emails"
            placeholder="Select"
            showSearch
            onChange={handleSelectChange}
            customOptions={[
              { value: "all", label: "All" },
              { value: "none", label: "None" },
              { value: "read", label: "Read" },
              { value: "unread", label: "Unread" },
            ]}
            dataTestid="select-emails-dropdown"
          />
        )}

        <div className="communication-content-header-left-buttons">
          {filteredEmails?.length > 0 && checkedList && (
            <MoreActions
              checkedList={checkedList}
              handleMarkAs={handleMarkAs}
              emails={filteredEmails}
            />
          )}

          {checkedList?.length > 0 && category === "INBOX" ? (
            <Tooltip title="Move to trash">
              <Button
                className="trash-button"
                size="middle"
                type="text"
                icon={<TrashIcon />}
                onClick={() =>
                  handleMoveTo(
                    "TRASH",
                    "INBOX",
                    checkedList?.map((email) => email.id)
                  )
                }
                data-testid="move-to-trash-button"
              />
            </Tooltip>
          ) : checkedList?.length && category === "TRASH" ? (
            <Tooltip title="Move to inbox">
              <Button
                className="new-button"
                size="middle"
                type="text"
                icon={<InboxIcon />}
                onClick={() =>
                  handleMoveTo(
                    "INBOX",
                    "TRASH",
                    checkedList.map((email) => email.id)
                  )
                }
                data-testid="move-to-inbox-button"
              />
            </Tooltip>
          ) : (
            <Button
              className="refresh-button"
              size="middle"
              type="text"
              icon={<RefreshIcon />}
              onClick={() => fetchData()}
              data-testid="refresh-button"
            />
          )}
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default CommunicationContentHeader;
