import { useMemo, useState } from "react";
import Card from "../ServiceTemplates/Card";
import { SearchOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";

import "./SingleServiceDrag.scss";

function SingleServiceDrag({ editorRef, proposalTemplates, issues }) {
  const [searchValue, setSearchValue] = useState("");
  const { proposalData, splitServices, setProposalData } = useProposalContext();
  const onDragEnd = (event, item) => {
    const droppedHTML = item?.serviceHtml || "";
    const x = event.clientX;
    const y = event.clientY;
    const elements = document.elementsFromPoint(x, y);
    const isOverTarget = elements?.some((element) =>
      element?.classList?.contains("jodit-workplace")
    );
    event.preventDefault();
    if (editorRef.current && isOverTarget) {
      const wrappedHTML = `<div>${droppedHTML}<div></div></div>`;
      // const myMadeSenseData = getMyMadeSenseData(wrappedHTML, proposalData);

      editorRef.current.component.selection.insertHTML(wrappedHTML);

      const getNewHtml = editorRef.current.component.value;
      const tmp = {
        ...(proposalData || {}),
        templateData: {
          ...(proposalData?.templateData || {}),
        },
        getNewHtml,
        parentTemplateContent: item?.parentTemplateContent || "",
      };

      setProposalData(tmp);
    }
  };

  const filteredServices = useMemo(() => {
    if (!searchValue) return splitServices || [];
    return (
      splitServices?.filter((service) =>
        service?.serviceName?.toLowerCase().includes(searchValue.toLowerCase())
      ) || []
    );
  }, [searchValue]);

  return (
    <div className="singleServiceDrag">
      <InputComponent
        allowClear
        noFormItem={true}
        value={searchValue}
        className="search-comp"
        placeholder="Search Services"
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined className="SearchLogoIcon" />}
      />

      <div className="general-templates ">
        {filteredServices?.map((template, i) => (
          <div key={i} className="serviceTemplateItem">
            <Card template={template} onDragEnd={onDragEnd} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SingleServiceDrag;
