import React from "react";
import { useSelector } from "react-redux";
import { Stepper } from "../../../commonComponents";

import "./ProposalBodyStepper.scss";

function ProposalBodyStepper({ proposalStatus, setProposalStatus }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const items = [
    { title: "Drafts" },
    { title: "Action Required" },
    { title: "Waiting for Client" },
    { title: "Finalized" },
    { title: "To Be Approved" },
    { title: "Approved" },
  ];
  return (
    <div className={`proposalStepper ${isDarkMode ? "dark" : ""}`}>
      {" "}
      <Stepper
        currentStep={items.findIndex(
          (el) =>
            el?.title?.toLocaleLowerCase() ===
            proposalStatus?.toLocaleLowerCase()
        )}
        setCurrentStep={(curr) => {
          setProposalStatus(items[curr]?.title);
        }}
        items={items}
        stepRenderer={false}
        type="navigation"
        componentContainerClassName="proposalBuilderWrapper"
      />
    </div>
  );
}

export default ProposalBodyStepper;
