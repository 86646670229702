import { fetchData } from "../../../../SidebarPages/Fleet/utils";

const fetchProposalTemplates = async (proposalData) => {
  let toReturn = {
    proposalTemplates: [],
    serviceTemplates: [],
  };
  try {
    const data = await fetchData("proposalTemplates");
    if (data) {
      let tempTemplates =
        data?.filter((el) => el?.templateCategory === "service") || [];
      toReturn.serviceTemplates = tempTemplates;
      let toShow = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element?.templateCategory?.toLowerCase() === "general") {
          toShow.push({
            ...element,
            serviceName: element?.templateTitle || "",
            templateCategory: element.templateCategory.toLowerCase(),
            parentTemplateContent: element?.templateContent || "",
            templateContent: getTemplateContent(element || "", tempTemplates),
            color: "",
            templateId: element?.templateId || "",
          });
        }
      }

      toReturn.proposalTemplates = Array.isArray(toShow) ? toShow : [];
    }
    return toReturn;
  } catch (e) {
    console.log("fetchProposalTemplates ~ e", e);
    return toReturn;
  }
};

export default fetchProposalTemplates;

export function tempChangeTemplate(
  id,
  templates,
  setTemplates,
  newTemplateContent
) {
  let temp = [...templates.proposalTemplates];
  let index = temp.findIndex((el) => el.templateId === id);
  temp[index].templateContent = getTemplateContent(
    { templateContent: newTemplateContent },
    templates.serviceTemplates
  );
  setTemplates((prev) => ({ ...prev, proposalTemplates: temp }));
}

export const getTemplateContent = (template, tempTemplates) => {
  let htmlContent = template?.templateContent;
  // let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
  let pattern = /\{\{([^}]+)\}\}/g;

  let toReturn = htmlContent
    ?.replace(pattern, (match, id) => bracketToHtml(match, id, tempTemplates))
    .replace(/border-color:/g, "border: 1px solid ")
    .replace(/<\/table><br>/g, "</table>")
    .replace(/<table([^>]*)>/g, removeTableMargin);
  return toReturn;
};

function bracketToHtml(match, id, tempTemplates) {
  if (id.includes("_")) {
    let parts = id.split("_");
    let serId = parts[1];
    let serviceName = parts[2];
    let particulateService = tempTemplates?.find((el) => {
      return el?.templateId === serId;
    });
    if (particulateService) {
      let droppedHTML = addStringToDoubleBracesTags(
        particulateService?.templateContent || "",
        serviceName
          ? serviceName
          : particulateService?.additionalDetails?.service?.workName || ""
      );
      return droppedHTML || "";
    } else {
      return "";
    }
  } else {
    return match;
  }
}

function removeTableMargin(match, id) {
  if (id.includes("style=")) {
    if (!/margin\s*:\s*0\s*;/.test(id)) {
      return match.replace(/style="([^"]*)"/, 'style="$1 margin:0;"');
    }
    return match;
  } else {
    return `<table${id} style="margin:0;">`;
  }
}

function addStringToDoubleBracesTags(html, keyToAdd) {
  const regex = /\{\{(\w+)\}\}/g;
  const updatedHtml = html.replace(
    regex,
    `<span class="proposalTag" contenteditable="false" style="cursor: not-allowed;">{{$1_${keyToAdd}}}</span>`
  );
  return updatedHtml;
}

export function getServiceContent(template) {
  let serviceName = template?.additionalDetails?.service?.workName;
  let htmlContent = template?.templateContent;
  let pattern = /\{\{(\w+)\}\}/g;

  let result = htmlContent?.replace(pattern, function (match, key) {
    return `{{${key}_${serviceName}}}`;
  });

  return result;
}
