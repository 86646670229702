import axios from "axios";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { filterTables } from "../../utils";
import proposalPathConfig from "./utils/pathConfig";
import { LoadableComp } from "../SidebarPages/XComponents";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ProposalHeader from "./components/ProposalHeader/ProposalHeader";
import { ProposalProvider } from "./WaitingRoomProposal/ProposalContext";
import ProposalSidebar from "./components/ProposalSidebar/ProposalSidebar";
import JoditEditorCustom from "./components/JoditEditorCustom/JoditEditorCustom";
import ProposalBodyStepper from "./components/ProposalBodyStepper/ProposalBodyStepper";
import { mapTagToData } from "./components/ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";
import { processWordHtml } from "../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";

import "./ProposalBuilder.scss";

function ProposalBuilder() {
  const editorRef = useRef(null);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { proposedTypeOfWork: serviceDefs } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const [loading, setLoading] = useState(false);
  const [signatures, setSignatures] = useState({});
  const [proposalData, setProposalData] = useState({});
  const [servicesReports, setServicesReports] = useState([]);
  const [proposalStatus, setProposalStatus] = useState("Drafts");

  const splitServices = useMemo(() => {
    // return [];
    if (!proposalData?.takeOff?.services) return [];
    const gridData = proposalData?.takeOff?.services;
    const projectAddress = proposalData?.estimation?.jobSiteAddress;
    const accountName = proposalData?.estimation?.accountName;
    const versionId = proposalData?.takeOff?.id;
    const estimation = proposalData?.estimation;
    return processWordHtml({
      gridData,
      projectAddress,
      serviceDefs,
      accountName,
      estimation,
      versionId,
      showIds: true,
      splitServices: true,
      contentEditable: false,
    });
  }, [proposalData]);

  //region getProposalData
  const getDataFromQueryParams = async () => {
    // new url params
    const urlParams = new URLSearchParams(window.location.search);
    const proposalId = urlParams.get("proposalId");
    const takeOffId = urlParams.get("takeOffId");
    let result = {};
    setLoading(true);
    await API.get("proposalBuilds", `/proposalBuilds/${proposalId}`)
      .then(async (currentProposal) => {
        result.currentProposal = currentProposal;
        await API.get(
          "estimations",
          `/estimations/${currentProposal?.estimationId}`
        )
          .then((estimation) => {
            setLoading(false);
            result.estimation = estimation;
          })
          .catch((error) => {
            console.log("error fetching estimation data", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log("error fetching proposal data", error);
        setLoading(false);
      });
    result.proposalId = proposalId;
    result.takeOffId = takeOffId;
    return result;
  };

  const onDownload = async (openNewTab) => {
    try {
      const myMadeSenseData = mapTagToData({
        proposalData: proposalData,
        html: editorRef.current.value,
      });
      const response = await axios.post(
        `${proposalPathConfig}/api/htmlDocusealConverter`,
        {
          html: myMadeSenseData,
        }
      );
      if (openNewTab) {
        window.open(response.data?.documents?.[0]?.url);
      }
    } catch (error) {
      console.error("Error converting HTML to document:", error.message);
    }
  };

  const onPreview = async (newTab) => {
    Swal.fire({
      title: "<strong>Finalizing...</strong>",
      icon: "info",
      html: "Please wait! ",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await onDownload(newTab);
    } catch (err) {
      console.log("err", err);
      Swal.fire({
        title: "<strong>Finalize Proposal Error</strong>",
        icon: "error",
        html: "Your proposal could not be finalized! ",
      });
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    getDataFromQueryParams().then((result) => {
      console.log("getDataFromQueryParams", result);
      const tmpBody = {
        takeOff:
          result?.estimation?.versionServices?.[`${result?.takeOffId}`] || {},
        estimation: result?.estimation || {},
        realTimePreview: false,
        realTimePreviewData: {},
        proposalId: result?.proposalId,
        takeOffId: result?.takeOffId,
        wrappedHtml: result?.currentProposal?.wrappedHtml || "",
        templateData: {
          ...(result?.currentProposal?.templateData || {}),
        },
        savedContent: result?.currentProposal?.savedContent || "",
      };
      setProposalData(tmpBody);
    });
  }, []);

  return (
    <ProposalProvider
      value={{
        proposalData,
        setProposalData,
        signatures,
        setSignatures,
        splitServices,
        servicesReports,
      }}
    >
      <LoadableComp loading={loading}>
        <div
          className={`new_proposal_builder ${
            isDarkMode ? "new_proposal_builder_dark" : ""
          }`}
        >
          <ProposalHeader
            {...{
              editorRef,
              onPreview,
            }}
          />
          <div className="new_proposal_builder__content">
            <ProposalBodyStepper
              {...{
                proposalStatus,
                setProposalStatus,
              }}
            />
            <div className="new_proposal_builder__body">
              <JoditEditorCustom ref={editorRef} />
              <ProposalSidebar {...{ editorRef }} />
            </div>
          </div>
        </div>
      </LoadableComp>
    </ProposalProvider>
  );
}

export default ProposalBuilder;
