import { API } from "aws-amplify";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotificationByAccess } from "../../../../../utils/notificationHelpers";

export const saveCitation = async (
  citation,
  status,
  userConfiguration,
  saveAddedLogs,
  authenticatedUser
) => {
  return API.post("citations", "/citations", {
    body: {
      citationName: citation.citationName,
      employeeId: citation.employeeId,
      citationObject: citation.citationObject,
      citationStatus: status,
      createdBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
      citationCategory: citation?.citationCategory || "General",
      teamsConfiguration: citation?.teamsConfiguration || [],
      googleDriveFolderIds: citation?.googleDriveFolderIds || {},
      googleDriveUpload: citation?.googleDriveUpload || "",
      lastModifiedBy: {},
      createdAt: new Date(),
    },
  })
    .then(async (response) => {
      await saveAddedLogs({
        recordId: response?.citationId,
        recordName: response?.citationName,
        category: "Safety Citations",
        topic: response?.citationCategory,
      });

      if (status?.toLowerCase() !== "draft") {
        broadcastNotification(
          "125",
          "onSafetyCitationCreation",
          [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotificationByAccess(
                userConfiguration,
                "Safety Citations"
              ),
            },
          ],
          response?.citationId
        );
      }
      return response;
    })
    .catch((error) => {
      console.error("There was a problem with the save operation:", error);
    });
};
