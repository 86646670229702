import { Form, Modal, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyIconWhite } from "../../../../../../../../SidebarPages/DynamicView/src";
import { MondayButton } from "../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { apiPut } from "../../../../../../../../../utils";
import {
  UserConfig,
  updateOtherUser,
} from "../../../../../../../../../actions";
import { useNavigate } from "react-router-dom";

import "./CloneUserAccess.scss";
import { InfoText } from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

/**
 * Renders a modal component for cloning user access.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.openCloneAccess - Flag indicating whether the clone access modal is open.
 * @param {Function} props.setOpenCloneAccess - Function to set the openCloneAccess flag.
 * @param {Object} props.currentUser - The current user object.
 * @returns {JSX.Element} The CloneUserAccess component.
 */
const CloneUserAccess = ({
  openCloneAccess,
  setOpenCloneAccess,
  currentUser,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [allDepartments, setAllDepartments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const departments = programFields?.find(
      ({ fieldName }) => fieldName === "Departments"
    )?.fieldOptions;
    const allUsers = userConfiguration?.allUsers?.Items?.filter(
      ({ identityId }) => identityId !== currentUser?.identityId
    );
    setAllUsers(allUsers || []);
    setAllDepartments(departments || []);
  }, [programFields, userConfiguration]);

  const onChangeDepartment = (e) => {
    setSelectedDepartment(e);
    form.setFieldsValue({ user: "" });
    setSelectedUser("");
  };

  async function onSave() {
    const selectedUserObject = allUsers?.find(
      ({ identityId }) => identityId === selectedUser
    );

    await apiPut("userConfiguration", currentUser?.identityId, {
      routeConfig: selectedUserObject?.routeConfig,
      sidebarConfig: selectedUserObject?.sidebarConfig,
    })
      .then((res) => {
        const updatedKeys = {
          routeConfig: selectedUserObject?.routeConfig,
          sidebarConfig: selectedUserObject?.sidebarConfig,
        };

        let userIndex = userConfiguration.allUsers?.Items?.findIndex(
          ({ identityId }) => identityId === currentUser?.identityId
        );
        if (userIndex === -1) {
          return;
        }

        userConfiguration.allUsers.Items[userIndex] = {
          ...userConfiguration.allUsers?.Items[userIndex],
          ...updatedKeys,
        };

        let updatedUsers = [...(userConfiguration?.allUsers?.Items || [])];

        let updatedUserConfig = {};
        if (currentUser?.identityId !== userConfiguration?.identityId) {
          updatedUserConfig = {
            ...userConfiguration,
            allUsers: { Items: updatedUsers },
          };
        } else {
          updatedUserConfig = {
            ...userConfiguration,
            allUsers: { Items: updatedUsers },
            ...updatedKeys,
          };
        }
        dispatch(UserConfig(updatedUserConfig));
        currentUser?.identityId === userConfiguration?.identityId &&
          navigate("/");
        setOpenCloneAccess(false);
        message.success({
          content: "User Access Updated Successfully!",
          key: "cloneUserAccess",
          duration: 2,
        });
      })
      .catch((err) => {
        message.success({
          content: "ERROR, Something went wrong!",
          key: "cloneUserAccess",
          duration: 2,
        });
        console.error(err);
      });
  }

  return (
    <Modal
      centered
      open={openCloneAccess}
      onCancel={() => setOpenCloneAccess(false)}
      className={`cloneAccessModal ${isDarkMode && "cloneAccessModalDark"}`}
      title={`Clone Access for ${currentUser?.nameOfUser}`}
      closeIcon={<XIcon />}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => setOpenCloneAccess(false)}
          >
            Close
          </MondayButton>
          <MondayButton
            onClick={() => onSave()}
            Icon={<CopyIconWhite />}
            className={
              !selectedUser || !selectedDepartment
                ? `mondayButtonGrey`
                : `mondayButtonBlue`
            }
            disabled={!selectedUser || !selectedDepartment}
          >
            Clone User Access
          </MondayButton>
        </div>
      }
    >
      <InfoText
        {...{
          text: "Select a department and user to clone access from.",
          isDarkMode,
          style: { marginBottom: "20px" },
        }}
      />
      <Form form={form} layout="vertical">
        <InputComponent
          {...{
            type: "select",
            formItemName: "department",
            label: "Department",
            placeholder: "Select Department",
            customOptions: allDepartments?.map(({ departmentName }) => ({
              label: departmentName,
              value: departmentName,
            })),
            dropdownClassName: isDarkMode && "darkDropDown",
            onChange: onChangeDepartment,
            initialValue: selectedDepartment,
            required: true,
            onClear: () => {
              form.setFieldsValue({ user: "" });
              setSelectedUser("");
              setSelectedDepartment(null);
            },
          }}
        />
        {selectedDepartment && (
          <InputComponent
            {...{
              type: "select",
              formItemName: "user",
              label: "Users",
              placeholder: "Select a User...",
              customOptions: allUsers
                ?.filter(
                  ({ departmentName }) => departmentName === selectedDepartment
                )
                ?.map(({ nameOfUser, identityId }) => ({
                  label: nameOfUser,
                  value: identityId,
                })),
              onChange: (v) => {
                setSelectedUser(v);
              },
              initialValue: selectedUser,
              required: true,
              showSearch: true,
            }}
          />
        )}
      </Form>
    </Modal>
  );
};

export default CloneUserAccess;
