import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { PlusIcon } from "../../../../assets";
import { SignatureFilled } from "@ant-design/icons";
import Card from "./components/ServiceTemplates/Card";
import { MondayButton } from "../../../commonComponents";
import React, { useState, useEffect, useMemo } from "react";
import findTemplateIssues from "./helpers/findTemplateIssues";
import fetchProposalTemplates, {
  tempChangeTemplate,
} from "./helpers/fetchProposalTemplates";
import { ServicesIcon } from "./components/SingleServiceDrag";
import ServicesSort from "./components/ServicesSort/ServicesSort";
import { GreenCheckMarkIcon } from "../../../../containers/Signup/icons";
import SignatureBuilder from "./components/SignatureBuilder/SignatureBuilder";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";
import SingleServiceDrag from "./components/SingleServiceDrag/SingleServiceDrag";
import ProposalAssistant from "../ProposalHeader/components/ProposalAssistant/ProposalAssitant";
import { ServicesInfo } from "../../../SidebarPages/components/AgGridComponents/ScheduleDays/Components/MoreInfo/MoreInfo";
import GeneralTemplatesSidebar, {
  getMyMadeSenseData,
} from "./components/GeneralTemplates/GeneralTemplatesSidebar";
import {
  getServicesAsHtml,
  processWordHtml,
} from "../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";

import "./ProposalSidebar.scss";

// region ServicesMapHtml
export const ServicesMapHtml = ({ editorRef }) => {
  const { proposalData } = useProposalContext();

  const [htmlTemp, setHtmlTemp] = useState("");
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const onInitialize = () => {
    let temp = {};
    proposalData?.takeOff?.services?.forEach((service) => {
      if (service?.isScope !== false) return;
      let tempHtml = [];
      getServicesAsHtml([service], serviceDefinitions, tempHtml);
      temp[service.serviceId] = {
        data: service,
        html: tempHtml,
      };
    });
    setHtmlTemp(temp);
  };
  useEffect(() => {
    const htmlTemp = getHtmlDataForAllServices(
      proposalData?.takeOff?.takeOffItem,
      proposalData?.estimation,
      serviceDefinitions,
      proposalData?.takeOff?.key
    );
    setHtmlTemp(htmlTemp);
    onInitialize();
  }, [proposalData, serviceDefinitions]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        flexDirection: "row",
      }}
    >
      {!!htmlTemp &&
        Object.values(htmlTemp).map((item) => {
          return (
            <CustomVariableCard
              {...{
                name: item?.data?.label,
                html: item?.html?.[0],
                id: item?.data?.serviceId,
                onDragEnd: (event) => {
                  // const droppedHTML = item?.html?.[0];
                  event.preventDefault();
                  if (editorRef.current) {
                    // const editor = tinymce.get(editorRef.current.id);
                    // editor.execCommand("mceInsertContent", false, droppedHTML);
                  }
                  // return editorRef.current?.editor?.setContent(htmlTemp);
                },
              }}
            />
          );
        })}
    </div>
  );
};

//region body
export const proposalSidebarData = [
  {
    icon: <PlusIcon />,
    component: GeneralTemplatesSidebar,
    key: "GeneralTemplate",
    hoverText: "General Templates",
  },
  {
    icon: <ServicesIcon />,
    component: SingleServiceDrag,
    key: "ServicesHtml",
    hoverText: "Single Service Drag",
  },
  {
    icon: <ServicesIcon />,
    component: ServicesSort,
    key: "ServiceReports",
    hoverText: "Multiple services",
  },
  // {
  //   icon: <PlusIcon />,
  //   key: "ServiceTemplates",
  //   component: ServiceTemplates,
  //   hoverText: "Service Templates",
  // },
  // {
  //   icon: <PlusIcon />,
  //   component: ServicesMapHtml,
  //   key: "ServiceTemplates1",
  //   hoverText: "Service Templates1",
  // },
  {
    icon: <GreenCheckMarkIcon width={18} height={18} />,
    component: ProposalAssistant,
    key: "ProposalAssistant",
    hoverText: "Proposal Assistant",
  },
  {
    icon: <SignatureFilled />,
    component: SignatureBuilder,
    key: "SignatureSidebar",
    hoverText: "Signature Templates",
  },
];

//region ProposalSidebar
function ProposalSidebar({ editorRef }) {
  const { proposalData } = useProposalContext();
  const [templates, setTemplates] = useState({});
  const [chosenView, setChosenView] = useState(false);

  const estimationSample = useMemo(() => {
    let tmpEstimation = { ...(proposalData?.estimation || {}) };
    tmpEstimation.versionServices = {
      [proposalData?.takeOff?.id]: proposalData?.takeOff,
    };
    return tmpEstimation;
  }, [proposalData?.estimation]);

  const issues = useMemo(() => {
    return findTemplateIssues(proposalData, templates?.proposalTemplates);
  }, [proposalData, templates?.proposalTemplates]);

  const onSwitchView = (index) => {
    if (index === chosenView) {
      setChosenView(false);
    } else {
      setChosenView(index);
    }
  };
  const chosenComponentData =
    chosenView === false ? null : proposalSidebarData?.[chosenView];
  const { component: ChosenSidebarComponent, hoverText } =
    chosenComponentData || {};

  function updateTemplate(id, newTemplateContent) {
    tempChangeTemplate(id, templates, setTemplates, newTemplateContent);
  }

  useEffect(() => {
    fetchProposalTemplates().then((res) => {
      setTemplates(res);
    });
  }, [proposalData?.takeOff?.services]);

  return (
    <div className="proposal-builder-sidebar_wrapper">
      <Sidebar
        chosenView={chosenView}
        onSwitchView={onSwitchView}
        proposalSidebarData={proposalSidebarData}
      />
      {chosenComponentData ? (
        <div
          className="main-content"
          style={{
            top: !chosenView ? 0 : chosenView * 40 + 5,
          }}
        >
          <div className="main-content-title">{hoverText}</div>
          <ChosenSidebarComponent
            {...{
              issues,
              editorRef,
              estimationSample,
              serviceTemplates: templates?.serviceTemplates,
              proposalTemplates: templates?.proposalTemplates,
              updateTemplate,
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ProposalSidebar;

//region CustomVariableCard
export const CustomVariableCard = ({
  name,
  id,
  description,
  html,
  onDragEnd,
  onDragStart,
  commandHandler,
  keyDetail,
}) => {
  return (
    <div
      className="proposal-builder_custom_variable_card"
      style={{
        width: "150px",
        padding: "10px",
        display: "flex",
        fontSize: "14px",
        cursor: "pointer",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "center",
        // height: "200px",
        // margin: "8px",
        // backgroundColor: "lightgray",
      }}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={() => {
        commandHandler(keyDetail);
      }}
    >
      <div className="previewIcon">
        <MondayButton hasIcon={false}> test</MondayButton>
      </div>
      {/* Card content */}
      <span>
        <h4 style={{ color: "#fff" }}> {name}</h4>
      </span>
    </div>
  );
};

//region getHtmlDataForAllServices
const getHtmlDataForAllServices = (
  takeOff,
  estimation,
  serviceDefs,
  versionId
) => {
  let tempHTML = processWordHtml({
    gridData: takeOff?.services || [],
    projectAddress: "Test Test",
    serviceDefs,
    accountName: "Test",
    estimation,
    versionId: takeOff?.id,
  });
  console.log("tempHTML", tempHTML);

  return tempHTML;
};
