import { useState } from "react";

import { MondayButton } from "../../../../commonComponents";
import EmailsModal from "../../../../commonComponents/EmailsModal";
import { EmailIcon } from "../../../BasePage/src";

const SafetyInspectionEmails = ({ recordId, categoryName, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MondayButton
        {...{
          Icon: <EmailIcon />,
          className: "mondayButtonBlue",
          onClick: () => setOpen(true),
        }}
      >
        Communications
      </MondayButton>

      <EmailsModal
        title={title}
        open={open}
        setOpen={setOpen}
        recordId={recordId}
        categoryName={categoryName}
      />
    </>
  );
};

export default SafetyInspectionEmails;
