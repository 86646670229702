import "./styles/TaskCommentInput.scss";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Divider, Form } from "antd";
import { getAdminAndTeamUsers } from "../../../../../../../utils";
import { CommentsContainer } from "src/components/commonComponents/Comment";

export const TaskCommentInput = ({
  form,
  mentionedUsers,
  setMentionedUsers,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const selectedDepatments = Form.useWatch("department", form);
  const taskRelatedToObj = Form.useWatch("taskRelatedToObj", form);
  const taskTitle = Form.useWatch("taskTitle", form);
  const taskThread = Form.useWatch("taskThread", form) || [];

  const defaultUsers = useMemo(() => {
    if (userConfiguration?.tasksConfig?.canAssignToOthers) {
      const usersToFilter = !!Array.isArray(
        taskRelatedToObj?.teamsConfiguration
      )
        ? getAdminAndTeamUsers(
            userConfiguration,
            taskRelatedToObj.teamsConfiguration
          )
        : userConfiguration?.allUsers?.Items?.filter(
            ({ isSuspended }) => !isSuspended
          );

      return usersToFilter
        ?.filter(
          (user) =>
            user.cognitoUserId !== userConfiguration.cognitoUserId &&
            selectedDepatments?.includes(user?.departmentName) &&
            !mentionedUsers?.some(
              ({ cognitoUserId }) => cognitoUserId === user.cognitoUserId
            )
        )
        .map((user, index) => ({
          id: user.cognitoUserId,
          value: user.nameOfUser,
          key: index,
        }));
    } else return [];
  }, [
    userConfiguration.allUsers,
    selectedDepatments,
    taskRelatedToObj,
    mentionedUsers,
  ]);

  const commentsChanged = (comments) => {
    form.setFieldValue("taskThread", comments);
  };

  const mentionNotification = ({ mentions }) => {
    setMentionedUsers((prev) => [...prev, ...mentions]);
  };

  return (
    <div className="task-comment-input-section">
      {!!taskThread.length && (
        <Divider>{`Comments To Save (${taskThread.length})`}</Divider>
      )}

      <Form.Item name="taskThread">
        <CommentsContainer
          {...{
            category: "Tasks",
            recordName: taskTitle,
            comments: taskThread,
            commentsChanged,
            usersWithAccess: defaultUsers,
            customMentionNotification: mentionNotification,
          }}
        />
      </Form.Item>
    </div>
  );
};
