import TakeOffStep from "./TakeOffStep";
import EstimationStep from "./EstimationStep";
import ProjectsInfo from "./infoViews/ProjectsInfo/ProjectsInfo";
import { SmallLoadableComp } from "../../../Sidebars/components";

function stepsConfig({
  stepperData,
  proposalType,
  onSelectDataSrc,
  onSelectEstimation,
  createProposalTakeOff,
}) {
  return [
    {
      //region Step 1
      title: stepperData?.sourceType || "Choose Source",
      content: (
        <div className="choose_source_wrapper">
          <SmallLoadableComp loading />
        </div>
      ),
    },
    {
      //region Step 2
      title: stepperData?.selectLabel || "Select Source",
      content: (
        <ProjectsInfo
          {...{
            onSelectDataSrc,
            typeView: proposalType,
            stepperData,
          }}
        />
      ),
    },
    {
      //region Step 3
      title: !!stepperData?.selectedEstimation
        ? "Pick Take Off"
        : "Pick Estimation",
      content: (
        <EstimationStep
          {...{
            stepperData,
            onSelectEstimation,
          }}
        />
      ),
    },
    {
      //region Step 4
      title: "Pick Take Off",
      content: (
        <TakeOffStep
          {...{
            stepperData,
            createProposalTakeOff,
          }}
        />
      ),
    },
  ];
}

export default stepsConfig;
