import { useEffect, useState } from "react";
import { ExportPreview } from "../../../commonComponents";
import { safetyContactsStyles } from "../../Projects/ProjectSafetyReport/helpers";
import SortReportFields from "../SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import {
  convertSafetyReport,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "../SafetyModal/components/SafetyExportPreview/helpers";
import { message } from "antd";

function SafetyCitationsExportPreview({
  showPdfPreview,
  setShowPdfPreview,
  rowObject,
  getFields,
}) {
  const [resolvedFields, setResolvedFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function resolveFields() {
      try {
        setIsLoading(true);
        const fields = await getFields();
        setResolvedFields([
          {
            title: "General",
            body: fields,
          },
        ]);
      } catch (error) {
        console.error("Error resolving fields:", error);
      } finally {
        setIsLoading(false);
      }
    }

    resolveFields();
  }, []);

  if (isLoading) {
    message.loading("PDF Preview is loading");
  }

  return showPdfPreview && !isLoading ? (
    <ExportPreview
      {...{
        open: showPdfPreview,
        onCancel: () => setShowPdfPreview(false),
        modalTitle: "Citation Report",
        documentTitle: rowObject?.citationName,
        getInitialData: async () => resolvedFields,
        keyName: "safetyCitation",
        asyncInitialData: true,
        applyFilters: safetyExportApplyFilters,
        convertData: convertSafetyReport,
        tabs: [
          {
            key: "sort",
            label: "Modify Fields",
            component: SortReportFields,
          },
        ],
        customStyles: { ...SAFETY_REPORT_STYLES, ...safetyContactsStyles },
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: rowObject?.citationName + " Report",
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
          {
            text: {
              paragraphs: [
                {
                  text: "Date: " + new Date().toLocaleDateString(),
                  class: "safety-section-header no-margin",
                  bold: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default SafetyCitationsExportPreview;
