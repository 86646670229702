import { Form } from "antd";
import Signature from "./Signature";
import "./FormSignature.scss";

const FormSignature = ({
  formItemName,
  label,
  initialValue = "",
  signDisabled = false,
  removeDisabled = false,
  signButtonName = "Sign",
  viewClassName = "",
  required = false,
  value = "",
  form,
}) => {
  return (
    <div className="formSignature">
      <Form.Item
        name={formItemName}
        label={
          <span style={{ fontSize: "16px", fontWeight: "600" }}>{label}</span>
        }
        initialValue={initialValue}
        required={required}
        className="labeledInputLabel"
        rules={[
          { required, message: `Please provide a ${label.toLowerCase()}` },
        ]}
      >
        <Signature
          signature={form.getFieldValue(formItemName)}
          setSignature={(value) => {
            form.setFieldValue(formItemName, value);
          }}
          existingSignature={value}
          signDisabled={signDisabled}
          removeDisabled={removeDisabled}
          signButtonName={signButtonName}
          viewClassName={viewClassName}
        />
      </Form.Item>
    </div>
  );
};

export default FormSignature;
