import {
  DatePicker,
  Input,
  Radio,
  Select,
  TimePicker,
  Checkbox,
  AutoComplete,
  Tooltip,
  Cascader,
  InputNumber,
  ColorPicker,
} from "antd";
// import MaskedInput from "antd-mask-input"; //library uninstalled
import PhoneInput from "react-phone-input-2";
import { LabeledInput } from "..";
import { compareIncluding } from "../../../utils";
import AntdTransfer from "./components/AntdTransfer";
import "react-phone-input-2/lib/style.css";
import "./InputComponent.scss";
import { DropDownArrow } from "./assets";
import { PredictInput } from "./components/index";
import PredictAutocomplete from "./components/PredictAutocomplete/PredictAutocomplete";
import QBDateInput from "../QBDateInput/QBDateInput";
import { getRangePresets } from "./utils";
import QBTimeInput from "../QBDateInput/QBTimeInput";
import { DatePickerMoment, FetchedOptions } from "../../../../commonComponents";
import CustomTimeInput from "../../../../pages/Payroll/Tabs/DEG/components/CustomTimeInput/CustomTimeInput";
import { useResponsive } from "../../../../../hooks";
import { useSelector } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const InputComponent = ({
  label,
  customLabel,
  form,
  type = "",
  formItemName,
  placeholder = "",
  initialValue,
  noFormItem = false,
  className = "",
  style = {},
  required = false,
  disabled = false,
  tooltip,
  status = "",
  defaultValue,
  rules = [],
  optionType = "default",
  dropdownRender,
  dropdownClassName = "",
  dropdownStyle = {},
  options = [],
  optionGroups = [],
  autoSuggestWords = [],
  onChange,
  modifyValue,
  onSelect,
  onDeselect,
  showArrow = true,
  indeterminate = false,
  autoFocus = false,
  placement = "bottomLeft",
  onBlur = () => {},
  onDropdownVisibleChange = () => {},
  onClear = () => {},
  optionFilterProp = "children",
  filterOption = compareIncluding(type, "select")
    ? (input, option) =>
        (typeof option?.label === "string"
          ? option.label
          : typeof option?.children === "string"
          ? option?.children
          : ""
        )
          ?.toLowerCase()
          ?.includes(input?.toLowerCase())
    : (inputValue, option) =>
        option?.title?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) > -1,
  picker,
  showSearch = true,
  disabledDate,
  disabledHours,
  disabledMinutes,
  maxLength,
  value,
  buttonStyle,
  typeNumber,
  addonAfter,
  addonBefore,
  selectClassName,
  showNow = false,
  hideDisabledOptions = false,
  rows = 2,
  id = "",
  showTime = false,
  format = "",
  minuteStep = 1,
  suffixIcon,
  suffix,
  popoverVisible = false,
  popoverTitle = "",
  popoverContent,
  popoverPlacement,
  popoverClassName,
  popoverStyle,
  onOk,
  mode = "",
  mask = "",
  defaultChecked = false,
  onSearch,
  dataSource = [],
  typePassword = false,
  render = (item) => item?.title,
  targetKeys,
  setTargetKeys,
  allowClear = true,
  onPressEnter,
  prefix,
  onKeyDown,
  customOptions,
  filterSort = undefined,
  addNewShow = true,
  addNewClick = () => {},
  defaultOpen = false,
  typeTel = false,
  /**
   * set getPopupContainer to document.body when the
   * height of the modal cuts the height of the dropdown
   */
  getPopupContainer = document.body,
  onlyUsPrefix = true,
  popupClassName,
  notFoundContent = undefined,
  onTabEnter = () => {},
  onPopupScroll = () => {},
  inputAllowClear = false,
  validTime = "all",
  maxTagCount = undefined,
  maxTagPlaceholder = undefined,
  customAutocompleteFilterOption = undefined,
  min,
  max,
  dataTestid,
  onOpenChange = () => {},
  tooltipCategory,
  tooltipKey,
  hasDatePicker,
  validationDate,
  customClassName,
  showLabel,
  customOnChange,
  rangePresets = getRangePresets(),
  onInputKeyDown = () => {},
  autoComplete = "off",
  setUserTime = false,
  ...rest
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { mobile } = useResponsive();

  return (
    <LabeledInput
      {...{
        label: type?.toLowerCase() === "checkbox" ? "" : customLabel || label,
        formItemName,
        initialValue,
        noFormItem:
          type?.toLowerCase() === "dateinput" ||
          type?.toLowerCase() === "timeinput"
            ? true
            : noFormItem, // noFormItem is always true for QBDateInput and QBTimeInput as it has it's own form item
        className,
        style:
          type?.toLowerCase() === "textarea"
            ? { ...style, width: "100%" }
            : style,
        required,
        tooltip,
        rules,
        popoverVisible,
        popoverTitle,
        popoverContent,
        popoverPlacement,
        popoverClassName,
        popoverStyle,
        valuePropName: type?.toLowerCase() === "checkbox" ? "checked" : "value",
        tooltipCategory,
        tooltipKey,
        ...rest,
      }}
    >
      {type?.toLowerCase() === "autocomplete" ? (
        <AutoComplete
          allowClear={allowClear}
          placeholder={placeholder}
          value={value}
          options={customOptions}
          onSelect={onSelect}
          onChange={onChange}
          disabled={disabled}
          popupClassName={`dynamicDropdownInputComponent ${
            isDarkMode && "darkDropDown"
          } ${dropdownClassName}`}
          popupMatchSelectWidth={false}
          onSearch={onSearch}
          initialValue={defaultValue}
          notFoundContent={notFoundContent}
          onClear={onClear}
          onPopupScroll={onPopupScroll}
          dropdownStyle={dropdownStyle}
          autoFocus={autoFocus}
          defaultOpen={defaultOpen}
          onBlur={onBlur}
          filterOption={customAutocompleteFilterOption}
          data-testid={dataTestid}
          {...(dropdownRender ? { dropdownRender } : {})}
        >
          {options}
          {optionGroups}
        </AutoComplete>
      ) : type?.toLowerCase() === "phone" ? (
        <PhoneInput
          disableCountryGuess
          // disableInitialCountryGuess
          {...{
            country: "us",
            // preferredCountries: !!!onlyUsPrefix ? ["al", "us", "il", "xk"] : [],
            onlyCountries: onlyUsPrefix ? ["us"] : [],
            disableCountryCode: onlyUsPrefix ? true : false,
            // countryCodeEditable: false,
            placeholder,
            disabled,
            specialLabel: "",
            copyNumbersOnly: false,
            onKeyDown,
            onChange,
            inputStyle: style,
            ["data-testid"]: dataTestid,
          }}
        />
      ) : type === "fetchOptions" ? (
        <FetchedOptions
          formItemName={formItemName}
          onChange={onChange}
          label={null}
          defaultValue={defaultValue}
          modifyValue={modifyValue}
          {...rest}
        />
      ) : type?.toLowerCase() === "select" ||
        type?.toLowerCase() === "styledmultiselect" ? (
        <Select
          {...rest}
          ref={rest.inputRef}
          id={id ?? undefined}
          data-testid={dataTestid}
          defaultValue={defaultValue}
          allowClear={allowClear}
          showSearch={showSearch}
          onSelect={onSelect}
          autoFocus={autoFocus}
          placement={placement}
          placeholder={placeholder}
          optionFilterProp={optionFilterProp}
          filterOption={filterOption}
          filterSort={filterSort}
          onDropdownVisibleChange={onDropdownVisibleChange}
          getPopupContainer={(triggerNode) => {
            if (getPopupContainer) {
              return getPopupContainer;
            } else {
              return triggerNode.parentElement;
            }
          }}
          notFoundContent={notFoundContent}
          className={
            type?.toLowerCase() === "styledmultiselect"
              ? `multiSelectDesign ${className}`
              : selectClassName
          }
          disabled={disabled}
          mode={type?.toLowerCase() === "styledmultiselect" ? "multiple" : mode}
          onChange={onChange}
          onDeselect={onDeselect}
          onClear={onClear}
          popupClassName={`${dropdownClassName} dynamicDropdownInputComponent ${
            isDarkMode && "darkDropDown"
          }`}
          dropdownStyle={dropdownStyle}
          options={
            customOptions?.length === 0
              ? []
              : customOptions?.every((option) => typeof option === "string")
              ? customOptions?.map((option, i) => ({
                  key: i,
                  label: option,
                  value: option,
                }))
              : customOptions
          }
          value={initialValue}
          loading={options?.length === 0 || customOptions?.length === 0}
          onPopupScroll={onPopupScroll}
          onSearch={onSearch}
          {...(type?.toLowerCase() === "select" && {
            ...rest,
            defaultOpen,
            maxTagCount,
            maxTagPlaceholder,
            onBlur,
          })}
          {...(dropdownRender ? { dropdownRender } : {})}
          {...(!showArrow
            ? { suffixIcon: null }
            : { suffixIcon: suffixIcon ?? <DropDownArrow /> })}
          onInputKeyDown={onInputKeyDown}
        >
          {options}
          {optionGroups}
        </Select>
      ) : type?.toLowerCase() === "textarea" ? (
        <Input.TextArea
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          type={typeNumber ? "number" : typePassword ? "password" : "text"}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={onChange}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          style={style ? style : {}}
          data-testid={dataTestid}
          {...rest}
        />
      ) : type?.toLowerCase() === "datepicker" ? (
        <DatePicker
          picker={picker}
          allowClear={allowClear}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabledDate={disabledDate}
          popupClassName={`${dropdownClassName} ${
            isDarkMode && "darkDateDropDown"
          }`}
          onOpenChange={onOpenChange}
          autoFocus={autoFocus}
          defaultOpen={defaultOpen}
          // onKeyDown={onKeyDown}
          onChange={onChange}
          format={format || "MM/DD/YYYY"}
          getPopupContainer={(triggerNode) => {
            if (getPopupContainer) {
              return getPopupContainer;
            } else {
              return triggerNode.parentElement;
            }
          }}
          disabled={disabled}
          showTime={showTime}
          value={value}
          onOk={onOk}
          style={style}
          data-testid={dataTestid}
        />
      ) : type?.toLowerCase() === "datepicker-moment" ? (
        <DatePickerMoment
          picker={picker}
          allowClear={allowClear}
          defaultValue={defaultValue}
          disabledDate={disabledDate}
          popupClassName={`${dropdownClassName} ${
            isDarkMode && "darkDropDown"
          }`}
          onChange={onChange}
          format={format || "MM/DD/YYYY"}
          getPopupContainer={(triggerNode) => {
            if (getPopupContainer) {
              return getPopupContainer;
            } else {
              return triggerNode.parentElement;
            }
          }}
          disabled={disabled}
          showTime={showTime}
          value={value}
          onOk={onOk}
          style={style}
          data-testid={dataTestid}
        />
      ) : type?.toLowerCase() === "rangepicker" ? (
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          showTime={showTime}
          onChange={onChange}
          placeholder={placeholder}
          picker={picker}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          format={format || "MM/DD/YYYY"}
          data-testid={dataTestid}
          popupClassName={`${dropdownClassName} ${
            isDarkMode && "darkDateDropDown"
          }`}
          style={style}
          presets={rangePresets}
          allowClear={allowClear}
          {...rest}
        />
      ) : type?.toLowerCase() === "radio" ? (
        <Radio.Group
          id={id}
          onChange={onChange}
          optionType={optionType}
          buttonStyle={buttonStyle}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          data-testid={dataTestid}
        >
          {disabled
            ? options.map((option, index) => (
                <Tooltip title="This field is view only">{option}</Tooltip>
              ))
            : options}
        </Radio.Group>
      ) : type?.toLowerCase() === "radiobutton" ? (
        <Radio.Group
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          buttonStyle={buttonStyle}
          data-testid={dataTestid}
        >
          {options.map((option) => (
            <Radio.Button
              id={option.value}
              value={option.value}
              onClick={option?.onClick ? option.onClick : () => {}}
              popupClassName={`${popupClassName} ${
                isDarkMode && "darkDropDown"
              }`}
              style={popoverStyle}
            >
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      ) : type?.toLowerCase() === "timepicker" ? (
        <TimePicker
          defaultValue={defaultValue}
          popupClassName={`${dropdownClassName} ${
            isDarkMode && "darkDropDown"
          }`}
          format={format || "HH:mm"}
          showNow={showNow}
          disabledTime={() => {
            return {
              disabledHours,
              disabledMinutes,
            };
          }}
          open={rest?.open}
          // disabledHours={disabledHours}
          // disabledMinutes={disabledMinutes}
          getPopupContainer={(triggerNode) => {
            if (!!getPopupContainer) {
              return getPopupContainer;
            } else {
              return triggerNode.parentElement;
            }
          }}
          onChange={onChange}
          disabled={disabled}
          hideDisabledOptions={hideDisabledOptions}
          minuteStep={minuteStep}
          value={value}
          allowClear={allowClear}
          data-testid={dataTestid}
          onSelect={onSelect}
        />
      ) : type === "customTimeInput" ? (
        <CustomTimeInput
          {...{
            form,
            formItemName,
            className,
            onChange,
            label,
            required,
            rules,
            dataTestid,
            ...rest,
          }}
        />
      ) : type?.toLowerCase() === "dateinput" ? (
        <QBDateInput
          {...{
            form,
            required,
            disabledDate,
            validTime,
            formItemName,
            defaultValue,
            disabled,
            customOnChange,
            ...rest,
          }}
        />
      ) : type?.toLowerCase() === "timeinput" ? (
        <QBTimeInput
          {...{
            form,
            required,
            validTime,
            formItemName,
            defaultValue,
            disabled,
            disabledHours,
            disabledMinutes,
            customOnChange,
            setUserTime,
            label,
            ...rest,
          }}
        />
      ) : // : type?.toLowerCase() === "custom" ? (
      //   <MaskedInput
      //     mask={mask}
      //     placeholder={placeholder}
      //     autoComplete="off"
      //     onChange={onChange}
      //     maxLength={maxLength}
      //     addonAfter={addonAfter}
      //     addonBefore={addonBefore}
      //     disabled={disabled}
      //   />
      // )
      type?.toLowerCase() === "checkbox" ? (
        <Checkbox
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          indeterminate={indeterminate}
          data-testid={dataTestid}
        >
          {label}
        </Checkbox>
      ) : type?.toLowerCase() === "checkboxgroup" ? (
        <Checkbox.Group
          options={customOptions}
          onChange={onChange}
          // disabled={disabled}
          defaultValue={defaultValue}
          // indeterminate={indeterminate}
          data-testid={dataTestid}
        >
          {label}
        </Checkbox.Group>
      ) : type?.toLowerCase() === "transfer" ? (
        <AntdTransfer
          dataSource={dataSource}
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          showSearch={showSearch}
          filterOption={filterOption}
          onSearch={onSearch}
          element={render}
          onChange={onChange}
          data-testid={dataTestid}
        />
      ) : type?.toLowerCase() === "predict" ? (
        <PredictInput
          value={value}
          prefix={prefix}
          suffix={suffixIcon}
          onPressEnter={onPressEnter}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          type="text"
          addonAfter={addonAfter}
          addonbefore={addonBefore}
          disabled={disabled}
          {...(!!noFormItem ? { defaultValue: initialValue } : {})}
          defaultValue={defaultValue}
          autoSuggestWords={autoSuggestWords}
          onTabEnter={onTabEnter}
          form={form}
          formItemName={formItemName}
          data-testid={dataTestid}
        />
      ) : type?.toLowerCase() === "predictautocomplete" ? (
        <PredictAutocomplete
          popupClassName={"predict-auto-dropdown"}
          customOptions={customOptions}
          allowClear={allowClear}
          placeholder={placeholder}
          defaultValue={defaultValue}
          style={style}
          onClear={onClear}
          onChange={onChange}
          onSelect={onSelect}
          addNewShow={addNewShow}
          addNewClick={addNewClick}
          disabled={disabled}
          value={value}
          autoFocus={autoFocus}
          defaultOpen={defaultOpen}
          data-testid={dataTestid}
          onBlur={onBlur}
        />
      ) : type?.toLowerCase() === "cascader" ? (
        <Cascader
          options={options}
          allowClear={allowClear}
          showSearch={showSearch}
          placeholder={placeholder}
          data-testid={dataTestid}
        />
      ) : type?.toLowerCase() === "otp" ? (
        <Input.OTP
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
          {...rest}
        />
      ) : type?.toLowerCase() === "number" ? (
        <InputNumber
          ref={rest.inputRef}
          style={style}
          min={min}
          max={max}
          value={value}
          type="number"
          prefix={prefix}
          suffix={suffix}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          changeOnWheel={true}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          data-testid={dataTestid}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          {...rest}
        />
      ) : type?.toLowerCase() === "color" ? (
        <ColorPicker
          format="hex"
          defaultFormat="hex"
          {...rest}
          onChange={(color) =>
            rest?.onChange && rest.onChange(color.toHexString())
          }
        />
      ) : !!typePassword ? (
        <Input.Password
          data-testid={dataTestid}
          id={id}
          style={style}
          status={status}
          ref={rest?.inputRef}
          onKeyDown={onKeyDown}
          prefix={prefix}
          suffix={suffixIcon}
          onPressEnter={onPressEnter}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          maxLength={maxLength}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          visibilityToggle={typePassword}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          disabled={disabled}
          onBlur={onBlur}
          allowClear={inputAllowClear} // Don't move this prop Input must have a separate allowClear prop than select
          {...(!!noFormItem ? { defaultValue: initialValue } : {})}
          className={mobile ? "search-input table-view" : ""}
        />
      ) : (
        <Input
          data-testid={dataTestid}
          id={id}
          style={style}
          status={status}
          ref={rest?.inputRef}
          onKeyDown={onKeyDown}
          prefix={prefix}
          suffix={suffixIcon}
          onPressEnter={onPressEnter}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          maxLength={maxLength}
          onWheel={(e) => e.target.blur()}
          type={
            typeNumber
              ? "number"
              : typePassword
              ? "password"
              : typeTel
              ? "tel"
              : "text"
          }
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          disabled={disabled}
          visibilityToggle={typePassword}
          onBlur={onBlur}
          allowClear={inputAllowClear} // Don't move this prop Input must have a separate allowClear prop than select
          {...(!!noFormItem ? { defaultValue: initialValue } : {})}
          className={mobile ? "search-input table-view" : ""}
          inputMode={typeTel ? "numeric" : ""}
        />
      )}
    </LabeledInput>
  );
};

export default InputComponent;
