import { API } from "aws-amplify";

/**
 * This function performs an optimized database query by utilizing {indexName} as a secondary index.
 * @param {{tableName: string, indexName: string, filterKey: string, filterValue: any}} param
 */
export async function gsiQueryTable({
  tableName,
  indexName,
  filterKey,
  filterValue,
}) {
  let lastEvalKey;
  let responseData = [];
  while (true) {
    const res = await API.get("queryTable", "/queryTable", {
      queryStringParameters: {
        tableName,
        indexName,
        filterKey,
        filterValue,
        lastEvaluatedKey: lastEvalKey,
      },
    });

    responseData = responseData.concat(res?.items || []);
    lastEvalKey = res.lastEvaluatedKey;
    if (!lastEvalKey) {
      break;
    }
  }
  return responseData;
}
