import { Modal, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useCallback, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import DegModalContext from "../DegModalContext";
import { teamShiftColumns } from "./teamShiftModalData";
import ShiftEditModal from "../ShiftEditModal/ShiftEditModal";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";

import "./TeamShiftsModal.scss";

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

function TeamShiftsModal({ open, onCancel = () => {} }) {
  const { isDarkMode, crewTeams } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();
  const [detailsModal, setDetailsModal] = useState(false);
  const [gridSearchInput, setGridSearchInput] = useState("");

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
  }, []);

  function addShiftHandler() {
    if (gridApi) {
      let selectedTeams = gridApi.getSelectedNodes().map(({ data }) => data);
      if (!selectedTeams.length) {
        message.warning("Please select a team to add shifts");
        return;
      }
      setDetailsModal(
        selectedTeams.flatMap(({ crewForeman, crewMembers }) => {
          let tmpArray = [];
          if (crewForeman?.crewId) {
            tmpArray?.push(crewForeman?.crewId);
          }
          for (const member of crewMembers) {
            const { crewId } = member;
            if (!!crewId) {
              tmpArray.push(crewId);
            }
          }
          return tmpArray;
        })
      );
    }
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setGridSearchInput(e);
  }

  function onModalCancel() {
    onCancel();
    updateDocumentTitle(); // reset document title to "Lead Manager";
  }

  function afterOpenChange(event) {
    if (event) {
      updateDocumentTitle({ newTitle: "Add Shifts For Teams" });
    }
  }

  return (
    <Modal
      {...{
        open,
        centered: true,
        afterOpenChange,
        closeIcon: <XIcon />,
        onCancel: onModalCancel,
        title: "Add Shifts For Teams",
        wrapClassName: "teamShiftModalWrap",
        ["data-testid"]: "team-shift-modal",
        className: `teamShiftModal ${isDarkMode && "teamShiftModalDark"}`,
        footer: (
          <MondayButton Icon={<AddIcon />} onClick={addShiftHandler}>
            Add Shift
          </MondayButton>
        ),
      }}
    >
      <InputComponent
        showSearch
        type="input"
        value={gridSearchInput}
        placeholder="Search Team..."
        style={{ maxWidth: 300 }}
        prefix={<SearchOutlined />}
        onChange={(e) => onFilterTextChange(e?.target?.value)}
      />
      <div
        className={`teamsGridContainer ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            onGridReady,
            defaultColDef,
            pagination: true,
            rowData: crewTeams,
            paginationPageSize: 8,
            rowSelection: "multiple",
            columnDefs: teamShiftColumns,
            suppressRowClickSelection: false,
          }}
        />
      </div>
      {detailsModal && (
        <ShiftEditModal
          {...{
            mode: "NEW",
            open: !!detailsModal,
            selectedMembers: detailsModal,
            onCancel() {
              setDetailsModal(false);
              onCancel();
            },
          }}
        />
      )}
    </Modal>
  );
}

export default TeamShiftsModal;
