import "./CommentsContainer.scss";
import CommentInput from "../components/CommentInput/CommentInput";
import { useSelector } from "react-redux";
import { Divider } from "antd";
import { v4 } from "uuid";
import CommentContent from "./CommentContent/CommentContent";
import broadcastNotification from "src/helpers/controllers/broadcastNotification";
import { recursivelyFindAndReplaceObj } from "../../../../utils";

const CommentsContainer = ({
  category = "Comments",
  recordName = "No Record Name",
  comments = [],
  commentsChanged = () => {},
  usersWithAccess,
  customMentionNotification,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const defaultUsersWithAccess = (userConfiguration?.allUsers?.Items || [])
    .filter(({ isSuspended }) => !isSuspended)
    .map(({ cognitoUserId: id, nameOfUser: value }, index) => ({
      id,
      value,
      key: index,
    }));

  //avoid me as user
  const updatedAssignedUsers = (
    usersWithAccess || defaultUsersWithAccess
  ).filter(({ id }) => id && id !== userConfiguration?.cognitoUserId);

  // function to send notification to mentioned user
  const mentionNotification = (commentId, mentions) => {
    //cehck for custom notification function
    if (!!customMentionNotification) {
      customMentionNotification({ mentions });
    } else {
      // 10 is topicId of Notifications for Mentions
      broadcastNotification(
        "10",
        "onCommentMention",
        [
          {
            common: userConfiguration?.nameOfUser,
            commonNext: category.toUpperCase(),
            commonName: recordName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            commentId,
            cognitos: mentions.map(({ cognitoUserId }) => cognitoUserId),
          },
        ],
        window.location.pathname.substring(1)
      );
    }
  };

  //Comment
  const saveComment = ({ inputValue, mentions, isPrivateComment }) => {
    const commentId = v4();
    !isPrivateComment &&
      mentions.length > 0 &&
      mentionNotification(commentId, mentions);

    const commentObj = {
      commentId,
      author: userConfiguration.nameOfUser,
      commentContent: inputValue,
      cognitoUserId: userConfiguration?.cognitoUserId,
      createdAt: Date.now(),
      replies: [],
      mentions,
      repliesVisibility: false,
      privateOnlyTo: !!isPrivateComment ? userConfiguration?.cognitoUserId : "",
    };

    commentsChanged([...comments, commentObj]);
  };

  const updateComment = ({
    comment: { commentId },
    inputValue,
    mentions,
    isPrivateComment,
  }) => {
    !isPrivateComment &&
      mentions.length > 0 &&
      mentionNotification(commentId, mentions);

    const updatedComments = comments.map((comment) => {
      return comment.commentId === commentId
        ? {
            ...comment,
            ...(isPrivateComment && {
              privateOnlyTo: userConfiguration.cognitoUserId,
            }),
            mentions,
            commentContent: inputValue,
            commentChanges: [
              {
                previous: comment.commentContent,
                current: inputValue,
                updatedAt: Date.now(),
              },
              ...(comment?.commentChanges || []),
            ],
          }
        : comment;
    });
    commentsChanged(updatedComments);
  };

  const deleteComment = (commentId) => {
    commentsChanged(
      comments.filter((thread) => thread.commentId !== commentId)
    );
  };

  //Comment reply
  const postReply = ({ comment, inputValue, mentions }) => {
    mentions.length > 0 && mentionNotification(comment.commentId, mentions);

    const updatedReply = {
      repliesVisibility: false,
      replies: [
        ...(comment.replies || []),
        {
          commentId: v4(),
          author: userConfiguration?.nameOfUser,
          cognitoUserId: userConfiguration?.cognitoUserId,
          mentions,
          replyContent: inputValue,
          createdAt: Date.now(),
          replies: [],
        },
      ],
    };

    const updatedComments = recursivelyFindAndReplaceObj(
      comments,
      "commentId",
      comment.commentId,
      updatedReply,
      "replies"
    );

    commentsChanged(updatedComments);
  };

  const updateReply = ({ comment, inputValue, mentions }) => {
    mentions.length > 0 && mentionNotification(comment.commentId, mentions);

    const updatedReply = {
      replyContent: inputValue,
      commentChanges: [
        {
          previous: comment.replyContent,
          current: inputValue,
          updatedAt: Date.now(),
        },
        ...(comment?.commentChanges || []),
      ],
      mentions,
    };

    const updatedComments = recursivelyFindAndReplaceObj(
      comments,
      "commentId",
      comment.commentId,
      updatedReply,
      "replies"
    );

    commentsChanged(updatedComments);
  };

  const deleteReply = (commentId) => {
    const updatedComments = recursivelyFindAndReplaceObj(
      comments,
      "commentId",
      commentId,
      false,
      "replies"
    );

    commentsChanged(updatedComments);
  };

  return (
    <div
      className={`all-comments-container ${
        isDarkMode ? "CommentsContainerDark" : ""
      }`}
    >
      <div className="current-comments">
        {comments?.map((comment, index) => {
          return (
            <CommentContent
              {...{
                key: index,
                thread: comment,
                usersWithAccess: updatedAssignedUsers,
                updateComment,
                deleteComment,
                postReply,
                updateReply,
                deleteReply,
              }}
            />
          );
        })}
      </div>
      <div
        className="comments-left-side"
        style={{ position: "sticky", top: "0" }}
      >
        <Divider>New Comment</Divider>

        <CommentInput
          {...{
            placeholder: "Add new comment!",
            usersWithAccess: updatedAssignedUsers,
            saveComment,
          }}
        />
      </div>
    </div>
  );
};

export default CommentsContainer;
