import { useMemo } from "react";
import { InputComponent } from "../../../../Fleet/components";
import { Tooltip } from "antd";

/**
 * Renders the "SafetyAssignedTo" component.
 *
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} setAssignedTo - The function to set the assigned user.
 * @param {boolean} disabledAllFields - Indicates whether all fields are disabled.
 * @returns {JSX.Element} The rendered component.
 */
function SafetyAssignedTo({
  isDarkMode = false,
  userConfiguration = {},
  setAssignedTo = () => {},
  disabledAllFields = false,
  assignedTo = [],
}) {
  const filterUsersByAccess = useMemo(() => {
    return userConfiguration?.allUsers?.Items?.reduce(
      (acc, user) =>
        !!user?.routeConfig?.find(
          ({ title, write }) => title === "Safety" && write === true
        ) &&
        user.cognitoUserId !== userConfiguration.cognitoUserId &&
        !user?.isSuspended
          ? [
              ...acc,
              {
                cognitoUserId: user?.cognitoUserId,
                nameOfUser: user?.nameOfUser,
                identityId: user?.identityId,
                userName: user?.userName,
              },
            ]
          : acc,
      []
    );
  }, [userConfiguration?.allUsers?.Items, userConfiguration.cognitoUserId]);

  function onSelect(_, data) {
    const { key, value, label, ...rest } = data;
    setAssignedTo((prev) => [...(prev || []), { ...rest, nameOfUser: label }]);
  }

  function onDeselect(value, data) {
    setAssignedTo((prev) =>
      (prev || [])?.filter((item) => item?.identityId !== value)
    );
  }

  return (
    <InputComponent
      mode="multiple"
      required
      showSearch
      label="Assign To"
      dropdownClassName={isDarkMode && "darkDropDown"}
      type="select"
      formItemName="assignedTo"
      initialValue={assignedTo?.map(({ identityId = "" }) => identityId)}
      onSelect={onSelect}
      onClear={() => setAssignedTo([])}
      onDeselect={onDeselect}
      customOptions={filterUsersByAccess?.map(
        ({ cognitoUserId, nameOfUser, identityId, userName }, i) => ({
          key: i,
          label: nameOfUser,
          value: identityId,
          identityId: identityId,
          cognitoUserId: cognitoUserId,
          userName: userName,
        })
      )}
      disabled={disabledAllFields}
      maxTagCount={2}
      maxTagPlaceholder={(e) => {
        return (
          <Tooltip title={e.map(({ label }) => label).join(", ")}>
            <span>{`+ ${e.length} more`}</span>
          </Tooltip>
        );
      }}
    />
  );
}

export default SafetyAssignedTo;
