import { useState } from "react";
import { useSelector } from "react-redux";
import { useDynamicViewContext } from "../DynamicView/utils/dynamicViewContext";
import CommunicationHeader from "./components/communication-header/CommunicationHeader";
import CommunicationSidebar from "./components/communication-sidebar/CommunicationSidebar";
import { CommunicationProvider } from "./providers/CommunicationProvider";

import "./Communication.scss";

// categoryName and recordId are provided inside each DynamicView where the communication will be shown and must be provided because that's how data get fetched.

const Communication = ({ categoryName, recordId, hideHeader = false }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { isLeadEditable = true } = useDynamicViewContext();
  const [filterObject, setFilterObject] = useState();
  const [searchValue, setSearchValue] = useState("");

  return (
    // Shared states between communication's components like: selectedEmail & authData
    <CommunicationProvider>
      <div
        className={`communication-container 
          ${isDarkMode && "CommunicationContainerDark"}
          ${hideHeader && "hide-header"}
        `}
      >
        <CommunicationHeader
          isLeadEditable={isLeadEditable}
          setSearchValue={setSearchValue}
          filterObject={filterObject}
          setFilterObject={setFilterObject}
        />

        <CommunicationSidebar
          searchValue={searchValue}
          filterObject={filterObject}
          categoryName={categoryName}
          recordId={recordId}
        />
      </div>
    </CommunicationProvider>
  );
};
export default Communication;
