export const departmentPageStepData = [
  {
    title: "Department Search",
    id: "#SearchDepartments",
  },
  {
    title: "User Search",
    id: "#SearchUser",
  },
  {
    title: "Department Card",
    id: "#departmentCard",
  },
  {
    title: "Add Department",
    id: "#addDepartment",
  },
];
