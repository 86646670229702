import {
  get_item_Scheduled,
  get_ProgressDimensionsByServiceId,
  totalProgress_forItem,
} from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { getItemProgress } from "./calculators";
import { iterateServicesInPLILevel } from "../../../Header/forms/Scheduling/helpers/iterators";
import _ from "lodash";
import { TypeOfWorkDefinitions } from "../models/TypeOfWorkDefinitions";
import { breakdown_dimension } from "../../../Header/forms/Scheduling/PLIPerService/componentsForPli/Breakdown/breakdown";
import moment from "moment-timezone";

export const ProgressStatistics = {
  elevationTotal(elevation, serviceId) {
    let scheduled = 0,
      progress = 0;
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

    for (const pli of elevation?.items || []) {
      scheduled += get_item_Scheduled(pli, { d1, d2 }) || 0;
      progress += totalProgress_forItem(pli) || 0;
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  elevationTotal2(elevation, serviceId, typeOfWork) {
    //with progress in days
    let scheduled = 0,
      progress = 0;
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

    for (const pli of elevation?.items || []) {
      scheduled += get_item_Scheduled(pli, { d1, d2 }) || 0;
      progress += pli?.["totalProgress"] || 0; //getItemProgress(pli, {d1, d2}) || 0
      //PS. pli["totalProgress"] is short way. The proper way is to calculate pli or subplis progress by day
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },

  serviceTotal(service) {
    let scheduled = 0,
      progress = 0;
    for (const elevation of service?.serviceOptions?.[0] || []) {
      const total = this.elevationTotal2(elevation, service?.serviceId);
      scheduled += total.scheduled;
      progress += total.progress;
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  estimationTotal(services) {
    let scheduled = 0,
      progress = 0,
      perc = 0;

    const availableServices =
      services?.filter((s) => !s?.isNotAppliedForProgress) || [];

    for (const service of availableServices) {
      const total = this.serviceTotal(service);

      scheduled += total.scheduled;
      progress += total.progress;
      perc += total?.progressPercentage || 0;
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);
    let pr = Number((perc / availableServices?.length || 0).toFixed(2)) || 0;

    return {
      scheduled,
      progress,
      progressPercentage: pr,
      remaining,
      remainingPercentage,
    };
  },
  scheduleTotal(toBeScheduled) {
    let scheduled = 0,
      progress = 0;

    if (toBeScheduled === undefined) {
      scheduled = NaN;
      progress = NaN;
    } else {
      for (const services of Object.values(toBeScheduled || {}) || []) {
        const total = this.estimationTotal(services);

        scheduled += total.scheduled;
        progress += total.progress;
      }
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  manySchedulesTotal(schedules, typeOfWork) {
    //     console.log(schedules)
    let scheduled = 0,
      progress = 0;

    if (schedules === undefined) {
      scheduled = NaN;
      progress = NaN;
    } else {
      for (const schedule of schedules) {
        if (typeOfWork !== schedule.typeOfWork) continue; //only measure for schedules of this type
        const total = this.scheduleTotal(schedule?.toBeScheduled, typeOfWork);

        scheduled += total.scheduled;
        progress += total.progress;
      }
    }
    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  scheduleDaysTotal(scheduleDays) {
    let scheduled = 0,
      progress = 0;

    for (const scheduleDay of scheduleDays) {
      const total = this.estimationTotal(scheduleDay.toBeScheduled);

      scheduled += total?.scheduled;
      progress += total?.progress;
    }

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  scheduleDifferences(toBeScheduled, toBeScheduled_toCompare) {
    let scheduled = 0,
      progress = 0;

    for (const single_toBeScheduled of toBeScheduled_toCompare) {
      const total = this.scheduleTotal(single_toBeScheduled);

      scheduled += total.scheduled;
      progress += total.progress;
    }

    const statisticsToSubtract = this.scheduleTotal(toBeScheduled);

    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(statisticsToSubtract.scheduled, progress);

    return {
      scheduled: statisticsToSubtract.scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  getItemStatistics(item, { d1, d2 }) {
    let scheduled = get_item_Scheduled(item, { d1, d2 }) || 0;
    let progress = getItemProgress(item, { d1, d2 }) || 0;
    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);
    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
  getStatistics(scheduled, progress) {
    let progressPercentage, remaining, remainingPercentage;

    progressPercentage = Math.round((progress / scheduled) * 100 || 0);
    //    console.log(scheduled)

    remaining = scheduled - progress;

    remainingPercentage = 100 - progressPercentage;

    return { progressPercentage, remaining, remainingPercentage };
  },
  scheduled: {
    getElevation(elevation, serviceId) {
      let scheduled = 0;
      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

      for (const pli of elevation?.items || []) {
        //   console.log(pli)
        scheduled += get_item_Scheduled(pli, { d1, d2 }) || 0;
      }
      return scheduled;
    },
    getService(service) {
      let scheduled = 0;
      if (service.serviceOptions.length > 0) {
        for (const elevation of service.serviceOptions[0]) {
          scheduled += this.getElevation(elevation, service.serviceId);
        }
      }
      return scheduled;
    },
    getEstimation(services) {
      let scheduled = 0;

      for (const service of services) {
        scheduled += this.getService(service);
      }

      return scheduled;
    },
    getAllEstimations(servicesByEstimation) {
      let scheduled = 0;
      for (const services of Object.values(servicesByEstimation)) {
        scheduled += this.getEstimation(services);
      }
      return scheduled;
    },
  },
  // pli: {
  //   processSchedules({ project, schedules }) {
  //     let pliList = [];
  //     let servicesWithProgressTrack = _.cloneDeep(project.services);

  //     let variablesNeeded = { schedules, pliList, servicesWithProgressTrack };

  //     iterateServicesInPLILevel({
  //       toBeScheduled: project.services,
  //       actionFunction: put_Pli_with_ProgressTrack,
  //       variablesNeeded,
  //     });

  //     //   console.log(variablesNeeded.servicesWithProgressTrack)
  //   },
  // },
  projectStatistics({ project, schedules, typeOfWork }) {
    //     console.log(schedules)
    const scheduled = project?.services
        ? this.scheduled.getAllEstimations(project.services)
        : NaN,
      progress = this.manySchedulesTotal(schedules, typeOfWork).progress;
    const { progressPercentage, remaining, remainingPercentage } =
      this.getStatistics(scheduled, progress);

    return {
      scheduled,
      progress,
      progressPercentage,
      remaining,
      remainingPercentage,
    };
  },
};

export const put_Pli_with_ProgressTrack = ({
  pli,
  serviceAddon,
  variablesNeeded,
}) => {
  const { schedules, pliList, servicesWithProgressTrack, indexes } =
    variablesNeeded;
  //pli here is pli in project services

  for (const schedule of schedules) {
    let daysMap = {}; //d1: 07/05/2021
    for (const scheduleDay of schedule.scheduleDays) {
      daysMap[scheduleDay.id] = new Date(
        scheduleDay.startDate
      ).toLocaleDateString();
    }

    const {
      estimationId,
      estimationNumber,
      serviceId,
      label,
      elevationId,
      elevationIndex,
      pliId,
    } = indexes;

    try {
      let pli_in_schedule = schedule?.toBeScheduled?.[estimationId]
        ?.find((s) => s?.serviceId === serviceId)
        ?.serviceOptions?.[0]?.[elevationIndex]?.items?.find(
          (pli) => pli?.id === pliId
        );

      let pli_with_progressTrack = servicesWithProgressTrack?.[estimationId]
        ?.find((s) => s?.serviceId === serviceId)
        ?.serviceOptions?.[0]?.[elevationIndex]?.items?.find(
          (pli) => pli?.id === pliId
        );

      if (pli_with_progressTrack.progressTrack === undefined)
        pli_with_progressTrack.progressTrack = {};

      for (const progressDay of Object.values(pli_in_schedule?.progressByDay)) {
        // console.log(daysMap, "!!!!!!!!!!!!!!!!!!!!!!!")
        const progressDate = daysMap[progressDay.day];

        if (pli_with_progressTrack.progressTrack[progressDate] === undefined)
          pli_with_progressTrack.progressTrack[progressDate] = {};

        pli_with_progressTrack.progressTrack[progressDate][
          schedule.scheduleId
        ] = progressDay.progress;
        //  console.log(pli_with_progressTrack)
      }
    } catch (e) {
      // console.log(e)
    }
  }
};

/**
 * pliToWork - pli that we will create
 * pli - pli from old schedules
 * */
export const sumTotalProgress = ({
  variablesNeeded: { iteratingScheduleData, scheduleDays },
  pli,
  typeOfWork,
  pliToWork,
  serviceAddonToWork,
  serviceAddon,
}) => {
  if (serviceAddonToWork && serviceAddon) {
    pli = serviceAddon;
    pliToWork = serviceAddonToWork;
  }
  // console.log(pli)
  if (pliToWork.addons) {
    for (let addonToWork of pliToWork?.addons) {
      const addon = pli?.addons?.find((a) => a?.id === addonToWork?.id);
      if (addon)
        sumTotalProgress({
          variablesNeeded: { iteratingScheduleData, scheduleDays },
          pli: addon,
          pliToWork: addonToWork,
          typeOfWork,
        });
    }

    // console.log(pliToWork)
  }

  if (pliToWork?.breakdownValue) {
    // console.log(pliToWork)
    for (let breakdownToWork of pliToWork?.breakdownValue) {
      const old_breakdown = pli?.breakdownValue?.find(
        (b) => b?.name === breakdownToWork?.name
      );

      if (isNaN(breakdownToWork[breakdown_dimension]))
        breakdownToWork[breakdown_dimension] = 100;
      //we don't have any progress variable for breakdowns by default. So we are adding it here manually
      if (old_breakdown)
        sumTotalProgress({
          variablesNeeded: { iteratingScheduleData, scheduleDays },
          pli: old_breakdown,
          pliToWork: breakdownToWork,
          typeOfWork,
        });
    }

    // console.log(pliToWork)
  }

  let old_pliTotalProgress = parseInt(pli["totalProgress"]);
  if (isNaN(old_pliTotalProgress)) old_pliTotalProgress = 0;

  // if(isNaN(old_pliTotalProgress) || old_pliTotalProgress === 0) return

  if (isNaN(pliToWork?.totalProgress)) pliToWork.totalProgress = 0;

  if (pliToWork?.history === undefined) pliToWork.history = [];

  // if(pliToWork.breakdownValue) {
  //     console.log(pliToWork, pli)
  // }

  if (typeOfWork === TypeOfWorkDefinitions?.INSTALLATION?.label) {
    pliToWork.totalProgress += old_pliTotalProgress;
  } else if (typeOfWork === TypeOfWorkDefinitions?.REMOVAL?.label) {
    pliToWork.totalProgress += old_pliTotalProgress;
  } else if (typeOfWork === TypeOfWorkDefinitions?.REPAIR?.label) {
    pliToWork.totalProgress -= old_pliTotalProgress;
  } else if (typeOfWork === TypeOfWorkDefinitions?.INSPECTION?.label) {
    pliToWork.totalProgress += old_pliTotalProgress;
  } else {
    throw new Error("sumTotalProgress: Specify type of work");
  }

  let { progressByDay = [] } = pli;

  // console.log(scheduleDays)
  for (const dayData of progressByDay) {
    dayData.scheduleDay = scheduleDays?.find(
      (scheduleDay) => scheduleDay?.id === dayData?.day
    );
  }

  pliToWork.navigate({
    ...iteratingScheduleData,
    pliTotalProgress: old_pliTotalProgress,
    progressByDay,
  });

  // if(serviceAddonToWork.id === 1) console.log(serviceAddonToWork.totalProgress)
};
