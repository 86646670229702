import { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
// import dayjs from "dayjs";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import getDocWarnings from "../../../../../SidebarPages/Documentation/View/components/DocumentationListCard/components/DocWarningsModal/helpers/getDocWarnings";
// import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";
// import { InfoLabel } from "../../../../../SidebarPages/Fleet/view/pageView/components/ProjectOverviewScreen/components";
// import { generateDocumentViewLink } from "../../../../../SidebarPages/utils"
import "./UploadedFilesRenderer.scss";
// import { FilePreviewModal } from "../../../../../SidebarPages/Documentation/View/components";
import { hexToRgba } from "../../../../../SidebarPages/utils";
import { CarouselModal } from "src/components/SidebarPages/Inspections/view/modals";
import DocWarningsModal from "../../../../../SidebarPages/Documentation/View/components/DocumentationListCard/components/DocWarningsModal/DocWarningsModal";
import { FilePreviewModal } from "../../../../../SidebarPages/Documentation/View/components";
import { LoadableComp } from "../../../../../SidebarPages/XComponents";
// const dateFormat = "MM/DD/YYYY";

const UploadedFilesRenderer = ({
  documentation,
  node: {
    data: { googleDriveUploads = [], amendmentDriveUploads = [] },
  },
  hasDelete = false,
  accessToken,
  editModal = false,
  updateGoogleDriveFolderId,
  fromDoc,
  isForProjectDocs = false,
}) => {
  const [visible, setVisible] = useState(false);
  // const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentationWarnings, setDocumentationWarnings] = useState(false);

  const driveRequest = driveApi({ accessToken });
  const { getParents } = driveRequest;

  const arrayToChoose = !!amendmentDriveUploads.length
    ? amendmentDriveUploads
    : googleDriveUploads.length
    ? googleDriveUploads
    : [];

  const onDelete = (currentFile) => {
    driveRequest.deleteDriveItem(currentFile.fileId).then(() => {
      updateGoogleDriveFolderId(currentFile.fileId);
    });
  };

  const formatFiles = async (arr = []) => {
    return await Promise.all(
      arr?.map(async ({ name, id, mimeType, url, lastEditedUtc }) => {
        return await driveRequest.getImageSrc(id).then((imageObj) => {
          return { ...imageObj, fileId: id };
        });
        // return {
        //   label: (
        //     <p
        //       className="fileName"
        //       onClick={() => {
        //         setPreviewModalVisible(true);
        //         driveRequest
        //           .getImageSrc(id)
        //           .then((image) =>
        //             setCurrentFile({ ...image, mimeType, url, id })
        //           );
        //       }}
        //     >
        //       {name}
        //     </p>
        //   ),
        //   id: id,
        //   name: name,
        //   value: dayjs(lastEditedUtc).format(dateFormat),
        // };
      })
    );
  };

  const getFile = async (fileId) => {
    try {
      const fileResponse = await driveRequest?.getDriveItem(fileId);
      const file = fileResponse ? await fileResponse.json() : null;

      const size = await driveRequest.getFileSize(fileId);

      return file ? { ...file, size } : null;
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  const changeCarouselSlide = async (index) => {
    const { name, mimeType, size, id } = await getFile(files[index].fileId);

    const formattedFile = {
      ...files[index],
      type: "document",
      mimeType,
      index,
      name,
      size,
      id,
    };
    setCurrentFile(formattedFile);
  };

  useEffect(() => {
    if (!!arrayToChoose.length && visible) {
      setLoading(true);
      formatFiles(arrayToChoose)
        .then(async (formattedFiles) => {
          setFiles(formattedFiles);

          const { name, mimeType, size, id } = await getFile(
            formattedFiles[0].fileId
          );

          const formattedFile = {
            ...formattedFiles[0],
            index: 0,
            type: "document",
            mimeType,
            name,
            size,
            id,
          };
          setCurrentFile(formattedFile);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  }, [
    JSON.stringify(googleDriveUploads),
    JSON.stringify(amendmentDriveUploads),
    visible,
  ]);

  useEffect(() => {
    if (
      !!documentation &&
      Object.keys(documentation || {})?.length > 0 &&
      visible
    ) {
      const fetchWarnings = async () => {
        const docWarnings = await getDocWarnings([documentation], getParents);
        setDocumentationWarnings(docWarnings);
      };

      fetchWarnings();
    }
  }, [JSON.stringify(documentation), visible]);

  // Show the Warning Modal only if file status is 'Not In Drive' ( file has been deleted )
  let showWarnings = false;
  if (!!documentationWarnings && documentationWarnings?.length > 0) {
    showWarnings = documentationWarnings.some((doc) =>
      doc.googleDriveUploads.some((upload) => upload.status === "Not in Drive")
    );
  }

  const disabledColor = hexToRgba("#1a3a6a", 0.4);
  return (
    <>
      <Tooltip
        title={!!arrayToChoose?.length ? "" : "there are no files to show"}
      >
        <Button
          data-testid="show-files-button"
          type="primary"
          onClick={() => !!arrayToChoose?.length && setVisible(true)}
          className={showWarnings ? "mondayButtonGrey" : ""}
          style={
            !!arrayToChoose?.length
              ? {}
              : {
                  backgroundColor: disabledColor,
                  borderColor: disabledColor,
                  cursor: "not-allowed",
                }
          }
        >
          {editModal
            ? `See ${arrayToChoose?.length} existed Files`
            : arrayToChoose?.length}
        </Button>
      </Tooltip>

      {visible && showWarnings ? (
        <DocWarningsModal
          {...{
            visible: visible,
            setVisible: setVisible,
            docWarnings: documentationWarnings,
            accessToken,
            setDocumentationWarnings,
            fromDoc,
            callbackFn: () => {},
            proppedCorrectFolderId: documentation.folderId,
          }}
        />
      ) : isForProjectDocs ? (
        currentFile ? (
          <FilePreviewModal
            {...{
              visible: visible ? "carousel" : false,
              setVisible: () => {
                setCurrentFile(null);
                setVisible(false);
              },
              file: currentFile,
              downloadProgress: loading,
              progressVisible: loading,
              carouselLength: files.length > 1 ? files.length : false,
              changeCarouselSlide,
            }}
          />
        ) : (
          <LoadableComp loading={loading} />
        )
      ) : (
        <CarouselModal
          {...{
            visible,
            setVisible,
            shownImages: files,
            isDraft: hasDelete,
            onDelete,
            loading,
          }}
        />
      )}
    </>
  );
};

export default UploadedFilesRenderer;
