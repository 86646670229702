import { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FloatButton } from "antd";

import VirtualAssistantContext from "./data/VirtualAssistantContext";
import { ASSISTANT_STATUSES } from "./data";
import { validateImage, updateAssistantMessages } from "./utils";
import { driveApi } from "../../../integrations/DriveRequest";
import {
  StoreType,
  DriveFile,
} from "../../SidebarPages/FleetMaintenanceView/types";
import { AutomationIconFilled } from "../../OnBoarding/assets/icons";
import { AssistantChatContainer } from "./components";
import { updateUserImages } from "../../../actions";
import { UserType } from "../../SidebarPages/FleetMaintenanceView/types";

import "./VirtualAssistant.scss";
import { useOpenAiAssistant } from "./hooks/useOpenAiAssistant";
import { getCommonQuestions } from "./utils/getCommonQuestions";
import { useLocalStorage } from "../../../utils";
import { INACTIVITY_LIMIT_MS, SESSION_KEY } from "./data/constants";
import { useCustomerWebsocketContext } from "../../../contexts/WebsocketProvider/useCustomerWebsocket";
import { useRedux } from "../../SidebarPages/hooks";

/**
 * @typedef AssistantMessageType
 * @property {string} messageId
 * @property {string} text
 * @property {string|undefined} senderId
 * @property {string} senderName
 * @property {string|undefined} roomId
 * @property {string|undefined} roomName
 * @property {boolean} botMessage
 * @property {string} parentFolderId
 * @property {DriveFile[]} attachments
 * @property {boolean} messageRetried
 * @property {number} createdAt
 * @property {"sent"|"error"|"typing"|"pending"} messageStatus
 */

/**
 * @typedef VirtualAssistantProps
 * @property {boolean} [floatButton=true]
 *
 * @param {VirtualAssistantProps} props
 */
function VirtualAssistant(props) {
  //#region HOOKS
  const { floatButton = true } = props;

  const { isDarkMode } = useSelector(
    /** @param {StoreType} state */
    (state) => state.darkMode
  );
  const { userConfiguration } = useSelector(
    /** @param {StoreType} state */
    (state) => state.userConfig
  );
  const { accessToken } = useSelector(
    /** @param {StoreType} state */
    (state) => state.accessToken
  );
  const [chatOpen, setChatOpen] = useState(false);
  const [openChatbot, setOpenChatbot] = useRedux("chatbot", false);

  const [connectedUser, setConnectedUser] = useState(
    /** @type {UserType|undefined} */ (undefined)
  );
  const [status, setStatus] = useState(
    /** @type {typeof ASSISTANT_STATUSES[number]} */ ("BotConnecting")
  );

  const [chatResized, setChatResized] = useState(false);
  const [profileRequests, setProfileRequests] = useState(
    /** @type {string[]} */ ([])
  );

  const [commonQuestions, setCommonQuestions] = useState([]);
  const [chatSession, setChatSession] = useLocalStorage(SESSION_KEY);

  const { finalWsConnection } = useCustomerWebsocketContext();

  const {
    messages,
    setMessages,
    sendMessage,
    createThread,
    retryMessage,
    closeSession,
    sendFeedback,
  } = useOpenAiAssistant({ setStatus });

  const dispatch = useDispatch();
  const driveRequest = driveApi({ accessToken });

  const getProfileImage = useCallback(
    /**
     * @param {string} driveFileId
     * @param {string} userId
     * @returns {Promise<void>}
     */
    async (driveFileId, userId) => {
      //#region GET PROFILE IMAGE
      if (!driveFileId || !accessToken) {
        return;
      }

      return driveRequest
        .getImageSrc(driveFileId)
        .then(async (res) => {
          return validateImage(res.src).then(() => {
            return res;
          });
        })
        .then((res) => {
          dispatch(
            updateUserImages({
              [userId]: res.src,
            })
          );
        });
    },
    [accessToken]
  );

  const questions = async () => {
    const data = await getCommonQuestions();
    if (data) setCommonQuestions(data.questions || []);
  };

  const updateSessionMessages = (messages) => {
    const updatedSession = {
      ...chatSession,
      messages,
      lastAccess: new Date().toISOString(),
    };
    setChatSession(updatedSession);
  };

  const sendNewMessage = async (message) => {
    await sendMessage(message).then((response) => {
      if (response) updateSessionMessages(response);
    });
  };

  const updateLastAccessTime = () => {
    if (chatSession) {
      const updatedSession = {
        ...chatSession,
        lastAccess: new Date().toISOString(),
      };
      setChatSession(updatedSession);
    }
  };

  useEffect(() => {
    if (chatSession && openChatbot) {
      const now = new Date().getTime();
      const lastAccess = new Date(chatSession.lastAccess).getTime();
      if (now - lastAccess > INACTIVITY_LIMIT_MS) {
        localStorage.removeItem(SESSION_KEY);
      } else {
        updateLastAccessTime();
      }
    }
  }, [openChatbot]);

  const updateMessages = useCallback(
    /** @param {AssistantMessageType} message */
    (message) => {
      //#region UPDATE MESSAGES
      if (!userConfiguration?.cognitoUserId) {
        return;
      }

      setMessages((prev) => {
        return updateAssistantMessages(prev, message);
      });
      const updatedSession = {
        ...chatSession,
        messages,
        lastAccess: new Date().toISOString(),
      };
      setChatSession(updatedSession);
    },
    [userConfiguration?.cognitoUserId]
  );

  const startNewSession = () => {
    const newSession = { messages: [], lastAccess: new Date().toISOString() };
    setChatSession(newSession);
    setMessages([]);
  };

  const startSessionAndCreateThread = async () => {
    if (!chatSession) startNewSession();
    if (finalWsConnection?.readyState === WebSocket.OPEN) {
      setStatus("Online");
      questions();
    }
  };

  useEffect(() => {
    if (openChatbot) {
      startSessionAndCreateThread();
    }

    if (finalWsConnection?.readyState === WebSocket.CLOSED) {
      setStatus("Offline");
    }
  }, [finalWsConnection?.readyState, openChatbot]);

  useEffect(() => {
    //#region PROFILE REQUESTS
    if (!userConfiguration) {
      return;
    }

    const usersSet = new Set(messages.map(({ senderId }) => senderId));
    if (usersSet.size > profileRequests.length) {
      const users = Array.from(usersSet);
      const prevUsers = new Set(profileRequests);

      setProfileRequests(users);

      for (const userId of usersSet) {
        if (userId === userConfiguration.cognitoUserId) {
          continue;
        }

        if (!prevUsers.has(userId)) {
          const fileId = userConfiguration.allUsers?.Items?.find(
            ({ cognitoUserId }) => cognitoUserId === userId
          )?.googleDriveFileId;

          getProfileImage(fileId, userId);
        }
      }
    }
  }, [userConfiguration, messages, profileRequests, getProfileImage]);

  useEffect(() => {
    //#region GET PROFILE EFFECT
    if (!userConfiguration?.googleDriveFileId) {
      return;
    }

    getProfileImage(
      userConfiguration.googleDriveFileId,
      userConfiguration.cognitoUserId
    ).catch((err) => {
      console.log("Error getting user profile image: ", err);
    });
  }, [userConfiguration?.googleDriveFileId, getProfileImage]);

  const updateConnectedUser = useCallback(
    /** @param {string} userId */
    async (userId) => {
      //#region CONNECTED USER
      if (!userConfiguration) {
        return;
      }

      const user = userConfiguration.allUsers?.Items?.find(
        ({ cognitoUserId }) => cognitoUserId === userId
      );

      try {
        await getProfileImage(user?.googleDriveFileId, user?.cognitoUserId);
      } catch (err) {
        console.log("Error getting user image: ", err);
      }

      setConnectedUser(user);
    },
    [userConfiguration, getProfileImage]
  );

  const contextValue = useMemo(() => {
    //#region CONTEXT VALUE
    return {
      status,
      messages,
      chatResized,
      connectedUser,
      updateMessages,
      sendMessage: sendNewMessage,
      commonQuestions,
      retryMessage,
      sendFeedback,
      updateConnectedUser,
      createThread,
      async closeAssistant() {
        // setChatOpen(false);
        await closeSession();
        setOpenChatbot(false);
        // setTimeout(() => {
        //   setStatus("Online");
        //   setMessages(chatSession?.messages || []);
        //   setConnectedUser(undefined);
        // }, 1000);
      },
      async newChatSession() {
        await closeSession();
      },
      async employeeConnectHandler() {
        // return new Promise(async (resolve) => {
        //   setStatus("Connecting");
        //   setTimeout(async () => {
        //     await updateConnectedUser("127cdf64-bcc2-4987-9d73-268c033b03d2");
        //     setStatus("Connected");
        //     setMessages([]);
        //     resolve();
        //   }, 1500);
        // });
      },
      toggleResize() {
        setChatResized((prev) => !prev);
      },
    };
  }, [
    status,
    messages,
    chatResized,
    connectedUser,
    updateMessages,
    commonQuestions,
    userConfiguration,
    updateConnectedUser,
    sendNewMessage,
    retryMessage,
    sendFeedback,
    createThread,
  ]);

  async function onClick() {
    //#region ON CLICK
    // setChatOpen((prev) => !prev);
    openChatbot ? setOpenChatbot(false) : setOpenChatbot(true);
  }

  //#region JSX
  return (
    <VirtualAssistantContext.Provider value={contextValue}>
      {floatButton ? (
        <FloatButton.Group
          trigger="click"
          type="primary"
          shape="square"
          data-testid="assistant-float-button"
          className={`virtual-assistant-group ${
            isDarkMode ? "virtual-assistant-group-dark" : ""
          } ${openChatbot ? "assistant-open" : "assistant-closed"}`}
          open={openChatbot}
          onClick={onClick}
          icon={<AutomationIconFilled />}
        >
          <AssistantChatContainer />
        </FloatButton.Group>
      ) : (
        <AssistantChatContainer />
      )}
    </VirtualAssistantContext.Provider>
  );
}

export default VirtualAssistant;
