import { Badge, Button, Checkbox, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { JumpProgressModal } from "./JumpProgressModal";
import _ from "lodash";
import { filterItemsByJump } from "../../../../../../SidebarPages/Scheduling/helpers/filters";
import {
  breakInspProgress,
  swalToast,
} from "../../../SchedulingFirstPage/helperData";
import { typeOfWorkColors } from "../../../DataAgGrid/columnDefs";

export const JumpsButtons = ({
  elevation,
  scheduleDays,
  serviceId,
  elevationIndex,
  setHoistPLis,
  typeOfWork,
  editMode,
  typeOfRemoval,
  serviceDefinitions,
  hoistDriveIds,
  updateProgressLogs,
  estimationId,
  elevationId,
  estimation,
  serviceName,
  elevationStatus,
  updateBreakdownLogs,
}) => {
  const [activeJump, set_activeJump] = useState(false);

  const onJumpClick = (name, jumps) => {
    if (jumps?.length === 0) {
      message.error("No floors found for this jump!");
      setTimeout(() => {
        message.info("Please go to configuration and add floors!");
      }, 2000);
    } else {
      let selectedJump = _.cloneDeep(elevation)?.jumps?.find?.(
        (j) => j?.name === name
      );
      set_activeJump(selectedJump);
    }
  };

  const onJumpModalClose = (
    save,
    name,
    { breakdownValue, jump, progressGivenByBreakdowns, modifiedItems } = {}
  ) => {
    const elevation_copy = _.cloneDeep(elevation);
    if (save) {
      for (let i = 0; i < elevation?.jumps?.length; i++) {
        if (elevation_copy?.jumps[i]?.name === activeJump?.name) {
          elevation_copy.jumps[i] = jump;
        }
      }
      const itemsOfJump = filterItemsByJump(elevation_copy?.items, activeJump);

      for (let item of itemsOfJump) {
        const modItem = modifiedItems?.find((f) => f?.floor === item?.floor);
        const { days = [] } = modItem;
        item.days = [
          ...new Set([
            ...(jump?.days || []),
            ...(breakdownValue?.flatMap?.(({ days }) => days) || []),
          ]),
        ];
        item.progressByDay = jump?.progressByDay;
        item.totalProgress =
          typeOfWork === "Inspection"
            ? breakdownValue
                ?.map?.(({ progressByDay = [], rate = 0 }) =>
                  breakInspProgress(
                    typeOfWork,
                    progressByDay,
                    rate,
                    breakdownValue
                  )
                )
                ?.reduce?.((a, b) => a + b, 0)
            : jump?.progressByDay
                ?.flatMap?.((el) => el?.progress)
                ?.filter?.((q) => q?.floor === modItem?.floor)
                ?.reduce?.((a, b) => a + b?.progress, 0);
        item.breakdownValue = breakdownValue;
        item.typeOfProgress = "jumpBreakdown";
        item.jump = jump?.name;
      }

      setHoistPLis(elevation_copy, { serviceId, elevationIndex });
      swalToast({
        icon: "success",
        title: "Saved successfully!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3500,
      });
    }
    set_activeJump(false);
  };

  const infoToolTipForJump = (jump) => {
    return {
      "Completed Inspections":
        jump?.breakdownValue?.filter(
          (el) =>
            el?.progressByDay?.reduce(
              (a, b) => a + b?.breakdown_dimension,
              0
            ) === 100
        )?.length || 0,
      "Total inspections": jump?.breakdownValue?.length || 0,
    };
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        width: "100%",
        height: 30,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      {elevation?.jumps?.map?.((j, i) => {
        const jumps = j?.floors?.flatMap?.((el) => {
          return elevation?.items?.filter?.(
            (item) => item?.floor === el?.floor
          );
        });
        const isJumpCompleted =
          jumps?.length > 0
            ? jumps?.every(
                ({ totalProgress = 0 }) => Number(totalProgress) === 100
              )
            : false;

        const hasForemanProgress = j?.progressByDay?.some((el) =>
          el?.progress?.some((q) => !!q?.foreman)
        );

        return (
          <Tooltip
            title={
              typeOfWork === "Inspection"
                ? Object.entries(infoToolTipForJump(j))?.map(([key, value]) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: 300,
                        overflow: "auto",
                        gap: 2,
                      }}
                    >
                      <Badge color="rgb(122, 193, 77)" />
                      <span style={{ fontWeight: 600 }}>{key}</span> : {value}
                    </div>
                  ))
                : null
            }
          >
            <span
              onClick={() => onJumpClick(j?.name, jumps)}
              style={{
                // display: "flex",
                // width: "auto",
                // justifyContent: "center",
                // alignItems: "center",
                // flexDirection: "row",
                // gap: "2rem",
                fontSize: 14,
                color: !isJumpCompleted
                  ? "#1264A3"
                  : typeOfWorkColors[typeOfWork],
                fontWeight: 600,
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              {typeOfWork === "Inspection"
                ? `${j?.name} - (${
                    j?.breakdownValue?.filter(
                      (el) =>
                        el?.progressByDay?.reduce(
                          (a, b) => a + b?.breakdown_dimension,
                          0
                        ) === 100
                    )?.length || 0
                  } / ${j?.breakdownValue?.length || 0})`
                : `${j?.name} 
              ` + (hasForemanProgress ? " (Foreman)" : "")}
            </span>
          </Tooltip>
        );
      })}
      {activeJump && (
        <JumpProgressModal
          {...{
            jump: {
              ...activeJump,
              floors: activeJump?.floors
                ?.filter?.((a) => a?.floor !== 0)
                ?.map?.((el) => ({
                  ...el,
                  floor: el?.floor,
                  progress: 0,
                })),
            },
            elevation,
            scheduleDays,
            onJumpModalClose,
            set_activeJump,
            typeOfWork,
            typeOfRemoval,
            serviceDefinitions,
            editMode,
            hoistDriveIds,
            updateProgressLogs,
            estimationId,
            elevationId,
            estimation,
            serviceName,
            elevationStatus,
            serviceId,
            updateBreakdownLogs,
          }}
        />
      )}
    </div>
  );
};
