import { Button } from "antd";
import React, { useEffect, useState } from "react";
import Reply from "./Reply";

import "./replies-view.scss";

function RepliesView({ replies }) {
  const [showReplies, setShowReplies] = useState(false);
  const [sortedReplies, setSortedReplies] = useState([]);

  useEffect(() => {
    const transformToHierarchy = (replies) => {
      const sortedReplies = replies.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      const map = new Map();
      sortedReplies.forEach((reply, index) => {
        map.set(index, { ...reply, children: [] });
      });

      const result = [];
      sortedReplies.forEach((reply, index) => {
        const currentReply = map.get(index);
        if (index > 0) {
          const parentIndex = index - 1;
          const parentReply = map.get(parentIndex);
          parentReply.children.push(currentReply);
        } else {
          result.push(currentReply);
        }
      });

      return result;
    };

    setSortedReplies(transformToHierarchy(replies));
  }, [replies, showReplies]);

  return (
    <div className="replies-view-container">
      <>
        <button
          onClick={() => setShowReplies((showReplies) => !showReplies)}
          className="show-replies-btn"
        >
          ...
        </button>

        {showReplies && (
          <div className="reply-list-container">
            {sortedReplies.map((item, index) => (
              <Reply key={index} {...item} />
            ))}
          </div>
        )}
      </>
    </div>
  );
}

export default RepliesView;
