import React, { useMemo, useState } from "react";
import { Drawer, Pagination, Badge } from "antd";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { useSelector } from "react-redux";
import "./SessionLogsDrawer.scss";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useDispatch } from "react-redux";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../commonComponents";
import SessionLogItem from "./components/SessionLogItem";
import SessionLogFilters from "./components/SessionLogFilters/SessionLogFilters";
import applySessionLogsFilters from "./helpers/applySessionLogsFilters";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import { isEmpty } from "lodash";
import { updateReduxSession } from "../../../../../reducers/commonsStates/sessionNotificationsReducer";
import useExportHeadersModal from "../../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { sessionLogsPDF, sessionsTableHeaders } from "./helpers/sessionLogsPDF";
import { sessionExportToExcel } from "./helpers/sessionLogsExcel";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);

function SessionLogsDrawer({ visible, setVisible }) {
  const dispatch = useDispatch();
  const { sessionNotifications } = useSelector(
    (state) => state.sessionNotifications
  );
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { allLogs = [], newCount = 0 } = sessionNotifications || {};

  const [quickFilter, setQuickFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openOtherFilters, setOpenOtherFilters] = useState(false);
  const [filters, setFilters] = useState({});

  const allUsersObj = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.reduce((acc, user) => {
        acc[user.identityId] = user;
        return acc;
      }, {}) || {},
    [userConfiguration]
  );

  const totalLogsPerPage = 20;

  const quickFilters = [
    { label: "Today", value: "today", color: "#4a90e2" },
    { label: "Yesterday", value: "yesterday", color: "#f5a623" },
    { label: "This Week", value: "thisWeek", color: "#18c704" },
    { label: "Last Week", value: "lastWeek", color: "#eb4345" },
  ];

  const filteredLogs = useMemo(() => {
    return applySessionLogsFilters({
      allLogs,
      filters,
      quickFilter,
    });
  }, [allLogs, quickFilter, filters]);

  const paginationLogs = useMemo(() => {
    const startIndex = (currentPage - 1) * totalLogsPerPage;
    const endIndex = Math.min(
      startIndex + totalLogsPerPage,
      filteredLogs?.length || 0
    );

    return filteredLogs?.slice(startIndex, endIndex) || [];
  }, [currentPage, totalLogsPerPage, filteredLogs]);

  const clearVisible = useMemo(() => {
    return (
      (Object.keys(filters).length > 0 &&
        Object.keys(filters)?.some((key) => !isEmpty(filters[key]))) ||
      quickFilter !== ""
    );
  }, [filters, quickFilter]);

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData: false,
      onGeneratePDF: (action, getDocDefinition, headersData) =>
        sessionLogsPDF(
          action,
          getDocDefinition,
          headersData,
          filteredLogs,
          base64,
          allUsersObj
        ),
      exportToExcel: (getDocDef, headersData) =>
        sessionExportToExcel(
          getDocDef,
          headersData,
          filteredLogs,
          allUsersObj,
          (type, getDocDefinition) =>
            sessionLogsPDF(
              type,
              getDocDefinition,
              headersData,
              filteredLogs,
              base64
            )
        ),
    });

  return (
    <Drawer
      open={visible}
      onClose={() => {
        setVisible(false);
        dispatch(updateReduxSession("resetNew", {}));
      }}
      destroyOnClose={true}
      width={750}
      closeIcon={<XIcon />}
      className={`logs-session-drawer ${
        isDarkMode ? "logs-session-drawer-dark" : ""
      }`}
      title={
        <div className="session-drawer-title">
          Session Logs
          <ExportOrEmailDropdown
            {...{
              onGeneratePDF: (action, getDocDefinition) => {
                console.log("pdf", { action, getDocDefinition });
                getDocDefinition
                  ? sessionLogsPDF(
                      action,
                      getDocDefinition,
                      sessionsTableHeaders,
                      filteredLogs,
                      base64,
                      allUsersObj
                    )
                  : getHeadersModal(action, getDocDefinition);
              },
              exportGridToExcel: (getDocDefinition) => {
                console.log("excel", { getDocDefinition });
                getDocDefinition
                  ? sessionExportToExcel(
                      // additionalData,
                      false,
                      getDocDefinition,
                      filteredLogs,
                      allUsersObj,
                      (type, getDocDefinition) =>
                        sessionLogsPDF(
                          type,
                          getDocDefinition,
                          sessionsTableHeaders,
                          filteredLogs,
                          base64
                        )
                    )
                  : getHeadersModal(false, getDocDefinition, "excel");
              },
              // uploadExcelToDrive,
              rowData: filteredLogs,
              defaultDocDefinition: true,
              title: "User Sessions Logs",
            }}
          />
          {newCount && currentPage !== 1 ? (
            <Badge
              onClick={() => {
                setCurrentPage(1);
              }}
              className="site-badge-count"
              count={newCount + " new"}
              style={{
                backgroundColor: "#52c41a",
                cursor: "pointer",
              }}
            />
          ) : null}
        </div>
      }
    >
      <div className="session-logs-quick-filters">
        <span className="quick-filters-text">Quick Filters: </span>
        <div className="quick-filters-container">
          {quickFilters.map((filter, index) => (
            <span
              key={index}
              style={{
                backgroundColor:
                  quickFilter === filter.value ? filter.color : "#f4f5f6",
                color: quickFilter === filter.value ? "white" : "black",
                border:
                  quickFilter === filter.value
                    ? `1px solid ${filter.color}`
                    : "1px solid #acacac",
              }}
              className="quick-filters-item"
              onClick={() => {
                setQuickFilter(
                  quickFilter === filter.value ? "" : filter.value
                );
                setCurrentPage(1);
              }}
            >
              {filter.label}
            </span>
          ))}
        </div>
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            onClick: () => setOpenOtherFilters(true),
            Icon: <FilterIcon />,
          }}
        >
          Other Filters
        </MondayButton>
        {clearVisible && (
          <MondayButton
            {...{
              "data-testid": "clear-filters-button",
              onClick: () => {
                setFilters({});
                setQuickFilter("");
              },
              className: "mondayButtonRed",
              Icon: <XIcon />,
            }}
          />
        )}
      </div>
      <div className="session-logs-body">
        {paginationLogs?.map((logItem, index) => (
          <SessionLogItem key={index} {...{ logItem, allUsersObj }} />
        ))}
      </div>
      <Pagination
        current={currentPage}
        pageSize={totalLogsPerPage}
        total={filteredLogs?.length || 0}
        onChange={(page) => setCurrentPage(page)}
        showSizeChanger={false}
      />
      {openOtherFilters && (
        <SessionLogFilters
          {...{
            visible: openOtherFilters,
            setVisible: setOpenOtherFilters,
            setFilters,
            filters,
            logsData: allLogs,
            setQuickFilter,
          }}
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: sessionsTableHeaders,
            title: "User Sessions Logs",
          }}
        />
      )}
    </Drawer>
  );
}

export default SessionLogsDrawer;
