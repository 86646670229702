import { useRef, useMemo, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { filterTables } from "../../../../../../utils";
import { useProgramFields, useResponsive } from "../../../../../../hooks";
import { uuidRegex } from "../../../../Documentation/View/documentationViewData";
import {
  attachTemplate,
  formatObjectSamples,
  mapProppedDataSources,
} from "../../../utils";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import {
  formatSavingReport,
  populateDataSources,
  reportToBase64,
  updateReportToolbar,
} from "../helpers";
import { useEmailBoxContainer } from "../../../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import { message } from "antd";
import { API } from "aws-amplify";

const FIELD_ID = "354gfdgd-5345bcbf-fgjfhjgfh"; // ARJS Templates field ID

function useReport({
  customTemplate,
  customCategoryName,
  sendByEmail = false,
  recordId,
  customData,
  customPartitionKeys = "",
  manualPath,
  rowData,
}) {
  const designerRef = useRef();
  const viewerRef = useRef();
  let location = useLocation();

  const formattedPath = location.pathname.replace(uuidRegex, "");

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [docDefinition, setDocDefinition] = useState();
  const [reportConfiguration, setReportConfiguration] = useState({});

  const activeFilters = {};
  const categoryParameters = [];

  const {
    ["Reports Integration Config"]: reportsIntegrationConfig,
    ["ARJS Templates"]: allTemplates,
  } = useProgramFields();

  const emailBoxProps = useEmailBoxContainer();

  const {
    emailBoxes,
    openEmailBox,
    closeEmailBox,
    selectedDraft,
    setAttachments,
    attachments,
  } = emailBoxProps || {};

  const defaultTemplates = useMemo(
    () => allTemplates.filter((template) => template.defaultTemplate),
    [allTemplates]
  );

  const allDataSources = useMemo(() => {
    if (!sampleObjects) return [];
    const toReturn = formatObjectSamples(sampleObjects);
    return mapProppedDataSources(toReturn) || [];
  }, [sampleObjects]);

  const categoryDataSources = useMemo(() => {
    if (!reportConfiguration) return allDataSources;
    const { datasets = [] } = reportConfiguration;
    return (allDataSources || [])?.filter((ds) => {
      return datasets.some((d) => d?.Query?.DataSourceName === ds?.title);
    });
  }, [allDataSources, reportConfiguration?.datasets]);

  const exportsSettings = useMemo(
    () => ({
      title: report?.reportName,
      filename: report?.reportName,
      author: report?.createdBy,
      printing: "highResolution",
      pdfVersion: "1.5",
    }),
    [report]
  );

  const { categoryName, partitionKeys } = useMemo(
    () =>
      manualPath
        ? reportsIntegrationConfig?.[manualPath]
        : reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location, manualPath]
  );

  const newReport = useMemo(
    () => attachTemplate(report, defaultTemplates),
    [report, defaultTemplates]
  );

  //region Fetch
  const getReport = async () => {
    if (!categoryName) {
      if (customTemplate) {
        setReport(customTemplate);
      }
      return;
    }
    try {
      setIsLoading(true);
      const [reportRes, reportConfigRes] = await Promise.all([
        filterTables("reportsAvailable", "categoryName", categoryName),
        API.get("reportConfiguration", `/reportConfiguration/${categoryName}`),
      ]);

      const defaultReport = reportRes?.find(
        (r) =>
          r.reportName === customCategoryName ||
          r.reportName === categoryName + "Report"
      );

      if (customTemplate) {
        setReport(customTemplate);
      } else {
        setReport(defaultReport);
      }

      const updatedConfig = {
        ...reportConfigRes,
        reportsAvailable: reportRes || [],
      };
      setReportConfiguration(updatedConfig);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      message.error("Error fetching data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const { mobile } = useResponsive();

  //region EmailButton
  const emailButton = useCallback(
    (newReport) => {
      return {
        key: "$emailButton",
        iconCssClass: "mdi mdi-email",
        enabled: true,
        action: async function (item) {
          message.info("Uploading the attachments...");
          if (emailBoxes.length > 0) {
            await closeEmailBox(0);
          }
          setAttachments([
            {
              type: "application/pdf",
              name: `${report?.reportName}.pdf`,
              blob: newReport?.res || docDefinition?.res,
              size: newReport?.size || docDefinition?.size,
            },
          ]);
          openEmailBox(null, mobile);
        },
      };
    },
    [emailBoxes, closeEmailBox, setAttachments, mobile, docDefinition?.res]
  );

  //region onReportChange
  async function onReportChange(r) {
    try {
      const newBase64 = await reportToBase64(r, exportsSettings);
      updateReportToolbar(viewerRef, emailButton(newBase64));

      const newReportBody = formatSavingReport(r, newReport);
      await reportConfig(newReportBody);
      return Promise.resolve();
    } catch (e) {
      console.error("onReportPreview ~ e:", { e });
      message.error("Something went wrong");
      return Promise.resolve();
    }
  }

  //region Designer
  async function getDesignerReport(data) {
    try {
      const { reportId, reportName, reportObj, datasets, reportParameters } =
        newReport || {};
      const isNew = !reportObj;
      const designerComponent = designerRef.current;
      let loadedReport = cloneDeep(await designerComponent.getReport());

      loadedReport = {
        ...loadedReport,
        id: reportId,
        displayName: reportName,
        definition: reportObj?.definition || loadedReport.definition,
      };

      loadedReport.definition.DataSets = datasets;

      designerComponent.setReport(data);
    } catch (error) {
      console.error("Error getting designer report", error);
      message.error("Error getting designer report");
    }
  }
  //region Populate
  async function reportConfig(update = false) {
    try {
      let data = {};
      setIsLoading(true);
      data = await populateDataSources(
        update || newReport,
        categoryParameters,
        accessToken,
        activeFilters,
        recordId,
        customPartitionKeys || partitionKeys,
        customData,
        fetchedData,
        setFetchedData
      );
      // }

      if (sendByEmail) {
        const base64 = await reportToBase64(data, exportsSettings);
        setDocDefinition(base64);
      }
      viewerRef.current?.Viewer.open(data?.definition);
      if (!update && !newReport?.isUpdating) {
        await getDesignerReport(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Something went wrong", error);
      message.error("Something went wrong");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (newReport?.reportId && accessToken) {
      reportConfig();
    }
  }, [newReport, accessToken]);

  useEffect(() => {
    if (!!viewerRef.current && sendByEmail) {
      try {
        viewerRef.current.Viewer.toolbar.addItem(emailButton());
      } catch (error) {
        console.error("Error opening report", error);
        message.error("Error opening report");
      }
    }
  }, [isLoading, docDefinition]);

  return {
    isLoading,
    report,
    isDarkMode,
    exportsSettings,
    designerRef,
    viewerRef,
    allDataSources,
    categoryDataSources,
    onReportChange,
    reportConfiguration,
    emailBoxProps,
    updateHandler: reportConfig,
    setReport,
    categoryName,
    setReportConfiguration,
  };
}

export default useReport;
