import { ExcelIcon } from "../../../../../../../assets";
import { forceToNumber } from "../../../../../../../utils/ActiveReportJsUtils/helpers";
import { MondayButton } from "../../../../../../commonComponents";
import * as XLSX from "xlsx";

const ExportElevationPlanks = ({ dataSrc, priceSheetDataSrc }) => {
    const onExport = () => {
      console.log("Exporting data to excel");
      console.log("ExportElevationPlanks", { dataSrc, priceSheetDataSrc });
  
      const createSheetData = (data, mapping) =>
        data.map((item) =>
          Object.fromEntries(
            Object.entries(mapping).map(([key, accessor]) => [key, accessor(item)])
          )
        );
  
      const SUMMARY_DATA = createSheetData(priceSheetDataSrc, {
        Entity: (item) => item.dataKey || "",
        Value: (item) => forceToNumber(item.dataValue || ""),
        PPU: (item) => forceToNumber(item.dataPPU || ""),
        "Total Price": (item) => forceToNumber(item.dataTotalPrice || ""),
      });
  
      const ELEVATION_DATA = createSheetData(dataSrc, {
        L: (item) => item?.length || "",
        H: (item) => item?.height || "",
        Sqft: (item) => item?.sqft || "",
        BaysLong: (item) => item?.baysLong || "",
        "Frames High": (item) => item?.framesHigh || "",
        "# of Full Decks": (item) => item?.fullDecksNo || "",
        "Full Deck": (item) => item?.fullDeck || "",
        "Bicycle Type": (item) => item?.bicType || "",
        "# Bic Levels": (item) => item?.bicLevelNo || "",
        "Bic Level": (item) => item?.bicLevel || "",
        "Full Deck Every Level": (item) => item?.fullDeckEveryLevel || "",
        "Bic Every Level": (item) => item?.bicEveryLevel || "",
        "Frames High W/O Safety and FD": (item) => item?.framesH || "",
      });
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(SUMMARY_DATA),
        "Summary"
      );
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(ELEVATION_DATA),
        "Elevation Data"
      );
  
      XLSX.writeFile(workbook, "ScaffoldPlanks.xlsx");
    };
  
    return (
      <MondayButton
        className="mondayButtonBlue"
        Icon={<ExcelIcon />}
        onClick={onExport}
      >
        Export Elevation Planks
      </MondayButton>
    );
  };
  
  export default ExportElevationPlanks;
  