import { useMemo, useState } from "react";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { RolesIcon } from ".";
import {
  SearchIcon,
  TrashIcon,
  XIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import TableTemplate from "./TableTemplate/TableTemplate";
import UsersDetails from "../../UserRoles/components/UserDetailsModal/UserDetails";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { MondayButtonIcon } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { RedWarningModal } from "../../../../../../../commonComponents";

function RolesList({
  departmentsList = [],
  selectedDepartment = "",
  setSelectedDepartment,
  deleteRoleHandler,
}) {
  const [searchVal, setSearchVal] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [roleToDelete, setRoleToDelete] = useState("");
  const navigate = useNavigate();

  const totalRoles = departmentsList.reduce(
    (acc, { roles }) => acc + roles.length,
    0
  );
  const headerRender = (depName) => {
    return [
      { name: "Roles of " + depName, flex: 3 },
      { name: "Nr. of Users", flex: 1 },
    ];
  };

  const searchedDepartments = useMemo(() => {
    return departmentsList.filter((dep) =>
      dep.roles.some((role) =>
        role.toLowerCase().includes(searchVal.toLowerCase())
      )
    );
  }, [searchVal, departmentsList]);

  return (
    <div className="dep-list roles-list">
      <div className="dep-list-header">
        <RolesIcon /> <span>{totalRoles + " Roles"} </span>
      </div>
      <div className="roles-list-search">
        <InputComponent
          {...{
            placeholder: "Search Role...",
            value: searchVal,
            onChange: (e) => setSearchVal(e.target.value),
            prefix: <SearchIcon />,
            noFormItem: true,
          }}
        />
        {(selectedDepartment || searchVal) && (
          <MondayButtonIcon
            {...{
              Icon: <XIcon />,
              onClick: () => {
                if (searchVal) {
                  setSearchVal("");
                }
                if (selectedDepartment) {
                  setSelectedDepartment("");
                }
              },
              name: "Show All",
              className: "mondayButtonRed",
            }}
          />
        )}
      </div>
      <div className="roles-list-container">
        {searchedDepartments?.length > 0 ? (
          searchedDepartments.map(
            ({ departmentName, roles = [], users = [] }, i) => {
              return (
                <TableTemplate
                  key={departmentName + i}
                  {...{
                    className: "roles-list-item",
                    header: headerRender(departmentName),
                    data: roles.filter((role) =>
                      role.toLowerCase().includes(searchVal.toLowerCase())
                    ),
                    renderItem: (role, index) => {
                      const userPerRole = users.filter(
                        (u) => u.groupName === role
                      );
                      return (
                        <>
                          <span
                            className="role-users"
                            style={{
                              flex: 3,
                              flexWrap: "nowrap",
                              display: "flex",
                              overflow: "visible",
                              alignItems: "center",
                              gap: 15,
                              width: "fit-content",
                            }}
                          >
                            {userPerRole.length === 0 ? (
                              <Tooltip title="Delete Role" placement={"top"}>
                                <TrashIcon
                                  fill="#FE4C4A"
                                  onClick={() => {
                                    setRoleToDelete({
                                      role,
                                      department: departmentName,
                                    });
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            <span
                              onClick={() => {
                                navigate(
                                  `/settings/rolesAccess/${departmentName}/${role}`
                                );
                              }}
                            >
                              {role}
                            </span>
                          </span>
                          <span
                            className="role-users"
                            onClick={() => {
                              if (users.length > 0) {
                                setSelectedRoleName({
                                  role,
                                  users: userPerRole,
                                });
                              }
                            }}
                            style={{ flex: 1 }}
                          >
                            <Tooltip title="View Users" placement={"left"}>
                              <span className="number-span">
                                {userPerRole.length}
                              </span>
                            </Tooltip>
                          </span>
                        </>
                      );
                    },
                  }}
                />
              );
            }
          )
        ) : (
          <div className="no-data">No Roles Found</div>
        )}
      </div>
      {!!selectedRoleName && (
        <UsersDetails
          {...{
            openUsersDetails: !!selectedRoleName,
            setOpenUsersDetails: () => setSelectedRoleName(""),
            selectedRoleName: selectedRoleName.role + " Role",
            filteredUsers: selectedRoleName.users,
          }}
        />
      )}
      {!!roleToDelete && (
        <RedWarningModal
          {...{
            visible: !!roleToDelete,
            onCancel: () => setRoleToDelete(false),
            deleteModal: true,
            footerProps: {
              onConfirm: async () => {
                await deleteRoleHandler(
                  roleToDelete.role,
                  roleToDelete.department,
                  () => {
                    setRoleToDelete(false);
                  }
                );
              },
            },
            children: "Are you sure you want to delete this Role?",
          }}
        />
      )}
    </div>
  );
}

export default RolesList;
